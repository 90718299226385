import {
  AppNotification,
  AppNotificationCategory,
  CustomerVersion,
} from '@contact/data-access';
import {
  useNotifications,
  useUpdateNotification,
} from '@contact/data-access-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOptionalUser } from '../User';
import {
  useAppStateVisible,
  useStableOptionalUser,
  useUnstableRef,
} from '../Utilities';
import {
  countUnreadNotifications,
  groupNotificationsById,
  isAppNotificationRead,
  mergeNotification,
  mergeNotifications,
  setAppNotificationAsRead,
  sortedNotificationByDate,
  withToken,
} from './notification-util';
import {
  AppNotificationsById,
  AppNotificationContextState,
} from './notification.types';

export interface AppNotificationStoreState extends AppNotificationContextState {
  /**
   * Merges notification data with the current state.
   */
  setNotifications: (notifications: AppNotification[]) => void;
}

/**
 * **Do not use directly!**
 *
 * For internal use by {@link AppNotificationProvider} only.
 *
 * Use {@link useAppNotifications} instead.
 */
export function useAppNotificationStore(
  version: CustomerVersion
): AppNotificationStoreState {
  const { token = '' } = useOptionalUser() || {};
  const { token: tokenStable = '' } = useStableOptionalUser(version) || {};
  const notificationsById = useMemo<AppNotificationsById>(() => ({}), []);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [, setDataNonce] = useState(0); // Used to trigger render after data changes
  const [parseError, setParseError] = useState<unknown>();

  const updateUnreadCount = useCallback(() => {
    setUnreadNotificationCount(countUnreadNotifications(notificationsById));
  }, [notificationsById]);

  const setNotifications = useCallback(
    (newNotifications: AppNotification[]) => {
      try {
        const oldUnreadCount = countUnreadNotifications(notificationsById);

        mergeNotifications(
          notificationsById,
          groupNotificationsById(newNotifications)
        );

        const newUnreadCount = countUnreadNotifications(notificationsById);
        if (newUnreadCount !== oldUnreadCount) {
          updateUnreadCount();
        }
        // Trigger render
        setDataNonce((x) => x + 1);
        setParseError(undefined);
      } catch (error: unknown) {
        setParseError(error);
      }
    },
    [notificationsById, updateUnreadCount]
  );

  // Subscribe to notifications API and merge with local state
  const {
    data: notificationsFromApi,
    refetch,
    isFetching,
    isSuccess: isFetchSuccess,
    error: fetchError,
  } = useNotifications('v1', { token });
  const stableNotificationsFromApi = useUnstableRef(notificationsFromApi);

  const refresh = useCallback(async () => {
    const res = await refetch();
    if (res.data) {
      // Always update local data with remote data when refreshing
      setNotifications(res.data);
    }
  }, [refetch, setNotifications]);

  useEffect(() => {
    if (stableNotificationsFromApi) {
      setNotifications(stableNotificationsFromApi);
    }
  }, [stableNotificationsFromApi, setNotifications]);

  useEffect(() => {
    if (!tokenStable) {
      // Reset notification on logout
      setNotifications([]);
    }
  }, [tokenStable, setNotifications]);

  // Refresh on enter foreground
  const isAppVisible = useAppStateVisible();
  useEffect(() => {
    if (isAppVisible && token) {
      refresh();
    }
  }, [isAppVisible, refresh, token]);

  const addNotification = useCallback(
    (notification: AppNotification) => {
      try {
        const id = notification.notification_id;
        if (!id) {
          throw new Error('Missing notification_id');
        }
        const unreadChanged =
          !notificationsById[id] ||
          isAppNotificationRead(notification) !==
            isAppNotificationRead(notificationsById[id]);
        notificationsById[id] = mergeNotification(
          notificationsById[id],
          notification
        );
        if (unreadChanged) {
          updateUnreadCount();
        }
        // Trigger render
        setDataNonce((x) => x + 1);
        setParseError(undefined);
      } catch (error: unknown) {
        setParseError(error);
      }
    },
    [notificationsById, updateUnreadCount]
  );

  const {
    mutate: updateNotification,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    error: updateError,
  } = useUpdateNotification('v1');

  const markAsRead = useCallback(
    async (notification_id: string) => {
      // Optimistically mark notification as read
      const notification = notificationsById[notification_id];
      if (notification && !isAppNotificationRead(notification)) {
        setAppNotificationAsRead(notification);
        updateUnreadCount();
      }

      // Mark notification as read using API.
      // The mutation will automatically invalidate the fetch query on error.
      await updateNotification(
        {
          notification_id,
          token,
        },
        { onError: () => refresh() }
      );
    },
    [notificationsById, refresh, token, updateNotification, updateUnreadCount]
  );

  const markCategoryAsRead = useCallback(
    async (category: AppNotificationCategory) => {
      // Optimistically mark notifications as read
      let hasChanges = false;
      Object.values(notificationsById).forEach((notification) => {
        if (
          notification.category === category &&
          !isAppNotificationRead(notification)
        ) {
          setAppNotificationAsRead(notification);
          hasChanges = true;
        }
      });
      if (hasChanges) {
        updateUnreadCount();
      }

      // Mark notifications as read using API.
      // The mutation will automatically invalidate the fetch query on error.
      await updateNotification(
        { category, token },
        { onError: () => refresh() }
      );
    },
    [notificationsById, refresh, token, updateNotification, updateUnreadCount]
  );

  const markAllAsRead = useCallback(async () => {
    // Optimistically mark notifications as read
    let hasChanges = false;
    Object.values(notificationsById).forEach((notification) => {
      if (!isAppNotificationRead(notification)) {
        setAppNotificationAsRead(notification);
        hasChanges = true;
      }
    });
    if (hasChanges) {
      updateUnreadCount();
    }

    // Mark all notification as read using API.
    // The mutation will automatically invalidate the fetch query on error.
    await updateNotification({ token }, { onError: () => refresh() });
  }, [
    notificationsById,
    refresh,
    token,
    updateNotification,
    updateUnreadCount,
  ]);

  const notifications = sortedNotificationByDate(notificationsById);
  const contextState: AppNotificationStoreState = {
    notifications,
    addNotification,
    setNotifications,
    markAsRead,
    markCategoryAsRead,
    markAllAsRead,
    unreadNotificationCount,
    refresh,
    isFetching: withToken(isFetching, { token, fallback: true }),
    isFetchSuccess: withToken(isFetchSuccess, { token, fallback: false }),
    fetchError:
      parseError || withToken(fetchError, { token, fallback: undefined }),
    isUpdating: withToken(isUpdating, { token, fallback: true }),
    isUpdateSuccess: withToken(isUpdateSuccess, { token, fallback: false }),
    updateError: withToken(updateError, { token, fallback: undefined }),
  };
  return useUnstableRef(contextState);
}
