import Decimal from 'decimal.js';
import {
  USAGE_TYPE_STANDARD,
  USAGE_TYPE_LOW,
  RATE_UNIT_TYPE_DAY,
  RATE_UNIT_TYPE_WATT,
  SERVICE_ID_ELECTRICITY,
  SERVICE_ID_PIPED_GAS,
} from './constants';

/**
 * Returns true, if has multiple rates for defined usage type.
 * @param usageType
 * @param rates
 * @return {boolean}
 */
export function hasMultipleRates(usageType, rates) {
  return Boolean(
    (usageType === USAGE_TYPE_LOW &&
      rates[0].lowRatesGSTExclusive &&
      rates[0].lowRatesGSTExclusive.length > 3) ||
      (usageType === USAGE_TYPE_STANDARD &&
        rates[0].standardRatesGSTExclusive &&
        rates[0].standardRatesGSTExclusive.length > 3)
  );
}

/**
 * Returns true, if rates are valid.
 * @param rates
 * @return {boolean|*}
 */
export function areRatesValid(rates) {
  if (!rates || !rates.length) {
    return false;
  }
  const hasLowRates = rates.find(
    (rate) => rate.lowRatesGSTExclusive && rate.lowRatesGSTExclusive.length
  );
  const hasStandardRates = rates.find(
    (rate) =>
      rate.standardRatesGSTExclusive && rate.standardRatesGSTExclusive.length
  );
  return Boolean(hasLowRates || hasStandardRates);
}

/**
 * Takes APIRate unit and returns the rate unit type.
 * @param apiRateUnit
 * @return
 */
function getRateType(apiRateUnit) {
  if (apiRateUnit) {
    return apiRateUnit.includes('day')
      ? RATE_UNIT_TYPE_DAY
      : RATE_UNIT_TYPE_WATT;
  }
  return '';
}

/**
 * Formats rates value.
 * @param apiDisplayRate
 * @param apiRateUnit
 * @return
 */
export function getFormattedRate(apiDisplayRate, apiRateUnit) {
  if (!apiRateUnit) return apiDisplayRate;
  const suffix = apiRateUnit.includes('cents') ? 'c' : '';
  return apiDisplayRate + suffix;
}

/**
 * Returns true if low user rate.
 * @param apiUserType
 * @return {boolean}
 */
export function isLowUserRate(apiUserType) {
  return apiUserType === USAGE_TYPE_LOW;
}

/**
 * Returns true if standard user rate.
 * @param apiUserType
 * @return {boolean}
 */
function isStandardUserRate(apiUserType) {
  return apiUserType === USAGE_TYPE_STANDARD;
}

/**
 * Takes rates object returned from api and converts it to readable rates.
 * @param apiRates
 * @param labels
 * @return {Rates}
 */
export function getRates(apiRates: any, labels?: any) {
  if (!apiRates) return [];
  return apiRates.map((apiRate) => {
    return (apiRate.Rates || []).reduce(
      (acc, apiServiceRate) => {
        const type = getRateType(apiServiceRate.Unit);
        const unit = type === RATE_UNIT_TYPE_DAY ? '/day' : '/kWh';
        const rateToPush = {
          name: apiServiceRate.Name,
          description: apiServiceRate.Description,
          rate: apiServiceRate.Rate,
          unit,
          type,
          powerType: apiServiceRate.PowerType,
          priceType: apiServiceRate.PriceType,
        };
        switch (true) {
          case isLowUserRate(apiServiceRate.UserType) &&
            !apiServiceRate.GSTInclusive:
            acc.lowRatesGSTExclusive.push(rateToPush);
            break;

          case isStandardUserRate(apiServiceRate.UserType) &&
            !apiServiceRate.GSTInclusive:
            acc.standardRatesGSTExclusive.push(rateToPush);
            break;

          case isLowUserRate(apiServiceRate.UserType) &&
            apiServiceRate.GSTInclusive:
            acc.lowRatesGSTInclusive.push(rateToPush);
            break;

          case isStandardUserRate(apiServiceRate.UserType) &&
            apiServiceRate.GSTInclusive:
            acc.standardRatesGSTInclusive.push(rateToPush);
            break;

          default:
            break;
        }
        return acc;
      },
      {
        type: apiRate.Service,
        lowRatesGSTInclusive: [],
        standardRatesGSTInclusive: [],
        lowRatesGSTExclusive: [],
        standardRatesGSTExclusive: [],
        title: labels && labels[apiRate.Service][0],
        titleMobile: labels && labels[apiRate.Service][1],
      }
    );
  });
}

/**
 * Returns true, if there is an error in electricity rates for specified usage type.
 * @param rates
 * @param usageType
 * @return {boolean}
 */
export function hasElectricityRatesError(rates, usageType) {
  return !rates?.find(
    (rate) =>
      rate.type === SERVICE_ID_ELECTRICITY &&
      ((usageType === USAGE_TYPE_STANDARD &&
        rate.standardRatesGSTExclusive.length) ||
        (usageType === USAGE_TYPE_LOW && rate.lowRatesGSTExclusive.length))
  );
}

/**
 * Returns true, if there is an error in gas rates.
 * @param rates
 * @return {boolean}
 */
export function hasGasRatesError(rates, usageType) {
  return !rates?.find(
    (rate) =>
      rate.type === SERVICE_ID_PIPED_GAS &&
      ((usageType === USAGE_TYPE_STANDARD &&
        rate.standardRatesGSTExclusive.length) ||
        (usageType === USAGE_TYPE_LOW && rate.lowRatesGSTExclusive.length))
  );
}

/**
 * Returns service rates per usage type.
 * @param usageType
 * @param type
 * @param lowRates
 * @param standardRates
 * @return {Array}
 */
function getUsageTypeRates(usageType, { type, lowRates, standardRates }) {
  return type === SERVICE_ID_ELECTRICITY
    ? usageType === USAGE_TYPE_LOW
      ? lowRates
      : standardRates
    : standardRates;
}

/**
 * Parses the rate (string, e.g 13c) and returns an instance of Decimal (number, value is in cents units).
 * @param rate {string}
 * @return {Decimal}
 */
function parseRate(rate) {
  const match = rate.match(/^(\$?)(\d+\.*\d*)(c?)$/);
  if (match) {
    // eslint-disable-next-line
    let [_, __, value, cents] = match;
    value = new Decimal(value);
    if (cents) {
      return value;
    } else {
      return value.times(100);
    }
  }
}

/**
 * Sums array of rates (string, e.g. 13c + $2.34) and returns a formatted value ($ or c).
 * @param rates {Array<string>}
 * @return {string}
 */
function sumRates(rates) {
  const summed = rates
    .map(parseRate)
    .reduce((acc, x) => acc.add(x), new Decimal(0));
  if (summed < 100) {
    return summed.toString() + 'c';
  } else {
    return '$' + summed.div(100).toString();
  }
}

/**
 * Returns rates which are summed (for rates overview).
 * @param usageType
 * @param rates
 * @return {Array}
 */
export function getSummedRates(usageType, rates) {
  return rates
    .map((rate) =>
      getUsageTypeRates(usageType, rate).reduce(
        (acc, { type, rate }) => {
          if (!acc.rates[type]) {
            acc.rates[type] = [];
          }
          acc.rates[type] = [...acc.rates[type], rate];
          return acc;
        },
        { usageType, rateType: rate.type, rates: {} }
      )
    )
    .map(({ usageType, rateType, rates }) => ({
      usageType,
      rateType,
      rates: Object.keys(rates).reduce((acc, x) => {
        acc[x] = sumRates(rates[x]);
        return acc;
      }, {}),
    }));
}

export function getElectricityRates(apiRates, labels) {
  return getRates(apiRates, labels).filter(
    ({ type }) => type === SERVICE_ID_ELECTRICITY
  );
}
