import React from 'react';

export const ArrowDown = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.2 6.6"
      width="11.2"
      height="6.6"
      style={{ enableBackground: 'new 0 0 11.2 6.6' }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        id="path-1"
        d="M9.5,0.3L5.6,4.2L1.7,0.3C1.5,0.1,1.3,0,1,0S0.5,0.1,0.3,0.3c-0.4,0.4-0.4,1,0,1.4l4.6,4.6
		c0.4,0.4,1,0.4,1.4,0l4.6-4.6c0.4-0.4,0.4-1,0-1.4C10.5-0.1,9.9-0.1,9.5,0.3z"
        fill="currentColor"
      />
    </svg>
  );
};
