import React from 'react';
import { Icon } from '@contact/ui-volta';

export function Logo() {
  return (
    <Icon width="108.997" height="20.027" viewBox="0 0 108.997 20.027">
      <path
        fill="#ef373e"
        d="M22.494 3.557a8.232 8.232 0 1 0 8.23 8.231 8.232 8.232 0 0 0-8.23-8.231zm0 12.525a4.295 4.295 0 1 1 4.292-4.294 4.295 4.295 0 0 1-4.292 4.294zM79.8 3.975h-3.365a.373.373 0 0 0-.373.375v.843A7.529 7.529 0 0 0 71.4 3.56a8.033 8.033 0 0 0-7.817 8.23 8.026 8.026 0 0 0 7.817 8.222 7.529 7.529 0 0 0 4.663-1.633v.858a.373.373 0 0 0 .373.375H79.8a.375.375 0 0 0 .375-.375V4.35a.375.375 0 0 0-.375-.375zm-7.91 12.11a4.295 4.295 0 1 1 4.293-4.31v.03a4.293 4.293 0 0 1-4.291 4.279zM42.045 3.557a6.284 6.284 0 0 0-4.13 1.651V4.35a.375.375 0 0 0-.375-.375h-3.462a.375.375 0 0 0-.373.375v14.891a.373.373 0 0 0 .373.373h3.462a.375.375 0 0 0 .375-.373v-8.662a3.177 3.177 0 0 1 3.535-3.052 3.309 3.309 0 0 1 2.827 3.339v8.375a.373.373 0 0 0 .373.373h3.462a.373.373 0 0 0 .373-.373V10.2a6.6 6.6 0 0 0-6.44-6.643zM60.593 8.5l1.446-3a.3.3 0 0 0-.273-.42h-5.848V.373A.373.373 0 0 0 55.545 0h-3.467a.373.373 0 0 0-.373.373v13.4c0 5.386 3.4 6.254 5.858 6.254a8.175 8.175 0 0 0 4.413-1.358.39.39 0 0 0 .135-.5L60.646 15.2a.3.3 0 0 0-.423-.115c-2.612 1.556-4.305.981-4.305-1.353V8.7h4.348a.37.37 0 0 0 .328-.2zm46.825 0l1.446-3a.3.3 0 0 0-.27-.428h-5.851V.373A.373.373 0 0 0 102.37 0h-3.464a.375.375 0 0 0-.368.373v13.4c0 5.386 3.4 6.254 5.858 6.254a8.175 8.175 0 0 0 4.4-1.366.388.388 0 0 0 .135-.5l-1.466-2.969a.3.3 0 0 0-.423-.115c-2.609 1.556-4.305.981-4.305-1.353V8.7h4.34a.37.37 0 0 0 .343-.2zM94.1 15.111a4.295 4.295 0 1 1 0-6.646.275.275 0 0 0 .438-.085l1.481-3a.378.378 0 0 0-.128-.485 8.23 8.23 0 1 0 0 13.786.385.385 0 0 0 .128-.485l-1.481-3a.278.278 0 0 0-.438-.08zm-83.178 0a4.295 4.295 0 1 1 0-6.646.275.275 0 0 0 .428-.085l1.481-3a.383.383 0 0 0-.128-.485 8.23 8.23 0 1 0 0 13.786.38.38 0 0 0 .125-.485l-1.478-3a.278.278 0 0 0-.428-.08zM98.538.373v13.4c0 5.386 3.4 6.254 5.858 6.254a8.175 8.175 0 0 0 4.4-1.366.388.388 0 0 0 .135-.5l-1.466-2.969a.3.3 0 0 0-.423-.115c-2.609 1.556-4.305.981-4.305-1.353V8.7h4.34a.37.37 0 0 0 .343-.2l1.446-3a.3.3 0 0 0-.27-.428h-5.851V.373"
        transform="translate(0.026)"
      />
    </Icon>
  );
}
