import React from 'react';

export const UncheckError = (props) => {
  return (
    <svg viewBox="0 0 18 18" {...props}>
      <path
        className="uncheck_svg__st0"
        d="M15 16H3c-.6 0-1-.5-1-1V3c0-.6.4-1 1-1h12c.5 0 1 .4 1 1v12c0 .5-.5 1-1 1zm1-16H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
        fill="#DD353F"
      />
    </svg>
  );
};
