import React, { useEffect } from 'react';
import { Empty } from '../Empty';
import { useUnstableRef } from '../Utilities/useUnstableRef';
import { HeaderConfig, useHeaderConfig } from './Context';
import { isEqual } from 'lodash-es';

export interface ConfigureHeaderProps extends HeaderConfig {
  screenName: string;
}

export function ConfigureHeader({
  screenName,
  ...config
}: ConfigureHeaderProps) {
  const stableConfig = useUnstableRef(config);
  const [currentConfig, setConfig] = useHeaderConfig(screenName);
  useEffect(() => {
    if (!isEqual(stableConfig, currentConfig)) {
      setConfig({ ...currentConfig, ...stableConfig });
    }
  }, [currentConfig, setConfig, stableConfig]);
  return <Empty />;
}
