import MessageTranslations from './Message/Translations';
import ExpansionPanelTranslations from './ExpansionPanels/Translations';
import AlertsTranslations from './Alerts/Translations';
import AppNotificationTranslations from './AppNotifications/Translations';
import EmptyPageTranslations from './EmptyPage/Translations';

export const ComponentsTranslations = {
  ...MessageTranslations,
  ...ExpansionPanelTranslations,
  ...AlertsTranslations,
  ...AppNotificationTranslations,
  ...EmptyPageTranslations,
};
