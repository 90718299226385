// See https://stackoverflow.com/a/53763420/1174869
import {
  DownloadOptions,
  DownloadProvider as CoreDownloadProvider,
} from '@contact/data-access-hooks';
import downloadjs from 'downloadjs';
import React, { PropsWithChildren } from 'react';

export function getBase64DataUri(base64: string, mime: string) {
  return `data:${mime};base64,${base64}`;
}

export async function getDownloadUrl(base64: string, mime: string) {
  if (typeof FileReader === 'undefined') {
    return getBase64DataUri(base64, mime);
  }
  const blob = getBase64Blob(base64, mime);
  const reader = new FileReader();
  const promise = new Promise<string>((resolve, reject) => {
    reader.addEventListener('load', () => {
      const result = reader.result;
      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject(new Error('Unexpected ArrayBuffer result from FileReader'));
      }
    });
    reader.addEventListener('error', () => {
      reject(reader.error);
    });
  });
  reader.readAsDataURL(blob);
  return await promise;
}

// See https://stackoverflow.com/a/16245768/1174869
function getBase64Blob(base64: string, mime: string) {
  const parts = getBase64Parts();
  return new Blob(parts, { type: mime });

  function getBase64Parts() {
    return getBase64Arrays(base64, 512).map((array) => new Uint8Array(array));
  }

  function getBase64Arrays(base64: string, slice = base64.length): number[][] {
    if (slice < base64.length) {
      // Suggestion from the stackoverflow answer was to slice up the bytes to create
      // smaller byte strings at time, and cycle again
      const next = base64.slice(0, slice);
      const remaining = base64.slice(slice);
      // This will recurse until we have our final slice
      return getBase64Arrays(next, slice).concat(
        getBase64Arrays(remaining, slice)
      );
    }
    const byteString = atob(base64);
    const workingByteArray: number[] = [];
    for (let index = 0; index < byteString.length; index++) {
      workingByteArray[index] = byteString.charCodeAt(index);
    }
    return [workingByteArray];
  }
}

export async function download({ mime, base64, name, open }: DownloadOptions) {
  const link = document.createElement('a');
  if (
    'download' in link &&
    typeof URL !== 'undefined' &&
    URL.createObjectURL &&
    typeof Blob !== 'undefined'
  ) {
    // Blob is supported
    const blob = getBase64Blob(base64 as string, mime);
    const fileURL = URL.createObjectURL(blob);
    if (open) {
      // Open file in new tab
      window.open(fileURL, '_blank');
      return;
    }
    link.href = fileURL;
    link.target = '_blank';
    link.download = name;
    link.className = 'download-js-link';
    link.innerHTML = 'downloading...';
    link.style.display = 'block';
    link.style.opacity = '0.001';
    link.style.color = 'rgb(0, 0, 0, 0.001)';
    document.body.appendChild(link);
    setTimeout(() => link.click(), 1);
    // How long does it take?
    setTimeout(() => document.body.removeChild(link), 500);
  } else {
    const url = await getDownloadUrl(base64 as string, mime);
    return downloadjs(url, name, mime);
  }
}

export function DownloadProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  return (
    <CoreDownloadProvider value={download}>{children}</CoreDownloadProvider>
  );
}
