import { VoltaTheme } from '@contact/ui-volta';
import { breakpoints } from './breakpoints';
import { components } from './components';
import { foundations } from './foundations';
import { styles } from './styles';
import { mergeWith } from '@chakra-ui/utils';

const overrides = {
  ...foundations,
  breakpoints,
  components,
  styles,
};

export const ContactEnergyTheme = mergeWith({}, VoltaTheme, overrides);
