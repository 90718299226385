// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
import { BaseConfig } from '@contact/react';
import { TrimesterTranslations } from '@contact/ui-trimester';
import { DefaultReactQueryConfig } from './DefaultReactQueryConfig';

export const environment: BaseConfig = {
  production: false,
  ...DefaultReactQueryConfig,
  translationResources: {
    'en-NZ': {
      TrimesterPage: {
        title: '4th Trimester',
      },
      ...TrimesterTranslations,
    },
  },
  i18next: {
    lng: 'en-NZ',
  },
};
