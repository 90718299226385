import { Alert } from '@contact/react';

export const ExampleFeedbackAlert: Alert = {
  content:
    '<b>Like this new page?</b> We\'ve still got some features to roll out so if you have feedback, <a href="https://example.com" rel="noreferrer" target="_blank">we\'d love to know.</a>',
  icon: 'Star',
  html: true,
  styles: {
    '--alert-background-color': 'rgba(165, 25, 25, 0.2)',
    '--alert-border-color': 'transparent',
    '--alert-icon-color': '#a51919',
    '--alert-close-color': '#000',
    '--alert-text-color': '#000',
  },
  rules: {
    customer: true,
  },
};

export const ExampleAlerts: Alert[] = [
  {
    content:
      'All or part of this bill is based on estimated usage. <a href="https://example.com" rel="noreferrer" target="_blank">What\'s this?</a>',
    icon: 'Electricity',
    html: true,
  },
  {
    content: 'This is your final bill.',
    icon: 'Flag',
    styles: {
      '--alert-text-color': 'var(--contact-core-red)',
      '--alert-icon-color': 'var(--contact-core-red)',
      '--alert-border-color': 'var(--contact-core-red-2)',
      height: '50px',
    },
  },
  ExampleFeedbackAlert,
  {
    content:
      "<b>We've adjusted your bill.</b> Due to a previous estimated reading being too high, we've credited back the incorrect charges and re-billed you for the actual amount of energy you used over that time.",
    icon: 'Electricity',
    html: true,
    styles: {
      '--alert-text-color': 'var(--purple)',
      '--alert-icon-color': 'var(--purple)',
      '--alert-border-color': 'var(--purple-2)',
    },
  },
  {
    content:
      '<b>You might notice some adjustments in this bill.</b> Due to a previous estimated reading being too high, we’ve credited back the incorrect charges and re-billed you for the actual amount of energy you used.',
    html: true,
    icon: 'Info',
    styles: {
      '--alert-background-color': 'var(--white)',
      '--alert-text-color': 'var(--purple)',
      '--alert-icon-color': 'var(--purple)',
    },
  },
  {
    content:
      '<b>Thoughts on Dollar Usage?</b> We just rolled out this new feature Dollar Usage and if you have feedback, <a href="https://contact.co.nz" rel="noreferrer" target="_blank">we\'d love to know.</a>',
    icon: 'Electricity',
    html: true,
    styles: {
      '--alert-background-color': '#F6E7E7',
      '--alert-border-color': 'transparent',
      '--alert-icon-color': '#A51919',
      '--alert-close-color': '#303030',
      '--alert-text-color': '#303030',
    },
  },
];
