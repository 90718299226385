import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ElectricityIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 13.6 22.6" {...props}>
    <g>
      <path
        d="M7.681 9.517L9.207.8.8 13.834l5.119-.751L4.393 21.8 12.8 8.766z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
      />
    </g>
  </Icon>
);
