import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

export const UncheckedIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g transform="translate(0 24)">
      <path
        fill="currentColor"
        d="M18-5H6a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1zm1-16H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2v-14a2.006 2.006 0 0 0-2-2z"
      />
    </g>
  </Icon>
);
