import queryString, { ParseOptions, StringifyOptions } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty, pick } from 'lodash-es';

export const UTMKeys = ['utm_source', 'utm_medium', 'utm_campaign'];

const parseOptions: ParseOptions = {
  arrayFormat: 'separator',
  arrayFormatSeparator: '|',
};

const stringifyOptions: StringifyOptions = {
  arrayFormat: 'separator',
  arrayFormatSeparator: '|',
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => queryString.parse(search, parseOptions), [search]);
};

export function useUTMQueryParams() {
  const queryParams = useQueryParams();
  return useMemo(() => pick(queryParams, UTMKeys), [queryParams]);
}

export function useUTMQuerySearchString() {
  const utm = useUTMQueryParams();
  return isEmpty(utm) ? '' : '?' + queryString.stringify(utm, stringifyOptions);
}

// Non-hook equivalents

export function getUTMQueryParams() {
  const queryParams = queryString.parse(window.location.search, parseOptions);
  return pick(queryParams, UTMKeys);
}

export function getUTMQuerySearchString() {
  const utm = getUTMQueryParams();
  return isEmpty(utm) ? '' : '?' + queryString.stringify(utm, stringifyOptions);
}
