const baseStyle = {
  borderRadius: 'base',
};

const standardButton = (nativeColor: string, hoverColor: string) => ({
  width: ['170px'],
  height: ['51px'],
  padding: '3',
  bg: nativeColor,
  border: '1px',
  borderColor: nativeColor,
  lineHeight: '1.5',
  fontSize: 'md',
  fontWeight: 'bold',
  color: 'white',
  _hover: {
    bg: hoverColor,
    borderColor: hoverColor,
    _disabled: {
      bg: 'gray.600',
      borderColor: 'gray.600',
    },
  },
  _disabled: {
    bg: 'gray.600',
    opacity: '0.8',
  },
});

const variants = {
  jumbo: {
    width: ['322px', '322px', '231px'], // sm, md, lg, xl
    height: ['56px'],
    padding: '17px 48px',
    bg: 'red.500',
    lineHeight: '1.44',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    _hover: {
      bg: 'red.700',
      _disabled: {
        bg: 'gray.600',
      },
    },
    _disabled: {
      bg: 'gray.600',
      opacity: '0.8',
    },
  },
  retail: {
    width: ['304px'],
    height: ['56px'],
    padding: '14px 45px 13px 46px',
    bg: 'transparent',
    lineHeight: '0.83',
    fontSize: '24px',
    fontWeight: 'extrabold',
    color: 'purple.800',
    border: '1px',
    borderColor: 'purple.800',
    _hover: {
      bg: 'purple.800',
      color: 'white',
      _disabled: {
        bg: 'purple.800',
      },
    },
    _active: {
      bg: 'purple.800',
      color: 'white',
    },
  },
  primary: standardButton('red.500', 'red.700'),
  secondary: standardButton('gray.900', 'black'),
  ghost: {
    width: ['166px'],
    height: ['51px'],
    padding: '3',
    border: '1px',
    borderColor: 'gray.900',
    lineHeight: '1.5',
    fontSize: 'md',
    fontWeight: 'bold',
    color: 'gray.900',
    _hover: {
      borderColor: 'black',
      color: 'black',
      bg: 'purpleAlpha.100',
      _disabled: {
        borderColor: 'gray.600',
        color: 'gray.600',
      },
    },
    _disabled: {
      borderColor: 'gray.600',
      color: 'gray.600',
    },
  },
  link: {
    height: ['24px'],
    color: 'purple.800',
    fontSize: 'md',
    fontWeight: 'medium',
    lineHeight: '1.5',
    textDecoration: 'underline',
    _hover: {
      textDecoration: 'none',
      boxShadow: 'none',
    },
  },
};

export const Button = {
  baseStyle,
  variants,
};
