import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { isObject } from 'lodash-es';
import { Namespace } from './Translations';
import { useTranslationWithNamespace } from '@contact/react';
import { Button, LoadingPage, Loading } from '@contact/ui-components';
import { Box, H3, Text } from '@contact/ui-volta';
import { useForm } from 'react-hook-form';
import {
  useTrimesterUpdate,
  UpdateTrimesterParams,
  useTrimesterValidateLink,
} from '@contact/data-access-hooks';
import { baseAxiosConfig, requestIdKey, passedSubmissionKey } from '../Config';
import { TrimesterContainer } from '../TrimesterContainer';
import { FormAlert } from '../FormComponents';
import {
  ModalAlert,
  ContactPhoneLink,
  UploadDocuments,
} from '../TrimesterComponents';

export const TrimesterUploadDocuments = () => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', { returnObjects: true });
  const formHeader = translations.header;
  const requestId = window.location.href.split('upload-documents/').pop();
  const [files, setFiles] = useState([]);
  const [readyToCall, setReadyToCall] = useState(false);
  const [updateParams, setUpdateParams] = useState<UpdateTrimesterParams>();
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);
  const [linkValid, setLinkValid] = useState(true);

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm();

  const { data: updateData, error: updateDataError } = useTrimesterUpdate(
    'v2',
    requestId,
    updateParams,
    readyToCall,
    baseAxiosConfig
  );

  const {
    data: validateLinkData,
    error: validateLinkError,
    isFetching,
  } = useTrimesterValidateLink('v2', requestId, baseAxiosConfig);

  useEffect(() => {
    const requestIdSaved = sessionStorage.getItem(requestIdKey);
    if (requestId && !requestIdSaved) {
      sessionStorage.setItem(requestIdKey, requestId);
    }
  }, [requestId]);

  useEffect(() => {
    setReadyToCall(false);
    if (updateDataError) {
      setIsErrorModalActive(true);
    }
  }, [updateDataError]);

  useEffect(() => {
    if (isObject(validateLinkData)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLinkValid(validateLinkData.valid);
    }
  }, [validateLinkData, validateLinkError]);

  const title = translations.title;
  const errorNoDocumentSelected = translations.errorNoDocumentSelected;
  const button = translations.button;
  const documentUploadError = translations.documentUploadError;

  const onSubmit = () => {
    const uploadedFiles = files.map((file) => ({
      name: file.filename,
      data: file.getFileEncodeBase64String(),
    }));

    const formValues: UpdateTrimesterParams = {
      files: uploadedFiles,
      action: 'upload',
    };
    setUpdateParams(formValues);
    setReadyToCall(true);
  };

  if (updateData?.message === 'Success') {
    sessionStorage.setItem(passedSubmissionKey, 'true');
    return <Redirect to="/success" />;
  }

  if (isErrorModalActive) {
    return (
      <ModalAlert
        isActive={isErrorModalActive}
        title={documentUploadError.header}
        text={documentUploadError.text}
        onHandleClose={() => setIsErrorModalActive(false)}
      />
    );
  }

  const uploadDocuments = () => {
    if (!linkValid) {
      return (
        <Box className="form-container" width="100%">
          <Box className="form-header">
            <H3>{translations.ineligible.title}</H3>
          </Box>
          <Box className="form-body">
            <FormAlert message={translations.ineligible.alert} />
          </Box>
          <Text className="form-link">
            {translations.ineligible.contact}
            <ContactPhoneLink />
            {translations.ineligible.link}
          </Text>
        </Box>
      );
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box className="form-container">
          <Box className="form-header">
            <H3>{formHeader}</H3>
          </Box>
          <Box className="form-body">
            <UploadDocuments
              control={control}
              errors={errors}
              errorMessage={errorNoDocumentSelected}
              files={files}
              setFiles={setFiles}
              trigger={trigger}
            />
            <Button
              type="submit"
              variant="primary"
              className={
                !isValid || readyToCall
                  ? 'submitButton disabled'
                  : 'submitButton'
              }
            >
              {readyToCall && (
                <Loading
                  customstyle={{
                    minWidth: '100%',
                    minHeight: '0',
                    top: '10px',
                  }}
                />
              )}
              {button}
            </Button>
          </Box>
        </Box>
      </form>
    );
  };

  if (isFetching) {
    return <LoadingPage />;
  }

  return (
    <TrimesterContainer
      showSidebar={linkValid}
      namespace="TrimesterUploadDocumentTranslations"
    >
      {uploadDocuments()}
    </TrimesterContainer>
  );
};
