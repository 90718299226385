import React from 'react';

export const ArrowRight = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      style={{ enableBackground: 'new 0 0 24 24' }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M9.8,15.9l3.9-3.9L9.8,8.1C9.6,7.9,9.5,7.7,9.5,7.4s0.1-0.5,0.3-0.7c0.4-0.4,1-0.4,1.4,0
	l4.6,4.6c0.4,0.4,0.4,1,0,1.4l-4.6,4.6c-0.4,0.4-1,0.4-1.4,0C9.4,16.9,9.4,16.3,9.8,15.9z"
        fill="currentColor"
      />
    </svg>
  );
};
