import moment from 'moment';

export const initiateTrackings = () => {
  dataLayerEvent({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  });
};

export const stepCompleted = (
  content: string,
  value: string | Date,
  format?: string
) => {
  let formattedValue = value;
  if (format === 'date') {
    formattedValue = moment(value).format('YYYYMMDD');
  }
  dataLayerEvent({
    event: 'step_completed',
    content_type: 'fourth trimester sign up',
    content_id: content,
    selected_value: formattedValue,
  });
};

export const signUpCompleted = () => {
  dataLayerEvent({
    event: '4th-tri-sign-up_completed',
    content_type: 'fourth trimester sign up',
    baid: null,
    hashedEmail: null,
  });
};

export const alertIneligible = (content: string) => {
  dataLayerEvent({
    event: 'alert_ineligble',
    content_type: 'fourth trimester sign up',
    content_id: content,
  });
};

export const apiError = () => {
  dataLayerEvent({
    event: 'virtualPageview',
    virtualUrl: '/technical-error',
    virtualPageTitle: 'Sorry, something went wrong',
  });
};

export const closeApiError = (url: string, title: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'virtualPageview',
    virtualUrl: url,
    virtualPageTitle: title,
  });
};

export const tooltipClick = (type: string, text: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'info_click',
    type: type,
    info_text: text,
  });
};

export const dataLayerEvent = (event) => {
  if (!window.dataLayer) {
    //eslint-disable-next-line no-console
    console.warn('data layer not installed');
  }

  window.dataLayer = window.dataLayer || [];
  if (event) {
    window.dataLayer.push(event);
  }
};
