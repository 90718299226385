import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const CloseIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 23.963 24" {...props}>
    <svg
      id="all_elements_close"
      data-name="all / elements / close"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path
        id="Rectangle_28"
        data-name="Rectangle 28"
        fill="none"
        d="M0 0h24v24H0z"
      />
      <g id="Group_3640" data-name="Group 3640" transform="translate(10.5 -.5)">
        <path
          id="Line_1"
          data-name="Line 1"
          className="cls-2"
          transform="translate(-6.5 4.5)"
          d="m0 0 16 16"
          fill="none"
          stroke="#303030"
          strokeLinecap="round"
          strokeWidth="2.5px"
        />
        <path
          id="Line_2"
          data-name="Line 2"
          className="cls-2"
          transform="translate(-6.5 4.5)"
          d="M16 0 0 16"
          fill="none"
          stroke="#303030"
          strokeLinecap="round"
          strokeWidth="2.5px"
        />
      </g>
    </svg>
  </Icon>
);
