export const colors = {
  gray: {
    400: '#E0D8E2',
    500: '#858585',
    600: '#979797',
    900: '#303030',
  },

  red: {
    200: '#FAD4D6',
    300: '#FEF4F5',
    500: '#DD353F',
    700: '#A51919',
    900: '#7D1D34',
  },

  purple: {
    100: '#EFEBF0',
    700: '#7C5C82',
    800: '#5C3463',
  },

  purpleAlpha: {
    100: 'rgba(92, 52, 99, 0.1)',
    900: 'rgba(92, 52, 99, 0.9)',
  },
};
