import React, { ReactElement } from 'react';
import { Box, Stack, HStack, useRadioGroup } from '@chakra-ui/react';
import { Radio, RadioGroupProps } from '@contact/ui-volta';
import { chunk } from 'lodash-es';

export interface MultiColumnRadioGroupProps extends RadioGroupProps {
  numberOfColumnPerRow: number;
  columnWidth?: string;
}

export const MultiColumnRadioGroup: React.FC<MultiColumnRadioGroupProps> = ({
  spacing,
  style,
  numberOfColumnPerRow,
  columnWidth = '120px',
  ...rest
}): ReactElement => {
  const { getRootProps, getRadioProps } = useRadioGroup(rest);

  const group = getRootProps();

  const options = rest.options;
  const chunkedOptions = chunk(options, numberOfColumnPerRow);

  return (
    <Stack>
      {chunkedOptions.map((_row, index) => {
        return (
          <HStack {...group} spacing={spacing} key={index} style={style}>
            {_row.map(({ value, label, ...rest }, index) => {
              const radio = getRadioProps(({
                value,
                ...rest,
              } as unknown) as Parameters<typeof getRadioProps>[0]);
              return (
                <Box width={columnWidth} key={index}>
                  <Radio {...radio} key={value}>
                    {label}
                  </Radio>
                </Box>
              );
            })}
          </HStack>
        );
      })}
    </Stack>
  );
};
