import React, { createContext, useContext, useState } from 'react';

export interface HeaderConfig {
  title?: string;
  leftControlType?: 'back' | 'menu';
  userAccounts?: boolean;
  onDownloadPress?: () => void;
  onBackPress?: () => void;
  onUserAccountsPress?: () => void;
  notificationItemDisabled?: boolean;
}

export interface HeaderConfigs {
  [screenName: string]: HeaderConfig;
}

export interface HeaderContextState {
  configs: HeaderConfigs;
  setConfigs: React.Dispatch<React.SetStateAction<HeaderConfigs>>;
}

export const HeaderContext = createContext<HeaderContextState>({
  configs: {},
  setConfigs: () => undefined,
});

export function useHeaderContext() {
  return useContext(HeaderContext);
}

export function HeaderProvider({ children }: { children?: React.ReactNode }) {
  const [configs, setConfigs] = useState<HeaderConfigs>({});
  const initialContextState: HeaderContextState = {
    configs,
    setConfigs,
  };
  return (
    <HeaderContext.Provider value={initialContextState}>
      {children}
    </HeaderContext.Provider>
  );
}

export function useHeaderConfig(
  screenName: string
): [HeaderConfig, (config: HeaderConfig) => void] {
  const headerContext = useHeaderContext();
  if (!screenName) {
    return [{}, () => undefined];
  }

  const config = headerContext.configs[screenName] ?? {};

  const setConfig = (config: HeaderConfig) =>
    headerContext.setConfigs((configs) => ({
      ...configs,
      [screenName]: { ...configs[screenName], ...config },
    }));

  return [config, setConfig];
}
