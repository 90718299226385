import React, { createContext, ReactNode, useContext } from 'react';
import { useStyleConfig, Box } from '@chakra-ui/react';
import { GlobalBrandedNavigation } from './GlobalBrandedNavigation';

export interface GlobalHeaderProps {
  variant?: string;
  left?: ReactNode;
  right?: ReactNode;
}

export const GlobalHeaderContext = createContext<GlobalHeaderProps | undefined>(
  undefined
);

const namespace = 'GlobalHeader';

export function GlobalHeader(props: GlobalHeaderProps) {
  const context = useContext(GlobalHeaderContext);
  const { variant, left, right } = context || props;
  const styles = useStyleConfig(namespace, { variant });
  const sideBoxStyles = useStyleConfig('GlobalHeaderSideBox', { variant });
  return (
    <Box sx={styles} as="header">
      {left ?? <Box sx={sideBoxStyles} />}
      <GlobalBrandedNavigation variant={variant} />
      {right ?? <Box sx={sideBoxStyles} />}
    </Box>
  );
}
