/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// add this ts-nocheck for now since doing `nx build forms` will trigger ts errors on this file
// however doing `nx build shared-ui-components` does not have any ts errors
// this requires further investigation

import React, { useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';

import './Checkbox.scss';
import Icon from '../Icon';
import { Check, Help, Uncheck, UncheckError } from '../../Assets/Icons';
import noop from 'nop';

interface CheckboxProps {
  checked: boolean;
  validateItself?: boolean;
  required?: boolean;
  iconClicked?: () => void;
  disabled?: boolean;
  onChange: (event: React.SyntheticEvent) => void;
  name: string;
  className?: string;
  hasHelpIcon?: boolean;
  label: string;
  subLabel?: string;
  handleError?: (hasError?: boolean) => void;
  hasError?: boolean;
  inputProps?: Record<string, any>;
}

export const Checkbox = ({
  checked = false,
  validateItself = false,
  required = false,
  iconClicked = undefined,
  disabled = false,
  onChange,
  name,
  className = undefined,
  hasHelpIcon = false,
  label,
  subLabel = '',
  handleError = noop,
  hasError = false,
  inputProps = undefined,
}: CheckboxProps) => {
  const icon = useMemo(
    () =>
      checked ? (
        <Check />
      ) : validateItself && required ? (
        <UncheckError />
      ) : (
        <Uncheck />
      ),
    [checked, validateItself, required]
  );

  // Note: this is used as a ref, it will always have unstable references to its scope, so useCallback is not needed here.
  const getInputRef = (input) => {
    if (inputProps && inputProps?.ref) {
      if (typeof inputProps?.ref === 'object') {
        inputProps.ref.current = input;
      } else if (typeof inputProps?.ref === 'function') {
        inputProps.ref(input);
      }
    }
  };

  useEffect(() => {
    if (handleError && validateItself && !hasError && required && !checked) {
      handleError(true);
    }
  }, [checked, handleError, hasError, required, validateItself]);

  useEffect(() => {
    if (handleError && hasError && checked) {
      handleError(false);
    }
  }, [checked, handleError, hasError]);

  const helpIconClick = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
      }
      if (iconClicked) {
        iconClicked();
      }
    },
    [iconClicked]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (disabled) return;
      if (e.key === ' ') {
        onChange(e);
        e.preventDefault();
      }
    },
    [onChange, disabled]
  );

  const onClick = useCallback(
    (e) => {
      if (disabled) return;
      onChange(e);
    },
    [onChange, disabled]
  );

  return (
    <div
      ref={getInputRef}
      id={name}
      className={classNames('contactUIComponentsCheckbox', className, {
        disabled,
      })}
      onClick={onClick}
      role="checkbox"
      aria-checked={checked}
    >
      <Icon
        className={
          checked
            ? 'contactUIComponentsCheckbox_box contactUIComponentsCheckbox_box--checked'
            : 'contactUIComponentsCheckbox_box'
        }
        icon={icon}
        tabindex="0"
        onKeyDown={onKeyDown}
      />
      <div className="contactUIComponentsCheckbox_container">
        <div className="contactUIComponentsCheckbox_label">{label}</div>
        {subLabel ? (
          <div className="contactUIComponentsCheckbox_sublabel">{subLabel}</div>
        ) : undefined}
      </div>
      {hasHelpIcon && (
        <Icon
          name="helpIcon"
          onClick={helpIconClick}
          className="contactUIComponentsCheckbox_box contactUIComponentsCheckbox_icon"
          icon={<Help />}
        />
      )}
    </div>
  );
};
