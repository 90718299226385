import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const TimelineIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 22.964 23.251" {...props}>
    <g>
      <path
        d="M22.964 11.625A11.631 11.631 0 003.047 3.469L.938 1.359A.56.56 0 000 1.781v5.906a.542.542 0 00.563.563h6a.559.559 0 00.42-.937l-2.3-2.25a9.253 9.253 0 016.66-2.813 9.375 9.375 0 110 18.75 9.526 9.526 0 01-6.28-2.344.573.573 0 00-.751 0l-.8.8a.6.6 0 000 .844 11.684 11.684 0 0019.452-8.675zm-7.87 4.219l.7-.891a.632.632 0 00-.14-.8l-3.19-2.343V5.06a.6.6 0 00-.56-.563h-1.12a.566.566 0 00-.56.563v7.921l4.12 3a.5.5 0 00.75-.137z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
