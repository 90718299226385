import { ResourceLanguage } from 'i18next';
import TrimesterComponentsTranslations from './TrimesterComponents/Translations';
import TrimesterSuccessTranslations from './TrimesterSuccess/Translations';
import TrimesterUploadDocumentTranslations from './TrimesterUploadDocuments/Translations';

export const TrimesterTranslations: ResourceLanguage = {
  ...TrimesterComponentsTranslations,
  ...TrimesterSuccessTranslations,
  ...TrimesterUploadDocumentTranslations,
};
