import { BaseConfig, ConfigProvider } from '../Config';
import React, { PropsWithChildren, ReactNode, Suspense, useMemo } from 'react';
import { TranslationProvider } from '../Translation';
import { AlertProvider } from '../Alert';
import { Empty } from '../Empty';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from '../ErrorBoundary';
import { UserProvider } from '../User';
import { FlagsProvider } from '../Flags';
import { HeaderProvider } from '../Header';

export interface BaseProviderProps {
  config: BaseConfig;
  fallback?: ReactNode;
}

function useQueryClient(config: BaseConfig) {
  return useMemo(() => new QueryClient(config.queryClient), [config]);
}

// Split out any providers that you want to actually use config
function BaseProviderWithConfig({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  return (
    <ErrorBoundary>
      <TranslationProvider>
        <AlertProvider>
          <HeaderProvider>{children}</HeaderProvider>
        </AlertProvider>
      </TranslationProvider>
    </ErrorBoundary>
  );
}

export function BaseProvider({
  config,
  children,
  fallback,
}: PropsWithChildren<BaseProviderProps>) {
  const queryClient = useQueryClient(config);
  return (
    <ErrorBoundary>
      {/* Providing this top level empty suspense allows low level tooling like i18n to initialise for the fallback component next */}
      <Suspense fallback={<Empty />}>
        <Suspense fallback={fallback ?? <Empty />}>
          <QueryClientProvider client={queryClient}>
            {/* This will provide a basic user object with no token */}
            <UserProvider>
              <ConfigProvider value={config}>
                <FlagsProvider>
                  <BaseProviderWithConfig>{children}</BaseProviderWithConfig>
                </FlagsProvider>
              </ConfigProvider>
            </UserProvider>
          </QueryClientProvider>
        </Suspense>
      </Suspense>
    </ErrorBoundary>
  );
}
