import React from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export function BellOutlineIcon(props: IconProps) {
  return (
    <Icon width={50} height={50} viewBox="0 0 50 50" fill="none" {...props}>
      <circle cx={25} cy={25} r={24} stroke="#303030" strokeWidth={1.5} />
      <path
        d="M34 31.636h-.75a.75.75 0 00.228.539l.522-.539zm-6.75-16.465h-.75c0 .35.241.653.582.73l.168-.73zm-4.5 0l.169.73a.75.75 0 00.581-.73h-.75zM16 31.636l.522.539a.75.75 0 00.228-.539H16zm-3 2.91l-.522-.539a.75.75 0 00-.228.538H13zM13 36h-.75c0 .414.336.75.75.75V36zm24 0v.75a.75.75 0 00.75-.75H37zm0-1.455h.75a.75.75 0 00-.228-.538l-.522.538zm-2.25-2.909v-7.272h-1.5v7.272h1.5zm0-7.272c0-4.744-2.621-8.842-7.332-9.924l-.336 1.462c3.9.896 6.168 4.274 6.168 8.462h1.5zM28 15.17v-.99h-1.5v.99H28zm0-.99c0-1.642-1.363-2.931-3-2.931v1.5c.853 0 1.5.66 1.5 1.432H28zm-3-2.931c-1.637 0-3 1.289-3 2.932h1.5c0-.772.647-1.432 1.5-1.432v-1.5zm-3 2.932v.989h1.5v-.99H22zm.581.258c-4.693 1.082-7.331 5.164-7.331 9.924h1.5c0-4.2 2.282-7.566 6.169-8.462l-.338-1.462zm-7.331 9.924v7.272h1.5v-7.272h-1.5zm.228 6.734l-3 2.909 1.044 1.077 3-2.91-1.044-1.076zm-3.228 3.447V36h1.5v-1.455h-1.5zM13 36.75h24v-1.5H13v1.5zM37.75 36v-1.455h-1.5V36h1.5zm-.228-1.993l-3-2.91-1.044 1.078 3 2.909 1.044-1.077z"
        fill="#303030"
      />
    </Icon>
  );
}
