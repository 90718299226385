import { Panel as ExpansionPanel } from './Context';

export const ExpansionPanelSingleExample: ExpansionPanel = {
  header: 'Account and invoice numbers',
  content: 'Example content',
  html: true,
  styles: { 'font-size': '16px', 'font-weight': 'bold' },
  variant: 'account',
};

export const ExpansionPanelsExample: ExpansionPanel[] = [
  {
    header: 'Why a digital bill?',
    content: `
    Customers have told us that the current PDF bill is hard to understand,
    and simple things are displayed in a complicated way.<br /><br />
    We want our bill to be straightforward, which is why we've created this digital bill.
    We've simplified how to get to it, how it looks and focussed on the things our customers
    have told us are the most confusing.<br /><br />
    Now you don’t have to download a PDF to see your
    bill (although it is still available if you want it).
    Simply sign into My Account online, view your latest bill and go on to pay it or
    check your energy usage. All the things you need to manage your energy are together in one place.
    `,
    html: true,
    styles: { 'font-size': '16px' },
    variant: 'list',
  },
  {
    header: "What's the difference between the old PDF bill and digital bill?",
    content: `
    The key differences are that the digital bill is easier to understand and easier to get
    to - just sign into My Account.<br /><br />
    The most important information (due date and total amount due) are right at the top.
    You can also see at a glance what your charges are made up of.
    If you want to view the details of your bill, we've provided a breakdown for each of
    your services (electricity, gas, broadband) so you can clearly see what makes
    up the cost per service – with just a click of the mouse or tap of the screen.<br /><br />
    The way we show credits is better now too! While the full credit amount has always been in
    the PDF bill, the GST is shown separately, so credits appear to be less than what is expected.
    On the new digital bill, credits now appear with GST included, so you can be assured first
    time that you've been credited the right amount. 
    `,
    html: true,
    styles: { 'font-size': '16px' },
    variant: 'list',
  },
  {
    header: 'Will I still get my PDF bill?',
    content: `
    Yes, you will. We hope that you'll find the digital bill easier to use but if you need
    the PDF tax invoice for other reasons, it will still be attached to your bill emails.<br /><br />
    All of your PDF tax invoices can also be found when you sign into My Account and go
    to View Bill > <a href="/view-bill/previous" rel="noreferrer">Previous Bills</a>.
    `,
    html: true,
    styles: { 'font-size': '16px' },
    variant: 'list',
  },
  {
    header: `Something isn't right on my digital bill. How can I tell you about it?`,
    content: `
    Customers just like you have helped create this digital bill and we're still
    keen to keep feedback coming if there are ways we can improve.
    <a href="https://0w7yx40f.optimalworkshop.com/questions/581a81z6" rel="noreferrer" target="_blank">Send us feedback about the digital bill here.</a>
    `,
    html: true,
    styles: { 'font-size': '16px' },
    variant: 'list',
  },
  {
    header: `What options do I have if I can't pay my bill?`,
    content: `
    The last thing we want you to worry about is how to keep the lights on and your house warm.
    If you’re finding it hard to pay your bills, here’s some ways we can help:
    <ul>
    <li>
    We can set up an arrangement, so you pay off the outstanding amount over a period of time
    </li>
    <li>
    Choose
    ‘<a href="/pay-bill/smoothpay" rel="noreferrer">SmoothPay’,</a>
    where you pay the same amount weekly, fortnightly or monthly
    (whatever works for you). We work out how much this is, so it covers your energy usage all
    year round.
    </li>
    <li>
    Choose <a href="/pay-bill/postpay" rel="noreferrer">weekly or fortnightly billing</a>
    to align your power bills with your pay.
    </li>
    <li>
    Try <a href="https://www.workandincome.govt.nz/" rel="noreferrer" target="_blank">Work and Income</a>
    for some possible extra help
    </li>
    <li>
    Visit <a href="https://www.moneytalks.co.nz/" rel="noreferrer" target="_blank">MoneyTalks</a>
    for some great budgeting advice
    </ul>
    <br />
    Need help? Please phone us on <a href="tel:0800-80-80-86">0800 80 80 86</a> during business hours and Saturdays.
    We’re ready to help. 
    `,
    html: true,
    styles: { 'font-size': '16px' },
    variant: 'list',
  },
];
