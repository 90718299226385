import React from 'react';

export const WhiteCheck = (props) => {
  return (
    <svg viewBox="0 0 31 24" {...props}>
      <path
        fill="none"
        fill-rule="evenodd"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M2 12.589L11.5 22 29 2"
      />
    </svg>
  );
};
