import React from 'react';

const Close = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enableBackground: 'new 0 0 24 24' }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        id="path-1_1_"
        className="st0"
        d="M20.4,3.6c-0.2-0.2-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4L12,10.1L5.5,3.6
   C5.2,3.4,4.9,3.2,4.5,3.2S3.8,3.4,3.6,3.6C3.1,4.1,3.1,5,3.6,5.5l6.5,6.5l-6.5,6.5c-0.5,0.5-0.5,1.4,0,1.9s1.4,0.5,1.9,0l6.5-6.5
   l6.5,6.5c0.5,0.5,1.4,0.5,1.9,0s0.5-1.4,0-1.9L13.9,12l6.5-6.5C20.9,5,20.9,4.1,20.4,3.6z"
        fill="currentColor"
      />
    </svg>
  );
};

export { Close };
