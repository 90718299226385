export const DefaultReactQueryConfig = {
  queryClient: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
      },
    },
  },
};
