export const GlobalNavigation = {
  baseStyle: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '17px 16px 16px',
  },
  defaultProps: {},
  variants: {},
};
