import React from 'react';
import { EnhancedText } from '../EnhancedText';

export interface PropertyAddressProps {
  isLoading?: boolean;
  children: string;
}

export const PropertyAddress: React.FC<PropertyAddressProps> = ({
  isLoading,
  children,
}) => (
  <EnhancedText
    fontSize="22px"
    fontWeight="extrabold"
    maxWidth="680px"
    isLoading={isLoading}
    loadingWidth={480}
  >
    {children}
  </EnhancedText>
);
