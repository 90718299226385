export const EmptyPageDefaultNamespace = 'EmptyPageNamespace';
export const EmptyPageErrorNamespace = 'EmptyPageErrorNamespace';

export default {
  [EmptyPageDefaultNamespace]: {
    icon: 'UsageLargeIcon',
    title: 'Oops!<br/>There’s nothing here yet.',
    message: 'Your data will show when it’s ready.',
  },
  [EmptyPageErrorNamespace]: {
    icon: 'OfflineIcon',
    title: 'Sorry!<br/>Something went wrong.',
    message:
      'We’re experiencing technical difficulties. Please come back later.',
  },
};
