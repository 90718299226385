import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const BillingIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 18 24" {...props}>
    <g>
      <path
        d="M17.3 4.594L13.362.703a2.255 2.255 0 00-1.547-.7H2.2a2.282 2.282 0 00-2.2 2.3v19.5a2.2 2.2 0 002.2 2.2h13.5a2.252 2.252 0 002.3-2.2V6.188a2.366 2.366 0 00-.7-1.594zm-5.344-2.156l3.609 3.609h-3.609zm3.8 19.359H2.2V2.3h7.5v4.875A1.141 1.141 0 0010.825 8.3h4.922zm-6-11.672a.4.4 0 00-.375-.375h-.75a.37.37 0 00-.375.375V11.3a2.052 2.052 0 00-2.016 2.109 2.127 2.127 0 001.454 2.016l2.109.656a.52.52 0 01.422.563.555.555 0 01-.563.609H8.35a1.443 1.443 0 01-.61-.141.361.361 0 00-.468.047l-.516.516a.351.351 0 000 .562 2.424 2.424 0 001.5.516v1.125a.345.345 0 00.375.375h.75a.37.37 0 00.375-.375V18.75a2.045 2.045 0 001.969-2.109 2.084 2.084 0 00-1.453-2.016l-2.11-.656a.52.52 0 01-.422-.563.6.6 0 01.563-.656h1.312a1.527 1.527 0 01.61.188.363.363 0 00.469-.047l.515-.516a.352.352 0 000-.563 2.39 2.39 0 00-1.453-.515zM4.131 5.25h3.75a.37.37 0 00.375-.375v-.75a.4.4 0 00-.375-.375h-3.75a.37.37 0 00-.375.375v.75a.345.345 0 00.369.375zm4.125 2.625v-.75a.4.4 0 00-.375-.375h-3.75a.37.37 0 00-.375.375v.75a.345.345 0 00.375.375h3.75a.37.37 0 00.369-.375z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
