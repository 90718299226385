import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const EmailIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 16 12" {...props}>
    <g>
      <path
        d="M14.5 0h-13A1.5 1.5 0 000 1.5v9A1.481 1.481 0 001.5 12h13a1.5 1.5 0 001.5-1.5v-9A1.521 1.521 0 0014.5 0zm0 1.5v1.281c-.719.594-1.844 1.469-4.219 3.344C9.75 6.531 8.719 7.531 8 7.5c-.75.031-1.781-.969-2.312-1.375-2.376-1.875-3.5-2.75-4.188-3.344V1.5zm-13 9V4.719c.688.562 1.719 1.375 3.25 2.593C5.438 7.844 6.656 9.031 8 9c1.312.031 2.5-1.156 3.219-1.688A502.446 502.446 0 0114.5 4.719V10.5z"
        fill="#121820"
      />
    </g>
  </Icon>
);
