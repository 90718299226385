import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export interface ButtonProps extends ChakraButtonProps {
  variant: ButtonVariant;
}

export type ButtonVariant =
  | 'jumbo'
  | 'retail'
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'link';

export const Button = (props: ButtonProps): ReactElement => {
  return <ChakraButton {...props}></ChakraButton>;
};
