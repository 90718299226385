import React, { useState, useRef, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import classNames from 'classnames';
import { ChevronRightIcon } from '@contact/ui-components';
import './Tabs.scss';

export interface Tab {
  header: string;
  content: any;
}

type Props = {
  tabList: Tab[];
  selectToTabHeader?: string;
  withOverflow?: boolean;
  onClick?: (value: any, index: number) => void;
  hasMadePaymentAttempt?: boolean;
  hasMadePaymentContent?: any;
  className?: string;
  tabListClassName?: string;
  variant?: 'default' | 'dashboard';
};

const Tabs = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState<string | number>(
    props.selectToTabHeader ? props.selectToTabHeader : props.tabList[0].header
  );
  const listElement = useRef<HTMLUListElement>(null);
  const slideMultiplier = 400;
  const [handleSlide, setHandleSlide] = useState({
    listElementScrollWidth: 0,
    listElementWidth: 0,
    slideCount: 0,
  });
  const { variant = 'default' } = props;
  const isDashboard = variant === 'dashboard';

  const getResizeData = () => {
    const stateObject = {
      listElementScrollWidth: listElement.current.scrollWidth,
      listElementWidth: listElement.current.parentElement.offsetWidth,
      slideCount:
        listElement.current.scrollWidth === listElement.current.offsetWidth
          ? 0
          : handleSlide.slideCount,
    };
    setHandleSlide(stateObject);
  };

  const debounceGetResizeData = _debounce(getResizeData, 150);

  useEffect(() => {
    if (props.hasMadePaymentAttempt) {
      setSelectedTab(-1);
    }
  }, [props.hasMadePaymentAttempt]);
  useEffect(() => {
    window.addEventListener('resize', debounceGetResizeData);
    return () => {
      window.removeEventListener('resize', debounceGetResizeData);
    };
  });
  useEffect(() => {
    if (props.selectToTabHeader && !isEmpty(props.selectToTabHeader)) {
      setSelectedTab(props.selectToTabHeader);
    }
  }, [props.selectToTabHeader]);
  useEffect(() => {
    getResizeData();
  }, []);
  const [slideList, setSlideList] = useSpring(() => ({
    x: 0,
  }));
  setSlideList({
    x:
      handleSlide.slideCount > 0
        ? handleSlide.listElementScrollWidth >=
          handleSlide.slideCount * slideMultiplier +
            handleSlide.listElementWidth
          ? -handleSlide.slideCount * slideMultiplier
          : -handleSlide.listElementScrollWidth + handleSlide.listElementWidth
        : 0,
  });

  const tabClickedHandler = (header: string, index: number) => {
    setSelectedTab(header);
    props.onClick?.(header, index);
  };

  const classes = classNames(
    {
      olsUIComponentsTabs: true,
      'olsUIComponentsTabs--overflow': props.withOverflow,
    },
    props.className
  );

  const displayContent = () => {
    const filteredContent = props.tabList.filter((tab) => {
      return tab.header === selectedTab;
    });

    if (props.hasMadePaymentAttempt) {
      return props.hasMadePaymentContent;
    }

    return filteredContent[0]?.content;
  };

  return (
    <div className={classes}>
      <div
        className={classNames(
          {
            olsUIComponentsTabs_headerListContainer: true,
            olsUIComponentsTabs_headerListContainer_dashboardVariant: isDashboard,
          },
          props.tabListClassName
        )}
      >
        {handleSlide.slideCount > 0 && (
          <animated.div
            className={classNames({
              olsUIComponentsTabs_iconArrow: true,
              'olsUIComponentsTabs_iconArrow--left': true,
              olsUIComponentsTabs_iconArrow_dashboardVariant: isDashboard,
            })}
            onClick={() =>
              handleSlide.slideCount > 1
                ? setHandleSlide({
                    ...handleSlide,
                    slideCount: handleSlide.slideCount - 1,
                  })
                : setHandleSlide({ ...handleSlide, slideCount: 0 })
            }
          >
            <ChevronRightIcon />
          </animated.div>
        )}

        <animated.ul
          className="olsUIComponentsTabs_headerList"
          ref={listElement}
          style={{
            transform: slideList.x.interpolate((x) => `translateX(${x}px)`),
          }}
        >
          {props.tabList.map((tabItem, index) => (
            <li
              className={classNames({
                olsUIComponentsTabs_headerListItem: true,
                active: selectedTab === tabItem.header,
                'olsUIComponentsTabs_headerListItem--singleTab':
                  props.tabList.length === 1,
                olsUIComponentsTabs_headerListItem_dashboardVariant: isDashboard,
              })}
              key={tabItem.header + index}
              role="tab"
              onClick={() => {
                if (selectedTab !== -1) {
                  tabClickedHandler(tabItem.header, index);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && selectedTab !== -1) {
                  tabClickedHandler(tabItem.header, index);
                }
              }}
            >
              {tabItem.header}
              {selectedTab === tabItem.header && (
                <div
                  className={classNames({
                    olsUIComponentsTabs_headerListItem_activeUnderline: true,
                    olsUIComponentsTabs_headerListItem_activeUnderline_dashboardVariant: isDashboard,
                  })}
                />
              )}
            </li>
          ))}
        </animated.ul>

        {handleSlide.listElementScrollWidth >
          handleSlide.slideCount * slideMultiplier +
            handleSlide.listElementWidth && (
          <animated.div
            className={classNames({
              olsUIComponentsTabs_iconArrow: true,
              'olsUIComponentsTabs_iconArrow--right': true,
              olsUIComponentsTabs_iconArrow_dashboardVariant: isDashboard,
            })}
            onClick={() =>
              setHandleSlide({
                ...handleSlide,
                slideCount: handleSlide.slideCount + 1,
              })
            }
          >
            <ChevronRightIcon />
          </animated.div>
        )}
      </div>
      <div className="olsUIComponentsTabs_contentContainer">
        {displayContent()}
      </div>
    </div>
  );
};

export default Tabs;
