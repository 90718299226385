import {
  createContext,
  RefObject,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';

export function useOnScreenDefault(ref: RefObject<Element>): boolean {
  const [intersecting, setIsIntersecting] = useState(false);
  // eslint-disable-next-line
  useLayoutEffect(() => {
    setIsIntersecting(!!ref?.current);
  }); // No deps, run every time
  return intersecting;
}

export interface UseOnScreenContextState {
  useOnScreen?: typeof useOnScreenDefault;
  setScrollOffset?: (offset: { x: number; y: number }) => void;
  setScrollRect?: (rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => void;
}

export const UseOnScreenContext = createContext<UseOnScreenContextState>({
  useOnScreen: useOnScreenDefault,
});
export const UseOnScreenProvider = UseOnScreenContext.Provider;
export function useUseOnScreenContext() {
  return useContext(UseOnScreenContext);
}

export function useOnScreen(ref: RefObject<Element>): boolean {
  const { useOnScreen: useContextBased } = useUseOnScreenContext() ?? {
    useOnScreen: useOnScreenDefault,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useContextBased(ref);
}
