import { FeatureFlagsVersion } from '@contact/data-access';
import {
  useBaseAxiosConfig,
  useFeatureFlags,
} from '@contact/data-access-hooks';
import { useConfig } from '../Config';
import { useFlag } from '../Flags';
import { useOptionalUser } from '../User';

export interface FeatureFlagHookOptions {
  /**
   * Read local env for the flag state as well.
   * Combines the local flag and remote flag state
   * using logical or.
   */
  useLocal?: boolean;
}

export const useFeatureFlag = (
  version: FeatureFlagsVersion,
  key: string,
  { useLocal }: FeatureFlagHookOptions = {}
) => {
  const config = useConfig();
  const baseAxiosConfig = useBaseAxiosConfig();
  const { bp } = useOptionalUser() || {};

  const { data, isSuccess } = useFeatureFlags(
    version,
    (config.environment as string) ||
      process.env.REACT_APP_ENVIRONMENT_NAME ||
      process.env.NX_ENVIRONMENT_NAME ||
      '',
    bp,
    '',
    baseAxiosConfig
  );

  let isEnabled = isSuccess && data && data[key];

  const enabledLocally = useFlag(key);
  if (useLocal && enabledLocally) {
    isEnabled = true;
  }

  return {
    isSuccess,
    data,
    isEnabled,
  };
};
