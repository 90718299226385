import React from 'react';
import { Flex, Text } from '@contact/ui-volta';
import { EnhancedText } from '../EnhancedText';

export interface Plan {
  planName: string;
  service: string;
  broadbandPrice?: string;
  contractPeriod: string;
  contractId: string;
  contractTerm: string;
  earlyTerminationFees: string;
}

export interface PlanContainerProps extends Plan {
  showContractId?: boolean;
}

export const PlanContainer = ({
  planName,
  service,
  broadbandPrice,
  contractPeriod,
  contractId,
  contractTerm,
  earlyTerminationFees,
  showContractId,
}: PlanContainerProps) => (
  <Flex direction="column" width="100%" data-testid="plan-container">
    <Flex
      paddingBottom={3}
      borderBottom="1px solid"
      borderBottomColor="grayAlpha.300"
    >
      <Flex direction="column" width="50%" marginRight={2}>
        <EnhancedText
          isTruncated
          variant="title"
          withTooltip
          tooltipPlacement="top-start"
        >
          {planName}
        </EnhancedText>
        <Text variant="subTitle" marginTop={1}>
          {service}
        </Text>
      </Flex>
      {broadbandPrice && (
        <Flex direction="column" width="50%" data-testid="broadband-price">
          <Text variant="label">Broadband Price</Text>
          <Text variant="normal" marginTop={1}>
            {`${broadbandPrice} /mth`}
          </Text>
        </Flex>
      )}
    </Flex>
    <Flex paddingTop={3} paddingBottom={3}>
      <Flex direction="column" width="50%" marginRight={2}>
        <Text variant="label">Contract</Text>
        <Text variant="normal" marginTop={1}>
          {contractPeriod}
        </Text>
      </Flex>
      {showContractId && (
        <Flex direction="column" width="50%" data-testid="contract-id">
          <Text variant="label">ID</Text>
          <Text variant="normal" marginTop={1}>
            {contractId}
          </Text>
        </Flex>
      )}
    </Flex>
    <Flex>
      <Flex direction="column" width="50%" marginRight={2}>
        <Text variant="label">Term</Text>
        <Text variant="normal" marginTop={1}>
          {contractTerm}
        </Text>
      </Flex>
      <Flex direction="column" width="50%">
        <Text variant="label">Early Termination Fees</Text>
        <Text variant="normal" marginTop={1}>
          {earlyTerminationFees}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
