import { format, parse } from 'date-fns';

const LEGACY_FORMAT = 'yyyyMM';

const MONTHLY_FORMAT = 'MMM'; // Jan, Feb, ..., Dec
const BILLING_PERIOD_FORMAT = 'dd MMM'; // 09 Jan, 09 Feb, ..., 09 Dec

const getDateFromApi = (value: string) => {
  if (value.length === LEGACY_FORMAT.length) {
    // e.g. 202106
    return parse(value, LEGACY_FORMAT, new Date());
  }
  // e.g. 2021-06-13T00:00:00.000+12:00
  return new Date(value);
};

export const formatDateTime = (value: string) => {
  if (value.length < LEGACY_FORMAT.length) {
    // Assume already formatted
    return value;
  }

  const parsedDate = getDateFromApi(value);

  const formatString =
    parsedDate.getDate() === 1 ? MONTHLY_FORMAT : BILLING_PERIOD_FORMAT;

  return format(parsedDate, formatString);
};
