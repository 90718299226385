import React from 'react';
import classNames from 'classnames';
import { HTML } from '../HTML';

interface ErrorProps {
  messages?: Record<string, string>;
  className?: string;
  errorClassName?: string;
  errorMessage?: string;
}

const Error = ({
  messages = {},
  className = '',
  errorClassName = '',
  errorMessage = '',
}: ErrorProps) => (
  <div className={classNames('contactUIComponentsRates', className)}>
    <div className="contactUIComponentsRates_top-row">
      <div
        className={classNames(
          'contactUIComponentsRates_error',
          errorClassName ? errorClassName : ''
        )}
      >
        <HTML html={errorMessage ? errorMessage : messages.error} />
      </div>
    </div>
  </div>
);

export default Error;
