export interface AxiosError {
  isAxiosError: boolean;
  response?: {
    status: number;
  };
}

export function isAxiosError(error: unknown): error is AxiosError {
  function isAxiosErrorLike(
    error: unknown
  ): error is { isAxiosError: unknown } {
    return !!error;
  }
  return isAxiosErrorLike(error) && error.isAxiosError === true;
}
