import React from 'react';
import { Box, forwardRef } from '@chakra-ui/react';
import { BellIcon } from '../../Icons';

import './AppNotificationIcon.scss';

export interface AppNotificationIconProps {
  badge?: boolean;
  height?: number;
  width?: number;
}

export const AppNotificationIcon = forwardRef<AppNotificationIconProps, 'div'>(
  ({ badge, ...props }, ref) => {
    return (
      <Box
        {...props}
        ref={ref}
        className="contactUIComponentsAppNotificationIcon_container"
      >
        <BellIcon />

        {!!badge && (
          <div className="contactUIComponentsAppNotificationIcon_badge" />
        )}
      </Box>
    );
  }
);
