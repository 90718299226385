import { Text } from '@chakra-ui/react';
import React from 'react';

export interface HTMLProps {
  html?: string;
  className?: string;
}

export const HTML = ({ html: __html = '', className }: HTMLProps) => (
  <Text className={className} dangerouslySetInnerHTML={{ __html }} />
);
