import { Flex, Spinner } from '@chakra-ui/react';
import { PageContent } from '../Page/PageContent';
import React from 'react';

export function LoadingPageContent() {
  return (
    <PageContent>
      <Flex
        data-testid="LoadingPageContent"
        alignItems="center"
        justifyContent="center"
        padding="3em"
        width="100%"
      >
        <Spinner thickness="4px" speed="0.65s" size="xl" />
      </Flex>
    </PageContent>
  );
}
