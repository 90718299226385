import React, { useState, PropsWithChildren, ReactNode } from 'react';
import { Input } from '../Input';
import { Button } from '../Button';
import { RedTick } from '../../Assets/Icons';
import { Spinner } from '@chakra-ui/react';
import classNames from 'classnames';
import './PromoCode.scss';

export interface PromoProps {
  placeholder: string;
  buttonText?: string;
  buttonTextHover?: string;
  buttonTextApplied?: string;
  maxLength: number;
  code?: string;
  hasError?: boolean;
  successMessage?: string;
  invalidMessage?: string;
  codeSubmitted?: boolean;
  setCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  validateItself?: boolean;
  setCodeSumbitted: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: string;
  handleError?: React.Dispatch<React.SetStateAction<boolean>>;
  isCodeValidating?: boolean;
  buttonDisabled?: boolean;
  inputProps?: Record<string, unknown>;
}

export function PromoCode({
  placeholder,
  buttonText,
  buttonTextHover,
  buttonTextApplied,
  maxLength = 10,
  code,
  hasError,
  errorMessage,
  successMessage,
  invalidMessage,
  codeSubmitted,
  setCode,
  setCodeSumbitted,
  validateItself,
  handleError,
  isCodeValidating,
  buttonDisabled,
  inputProps,
}: PropsWithChildren<PromoProps>) {
  const [hover, setHover] = useState(false);
  return (
    <div className="promoCode">
      {successMessage && (
        <div className="promoCode_banner">
          {successMessage ? successMessage : ''}
        </div>
      )}
      <div className="promoCode_field">
        <Input
          name="promoCode"
          value={code}
          handleChange={(val) => {
            setCode(val);
            // mark it as not ready to validate when the promo code is changed
            // we only validate when clicking the Validate button
            setCodeSumbitted(false);
          }}
          maxLength={maxLength}
          labelText={placeholder}
          handleError={handleError}
          hasError={hasError}
          errorMessage={errorMessage}
          invalidMessage={invalidMessage}
          validateItself={validateItself}
          inputProps={inputProps}
        />
        <Button
          className={classNames(
            'promoCode_field_button',
            codeSubmitted && 'promoCode_field_button_submitted'
          )}
          type="button"
          onClick={() => setCodeSumbitted(!!code)}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          disabled={buttonDisabled || codeSubmitted}
          variant="primary"
        >
          {codeSubmitted ? (
            <>
              <RedTick /> {buttonTextApplied}
            </>
          ) : hover ? (
            <>
              {buttonTextHover}
              {isCodeValidating && (
                <div className="promoCode_loader">
                  <Spinner thickness="10px" speed="0.65s" size="xxl" />
                </div>
              )}
            </>
          ) : (
            <>
              {buttonText}
              {isCodeValidating && (
                <div className="promoCode_loader">
                  <Spinner thickness="10px" speed="0.65s" size="xxl" />
                </div>
              )}
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
