import React, { useRef } from 'react';
import {
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Popover,
  PopoverArrow,
} from '@chakra-ui/popover';
import { Box, ChakraProps, PopoverContentProps } from '@chakra-ui/react';
import {
  useAppNotifications,
  useTranslationWithNamespace,
} from '@contact/react';
import { AppNotificationNamespace } from '../Translations';
import { AppNotificationIconConnected } from '../Icon';
import { AppNotificationPopoverContent } from './AppNotificationPopoverContent';
import { AppNotificationPopoverContainerProps } from './AppNotificationPopover.types';

import './AppNotificationPopoverDesktop.scss';

const styles: {
  content: PopoverContentProps;
  [key: string]: ChakraProps;
} = {
  content: {
    bg: '#fff',
    padding: 0,
    width: '440px',
    maxWidth: '440px',
    maxHeight: '504px',
    margin: '44px 0 20px 0',
    borderRadius: '4px',
    boxShadow: '2px 10px 30px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    zIndex: -2,
  },
  arrow: {
    bg: '#fff !important',
    top: 0,
  },
  triggerInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxSize: '32px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 4,
    overflow: 'hidden',
  },
};

export function AppNotificationPopoverDesktop({
  onMarkAllAsRead,
  ...props
}: AppNotificationPopoverContainerProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = props;

  // When the popover content is modified, the popover no longer
  // closes when clicking outside of the popover. Workaround this
  // issue by restoring focus to popover content on every render,
  // when it is open.
  if (isOpen && contentRef.current) {
    contentRef.current.focus();
  }

  return (
    // Re-align with marginTop, since trigger has zero height
    <Box display="inline-block" position="relative" marginTop="-6px">
      <div className="contactUIComponentsAppNotificationPopoverDesktop">
        <Popover
          isLazy
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          placement="bottom-end"
          arrowSize={16}
          offset={[60, 0]}
          initialFocusRef={contentRef}
        >
          <PopoverTrigger>
            <Box cursor="pointer" display="inline-block">
              {/*
               * Use a zero height trigger to workaround issue in Safari
               * https://github.com/chakra-ui/chakra-ui/issues/3461
               */}
              <Box height="0">
                <Box {...styles.triggerInnerContainer}>
                  <button className="contactUIComponentsAppNotificationPopoverDesktop_button">
                    <AppNotificationIconConnected />
                  </button>
                </Box>
              </Box>
            </Box>
          </PopoverTrigger>

          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <PopoverContent ref={contentRef} {...(styles.content as any)}>
            <PopoverArrow {...styles.arrow} />
            <PopoverBody {...styles.body}>
              <AppNotificationPopoverDesktopHeader
                onMarkAllAsRead={onMarkAllAsRead}
              />
              <AppNotificationPopoverContent {...props} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
    </Box>
  );
}

function AppNotificationPopoverDesktopHeader({
  onMarkAllAsRead,
}: Pick<AppNotificationPopoverContainerProps, 'onMarkAllAsRead'>) {
  const [t] = useTranslationWithNamespace(AppNotificationNamespace);
  const { markAllAsRead, unreadNotificationCount } = useAppNotifications();
  return (
    <div
      className="contactUIComponentsAppNotificationPopoverDesktop_header"
      role="menubar"
    >
      {t('headerTitle')}
      {unreadNotificationCount > 0 && (
        <button
          className="contactUIComponentsAppNotificationPopoverDesktop_markAllAsReadButton"
          onClick={() => {
            markAllAsRead();
            onMarkAllAsRead?.();
          }}
          role="menuitem"
        >
          {t('markAllAsRead')}
        </button>
      )}
    </div>
  );
}
