import React from 'react';
import { useStyleConfig } from '@chakra-ui/react';
// Use full import else will create circular dep
import { PageContent } from '../Page/PageContent';

export interface GlobalFooterProps {
  variant?: string;
}

export function GlobalFooter({ variant }: GlobalFooterProps) {
  const styles = useStyleConfig('GlobalFooter', { variant });
  return (
    <PageContent sx={styles} as="footer">
      {' '}
    </PageContent>
  );
}
