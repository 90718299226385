import React from 'react';
import { Alert } from './Alert';
import { useAlerts } from '@contact/react';
import './Alerts.scss';
import classNames from 'classnames';

export interface AlertsProps {
  section?: unknown;
  className?: string;
  classNameNotEmpty?: string;
}

export function Alerts({ section, className, classNameNotEmpty }: AlertsProps) {
  const [alerts] = useAlerts(section);
  return (
    <div
      className={classNames(
        {
          contactUIComponentsAlerts_container: true,
          'contactUIComponentsAlerts_container--notEmpty': !!alerts.length,
        },
        className,
        classNameNotEmpty && !!alerts.length ? classNameNotEmpty : null
      )}
    >
      {alerts.map((alert, index) => (
        <Alert key={index} alert={alert} />
      ))}
    </div>
  );
}
