import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  MouseEventHandler,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ArrowRight } from '../../Assets/Icons';
import Icon from '../Icon';
import { NavigationDots } from '../NavigationDots';
import './PlanCarousel.scss';

export interface PlanCarouselItem<T> {
  id: string;
  data: T;
}

export interface PlanCarouselItemsProps<T> {
  data: PlanCarouselItem<T>[];
  itemChecked: number | null;
  onItemSelect: MouseEventHandler<HTMLButtonElement>;
  registerRef: React.Ref<HTMLDivElement>;
  baseUrl: string;
}

export interface PlanCarouselProps<T> {
  id: string;
  data: PlanCarouselItem<T>[];
  renderItems: (props: PlanCarouselItemsProps<T>) => React.ReactElement;
  selectedIndex: number;
  onSelect: (item: T | null, index: number | null) => void;
  scrollToSelectedItem?: boolean;
  resetScrollToSelectedItem?: () => void;
  baseUrl: string;
}

export function PlanCarousel<T>({
  id,
  data,
  selectedIndex,
  onSelect,
  scrollToSelectedItem,
  resetScrollToSelectedItem,
  renderItems,
  baseUrl,
}: PlanCarouselProps<T>) {
  const [itemChecked, setItemChecked] = useState<number | null>(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [dragScroll, setDragScroll] = useState(0);
  const [controlsScroll, setControllsScroll] = useState(false);
  const step = 350;

  const refs = useRef<HTMLDivElement[]>([]);
  const registerRef = useCallback((element) => refs.current.push(element), []);

  useEffect(() => {
    if (scrollToSelectedItem) {
      refs.current[selectedIndex]?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
    return () => resetScrollToSelectedItem?.();
  });

  useEffect(() => {
    setItemChecked(selectedIndex);
  }, [selectedIndex]);

  const onItemSelect = (index) => {
    setItemChecked(itemChecked !== index ? index : null);
    if (itemChecked !== index) {
      onSelect(data[index].data, index);
    } else {
      onSelect(null, null);
    }
  };

  const scrollToElement = useCallback(
    (params, isIndex) => {
      setControllsScroll(true);
      let index = 0;
      if (scrollIndex >= 0) {
        if (isIndex) {
          index = params;
        } else {
          index =
            scrollIndex +
            (params === 'right'
              ? scrollIndex < data.length - 1
                ? 1
                : 0
              : scrollIndex > 0
              ? -1
              : 0);
        }
      }
      const element = document.getElementById(data[index]?.id);
      setScrollIndex(index);
      if (element) {
        // setTimout gives enough time for the element to be rendered
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }, 0);
        if (index === data.length - 1) {
          setScrollIndex(index);
        }
      }
    },
    [data, scrollIndex]
  );

  useEffect(() => {
    if (selectedIndex > -1) {
      scrollToElement(selectedIndex, true);
    }
    // Only scroll when selectedIndex has been changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const onScrollStart = (event) => {
    console.log('scroll started');
    setDragScroll(event);
  };

  const onScrollEnd = (event) => {
    if (!controlsScroll) {
      const scrollDifference = event - dragScroll;
      const scrollDitanse = Math.round(scrollDifference / step) * step;
      const numberOfSteps = Math.round(scrollDitanse / step);
      if (numberOfSteps >= 0) {
        setScrollIndex(scrollIndex + numberOfSteps);
      } else if (numberOfSteps < 0) {
        setScrollIndex(scrollIndex - Math.abs(numberOfSteps));
      }
      setControllsScroll(false);
    } else {
      setControllsScroll(false);
    }
  };

  const scrollRight = () => {
    scrollToElement('right', false);
  };

  const scrollLeft = () => {
    scrollToElement('left', false);
  };

  const showArrows = data.length > 3;

  return (
    <div id={id} className="contactUIComponentsPlanCarousel">
      <div className="contactUIComponentsPlanCarousel_listContainer">
        {showArrows && (
          <div
            className="contactUIComponentsPlanCarousel_arrow contactUIComponentsPlanCarousel_arrow--left"
            onClick={scrollLeft}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
        <ScrollContainer
          className="contactUIComponentsPlanCarousel_container"
          onStartScroll={(event) => onScrollStart(event)}
          onEndScroll={(event) => onScrollEnd(event)}
        >
          {renderItems({
            data,
            itemChecked,
            onItemSelect,
            registerRef,
            baseUrl,
          })}
        </ScrollContainer>
        {showArrows && (
          <div
            className="contactUIComponentsPlanCarousel_arrow contactUIComponentsPlanCarousel_arrow--right"
            onClick={scrollRight}
          >
            <Icon icon={<ArrowRight />} size={28} />
          </div>
        )}
      </div>
      <NavigationDots
        elements={data}
        index={scrollIndex}
        onClick={scrollToElement}
      />
    </div>
  );
}
