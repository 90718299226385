import { DisplayError, isDisplayError } from './DisplayError';

export class DisplayReactComponentError extends DisplayError {
  readonly node: JSX.Element;

  constructor(node: JSX.Element, message = 'See UI for details') {
    super(message);
    this.node = node;
  }
}

export function isDisplayReactComponentError(
  error: unknown
): error is DisplayReactComponentError {
  return (
    error instanceof DisplayReactComponentError ||
    (isLike(error) && !!error.node)
  );
  function isLike(
    error: unknown
  ): error is Partial<DisplayReactComponentError> {
    return isDisplayError(error);
  }
}
