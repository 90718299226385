import { VoltaTheme } from '@contact/ui-volta';

export const Popover = {
  baseStyle: (props) => ({
    ...VoltaTheme.components.Popover.baseStyle(props),
    popper: { minWidth: 'max-content', zIndex: 999 },
    content: {
      display: 'inline-block',
      bg: '#f2e8ea',
      boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      padding: '20px 16px 16px',
      maxWidth: '300px',
      mt: '40px',
      outline: 'none',
    },
    header: {
      border: 'none',
      color: 'var(--red-2)',
      fontSize: '16px',
      fotnWeight: '800',
      mb: '8px',
      mr: '26px',
    },
    body: {
      padding: 0,
      fontSize: '14px',
      lineHeight: 1.57,
      whiteSpace: 'pre-line',
    },
    footer: {
      borderTopWidth: 0,
      paddingTop: '16px',
      fontSize: '14px',
    },
    arrow: {
      bg: '#f2e8ea !important',
      width: '16px !important',
      height: '16px !important',
      transform: 'rotate(45deg) !important',
      top: '-3px !important',
      zIndex: -1,
      boxShadow: 'none !important',
    },
  }),
};
