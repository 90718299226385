import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';

export const resetPassword = (
  activationKey: string,
  requestId: string,
  password: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).put<void>('/password/reset-password', {
    activationKey,
    requestId,
    password,
  });
};
