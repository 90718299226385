import React, { MouseEventHandler } from 'react';
import { PlanCarouselItem } from '../PlanCarousel';
import { PlanCard, SiteCorePlan } from '../PlanCard';
import './PlanCards.scss';

export interface BillCompareSavedAmount {
  amt: number;
  planId: string;
}

interface PlanCardsProps {
  data: PlanCarouselItem<SiteCorePlan>[];
  itemChecked: number | null;
  onItemSelect: MouseEventHandler<HTMLButtonElement>;
  registerRef: React.Ref<HTMLDivElement>;
  baseUrl: string;
  billCompareSaves: BillCompareSavedAmount[];
}

export const PlanCards = ({
  data,
  baseUrl,
  itemChecked,
  onItemSelect,
  registerRef,
  billCompareSaves,
}: PlanCardsProps) => {
  return (
    <>
      {data.map((item, index) => {
        const saved = billCompareSaves?.find(
          (result) => result.planId === item.id
        );
        return billCompareSaves.length > 0 ? (
          <div className="contactUIComponentsPlanCards_billCompare" key={index}>
            <div
              className={
                saved
                  ? 'contactUIComponentsPlanCards_enrichedCards'
                  : 'contactUIComponentsPlanCards_enrichedCards contactUIComponentsPlanCards_enrichedCards--disabled'
              }
            >
              You would've saved
              <span>&nbsp;${saved ? saved.amt.toFixed(2) : 0}</span>
              &nbsp; on your bill!
            </div>
            <PlanCard
              index={index}
              item={item}
              url={baseUrl}
              itemChecked={itemChecked}
              onItemSelect={onItemSelect}
              itemRef={registerRef}
            />
          </div>
        ) : (
          <PlanCard
            index={index}
            item={item}
            url={baseUrl}
            itemChecked={itemChecked}
            onItemSelect={onItemSelect}
            itemRef={registerRef}
          />
        );
      })}
    </>
  );
};
