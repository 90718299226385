export const PageContent = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    margin: '0 auto',
    width: '100%',
    maxWidth: 'var(--max-page-content-width)',
    padding: '0 var(--page-content-padding)',
  },
  defaultProps: {},
  variants: {},
};
