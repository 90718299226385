import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

export const CheckedIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g transform="translate(0 24)">
      <path
        fill="currentColor"
        d="M19-21H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2v-14a2.006 2.006 0 0 0-2-2zM10.71-7.71a1 1 0 0 1-1.41 0L5.71-11.3a1 1 0 0 1-.292-.7 1 1 0 0 1 .292-.7 1 1 0 0 1 1.41 0L10-9.83l6.88-6.88a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.41z"
      />
    </g>
  </Icon>
);
