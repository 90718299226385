export interface DisplayError extends Error {
  display: true;
}

export class DisplayError extends Error {
  display: true = true;
}

export function isDisplayError(error: unknown): error is DisplayError {
  function isError(error: unknown): error is Error & { display: unknown } {
    return error instanceof Error;
  }
  return (
    error instanceof DisplayError || (isError(error) && error.display === true)
  );
}
