import React, { ReactElement } from 'react';
import {
  useRadio,
  RadioProps as ChakraRadioProps,
  Box,
  Flex,
} from '@chakra-ui/react';
import { CheckedIcon } from './checked-icon';
import { UncheckedIcon } from './unchecked-icon';

export type RadioProps = Omit<ChakraRadioProps, 'size' | 'colorScheme'>;

export const Radio = (props: RadioProps): ReactElement => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const iconSize = 6;
  let iconColor = 'purple.800';
  if (props.isInvalid) {
    iconColor = 'red.500';
  } else if (props.isDisabled) {
    iconColor = 'gray.600';
  }

  return (
    <Flex {...props} as="label" cursor="pointer">
      <input {...input} />
      <Box width={iconSize} height={iconSize} color={iconColor}>
        {props.isChecked ? (
          <CheckedIcon width={iconSize} height={iconSize} />
        ) : (
          <UncheckedIcon width={iconSize} height={iconSize} />
        )}
      </Box>
      <Box
        {...checkbox}
        marginLeft="2"
        _disabled={{
          color: 'gray.600',
        }}
      >
        {props.children}
      </Box>
    </Flex>
  );
};
