import { AnalyticsEvent } from './event';

export interface DataLayerItem {
  [key: string]: unknown;
}
export type DataLayer = DataLayerItem[];

declare global {
  interface Window {
    dataLayer?: DataLayer;
  }
}

export const dataLayerEvent = (event: AnalyticsEvent) => {
  if (event && typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
};

export const dleHeaderPayNowButtonPressedEvent = () => {
  return {
    event: 'cta_click',
    panel: 'view_bill_header',
    component: 'pay now',
  };
};

export const dleHeaderPayNowButtonPressed = () => {
  return dataLayerEvent(dleHeaderPayNowButtonPressedEvent());
};

export const dleDownloadLatestInvoiceButtonPressedEvent = () => {
  return {
    event: 'view_pdf',
    //   'file_name' : listItem_date  //optional (add once/if available)
  };
};

export const dleDownloadLatestInvoiceButtonPressed = () => {
  return dataLayerEvent(dleDownloadLatestInvoiceButtonPressedEvent());
};

export const dleDownloadSpecificInvoiceButtonPressedEvent = (
  index: number
): AnalyticsEvent => {
  return {
    event: 'view_pdf',
    index: `${index}`, //optional - returns the position of the bill in the list
    //   'file_name' : listItem_date, //optional (add once/if available)
  };
};

export const dleDownloadSpecificInvoiceButtonPressed = (index: number) => {
  return dataLayerEvent(dleDownloadSpecificInvoiceButtonPressedEvent(index));
};

export const dlePanelExpandedEvent = (expandedItem: string): AnalyticsEvent => {
  return {
    event: 'expand',
    expandedItem,
  };
};

export const dlePanelExpanded = (expandedItem: string) => {
  return dataLayerEvent(dlePanelExpandedEvent(expandedItem));
};

export const dleVirtualPageViewEvent = (
  virtualUrl: string,
  virtualPageTitle?: string
): AnalyticsEvent => {
  return {
    event: 'virtualPageview',
    virtualUrl,
    virtualPageTitle,
  };
};

export const dleScreenViewEvent = (
  screenName: string,
  details: { error_msg?: string } = {}
): AnalyticsEvent => {
  return {
    event: 'screen_view',
    screen_name: screenName,
    ...details,
  };
};

export const dleVirtualPageview = (virtualUrl: string) => {
  return dataLayerEvent(dleVirtualPageViewEvent(virtualUrl));
};
export const dleVirtualPageView = dleVirtualPageview;

export const dleTechnicalError = (
  errorType?: string | number
): AnalyticsEvent => {
  return dleVirtualPageViewEvent(
    `/technical-error/${errorType ?? ''}`,
    'Sorry, something went wrong'
  );
};

export const htmlToText = (html: string) => {
  if (typeof document === 'undefined') {
    return html;
  }
  const tempEle = document?.createElement('div');
  tempEle.innerHTML = html;
  return tempEle.textContent || tempEle.innerText || html;
};
