import { createContext, useContext } from 'react';

function reload() {
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
}

export const ReloadContext = createContext(reload);
export const ReloadProvider = ReloadContext.Provider;

export function useReload() {
  return useContext(ReloadContext);
}
