import React from 'react';

export const RedTick = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="15"
      viewBox="0 0 19 15"
    >
      <path
        fill="none"
        fill-rule="evenodd"
        stroke="#DD353F"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M2 7.988L6.695 13 17 2"
      />
    </svg>
  );
};
