import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const CalendarIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M16 2h-1V1c0-.5-.5-1-1-1s-1 .5-1 1v1H5V1c0-.5-.4-1-1-1S3 .5 3 1v1H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 16H3c-.6 0-1-.5-1-1V7h14v10c0 .5-.5 1-1 1zM5 9h3c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-3c0-.6.4-1 1-1z"
      fill="currentColor"
    />
  </Icon>
);
