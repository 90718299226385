// eslint-disable @typescript/camelcase
import React from 'react';
import classNames from 'classnames';

import './Loading.scss';

export const Loading = (props) => {
  const loadingClass = classNames({
    contactUIComponentsLoading: true,
    'contactUIComponentsLoading--inContainer': props.isInContainer,
  });

  const spinnerClass = classNames({
    contactUIComponentsLoading_spinner: true,
    'contactUIComponentsLoading_spinner--inContainer': props.isInContainer,
  });

  const bounce1 = classNames({
    contactUIComponentsLoading_doubleBounce1: true,
    'contactUIComponentsLoading_doubleBounce1--white': props.isInContainer,
  });

  const bounce2 = classNames({
    contactUIComponentsLoading_doubleBounce2: true,
    'contactUIComponentsLoading_doubleBounce2--white': props.isInContainer,
  });

  return (
    <div className={loadingClass} style={props.customstyle}>
      <div className={spinnerClass}>
        <div className={bounce1} />
        <div className={bounce2} />
      </div>
    </div>
  );
};
