export const Checkbox = {
  baseStyle: {
    control: {
      width: 6,
      height: 6,
      border: 0,
      _disabled: {
        backgroundColor: 'white',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    icon: {
      width: 6,
      height: 6,
      opacity: 1,
      backgroundColor: 'white',
    },
    label: {
      _disabled: {
        opacity: 1,
        color: 'gray.600',
      },
    },
  },
  sizes: {
    default: {},
  },
  defaultProps: {
    size: 'default',
    colorScheme: 'white',
  },
};
