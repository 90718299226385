export const Accordion = {
  baseStyle: {
    button: {
      paddingX: 0,
      paddingY: 2,
      borderBottomWidth: '1px',
      borderBottomColor: 'gray.200',

      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        background: 'transparent',
      },
    },
    container: {
      borderTopWidth: 0,
      _last: {
        borderBottomWidth: 0,
      },
    },
    icon: {
      fontSize: '3xl',
      color: 'purple.800',
    },
    panel: {
      paddingX: 0,
      paddingY: 3,
    },
  },
};
