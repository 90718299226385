import React from 'react';
import { Alert } from '@contact/ui-components';
import { FormControl, FormErrorMessage } from '@contact/ui-volta';

export const FormAlert = ({ message, icon = 'Flag' }) => {
  return (
    <FormControl isInvalid={true}>
      <FormErrorMessage className="form-error">
        <Alert
          alert={{
            content: message,
            icon,
            styles: {
              '--alert-text-color': 'var(--contact-core-red)',
              '--alert-icon-color': 'var(--contact-core-red)',
              '--alert-border-color': '#E62A3233',
            },
          }}
        />
      </FormErrorMessage>
    </FormControl>
  );
};
