import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const PlanIconWithCircle = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g
      id="Ellipse_111"
      data-name="Ellipse 111"
      fill="none"
      stroke="#e62a32"
      strokeWidth="1.5"
    >
      <circle cx="24" cy="24" r="24" stroke="none" />
      <circle cx="24" cy="24" r="23.25" fill="none" />
    </g>
    <g
      id="Group_3924"
      data-name="Group 3924"
      transform="translate(13.981 11.187)"
    >
      <g
        id="Path_2309"
        data-name="Path 2309"
        transform="translate(-155.225 58.947)"
        fill="none"
      >
        <path
          d="M170.016-35.5H157.66a2.425,2.425,0,0,1-2.435-2.435V-56.512a2.425,2.425,0,0,1,2.435-2.435h12.356a2.425,2.425,0,0,1,2.435,2.435v18.579A2.319,2.319,0,0,1,170.016-35.5Z"
          stroke="none"
        />
        <path
          d="M 170.0160064697266 -36.99800109863281 C 170.3248443603516 -36.99800109863281 170.5909881591797 -37.10065841674805 170.7654113769531 -37.28707122802734 C 170.9049530029297 -37.43621063232422 170.968505859375 -37.62002944946289 170.9543151855469 -37.83343887329102 L 170.9510040283203 -37.88317108154297 L 170.9510040283203 -56.51200103759766 C 170.9510040283203 -57.03630065917969 170.5402984619141 -57.44699859619141 170.0160064697266 -57.44699859619141 L 157.6600036621094 -57.44699859619141 C 157.1357116699219 -57.44699859619141 156.7250061035156 -57.03630065917969 156.7250061035156 -56.51200103759766 L 156.7250061035156 -37.93299865722656 C 156.7250061035156 -37.40869903564453 157.1357116699219 -36.99800109863281 157.6600036621094 -36.99800109863281 L 170.0160064697266 -36.99800109863281 M 170.0160064697266 -35.49800109863281 L 157.6600036621094 -35.49800109863281 C 156.3070068359375 -35.49800109863281 155.2250061035156 -36.57999801635742 155.2250061035156 -37.93299865722656 L 155.2250061035156 -56.51200103759766 C 155.2250061035156 -57.8650016784668 156.3070068359375 -58.94699859619141 157.6600036621094 -58.94699859619141 L 170.0160064697266 -58.94699859619141 C 171.3679962158203 -58.94699859619141 172.4510040283203 -57.8650016784668 172.4510040283203 -56.51200103759766 L 172.4510040283203 -37.93299865722656 C 172.5410003662109 -36.57999801635742 171.4589996337891 -35.49800109863281 170.0160064697266 -35.49800109863281 Z"
          stroke="none"
          fill="#e62a32"
        />
      </g>
      <path
        id="Path_2310"
        data-name="Path 2310"
        d="M172.83-56.133a2.425,2.425,0,0,1,2.435,2.435v17.583a3.432,3.432,0,0,1-3.432,3.431H160.474a2.425,2.425,0,0,1-2.435-2.435"
        transform="translate(-155.225 58.947)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <line
        id="Line_186"
        data-name="Line 186"
        x2="8.748"
        transform="translate(4.241 14.971)"
        fill="none"
        stroke="#e62a32"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_187"
        data-name="Line 187"
        x2="8.748"
        transform="translate(4.241 18.038)"
        fill="none"
        stroke="#e62a32"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <g
        id="Group_3923"
        data-name="Group 3923"
        transform="translate(6.433 4.233)"
      >
        <path
          id="Path_2311"
          data-name="Path 2311"
          d="M165.572-49.049a2.189,2.189,0,0,1-1.3.543v.84h-.75v-.818a4.035,4.035,0,0,1-1.057-.194,2.661,2.661,0,0,1-.81-.4l.413-.915a2.68,2.68,0,0,0,.667.352,3.048,3.048,0,0,0,.787.18v-1.252a5.658,5.658,0,0,1-.9-.285,1.586,1.586,0,0,1-.614-.461,1.236,1.236,0,0,1-.248-.806,1.447,1.447,0,0,1,.454-1.068,2.157,2.157,0,0,1,1.308-.544v-.832h.75v.817a3.294,3.294,0,0,1,1.522.458l-.375.922a2.98,2.98,0,0,0-1.147-.39v1.274a5.569,5.569,0,0,1,.889.274,1.553,1.553,0,0,1,.61.458,1.217,1.217,0,0,1,.248.8A1.421,1.421,0,0,1,165.572-49.049Zm-2.459-2.973a1.17,1.17,0,0,0,.412.2v-1.065a.816.816,0,0,0-.419.214.484.484,0,0,0-.136.341A.385.385,0,0,0,163.113-52.022Zm1.567,2.332a.441.441,0,0,0,.135-.323.389.389,0,0,0-.139-.307,1.222,1.222,0,0,0-.4-.21v1.035A.809.809,0,0,0,164.68-49.69Z"
          transform="translate(-161.658 54.714)"
          fill="#e62a32"
        />
      </g>
    </g>
  </Icon>
);
