import React, {
  ReactNode,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import Portal from '../Modal/Portal';
import { CloseIcon } from '../Icons';
import './ErrorModal.scss';
import { dataLayerEvent } from '@contact/react';

export type ErrorModalProps = {
  buttonText?: string;
  content?: ReactNode;
  buttonUrl?: string;
  onButtonClick?: MouseEventHandler<HTMLElement>;
  handleClose?: () => void;
  isActive: boolean;
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  containerClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
};

export const ErrorModal = forwardRef<HTMLDivElement, ErrorModalProps>(
  (props: ErrorModalProps, ref) => {
    const body = useMemo(() => document.querySelector('body'), []);
    const {
      children,
      isActive,
      buttonUrl,
      handleClose,
      onButtonClick,
      buttonText,
      className,
      contentClassName,
      containerClassName,
      titleClassName,
      subTitleClassName,
      subTitle,
      ...rest
    } = props;

    useEffect(() => {
      if (body && body.style) {
        body.style.overflow = isActive ? 'hidden' : '';
      }
    }, [body, isActive]);

    useEffect(() => {
      dataLayerEvent({
        event: 'error',
        msg: props.content,
        journey: 'ChangePlan',
        type: 'eligibility',
      });
    }, [props.content]);

    const modalClassNames = classNames(
      'contactUIComponentsModal',
      isActive && 'contactUIComponentsModal--visible',
      className
    );

    const stopPropagation = useCallback((e) => {
      e.stopPropagation();
    }, []);

    return (
      <Portal>
        <div
          ref={ref}
          {...rest}
          className={modalClassNames}
          onClick={handleClose}
        >
          <div
            className={classNames(
              'contactUIComponentsModal_container',
              containerClassName
            )}
            onClick={stopPropagation}
          >
            <div className="contactUIComponentsModal_header">
              {handleClose && (
                <CloseIcon
                  onClick={handleClose}
                  className="contactUIComponentsModal_close-icon"
                  boxSize={{
                    base: '24px',
                    lg: '24px',
                  }}
                />
              )}
              <h2
                className={classNames(
                  'contactUIComponentsModal_title',
                  titleClassName
                )}
              >
                {props.title}
              </h2>
            </div>

            <div className="contactUIComponentsModal_contentContainer">
              {props.subTitle && (
                <h2
                  className={classNames(
                    'contactUIComponentsModal_subTitle',
                    subTitleClassName
                  )}
                >
                  {props.subTitle}
                </h2>
              )}
              <p
                className={classNames(
                  'contactUIComponentsModal_content',
                  contentClassName
                )}
              >
                {props.content}
              </p>
            </div>
            {buttonUrl && (
              <div className="contactUIComponentsModal_buttonWrapper">
                <a
                  href={buttonUrl}
                  onClick={onButtonClick}
                  className="contactUIComponentsModal_button"
                >
                  {buttonText}
                </a>
              </div>
            )}
          </div>
        </div>
      </Portal>
    );
  }
);
