import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../components';

export const BottledGas = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g transform="translate(15 8)">
      <g transform="translate(15.871 0.306)">
        <path
          d="M0,0V7"
          transform="translate(-0.5 -0.299)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(3.206 0.306)">
        <path
          d="M0,0V7.165"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(0.79)">
        <path
          d="M0,0H17.487"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(3.202 29.004)">
        <path
          d="M12.634,0V4.247A.8.8,0,0,1,11.79,5H.844A.8.8,0,0,1,0,4.247V0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(0.139 18.759)">
        <path
          d="M0,0A29.169,29.169,0,0,0,18.686.024"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(0 5.097)">
        <path
          d="M9.511,26h0A9.28,9.28,0,0,1,0,17.028V8.971A9.28,9.28,0,0,1,9.51,0h0a9.28,9.28,0,0,1,9.51,8.971v8.057A9.28,9.28,0,0,1,9.511,26Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </g>
    <rect width="50" height="50" fill="none" />
  </Icon>
);
