import React from 'react';
import { Namespace } from './Translations';
import { useTranslationWithNamespace } from '@contact/react';

export const ContactPhoneLink = () => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', { returnObjects: true });
  const contactPhone = translations.phone;

  return (
    <a href={`tel:${contactPhone}`} className="link-underlined">
      {contactPhone}
    </a>
  );
};
