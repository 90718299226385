import { useMemo, useReducer } from 'react';
import { ElementType, parseDocument } from 'htmlparser2';
import { Node } from 'domhandler';
import render from 'dom-serializer';
import { textContent } from 'domutils';

export function useAlertSeeMore(
  text: string,
  {
    enabled = true,
    maxLength = 0,
  }: { enabled?: boolean; maxLength?: number } = {}
): [string, boolean, () => void, boolean] {
  const [seeMore, onToggle] = useReducer((value) => !value, false);
  const trimmed = useAlertSeeMoreTextTrimmed(text, maxLength);
  return [
    !enabled || seeMore ? text : trimmed,
    seeMore,
    onToggle,
    // Disable if the text is the same as original
    enabled && text !== trimmed,
  ];
}

export function useAlertSeeMoreTextTrimmed(
  text: string,
  maxLength: number
): string {
  return useMemo(
    () => (maxLength > 0 ? getAlertSeeMoreTextTrimmed(text, maxLength) : text),
    [text, maxLength]
  );
}

/*


The base requirement for this set of code is to provide "see more" functionality for an alert.

If the maximum number of characters is 80, then these should be visible on mobile by default, which then should expand to the full
content of the alert:

  And then when they see the bill in Mobile device ( <390px ) they see the "...see more" with default (refer UI 6j.1) and
  80 chars are displayed form the message and on click of “…see more” (refer UI 6j.2) they see full message and can
  toggle between the show/hide

  Copy : We've adjusted your bill. Due to a previous estimated reading being to high, we've credited back the incorrect
  charges and re-billed you for the actual amount of energy you used over that time.

  Copy Trimmed: We've adjusted your bill. Due to a previous estimated reading being to high, we...See more

One thing about the above is that the final punctuation has been dropped, e.g. because it lands on "we've" we don't
want to show "we'...See more" and instead want "we...See more"

If we do a direct slice(0, 80) then we will get

  Copy Sliced: We've adjusted your bill. Due to a previous estimated reading being to high, we'

So we need to do a regex to remove this final punctuation (see https://stackoverflow.com/a/59694854/1174869):
*/
const trailingPunctuation = /\W+$/;
/*
Now our slice + regex replace would result in:

  Copy Trimmed Expected: We've adjusted your bill. Due to a previous estimated reading being to high, we

*/

function getAlertSeeMoreTextTrimmedText(text: string, max: number) {
  if (text.length < max) {
    return text;
  }
  return text.slice(0, max).replace(trailingPunctuation, '');
}

/*

The next problem we need to over come is that for translations, we can freely use html like definitions
to produce formatting using https://react.i18next.com/latest/trans-component

This means we may want to link something, or link and apply a format:

  Copy with elements: We've adjusted your <billanchor>bill</billanchor>. Due to a previous estimated
  <meterreadinganchor>reading</meterreadinganchor> being to high, <creditanchor>we've credited back</creditanchor>
  the incorrect charges and re-billed you for the actual amount of energy you used over that time.

Well, now we have all this extra content we have to filter out before doing the max character count check. We also would
want to retain the existence of an element that contains the split of our content, for example above, we expect to retain
up to "we've", so we would end with <creditanchor>we've</creditanchor>

To put this together we're first going to parse our document and rule out that we don't just have words:
 */
export function getAlertSeeMoreTextTrimmed(text: string, max: number) {
  const parsed = parseDocument(text, {
    decodeEntities: true,
  });
  const children = Array.from(parsed.children);
  const allText = children.every(
    (element: { type: unknown }) => element.type === ElementType.Text
  );
  if (allText) {
    return getAlertSeeMoreTextTrimmedText(text, max);
  }
  return getAlertSeeMoreHTMLTextTrimmed(text, children, max);
}

/*
If we don't have just text, we have at least one html link node

From here, we will want to get the expected visual length of our text content
 */
function getAlertSeeMoreTextTrimmedExpectedLength(
  textContent: string,
  max: number
) {
  return getAlertSeeMoreTextTrimmedText(textContent, max).length;
}

/*
Using the visual content's length, we will collect nodes until we have hit this target

includedLength here is what will be included in the end result
 */
interface HTMLTrimContext {
  includedLength: number;
  readonly expectedLength: number;
}

/*
Using the available context, we will then map through all of the given nodes
until we have hit the limit

The returned nodes will produce a correctly closing html string ready to be parsed by i18next or used directly in html
 */
function trimmedHTMLNodes(
  context: HTMLTrimContext,
  children: (Node & { data?: unknown; children?: Node[] })[]
) {
  return children.flatMap((node) => {
    if (context.includedLength === context.expectedLength) return [];
    let data: unknown = node.data;
    const type: unknown = node.type;
    if (type === ElementType.Text && typeof data === 'string') {
      const remainingLength = context.expectedLength - context.includedLength;
      const trimmed = textContent(node).slice(0, remainingLength);
      data = trimmed;
      context.includedLength += trimmed.length;
    }
    return [
      {
        ...node,
        data,
        children: node.children
          ? trimmedHTMLNodes(context, node.children)
          : undefined,
      },
    ];
  });
}

function getAlertSeeMoreHTMLTextTrimmed(
  original: string,
  children: Node[],
  max: number
) {
  // Using the textContent of our html nodes we can find out the maximum amount
  // of words that match the size of content we want to display
  const htmlTextContent = textContent(children);
  if (htmlTextContent.length < max) {
    return original;
  }
  const expectedLength = getAlertSeeMoreTextTrimmedExpectedLength(
    htmlTextContent,
    max
  );
  const trimmedChildren = trimmedHTMLNodes(
    { expectedLength, includedLength: 0 },
    children
  );
  return render(trimmedChildren);
}

/*
Now that you have gone through all this, you realise what you originally included in source had a grammar difference:

  Copy: We've adjusted your bill. Due to a previous estimated reading being to high, we've credited

vs the corrected:

  Copy: We've adjusted your bill. Due to a previous estimated reading being too high, we've credited

When we use the original copy, our normal slice provides:

  .slice(0, 80): "We've adjusted your bill. Due to a previous estimated reading being to high, we'"

but then when you use the updated copy, you no longer see the trailing punctuation issue:

  .slice(0, 80): "We've adjusted your bill. Due to a previous estimated reading being too high, we"

Do you spot what the difference is?

  "to".length !== "too".length

¯\_(ツ)_/¯
 */
