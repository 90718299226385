import React from 'react';
import { ExpansionPanel } from './ExpansionPanel';
import { Panel as ExpansionPanelShape } from './Context';
import classNames from 'classnames';
import './ExpansionPanels.scss';
import { useTranslationWithNamespace } from '@contact/react';
import { ExpansionPanelsNamespace } from './Translations';

export interface ExpansionPanelsProps {
  panels?: Array<ExpansionPanelShape>;
  className?: string;
  link?: string;
  linkText?: string;
  header?: string;
  onExpanded?(panel: ExpansionPanelShape): void;
  disableModal?: boolean;
}

export function ExpansionPanels({
  panels = [],
  className,
  link,
  linkText,
  header,
  onExpanded,
  disableModal,
}: ExpansionPanelsProps) {
  const [t] = useTranslationWithNamespace(ExpansionPanelsNamespace);
  return (
    <div className={classNames(className)}>
      {header && (
        <div className="contactUIComponentsExpansionPanels_mainHeader">
          {header}
        </div>
      )}
      {panels.slice(0, 5).map((panel, index, array) => (
        <ExpansionPanel
          panel={panel}
          key={index}
          isLastChild={index === array.length - 1}
          onExpanded={onExpanded}
          disableModal={disableModal}
        />
      ))}
      {panels.length > 4 && link && (
        <div className="contactUIComponentsExpansionPanels_link">
          <a href={link} rel="noreferrer" target="_blank">
            {linkText ?? t('link')}
          </a>
        </div>
      )}
    </div>
  );
}
