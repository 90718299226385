import {
  Alert as AlertShape,
  Empty,
  htmlToText,
  Text,
  useAlerts,
  useAnalytics,
  useFlag,
  useTranslationWithNamespace,
} from '@contact/react';
import React, { useCallback, useEffect, useRef, MouseEvent } from 'react';
import ClassNames from 'classnames';
import { HTMLAlertContent } from './HTMLAlertContent';
import { AlertIcon, AlertIcons, isSmallIcon } from './AlertIcon';
import './Alert.scss';
import { Box } from '@chakra-ui/react';
import { useAlertSeeMore } from '@contact/react';
import { useIntersectionObserver, useMediaQuery } from '@contact/web-react';
import { AlertNamespace } from './Translations';

export interface AlertProps {
  alert: AlertShape;
}

export function Alert({ alert }: AlertProps) {
  const [t] = useTranslationWithNamespace(AlertNamespace);
  const [, , removeAlert] = useAlerts();
  const remove = useCallback(() => {
    removeAlert(alert);
  }, [alert, removeAlert]);
  const ref = useRef();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isIntersecting } = useIntersectionObserver(ref);
  const analytics = useAnalytics({
    once: true,
  });
  const clickAnalytics = useAnalytics({
    once: false,
  });
  useEffect(() => {
    const visible = alert?.analytics?.visible;
    if (visible) {
      const fn = typeof visible === 'function' ? visible : () => visible;
      const content =
        typeof alert.content === 'string'
          ? htmlToText(alert.content)
          : alert.content;
      analytics(fn({ ...alert, content }));
    }
  }, [isIntersecting, analytics, alert]);
  const contentClassName = 'contactUIComponentsAlertMessage_contentInner';
  const onContentsClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const link = alert?.analytics?.link;
      if (!link) return;
      const target = event.target;
      if (!(target instanceof Element)) return;
      if (target.closest('[data-ignore-analytics-click]')) return;
      const closest = target.closest(`.${contentClassName}`);
      if (!closest) return;
      const isLinkClick = target.matches('a[href]');
      if (!isLinkClick) return;
      const fn = typeof link === 'function' ? link : () => link;
      const content =
        typeof alert.content === 'string'
          ? htmlToText(alert.content)
          : alert.content;
      clickAnalytics(
        fn({
          ...alert,
          content,
        })
      );
    },
    [alert, clickAnalytics]
  );
  const initialStringContent =
    typeof alert.content === 'string' ? alert.content : '';
  const isDisabled = useFlag(
    `components_alert_${alert.name ?? 'unknown_name'}_disable`
  );
  const isSeeMoreMediaEnabled = useMediaQuery('belowTablet');
  const isSeeMoreDisabledAll = useFlag('components_alert_see_more_disable');
  const isSeeMoreDisabledNamed = useFlag(
    `components_alert_${alert.name ?? 'unknown_name'}_see_more_disable`
  );
  const [
    stringContent,
    isExpanded,
    toggleSeeMore,
    isSeeMoreEnabled,
  ] = useAlertSeeMore(initialStringContent, {
    enabled:
      isSeeMoreMediaEnabled &&
      !(isSeeMoreDisabledAll || isSeeMoreDisabledNamed),
    maxLength: alert.maxLength,
  });
  if (isDisabled) return <Empty />;
  return (
    <Box
      id={alert.name ? `alert_${alert.name}` : undefined}
      as="div"
      sx={alert.styles}
      ref={alert.ref}
      className={ClassNames(
        'contactUIComponentsAlertMessage',
        `contactUIComponentsAlertMessage_${alert.variant}`
      )}
    >
      <div
        className={
          isSmallIcon(alert.icon)
            ? 'contactUIComponentsAlertMessage_small_icon'
            : 'contactUIComponentsAlertMessage_icon'
        }
      >
        <AlertIcon
          icon={alert.icon}
          fill={alert.styles?.['--alert-icon-color']}
        />
      </div>
      <div className="contactUIComponentsAlertMessage_content">
        {alert.header ? (
          <div className="contactUIComponentsAlertMessage_header">
            {alert.header}
          </div>
        ) : undefined}
        <div className={contentClassName} onClick={onContentsClick}>
          {typeof alert.content === 'string' ? (
            alert.html ? (
              <HTMLAlertContent html={stringContent} />
            ) : (
              stringContent
            )
          ) : (
            alert.content
          )}
          {!!isSeeMoreEnabled && !isExpanded && (
            <>
              {t('seeMorePrefix', { context: isExpanded ? 'Expanded' : '' })}
              <button
                className="contactUIComponentsAlertMessage_seeMore"
                onClick={toggleSeeMore}
                aria-pressed={isExpanded}
                data-ignore-analytics-click=""
              >
                {t('seeMore', { context: isExpanded ? 'Expanded' : '' })}
              </button>
            </>
          )}
        </div>
      </div>
      {!alert.disableClose ? (
        <button type="button" className="close" onClick={remove} title="Close">
          <AlertIcons.Close />
        </button>
      ) : undefined}
    </Box>
  );
}
