export const styles = {
  global: {
    'html body': {
      fontFamily: 'Montserrat',
      color: 'gray.900',
      fontSize: 'sm',
      fontWeight: 'medium',
      lineHeight: 'shorter',
    },
  },
};
