import { ContactEnergyTheme } from '@contact/ui-components';
import { OLSTheme } from '@contact/ols-ui-components';
// Component style overrides
import { components } from './components';

export const TrimesterTheme = {
  ...OLSTheme,
  ...ContactEnergyTheme,
  components: {
    ...OLSTheme.components,
    ...ContactEnergyTheme.components,
    ...components,
  },
};
