import React from 'react';
import classNames from 'classnames';
import noop from 'nop';

import './Tooltip.scss';

interface TooltipProps {
  children: React.Component;
  className?: string;
  onMouseEnter: () => void;
}

const Tooltip = ({
  children,
  className,
  onMouseEnter = noop,
}: TooltipProps) => (
  <div
    className={classNames(['contactUIComponentsTooltip', className])}
    onMouseEnter={onMouseEnter}
  >
    <div className="contactUIComponentsTooltip_info">i</div>
    <div className="contactUIComponentsTooltip_content">{children}</div>
  </div>
);

export default Tooltip;
