import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const DisabledButtonIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 40 40" {...props}>
      <svg
        id="Disabled_Icon"
        data-name="Disabled Icon"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g
          id="Group_4246"
          data-name="Group 4246"
          transform="translate(-1319 -417)"
        >
          <path
            id="Path_2382"
            data-name="Path 2382"
            d="M0,0H48V48H0Z"
            transform="translate(1319 417)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_4247"
          data-name="Group 4247"
          transform="translate(-1226 -414)"
        >
          <g
            id="Path_2381"
            data-name="Path 2381"
            transform="translate(1240 428)"
            fill="none"
          >
            <path d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" stroke="none" />
            <path
              d="M 10 2 C 5.588789939880371 2 2 5.588789939880371 2 10 C 2 14.41121006011963 5.588789939880371 18 10 18 C 14.41121006011963 18 18 14.41121006011963 18 10 C 18 5.588789939880371 14.41121006011963 2 10 2 M 10 0 C 15.52285003662109 0 20 4.477149963378906 20 10 C 20 15.52285003662109 15.52285003662109 20 10 20 C 4.477149963378906 20 0 15.52285003662109 0 10 C 0 4.477149963378906 4.477149963378906 0 10 0 Z"
              stroke="none"
              fill="#858585"
            />
          </g>
          <line
            id="Line_207"
            data-name="Line 207"
            x1="13"
            y2="13"
            transform="translate(1243.5 431.5)"
            fill="none"
            stroke="#858585"
            strokeWidth="2"
          />
        </g>
      </svg>
    </Icon>
  );
};
