import React from 'react';
import { BaseProvider } from '@contact/react';
import { environment } from '../environments/environment';
import { Page, PageContent, Footer, Header } from '@contact/ui-components';
import { Trimester, TrimesterTheme } from '@contact/ui-trimester';
import { ChakraProvider } from '@contact/ui-volta';

export function App() {
  return (
    <ChakraProvider theme={TrimesterTheme}>
      <BaseProvider config={environment}>
        <Page translationNamespace="TrimesterPage" hasGlobalHeader={false}>
          <PageContent id>
            <Header />
            <Trimester />
            <Footer />
          </PageContent>
        </Page>
      </BaseProvider>
    </ChakraProvider>
  );
}

export default App;
