import React from 'react';

import SecondaryNavBar from '../SecondaryNavBar/SecondaryNavBar';
import PrimaryNavBar from '../PrimaryNavBar/PrimaryNavBar';
import './Header.scss';

export const Header = () => {
  return (
    <div className="header">
      <SecondaryNavBar />
      <PrimaryNavBar />
    </div>
  );
};
