import { ContractType, useAccounts } from '@contact/data-access-hooks';
import { useMemo } from 'react';
import { useOptionalUser } from '../User';
import * as Usage from './UsageConstants';

export function useSelectedContracts() {
  const { token = '', ba: baId = '', premise: premiseId = '' } =
    useOptionalUser() || {};
  const { data: account, isLoading } = useAccounts('v2', token, baId);

  const contracts = useMemo(() => {
    const accountContracts = account?.accountDetail.contracts;
    const result: Usage.SelectedContracts = {};
    result[Usage.ELECTRICITY] = accountContracts?.find(
      (contract) =>
        contract.premise.id === premiseId &&
        contract.contractType === ContractType.Electricity
    );
    result[Usage.GAS] = accountContracts?.find(
      (contract) =>
        contract.premise.id === premiseId &&
        contract.contractType === ContractType.Gas
    );
    result[Usage.BROADBAND] = accountContracts?.find(
      (contract) => contract.premise.id === premiseId && !!contract.broadband
    );
    return result;
  }, [account?.accountDetail.contracts, premiseId]);

  return { data: contracts, isLoading };
}
