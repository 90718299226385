import React from 'react';
import { Box, BoxProps, Flex, Skeleton } from '@contact/ui-volta';

export interface CardProps extends BoxProps {
  children: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  error?: React.ReactElement;
}
export const Card: React.FC<CardProps> = ({
  children,
  isLoading,
  isError,
  error,
  ...rest
}) => (
  <Flex
    data-testid="card"
    {...rest}
    backgroundColor="white"
    boxShadow="3px 3px 3px #00000029"
    borderRadius="base"
    padding={3}
    margin={2}
    flex={1}
  >
    {!isError && (
      <Skeleton
        data-testid={isLoading ? 'loader' : undefined}
        isLoaded={!isLoading}
        display="flex"
        flexDirection="column"
        flex={1}
        overflow="hidden"
      >
        {children}
      </Skeleton>
    )}
    {isError && <Box data-testid="error">{error}</Box>}
  </Flex>
);

export const CardHeader = ({ children, ...rest }) => (
  <Box data-testid="card-header" {...rest}>
    {children}
  </Box>
);
export const CardBody = ({ children, ...rest }) => (
  <Box
    data-testid="card-body"
    marginTop={4}
    paddingLeft={2}
    paddingRight={2}
    flex={1}
    {...rest}
  >
    {children}
  </Box>
);
export const CardFooter = ({ children, ...rest }) => (
  <Flex
    data-testid="card-footer"
    marginTop={4}
    justifyContent={{ base: 'center', md: 'flex-end' }}
    {...rest}
  >
    {children}
  </Flex>
);
