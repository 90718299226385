export interface ContractSummary {
  contractId: string;
  premiseId: string;
  address: string;
}

export interface AccountsSummary {
  id: string;
  nickname: string;
  contracts: ContractSummary[];
}

export interface AccountBalance {
  prepayDebtBalance: number;
  currentBalance: number;
  formattedCurrentBalance: string;
  remainingDays: number;
  refundEligible: boolean;
  refundMax: number;
  refundInProgress: number;
}

export interface Invoice {
  amountPaid: number;
  amountDue: number;
  discountTotal: number;
  paymentDueDate: string;
  daysTilOverdue: number;
}

export interface NextBill {
  date: string;
  amount: number;
  ppd: number;
}

export interface MonthOff {
  capAmount: number;
  possibleRedeemableAmount: number;
  actualRedeemableAmount: number;
  remainingDays: number;
  numbeOfMonthOffBills: number;
  remainingMonthOffBills: number;
  billingFrequency: 'Monthly' | 'Fortnightly' | 'Weekly';
  billExpiryDate: string;
  rewardItem: string;
  rewardNumber: string;
  ineligibleType: number;
  planExpiryDate: string;
}

export interface Payment {
  amount: string;
  date: string;
}

export interface SupplyAddress {
  houseNumber: string;
  street: string;
  city: string;
  postcode: number;
  shortForm: string;
}

export interface Premise {
  id: string;
  supplyAddress: SupplyAddress;
  isEligibleForBroadband: boolean;
}

export interface Register {
  id: string;
  registerType: string;
  previousMeterReading: string;
  readingUnit: string;
  previousMeterReadingDate: string;
}

export interface NonBillableRegister {
  id: string;
  registerType: string;
  previousMeterReading: string;
  readingUnit: string;
  previousMeterReadingDate: string;
}

export interface Device {
  id: string;
  serialNumber: string;
  deviceProductTypeId: number;
  nextMeterReadDate: string;
  nextReadingIsEstimate: boolean;
  meterLocationCode: string;
  registers: Register[];
  nonBillableRegisters: NonBillableRegister[];
}

export interface Broadband {
  ont: string;
  productCode: string;
  detail?: string;
  additionalDetail?: string;
}

export enum ContractType {
  Electricity = 1,
  Gas = 2,
  LPG = 3,
  Broadband = 4,
}

export enum MeterType {
  StandardMeter = 'STANDARD METER',
  SmartMeter = 'SMART METER',
}

export interface ContractDetail {
  id: string;
  icp: string;
  meterType: MeterType;
  promptPaymentDiscount: number;
  contractType: ContractType;
  contractTypeLabel: string;
  dualEnergy: boolean;
  premise: Premise;
  devices: Device[];
  broadband?: Broadband;
}

export interface AccountDetail {
  id: string;
  nickname: string;
  correspondencePreference: string;
  paymentMethod: string;
  isDirectDebit: boolean;
  isSmoothPay: boolean;
  isPrepay: boolean;
  isControlpay: boolean;
  isEligibleMonthOff: boolean;
  directDebitAccount: string;
  billingFrequency: 'Monthly' | 'Fortnightly' | 'Weekly';
  accountBalance: AccountBalance;
  invoice: Invoice;
  nextBill: NextBill;
  monthOff?: MonthOff;
  payments: Payment[];
  contracts: ContractDetail[];
  hasMedicalDependant: boolean;
  hasVulnerablePerson: boolean;
  accountType: 'RESI' | 'BUSI';
}

export interface AccountsApiResponse {
  accountsSummary: AccountsSummary[];
  accountDetail: AccountDetail;
  statusCode: number;
  xcsrfToken: string;
}
