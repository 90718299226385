import React from 'react';
import { LoremIpsum } from 'lorem-ipsum';

export interface SampleTextProps {
  numberOfWords: number;
}

const lorem = new LoremIpsum();

/**
 * Use this component to generate sample text when the API call is still loading,
 * so Skeleton component has something to render, and then the width and height can be defined.
 */
export const SampleText = ({ numberOfWords }: SampleTextProps) => (
  <span style={{ opacity: 0 }} data-testid="sample-text">
    {lorem.generateWords(numberOfWords)}
  </span>
);
