import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ChevronRightIcon = (props: IconProps): ReactElement => {
  return (
    <Icon viewBox="0 0 40 40" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
        <defs>
          <clipPath id="a">
            <path
              data-name="Path 32"
              d="M16.183-6.805 9.717-.338 3.25-6.805a1.66 1.66 0 0 0-1.175-.487A1.66 1.66 0 0 0 .9-6.805a1.66 1.66 0 0 0 0 2.35l7.65 7.65a1.66 1.66 0 0 0 2.35 0l7.65-7.65a1.66 1.66 0 0 0 0-2.35 1.7 1.7 0 0 0-2.367 0z"
              transform="translate(-.412 7.292)"
            />
          </clipPath>
          <clipPath id="b">
            <path data-name="Rectangle 13" d="M0 0h166.667v166.667H0z" />
          </clipPath>
          <clipPath id="c">
            <path
              data-name="Path 31"
              d="M0 5.333h20V-8H0z"
              transform="translate(0 8)"
            />
          </clipPath>
        </defs>
        <g data-name="all / icons / 24px down chevron">
          <g data-name="Group 46">
            <g
              data-name="Group 45"
              transform="rotate(-90 22.5 7.5)"
              style={{ clipPath: 'url(#a)' }}
            >
              <g data-name="Group 44" style={{ isolation: 'isolate' }}>
                <g
                  data-name="Group 43"
                  style={{ clipPath: 'url(#b)' }}
                  transform="translate(-67.354 -69.513)"
                >
                  <g
                    data-name="Group 42"
                    transform="translate(66.667 68.333)"
                    style={{ clipPath: 'url(#c)' }}
                  >
                    <path
                      data-name="Path 30"
                      d="M-75-76.667h183.333v183.333H-75z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            data-name="Rectangle 35"
            style={{ fill: 'none' }}
            d="M0 0h40v40H0z"
            transform="rotate(-90 20 20)"
          />
        </g>
      </svg>
    </Icon>
  );
};
