import React, { PropsWithChildren } from 'react';
import {
  Tabs as VoltaTabs,
  TabsProps as VoltaTabsProps,
} from '@contact/ui-volta';

export type TabsProps = Omit<VoltaTabsProps, 'size' | 'align' | 'isFitted'>;

export const Tabs = ({ children, ...props }: PropsWithChildren<TabsProps>) => (
  <VoltaTabs {...props}>{children}</VoltaTabs>
);
