import React, {
  ReactNode,
  ReactElement,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
} from 'react';
import classNames from 'classnames';
import Portal from './Portal';
import { Button } from '../Button';
import './Modal.scss';
import { CloseIcon } from '../Icons';

export type ModalVariant = 'default' | 'compact';

export type ModalProps = {
  buttonText?: string;
  content?: ReactNode;
  handleButtonClick?: MouseEventHandler<HTMLButtonElement>;
  handleClose?: () => void;
  isActive: boolean;
  title?: string;
  children?: ReactNode;
  icon?: ReactElement;
  className?: string;
  contentClassName?: string;
  containerClassName?: string;
  titleClassName?: string;
  variant?: ModalVariant;
};

const defaultDimensions = {
  closeIconBoxSize: '24px',
};

const variantDimensions = {
  default: defaultDimensions,
  compact: {
    closeIconBoxSize: '20px',
  },
};

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (props: ModalProps, ref) => {
    const body = useMemo(() => document.querySelector('body'), []);
    const {
      children,
      icon,
      isActive,
      handleButtonClick,
      handleClose,
      buttonText,
      className,
      contentClassName,
      containerClassName,
      titleClassName,
      variant = 'default',
      ...rest
    } = props;

    const closeIconBoxSize = (variantDimensions[variant] || defaultDimensions)
      .closeIconBoxSize;

    useEffect(() => {
      if (body && body.style) {
        body.style.overflow = isActive ? 'hidden' : 'auto';
      }
      return () => {
        if (body && body.style) {
          body.style.overflow = 'auto';
        }
      };
    }, [body, isActive]);

    const modalClassNames = classNames(
      'contactUIComponentsModal',
      isActive && 'contactUIComponentsModal--visible',
      className
    );
    const stopPropagation = useCallback((e) => {
      e.stopPropagation();
    }, []);

    return (
      <Portal>
        <div
          ref={ref}
          {...rest}
          className={modalClassNames}
          onClick={handleClose}
        >
          <div
            className={classNames(
              ...variantClassNames(
                'contactUIComponentsModal_container',
                variant
              ),
              containerClassName
            )}
            onClick={stopPropagation}
          >
            {handleClose && (
              <CloseIcon
                onClick={handleClose}
                className={classNames(
                  ...variantClassNames(
                    'contactUIComponentsModal_close-icon',
                    variant
                  )
                )}
                boxSize={{
                  base: closeIconBoxSize,
                  lg: closeIconBoxSize,
                }}
              />
            )}
            <div
              className={classNames(
                ...variantClassNames(
                  'contactUIComponentsModal_contentContainer',
                  variant
                )
              )}
            >
              {icon}
              <h2
                className={classNames(
                  ...variantClassNames(
                    'contactUIComponentsModal_title',
                    variant
                  ),
                  titleClassName
                )}
              >
                {props.title}
              </h2>
              <p
                className={classNames(
                  ...variantClassNames(
                    'contactUIComponentsModal_content',
                    variant
                  ),
                  contentClassName
                )}
              >
                {props.content}
              </p>
            </div>
            {children}
            {handleButtonClick && (
              <div
                className={classNames(
                  ...variantClassNames(
                    'contactUIComponentsModal_buttonWrapper',
                    variant
                  )
                )}
              >
                <Button
                  onClick={handleButtonClick}
                  variant="primary"
                  className={classNames(
                    ...variantClassNames(
                      'contactUIComponentsModal_button',
                      variant
                    )
                  )}
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Portal>
    );
  }
);

function variantClassNames(className: string, variant: ModalVariant) {
  return [
    className,
    variant && className ? `${className}_${variant}` : undefined,
  ].filter(Boolean);
}
