/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useSpring, animated, config } from 'react-spring';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';

import ReactDatePicker from 'react-datepicker';
import { CalendarIcon } from '../Icons';
import DateUtil from '../Utils/DateUtil';
import {
  validateDate,
  validateFirstDateBeforeSecond,
} from '../Utils/ValidationUtil';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

export interface DatePickerProps {
  validateItself?: boolean;
  hasError?: boolean;
  required?: boolean;
  value?: any;
  iconLast?: boolean;
  isSmall?: boolean;
  minDate?: Date;
  maxDate?: Date;
  handleChange: any;
  minErrorMessage?: string;
  maxErrorMessage?: string;
  validDates?: any;
  handleError?: any;
  onError?: any;
  error?: any;
  disabled?: boolean;
  labelText?: string;
  name?: string;
  errorMessage?: string;
  numberValidDays?: number;
  onValidate?(value: any): any;
  onlyWeekdays?: boolean;
  placeholder?: string | undefined;
  customFilter?: any;
  formTouched?: boolean;
  numberYearOptions?: number;
  inputProps?: any;
  dateFormat?: string;
}

export const DatePicker = ({
  validateItself = false,
  hasError = false,
  required = false,
  value,
  error,
  handleError = undefined,
  iconLast = false,
  isSmall = false,
  onError,
  onValidate,
  minDate = undefined,
  maxDate = undefined,
  handleChange,
  minErrorMessage = '',
  maxErrorMessage = '',
  validDates = [],
  name,
  disabled = false,
  labelText,
  formTouched,
  errorMessage = undefined,
  numberValidDays = 0,
  placeholder = undefined,
  onlyWeekdays = false,
  customFilter = undefined,
  numberYearOptions = 0,
  inputProps = undefined,
  dateFormat = 'dd/MM/yyyy',
}: DatePickerProps) => {
  const MobileWidth = 640;
  const [placeholderState, setPlaceholderState] = useState('');
  const [isActive, setActive] = useState(false);
  const [withPortal, setWithPortal] = useState(false);

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (formTouched) {
      setTouched(true);
    }
  }, [formTouched]);

  //trigger auto validation
  useEffect(() => {
    if (validateItself && !hasError && required && !value && handleError) {
      handleError(true);
    }
  }, [validateItself, value, handleError, required, hasError]);

  useEffect(() => {
    const error = onValidate?.(value);
    onError?.(error);
  }, [onError, onValidate, value]);

  useEffect(() => {
    setActive((isActive) => (value ? true : isActive));
  }, [value]);

  const getResizeData = () => {
    setWithPortal(window.innerWidth < MobileWidth);
  };

  const debounceGetResizeData = _debounce(getResizeData, 150);

  useEffect(() => {
    window.addEventListener('resize', debounceGetResizeData);
    return () => {
      window.removeEventListener('resize', debounceGetResizeData);
    };
  });

  useEffect(() => {
    getResizeData();
  }, []);

  const handleFilter = useCallback(
    (date) => {
      const weekdaysValid = onlyWeekdays ? DateUtil.isWeekday(date) : true;
      const customFilterValid = customFilter ? customFilter(date) : true;
      return weekdaysValid && customFilterValid;
    },
    [customFilter, onlyWeekdays]
  );

  const [animateInput, setInput] = useSpring(() => ({
    opacity: 0,
    config: config.stiff,
    width: isSmall ? '150px' : '100%',
  }));

  setInput({
    opacity: isActive ? 1 : 0,
  });

  const dateOnChangeHandler = (value) => {
    if (handleError) {
      validateDate(minDate, maxDate, value, handleError);
    }
    handleChange(value);
  };

  const replaceInputHandler = (event) => {
    const cursorPosition = event.target.selectionStart;
    const initiallength = event.target.value.length;
    event.target.value = event.target.value
      .replace(/[^\d-]/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[\/]?/g, '')
      .replace(/(^\d{2})/g, '$1/')
      .replace(/(^\d{2}\/\d{2})/g, '$1/')
      // eslint-disable-next-line no-useless-escape
      .replace(/[\/]$/g, '');
    const finallength = event.target.value.length;
    const newCursorPosition = cursorPosition + finallength - initiallength;
    event.target.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  const dateOnFocusHandler = (event) => {
    event.target.readOnly = window.innerWidth < MobileWidth;
    setPlaceholderState(placeholder ? placeholder : '');
    setActive(true);
  };

  const dateOnBlurHandler = () => {
    setPlaceholderState('');
    setActive(value != null);
    setTouched(true);
  };

  const getErrorMessage = () => {
    return validateFirstDateBeforeSecond(value, minDate)
      ? minErrorMessage
      : maxErrorMessage;
  };

  const customInput = (
    <animated.input
      id={name}
      name={name}
      type="text"
      // htmlFor={name}
      style={animateInput}
      maxLength={10}
    />
  );

  const dates = validDates ? validDates : [];

  if (numberValidDays) {
    for (let days = 0; days < numberValidDays; days++) {
      dates.push(DateUtil.addDays(new Date(), days));
    }
  }

  const icon = (
    <span className="contactUIComponentsDatePicker_calendar">
      <CalendarIcon className="contactUIComponentsDatePicker_calendar_icon" />
    </span>
  );

  const classes = classNames({
    contactUIComponentsDatePicker: true,
    'contactUIComponentsDatePicker--spacearound': iconLast,
    'contactUIComponentsDatePicker--error': hasError,
  });

  return (
    <div className="contactUIComponentsDatePicker_main">
      {labelText && (
        <animated.label
          id={name}
          // name={name}
          htmlFor={name}
          className={
            required
              ? 'contactUIComponentsDatePicker_label required'
              : 'contactUIComponentsDatePicker_label'
          }
        >
          {labelText}
        </animated.label>
      )}
      <div
        className={classes}
        aria-disabled={disabled}
        ref={inputProps && inputProps.ref}
      >
        {/* eslint-disable-next-line*/}

        {/* eslint-disable jsx-a11y/label-has-for */}

        {iconLast ? null : icon}
        <ReactDatePicker
          className="contactUIComponentsDatePicker_input"
          dateFormat={dateFormat}
          selected={value}
          onChange={dateOnChangeHandler}
          placeholderText={placeholderState}
          required={required}
          disabled={disabled}
          onFocus={dateOnFocusHandler}
          onBlur={dateOnBlurHandler}
          customInput={customInput}
          filterDate={handleFilter}
          maxDate={maxDate}
          minDate={minDate}
          withPortal={withPortal}
          onChangeRaw={replaceInputHandler}
          disabledKeyboardNavigation
          showYearDropdown
          dateFormatCalendar="MMMM"
          scrollableYearDropdown
          yearDropdownItemNumber={numberYearOptions ? numberYearOptions : 3}
        />
        {!hasError && !touched && iconLast ? icon : null}
      </div>
      {(hasError || (error && touched)) && (
        <label
          id={name}
          htmlFor={name}
          className="contactUIComponentsDatePicker_label--error"
        >
          {value && (minErrorMessage || maxErrorMessage)
            ? getErrorMessage()
            : errorMessage}
        </label>
      )}
    </div>
  );
};
