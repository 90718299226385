import React from 'react';
import { noop } from 'lodash-es';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

/*
 More details can be found: https://pqina.nl/filepond/docs/

 Props: https://pqina.nl/filepond/docs/api/instance/properties/

 Plugins doc:
 https://pqina.nl/filepond/docs/api/plugins/file-validate-size/
 https://pqina.nl/filepond/docs/api/plugins/file-validate-type/

 Quick examples:
 https://pqina.nl/filepond/#examples
*/

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFileEncode
);

export interface FileUploadProps {
  name?: string;
  fileRef?: React.LegacyRef<FilePond>;
  files?: string[];
  instantUpload?: boolean;
  isRequired?: boolean;
  acceptedFileTypes?: string[]; // e.g. ['image/*'] or ['image/png', 'image/jpeg']
  maxFileSize?: string; // e.g. 2MB, 2KB, 2B
  allowMultiple?: boolean;
  allowReorder?: boolean;
  maxFiles?: number;
  server?: string;
  handleInit?: () => void; // FilePond instance has been created and is ready.
  handleError?: (error, file, status) => void; // FilePond instance throws an error. Optionally receives file if error is related to a file object.
  handleUpdateFiles?: (fileItems) => void; // A file has been added or removed, receives a list of file items
  filenameValidator?: RegExp;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  name = 'file-upload',
  fileRef = undefined,
  files = [],
  instantUpload = false,
  isRequired = false,
  acceptedFileTypes = null, // allow all types by default
  maxFileSize = null,
  allowMultiple = true,
  allowReorder = true,
  maxFiles = null,
  server,
  handleInit = noop,
  handleError = noop,
  handleUpdateFiles = noop,
  filenameValidator,
}) => {
  return (
    <FilePond
      name={name}
      ref={fileRef}
      files={files}
      required={isRequired}
      instantUpload={instantUpload}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acceptedFileTypes={acceptedFileTypes}
      fileValidateTypeDetectType={(source, type) =>
        // Note: we need this here to activate the file type validations and filtering
        new Promise((resolve, reject) => {
          // Do custom type detection here and return with promise
          if (
            filenameValidator &&
            !((source as unknown) as File).name.match(filenameValidator)
          ) {
            reject(new Error('Invalid filename'));
          }
          resolve(type);
        })
      }
      maxFileSize={maxFileSize}
      allowMultiple={allowMultiple}
      allowReorder={allowReorder}
      maxFiles={maxFiles}
      server={server}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      credits={false}
      allowFileEncode
      oninit={handleInit}
      onerror={handleError}
      onupdatefiles={(fileItems) => {
        // Set currently active file objects to this.state
        handleUpdateFiles(fileItems);
      }}
    />
  );
};
