import React from 'react';
import {
  IconProps,
  Tab as VoltaTab,
  TabProps as VoltaTabProps,
} from '@contact/ui-volta';

export interface TabProps extends VoltaTabProps {
  icon?: React.FC<IconProps>;
}

export const Tab = ({ icon: Icon, children, ...rest }: TabProps) => (
  <VoltaTab {...rest}>
    {Icon && <Icon />}
    {children}
  </VoltaTab>
);
