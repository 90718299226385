import React from 'react';
import { useStyleConfig, Image } from '@chakra-ui/react';
import { Link, Box } from '@contact/ui-volta';
import { useTranslationWithNamespace } from '@contact/react';
import { Logo } from './DefaultAssets/Logo';

export interface GlobalBrandedNavigationProps {
  variant?: string;
}

export function GlobalBrandedNavigation({
  variant,
}: GlobalBrandedNavigationProps) {
  const styles = useStyleConfig('GlobalBrandedNavigation', { variant });
  const [t] = useTranslationWithNamespace('GlobalBrandedNavigation');
  const href = t('href', {
    defaultValue: '/',
  });

  return href ? (
    <Link sx={styles} href={href}>
      <Logo />
    </Link>
  ) : (
    <Box sx={styles}>
      <Logo />
    </Box>
  );
}
