import { useEffect } from 'react';

/**
 * Adds a `--vh` var to CSS and keeps it updated as long as
 * this hook is used.
 *
 * Adapted from https://ilxanlar.medium.com/you-shouldnt-rely-on-css-100vh-and-here-s-why-1b4721e74487
 */
export function useViewHeightCssVar() {
  useEffect(() => {
    // Re-calculate on resize
    window.addEventListener('resize', calculateVh);

    // Re-calculate on device orientation change
    window.addEventListener('orientationchange', calculateVh);

    return () => {
      window.removeEventListener('resize', calculateVh);
      window.removeEventListener('orientationchange', calculateVh);
    };
  }, []);
}

function calculateVh() {
  const vh = window.innerHeight;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}

// Initial calculation is best done before react DOM mounts
calculateVh();
