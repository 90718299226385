export const GlobalBrandedNavigation = {
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 1024px)': {
      justifyContent: 'flex-start',
    },
  },
  defaultProps: {},
  variants: {},
};
