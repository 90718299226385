import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  H4,
  Text,
} from '@contact/ui-volta';
import { FormAlert } from './FormAlert';
import { MultiColumnRadioGroup } from '@contact/ui-components';
import { Controller } from 'react-hook-form';

export const FormRadioGroup = ({
  name,
  label,
  title = undefined,
  description = undefined,
  descriptionComponent: DescriptionComponent = undefined, // react component
  spacing = undefined,
  options,
  control,
  errors,
  className = undefined,
  isRequired = false,
  errorMessage,
  invalidValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: errorMessage.required,
        validate: (value) => {
          if (name === 'paymentMethod') {
            return value === 'Monthly';
          } else {
            return value === 'true' || value === true;
          }
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const customOptions = options.map((option) => {
          return { ...option, isInvalid: error && error.type === 'required' };
        });
        return (
          <FormControl
            className={className}
            isRequired={isRequired}
            isInvalid={!!errors[name]}
          >
            {title && <H4 className="form-control-title">{title}</H4>}
            {description && (
              <Text className="form-control-description">{description}</Text>
            )}
            {DescriptionComponent && <DescriptionComponent />}
            {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <MultiColumnRadioGroup
              name={name}
              options={customOptions}
              numberOfColumnPerRow={2}
              columnWidth="60%"
              value={value}
              onChange={onChange}
              spacing={spacing}
              style={{ marginBottom: '5px' }}
            />
            {invalidValue && (
              <FormAlert message={errorMessage.criteriaNotMet} />
            )}
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};
