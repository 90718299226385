import { format as formatString } from 'date-fns';

const currencies = {
  'en-NZ': 'NZD',
};

function getFormatArguments(format, input) {
  const [, args] = format.replace(/\)$/, '').split('(');
  if (!args) return input;
  return {
    ...input,
    ...JSON.parse(args),
  };
}

function formatNumber(
  value: string | number,
  format: string,
  lng: string,
  input: Record<string, unknown>
) {
  const args = getFormatArguments(format, input);
  let formattedValue = Number(
    `${!value ? '0' : value}`
      // Have to remove commas from formatted number values
      .replace(/,/g, '')
  );
  if (args.absolute || args.positive || args.negative) {
    formattedValue = Math.abs(formattedValue);
  }
  let result = new Intl.NumberFormat(lng, args).format(formattedValue);
  if (args.negative && Math.abs(formattedValue) !== 0) {
    // We have an internal rule not to display a zero as a negative
    // to a human, even if we display other labels along with it, it still needs to be 0
    result = `-${result}`;
  }
  return result;
}

function formatCurrency(
  value: string | number,
  format: string,
  lng: string,
  input: Record<string, unknown>
) {
  const currency = currencies[lng] || currencies['en-NZ'];
  return formatNumber(value, format, lng, {
    // absolute had been the default for currency from the start
    // Pass absolute: false to disable the default
    absolute: true,
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    ...input,
  });
}

function formatOptionalPrefix(
  value: string,
  format: string,
  lng: string,
  input: Record<string, unknown>
) {
  const args = getFormatArguments(format, input);
  if (!value) return '';
  return `${decodeURI(args.prefix)}${value}`;
}

function formatPercentageOrPrefix(
  value: string,
  format: string,
  lng: string,
  input: Record<string, unknown>
) {
  const args = getFormatArguments(format, input);
  if (value.toLowerCase() === 'percent') {
    return '%';
  }
  return `${args.prefix}${value}`;
}

function formatArray(
  value: unknown[],
  format: string,
  lng: string,
  input: Record<string, unknown>
) {
  const args = getFormatArguments(format, input);
  // https://en.wikipedia.org/wiki/Serial_comma
  if (args.join === 'serial-comma') {
    const conjunction = args.conjunction ?? 'and';
    if (value.length < 3) {
      return value.join(conjunction);
    }
    const copy = [...value];
    const last = copy.pop();
    return [...copy, `${conjunction} ${last}`].join(', ');
  }
  return value.join(', ');
}

function format(
  value,
  format: string,
  lng: string,
  input: Record<string, unknown>
) {
  if (format.startsWith('percentageOrPrefix')) {
    return formatPercentageOrPrefix(value, format, lng, input);
  } else if (format.startsWith('optionalPrefix')) {
    return formatOptionalPrefix(value, format, lng, input);
  } else if (format.startsWith('currency')) {
    return formatCurrency(value, format, lng, input);
  } else if (format.startsWith('number')) {
    return formatNumber(value, format, lng, input);
  } else if (format.startsWith('date') && value) {
    // use 'do LLL y' for '5th Sep 2021'
    return formatString(new Date(value), 'd LLL y');
  } else if (format.startsWith('array') && Array.isArray(value)) {
    // use 'do LLL y' for '5th Sep 2021'
    return formatArray(value, format, lng, input);
  } else if (format.startsWith('lower')) {
    return `${value}`.toLowerCase();
  } else if (format.startsWith('upper')) {
    return `${value}`.toUpperCase();
  }
  return value;
}

export const interpolation = {
  format,
  escapeValue: false,
};
