export const Input = {
  baseStyle: {
    field: {
      padding: 3,
      height: '51px',
      border: '1px',
      borderColor: 'rgba(92, 52, 99, 0.5)',
      borderRadius: 'base',
      _focus: {
        boxShadow: 'none',
        borderColor: 'rgba(92, 52, 99, 1)',
      },
      _invalid: {
        boxShadow: 'none',
        borderColor: 'red.500',
        _focus: {
          boxShadow: 'none',
          borderColor: 'red.500',
        },
      },
    },
  },
  sizes: {
    default: {},
  },
  variants: {
    default: {},
  },
  defaultProps: {
    size: 'default',
    variant: 'default',
  },
};
