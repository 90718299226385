import React from 'react';

const Menu = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 15"
      style={{ enableBackground: 'new 0 0 22 15' }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        id="path-1_1_"
        d="M1.2,15h19.6c0.7,0,1.2-0.6,1.2-1.2s-0.5-1.2-1.2-1.2H1.2c-0.7,0-1.2,0.6-1.2,1.2S0.5,15,1.2,15z M1.2,8.7
 h19.6c0.7,0,1.2-0.6,1.2-1.2s-0.5-1.2-1.2-1.2H1.2C0.5,6.3,0,6.9,0,7.5S0.5,8.7,1.2,8.7z M0,1.2c0,0.7,0.5,1.2,1.2,1.2h19.6
 c0.7,0,1.2-0.6,1.2-1.2S21.5,0,20.8,0H1.2C0.5,0,0,0.6,0,1.2z"
        fill="currentColor"
      />
    </svg>
  );
};

export { Menu };
