import { useCallback, useRef } from 'react';

export function useMutationObserver<T extends Node>(
  fn: (element: T, ...args: Parameters<MutationCallback>) => void
) {
  const ref = useRef<T>();
  const observer = useRef<MutationObserver>();
  return useCallback(
    (element: T) => {
      if (ref.current === element) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      if (!element) return;
      observer.current = new MutationObserver((...args) => {
        return fn(element, ...args);
      });
      observer.current.observe(element, {
        childList: true,
      });
      ref.current = element;
    },
    [fn]
  );
}
