import React, { useState } from 'react';
import { Flex } from '@contact/ui-volta';
import ReactCarousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './Carousel.css';

export interface CarouselProps {
  children: React.ReactNode;
  onChange: (selectedIndex: number) => void;
}

export const Carousel: React.FC<CarouselProps> = ({ children, onChange }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const noOfChildren = React.Children.count(children);
  const handleOnChange = (selectedIndex: number) => {
    setSelectedIndex(selectedIndex);
    onChange(selectedIndex);
  };

  return (
    <Flex direction="column" data-testid="carousel-container">
      <ReactCarousel
        data-testid="carousel"
        plugins={['fastSwipe']}
        value={selectedIndex}
        onChange={handleOnChange}
      >
        {children}
      </ReactCarousel>
      {noOfChildren > 1 && (
        <Dots
          data-testid="carousel-dots"
          number={noOfChildren}
          value={selectedIndex}
          onChange={handleOnChange}
        />
      )}
    </Flex>
  );
};
