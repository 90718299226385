import React from 'react';
import { Namespace } from './Translations';
import { useTranslationWithNamespace } from '@contact/react';
import { Redirect } from 'react-router-dom';
import './TrimesterSuccess.scss';
import { passedSubmissionKey } from '../Config';

export const TrimesterSuccess = () => {
  const [t] = useTranslationWithNamespace(Namespace);
  const title = t('title');
  const subtitle = t('subtitle');
  const passedSubmission = sessionStorage.getItem(passedSubmissionKey);

  sessionStorage.clear();

  if (passedSubmission === 'false') {
    return <Redirect to="/" />;
  }

  const details: any = t('details', {
    returnObjects: true,
  });

  return (
    <div className="successContainer">
      <h1 className="title">{title}</h1>
      <h2 className="subtitle">{subtitle}</h2>
      {details.map((text: string, index) => (
        <p key={index} className="text">
          {text}
        </p>
      ))}
    </div>
  );
};
