export const GlobalHeader = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    width: '100%',
    height: 'var(--header-height, 66px)',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: 'white',
  },
  defaultProps: {},
  variants: {},
};

export const GlobalHeaderSideBox = {
  baseStyle: {
    flex: 0,
    minWidth: '70px',
    padding: '17px 16px 16px',
  },
};
