import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const DisabledBroadBandIconNew = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g id="Group_3645" data-name="Group 3645">
      <g id="Group_3644" data-name="Group 3644">
        <g id="Group_3643" data-name="Group 3643">
          <path
            id="Path_2191"
            data-name="Path 2191"
            d="M0,0H32V32H0Z"
            fill="none"
          />
          <path
            id="Path_2192"
            data-name="Path 2192"
            d="M12.343,27.572a1.327,1.327,0,0,1-1.226-.858,1.452,1.452,0,0,1,.281-1.521,6.387,6.387,0,0,1,9.364-.039l.007.008a1.45,1.45,0,0,1,0,1.975,1.278,1.278,0,0,1-1.874,0,3.827,3.827,0,0,0-5.611.01l-.008.008a1.282,1.282,0,0,1-.936.412ZM23.57,22.617a1.29,1.29,0,0,1-.939-.406,8.933,8.933,0,0,0-13.1.028l-.014.014a1.28,1.28,0,0,1-1.875.008,1.451,1.451,0,0,1-.007-1.975,11.5,11.5,0,0,1,16.853-.07l.014.014a1.45,1.45,0,0,1,.29,1.52,1.328,1.328,0,0,1-1.221.865h0ZM27.3,17.687a1.288,1.288,0,0,1-.94-.406,14.043,14.043,0,0,0-20.589.042l-.023.024a1.284,1.284,0,0,1-1.3.416,1.361,1.361,0,0,1-.964-1.007,1.446,1.446,0,0,1,.384-1.373,16.6,16.6,0,0,1,24.339-.1l.023.024a1.453,1.453,0,0,1,.289,1.519,1.329,1.329,0,0,1-1.221.865Zm3.375-4.549a1.29,1.29,0,0,1-.939-.406,18.662,18.662,0,0,0-27.361.057l-.03.031a1.278,1.278,0,0,1-1.866,0,1.45,1.45,0,0,1-.015-1.966,21.218,21.218,0,0,1,31.109-.132l.031.033a1.449,1.449,0,0,1,.29,1.519,1.328,1.328,0,0,1-1.22.866Z"
            transform="translate(0 0)"
            fill="#a51919"
          />
        </g>
      </g>
    </g>
  </Icon>
);
