export const Link = {
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'medium',

    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    signUp: {
      color: 'purple.500',
    },
    attachment: { color: 'purple.800' },
  },
};
