import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const BroadbandIconCircle = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 49.506 49.506" {...props}>
    <g
      id="all_elements_icons_48px_broadband"
      data-name="all / elements / icons / 48px broadband"
      transform="translate(0.753 0.753)"
    >
      <circle
        id="Ellipse_106"
        data-name="Ellipse 106"
        cx="24"
        cy="24"
        r="24"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.506"
      />
      <g
        id="Group_3917"
        data-name="Group 3917"
        transform="translate(-443.875 -304.701)"
      >
        <path
          id="Path_2293"
          data-name="Path 2293"
          d="M472.742,331.9a6.227,6.227,0,0,0-4.891-2.209A6.1,6.1,0,0,0,463,331.9"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <path
          id="Path_2294"
          data-name="Path 2294"
          d="M476.288,328.263a12.221,12.221,0,0,0-16.865.057"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <path
          id="Path_2295"
          data-name="Path 2295"
          d="M479.638,324.913a17.838,17.838,0,0,0-23.525-.019"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <circle
          id="Ellipse_107"
          data-name="Ellipse 107"
          cx="1.21"
          cy="1.21"
          r="1.21"
          transform="translate(466.662 334.508)"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <path
          id="Path_2296"
          data-name="Path 2296"
          d="M467.872,335.055"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
      </g>
    </g>
  </Icon>
);
