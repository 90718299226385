import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const Arrow = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 40 40" {...props}>
    <defs id="defs833">
      <clipPath id="89zpqodlea">
        <path
          d="M 16.183,-6.805 9.717,-0.338 3.25,-6.805 C 2.9385256,-7.1168976 2.5157907,-7.2921078 2.075,-7.292 1.6342093,-7.2921078 1.2114744,-7.1168976 0.9,-6.805 c -0.64987563,0.6485445 -0.64987563,1.7014555 0,2.35 l 7.65,7.65 c 0.6485445,0.6498756 1.701455,0.6498756 2.35,0 l 7.65,-7.65 c 0.649876,-0.6485445 0.649876,-1.7014555 0,-2.35 -0.659421,-0.6394931 -1.707579,-0.6394931 -2.367,0 z"
          transform="translate(-0.412,7.292)"
          id="path824"
        />
      </clipPath>
      <clipPath id="zdyt9bz5zb">
        <path d="M0 0H166.667V166.667H0z" id="path827" />
      </clipPath>
      <clipPath id="oz56zqtv1c">
        <path d="M0 5.333h20V-8H0z" transform="translate(0 8)" id="path830" />
      </clipPath>
    </defs>
    <path fill="none" d="M0 0H40V40H0z" id="path847" />
    <path
      d="m 25.772299,15.485859 -6.466001,6.467 -6.466999,-6.467 c -0.311474,-0.311898 -0.734209,-0.487108 -1.175,-0.487 -0.440791,-1.08e-4 -0.863526,0.175102 -1.175,0.487 -0.6498762,0.648545 -0.6498762,1.701455 0,2.35 l 7.65,7.65 c 0.648544,0.649876 1.701455,0.649876 2.35,0 l 7.65,-7.65 c 0.649876,-0.648545 0.649876,-1.701455 0,-2.35 -0.659422,-0.639493 -1.707579,-0.639493 -2.367,0 z"
      id="path965"
    />
  </Icon>
);
