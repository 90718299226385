import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ThumbsDownIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 24 32" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1156"
          data-name="Rectangle 1156"
          width="24"
          height="32"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g
      id="Group_4303"
      data-name="Group 4303"
      transform="translate(24 32) rotate(180)"
    >
      <g
        id="Group_4301"
        data-name="Group 4301"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_2514"
          data-name="Path 2514"
          d="M13.955,12.778a18.638,18.638,0,0,0,3.709-.012c.3.008.6-.007.9.009.892.048,1.785.091,2.675.17a1.921,1.921,0,0,1,.769.22,4.084,4.084,0,0,1,1.3,1.316,4.714,4.714,0,0,1,.545,1.061c.035.069.3.284.034,1.218a8.314,8.314,0,0,0-.792,1.162.97.97,0,0,0,.08.945c.251.421.485.85.72,1.28a3.044,3.044,0,0,1,0,.668,3.9,3.9,0,0,1-.155.389,11.464,11.464,0,0,0-.928,1.2.6.6,0,0,0-.088.284,4.583,4.583,0,0,0,.483,1.728,1.7,1.7,0,0,1-.188,1.489c-.345.4-.695.8-1.065,1.175a1.155,1.155,0,0,0-.366.838c0,.341.014.683,0,1.023a1.267,1.267,0,0,1-.129.646,4.942,4.942,0,0,1-.624.818,6.3,6.3,0,0,1-1.413,1.114,2.389,2.389,0,0,1-.651.263,13.924,13.924,0,0,1-2.925.2c-1.341.031-2.682.01-4.021-.082-.884-.061-1.769-.119-2.652-.191a4.017,4.017,0,0,1-1.212-.16,3.745,3.745,0,0,0-1.1-.242c-.682-.053-1.338-.237-2.009-.342-.358-.056-.7-.2-1.058-.243-.4-.051-.786-.125-1.176-.2-.288-.055-1.989-.55-1.989-.837,0-4.166-1.422-8.61,0-12.5.087-.236,1.768.134,1.919.123a2.131,2.131,0,0,0,.652-.059,4.817,4.817,0,0,0,2.11-2.323,14.765,14.765,0,0,0,.819-1.509c.415-.9.807-1.816,1.171-2.743a6.239,6.239,0,0,1,.414-.956A6.231,6.231,0,0,1,9.267,8.152a4.821,4.821,0,0,0,1.051-1.206,2.185,2.185,0,0,0,.294-.479c.157-.514.42-.979.534-1.52.1-.475.2-.947.33-1.414a6.433,6.433,0,0,0,.1-1.022,10.668,10.668,0,0,0,.18-1.588.756.756,0,0,1,.612-.9c.83-.082.744.074,1.069.229a4.14,4.14,0,0,0,.514.218,1.894,1.894,0,0,1,.761.489,4.027,4.027,0,0,1,1.216,2.786c.035.572.024,1.143.012,1.716a7.194,7.194,0,0,1-.254,1.43,4.757,4.757,0,0,1-.61,1.465,12.7,12.7,0,0,0-.76,1.69,10.9,10.9,0,0,0-.5,1.266,3.579,3.579,0,0,0-.037,1.264"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </g>
  </Icon>
);
