import React from 'react';
import { AspectRatio, Box, Grid, Skeleton, Stack } from '@chakra-ui/react';

const olsBarCount = 7;

export interface UsageGraphLoadingProps {
  width: number;
  height: number;
}

export function UsageGraphLoading({ width, height }: UsageGraphLoadingProps) {
  return (
    <AspectRatio ratio={width / height}>
      <Box position="relative">
        <Stack
          position="absolute"
          top="56px"
          left="24px"
          bottom="37px"
          right="0"
          justifyContent="space-between"
        >
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <Box
                key={i}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Skeleton
                  width="32px"
                  height="12px"
                  marginRight="9px"
                  borderRadius="6px"
                />
                <Box width="100%" height="1px" backgroundColor="#3030301A" />
              </Box>
            ))}
        </Stack>
        <Grid
          position="absolute"
          top="0"
          left="63px"
          bottom="24px"
          right="63px"
          templateColumns={`repeat(${olsBarCount}, 1fr)`}
          gap="2px"
          alignItems="flex-end"
        >
          {[
            <Skeleton width="100%" height="30%" />,
            <Skeleton width="100%" height="50%" />,
            <Skeleton width="100%" height="40%" />,
            <Skeleton width="100%" height="65%" />,
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                width="1px"
                height="calc(20% - 4px)"
                backgroundColor="#3030301A"
              />
              <Skeleton width="100%" height="80%" />
            </Box>,
            <Skeleton width="100%" height="55%" />,
            <Skeleton width="100%" height="60%" />,
            <Skeleton width="100%" height="45%" />,
            <Skeleton width="100%" height="38%" />,
          ]
            .filter((bar, i, bars) => {
              const targetCount = olsBarCount;
              return (
                i >= Math.min((bars.length - targetCount) / 2) &&
                i < Math.max((bars.length + targetCount) / 2)
              );
            })
            .map((bar) => (
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
              >
                {bar}
                <Skeleton
                  width="32px"
                  height="12px"
                  marginTop="7px"
                  borderRadius="6px"
                />
              </Box>
            ))}
        </Grid>
      </Box>
    </AspectRatio>
  );
}
