export const sitecoreVariables = {
  promoCodeCookieName: 'sharedPromocode',
  servicesCookieName: 'selectedService',
  sharedPlanIdCookieName: 'sharedPlanId',
  saveForLaterCookieName: 'saveForLaterParameters',
};

export const emailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'xtra.co.nz',
  'live.com',
  'mail.com',
  'msn.com',
  'aol.com',
];
