import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const DisabledBottleGasIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 17.286 29.714" {...props}>
    <g id="Group_3652" data-name="Group 3652">
      <path
        id="Path_2197"
        data-name="Path 2197"
        d="M29.673,13.8h0l0,0Z"
        transform="translate(-17.472 -7.163)"
        fill="none"
      />
      <path
        id="Path_2198"
        data-name="Path 2198"
        d="M22.778,9.469V9.136a.754.754,0,0,1,1.508,0v.333a.748.748,0,0,1-.057.285,8.894,8.894,0,0,1,3.788,1.189V7h-9v3.918a8.9,8.9,0,0,1,3.816-1.167A.758.758,0,0,1,22.778,9.469Z"
        transform="translate(-14.852 -5.491)"
        fill="none"
      />
      <path
        id="Path_2199"
        data-name="Path 2199"
        d="M28.166,11.562V6.507h1.242a.754.754,0,1,0,0-1.508H14.9a.754.754,0,1,0,0,1.508h1.249v5.016a7.971,7.971,0,0,0-2.659,5.9v3.426a.9.9,0,0,1,.244.038c.019.006.474.158,1.265.354a30.366,30.366,0,0,0,14.27.038c.45-.115.9-.239,1.358-.382a.919.919,0,0,1,.15-.02V17.423A7.971,7.971,0,0,0,28.166,11.562Zm-2.472.074v0l0,0ZM17.66,6.507h9v3.945A8.894,8.894,0,0,0,22.87,9.263a.748.748,0,0,0,.057-.285V8.644a.754.754,0,0,0-1.508,0v.333a.758.758,0,0,0,.057.281,8.9,8.9,0,0,0-3.816,1.167Z"
        transform="translate(-13.493 -4.999)"
        fill="#858585"
      />
    </g>
    <g id="Group_3653" data-name="Group 3653" transform="translate(0 17.413)">
      <path
        id="Path_2201"
        data-name="Path 2201"
        d="M21.174,38.438H21.12l.054.024Z"
        transform="translate(-15.369 -30.636)"
        fill="#858585"
      />
      <path
        id="Path_2202"
        data-name="Path 2202"
        d="M29.271,28.522a30.212,30.212,0,0,1-6.954.8A32.544,32.544,0,0,1,15,28.492c-.677-.158-1.189-.3-1.508-.4v1.694a7.971,7.971,0,0,0,2.655,5.9v3.332A1.42,1.42,0,0,0,17.6,40.392h9.08a1.42,1.42,0,0,0,1.454-1.379V35.672a7.969,7.969,0,0,0,2.643-5.887V28.12Q30.019,28.346,29.271,28.522ZM19.3,35.893v.024l-.054-.024Zm7.33,2.991H17.656v-2.1a8.927,8.927,0,0,0,4.48,1.2h0a8.928,8.928,0,0,0,4.492-1.207Z"
        transform="translate(-13.493 -28.091)"
        fill="#858585"
      />
    </g>
  </Icon>
);
