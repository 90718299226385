import React from 'react';
import {
  Text,
  Skeleton,
  TextProps,
  Tooltip,
  Placement,
} from '@contact/ui-volta';
import { SampleText } from '../SampleText';

export interface EnhancedTextProps extends TextProps {
  isLoading?: boolean;
  loadingWidth?: number;
  withTooltip?: boolean;
  tooltipPlacement?: Placement;
}

/**
 * Use this component to display a skeleton loader when the text content is still loading,
 * passing loadingWidth through to have a similar length of loading skeleton.
 */
export const EnhancedText = ({
  isLoading,
  loadingWidth = 80,
  withTooltip,
  tooltipPlacement,
  children,
  ...rest
}: EnhancedTextProps) => {
  const text = (
    <Text {...rest}>
      {!isLoading ? children : <SampleText numberOfWords={1} />}
    </Text>
  );
  return (
    <Skeleton
      isLoaded={!isLoading}
      width={isLoading ? `${loadingWidth}px` : undefined}
    >
      {withTooltip ? (
        <Tooltip
          label={children}
          placement={tooltipPlacement}
          overflowWrap="break-word"
          data-testid="enhanced-text-tooltip"
        >
          {text}
        </Tooltip>
      ) : (
        text
      )}
    </Skeleton>
  );
};
