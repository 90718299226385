import React, { PropsWithChildren, useMemo } from 'react';
import { Alert, useAlerts, AlertContext, useAlertsFiltered } from './Context';

export interface StaticAlertProviderProps {
  alerts?: (Alert | undefined)[];
  alert?: Alert;
}

export function StaticAlertProvider({
  children,
  alerts,
  alert,
}: PropsWithChildren<StaticAlertProviderProps>) {
  const providedAlerts = useAlertsFiltered();
  const mergedAlerts = useMemo(() => {
    const givenAlerts = (alerts ?? [alert]).filter(Boolean);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return providedAlerts.concat(givenAlerts);
  }, [alerts, alert, providedAlerts]);
  return (
    <AlertContext.Provider value={mergedAlerts}>
      {children}
    </AlertContext.Provider>
  );
}
