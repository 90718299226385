import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const BirthdayIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 14 16" {...props}>
    <g>
      <path
        d="M6 2a.988.988 0 001 1c.531 0 1-.406 1-1.25C8 .938 7.375 0 7 0c0 1.281-1 1.031-1 2zm5 1c.531 0 1-.406 1-1.25C12 .938 11.375 0 11 0c0 1.281-1 1.031-1 2a.988.988 0 001 1zm3 5.5A1.522 1.522 0 0012.5 7h-.75V3.5h-1.5V7h-2.5V3.5h-1.5V7h-2.5V3.5h-1.5V7H1.5A1.5 1.5 0 000 8.5V16h14zm-12.5.188a.18.18 0 01.188-.188l10.624.031a.181.181 0 01.188.188v1.75a1.342 1.342 0 01-.844.406c-.781 0-.937-1-2.344-1-1.374 0-1.531 1-2.312 1s-.969-1-2.344-1c-1.406 0-1.531 1-2.344 1a1.2 1.2 0 01-.812-.406zm11 5.812h-11V12a2.015 2.015 0 00.812.125c1.407 0 1.532-1 2.344-1 .782 0 .969 1 2.344 1s1.531-1 2.312-1c.813 0 .938 1 2.344 1A2.282 2.282 0 0012.5 12zM3 3c.531 0 1-.406 1-1.25C4 .938 3.375 0 3 0c0 1.281-1 1.031-1 2a.988.988 0 001 1z"
        fill="#121820"
      />
    </g>
  </Icon>
);
