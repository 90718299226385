export const Page = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100vw',
    padding: 0,
    margin: 0,
  },
  defaultProps: {},
  variants: {},
};
