import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const OfflineIcon = ({
  color = '#303030',
  ...props
}: IconProps): ReactElement => (
  <Icon
    width="56px"
    height="56px"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.87878 15.4069C11.0858 7.13563 18.3136 3 27.5621 3C36.8107 3 44.0391 7.13563 49.2474 15.4069"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.87878 41.1027C11.0858 49.3739 18.3136 53.5095 27.5621 53.5095C31.841 53.5963 36.0666 52.5485 39.8092 50.4725C43.5518 48.3966 46.678 45.3666 48.8697 41.6906"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.45361 23.8247L10.7573 33.1401"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3037 23.8247L1 33.1401"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.8041 15.4066H45.6905C44.4777 15.4066 43.4945 16.3897 43.4945 17.6025V22.0724C43.4945 23.2852 44.4777 24.2683 45.6905 24.2683H52.8041C54.0169 24.2683 55 23.2852 55 22.0724V17.6025C55 16.3897 54.0169 15.4066 52.8041 15.4066Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.8041 32.4899H45.6905C44.4777 32.4899 43.4945 33.4731 43.4945 34.6859V39.1557C43.4945 40.3685 44.4777 41.3517 45.6905 41.3517H52.8041C54.0169 41.3517 55 40.3685 55 39.1557V34.6859C55 33.4731 54.0169 32.4899 52.8041 32.4899Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.8041 32.4899H45.6905C44.4777 32.4899 43.4945 33.4731 43.4945 34.6859V39.1557C43.4945 40.3685 44.4777 41.3517 45.6905 41.3517H52.8041C54.0169 41.3517 55 40.3685 55 39.1557V34.6859C55 33.4731 54.0169 32.4899 52.8041 32.4899Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.0339 31.5995V29.5612"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.4589 31.5995V29.5612"
      stroke={color as string}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
