export const AlertNamespace = 'Alert';

export default {
  [AlertNamespace]: {
    seeMore: 'See more',
    seeMore_Expanded: 'See less',
    seeMorePrefix: '... ',
    seeMorePrefix_Expanded: ' ',
  },
};
