import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import { TimeAgoCustomComponentProps } from '@contact/react';

import './AppNotificationItem.scss';

export interface AppNotificationItemProps {
  title?: string;
  body?: string;
  date?: Date;
  isRead?: boolean;
  onPress?: () => void;
}

export function AppNotificationItem({
  title = '',
  body = '',
  date,
  isRead = false,
  onPress,
  ...props
}: AppNotificationItemProps) {
  return (
    <button
      {...props}
      onClick={onPress}
      className={['container', isRead ? 'readContainer' : '']
        .filter((x) => !!x)
        .map((x) => 'contactUIComponentsAppNotificationItem_' + x)
        .join(' ')}
      role="listitem"
    >
      <div className="contactUIComponentsAppNotificationItem_titleContainer">
        <div
          className="contactUIComponentsAppNotificationItem_unreadDot"
          style={isRead ? { opacity: 0 } : undefined}
        />
        <p className="contactUIComponentsAppNotificationItem_title">{title}</p>
      </div>
      <p className="contactUIComponentsAppNotificationItem_body">{body}</p>
      {!!date && (
        // Render time and update when time passes.
        // See https://github.com/catamphetamine/react-time-ago#react-native
        <ReactTimeAgo date={date} component={Time} timeStyle="mini-minute" />
      )}
    </button>
  );
}

function Time({
  date,
  verboseDate,
  tooltip,
  children,
}: TimeAgoCustomComponentProps) {
  return (
    <label className="contactUIComponentsAppNotificationItem_date">
      {children}
    </label>
  );
}
