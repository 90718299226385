import React, { ReactElement } from 'react';
import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
} from '@chakra-ui/react';

export type FormErrorMessageProps = ChakraFormErrorMessageProps;

export const FormErrorMessage = (
  props: FormErrorMessageProps
): ReactElement => <ChakraFormErrorMessage {...props} />;
