import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const PersonIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 20 16.01" {...props}>
    <g>
      <path
        d="M19.438 8.5l-3.594-1.406a.919.919 0 00-.719 0L11.531 8.5a.844.844 0 00-.531.75 7.084 7.084 0 004.125 6.688.938.938 0 00.719 0A7.085 7.085 0 0020 9.25a.829.829 0 00-.562-.75zM15.5 14.469V8.562l2.969 1.157a5.692 5.692 0 01-2.969 4.75zm-4.375.031H1.5v-.781A2.7 2.7 0 014.188 11 12.269 12.269 0 007 11.5a9.567 9.567 0 002.656-.469 7.6 7.6 0 01-.156-1.5A10.468 10.468 0 016.969 10c-1.469 0-1.875-.5-2.781-.5A4.215 4.215 0 000 13.719v.781A1.481 1.481 0 001.5 16h10.969a6.949 6.949 0 01-1.344-1.5zM7 9a4.5 4.5 0 10-4.5-4.5A4.5 4.5 0 007 9zm0-7.5a3 3 0 010 6 3 3 0 010-6z"
        fill="#121820"
      />
    </g>
  </Icon>
);
