import React, { ElementType, PropsWithChildren } from 'react';
import { useStyleConfig, Box } from '@chakra-ui/react';
import { useTranslationWithNamespace } from '@contact/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';

export interface PageContentProps {
  variant?: string;
  // if true will use the result from `usePageContentAnchorId`
  id?: string | boolean;
  sx?: SystemStyleObject;
  as?: ElementType;
}

export function usePageContentAnchorId() {
  const [t] = useTranslationWithNamespace('PageContent');
  return t('anchorId', {
    defaultValue: 'content',
  });
}

export function PageContent({
  variant,
  children,
  id,
  sx,
  as,
}: PropsWithChildren<PageContentProps>) {
  const styles = useStyleConfig('PageContent', { variant });
  const contentId = usePageContentAnchorId();
  return (
    <Box
      as={as}
      sx={{ ...sx }}
      // id={typeof id === 'boolean' ? (id ? contentId : undefined) : id}
    >
      {children}
    </Box>
  );
}
