import {
  useTranslationNamespaceContextOptional,
  useTranslationWithNamespace,
} from '../Translation';
import React, { PropsWithChildren, useMemo } from 'react';
import { StaticAlertProvider } from './StaticAlertProvider';
import { isAlert, isAlertArray, Alert } from './Context';

export interface TranslationAlertProviderProps {
  namespace?: string;
  alertKey: string;
  section?: string;
}

export function TranslationAlertProvider({
  namespace,
  alertKey: key,
  children,
  section,
}: PropsWithChildren<TranslationAlertProviderProps>) {
  const providedNamespace = useTranslationNamespaceContextOptional();
  const [t] = useTranslationWithNamespace(namespace ?? providedNamespace);
  const configuredAlerts = useMemo((): Alert[] => {
    const alerts = t(key, {
      returnObjects: true,
    });
    if (isAlert(alerts)) {
      return [alerts];
    }
    if (isAlertArray(alerts)) {
      return alerts;
    }
    return [];
  }, [t, key]);
  const alerts = useMemo(() => {
    if (!section) return configuredAlerts;
    return configuredAlerts.map((alert) => ({
      // Defaults
      section,
      ...alert,
    }));
  }, [configuredAlerts, section]);
  return <StaticAlertProvider alerts={alerts}>{children}</StaticAlertProvider>;
}
