import React, { useState, PropsWithChildren, MouseEventHandler } from 'react';

import { Button } from '../Button';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { WhiteCheck, LongArrowUp, LongArrowDown } from '../../Assets/Icons';
import { CloseIcon } from '../Icons';
import { PlanCarouselItem } from '../PlanCarousel';
import './PlanCard.scss';
import { dataLayerEvent } from '@contact/react';

export interface SiteCorePlan {
  fields: {
    ExistingPlan: {
      fields: {
        ApiPlanId: {
          value: string;
        };
        FullPlanDetailsText?: {
          value: string;
        };
        Title?: {
          value: string;
        };
      };
    };
    AASFlogoImage?: {
      value: {
        src: string;
      };
    };
  };
}

export interface SiteCoreBBPlan {
  LearnMoreDescription: string;
}

export interface PlanCardProps {
  index: number;
  item: PlanCarouselItem<SiteCorePlan>;
  url: string;
  itemChecked: number | null;
  onItemSelect: MouseEventHandler<HTMLButtonElement>;
  itemRef: React.Ref<HTMLDivElement>;
  isBBCard?: boolean;
  isDiscount?: boolean;
  downLoadText?: string;
  byoModemText?: string;
  buttonText?: string;
  buttonLinkText?: string;
  siteCoreData?: SiteCoreBBPlan;
  uploadText?: string;
}

export function PlanCard({
  index,
  item,
  url,
  itemChecked,
  onItemSelect,
  itemRef,
  isBBCard,
  isDiscount,
  downLoadText,
  byoModemText,
  buttonText,
  siteCoreData,
  buttonLinkText,
  uploadText,
}: PropsWithChildren<PlanCardProps>) {
  const plan = item?.data?.fields;
  const field = item?.data?.fields.ExistingPlan.fields;
  const imageUrl = url + plan?.AASFlogoImage?.value?.src;

  const [isModalActive, setIsModalActive] = useState(false);
  const modalRoot = document.getElementById('modalRoot')!;
  const [isTCModalActive, setIsTCModalActive] = useState(false);
  const [modalBBCard, setModalBBCard] = useState({
    header: '',
    content: '',
    card: <div></div>,
    buttonIndex: 0,
    item: '',
  });
  const [modal, setModal] = useState({
    content: '',
    tcButtonText: '',
    card: <div></div>,
    plan: '',
    field: {
      TandCBodyText: { value: '' },
      TandCTitle: { value: '' },
      TandCPdfLink: { value: { text: '' } },
    },
    buttonIndex: 0,
    header: '',
    tcUrl: '',
  });

  const choosePlanitemSelect = (index) => {
    setIsModalActive(false);
    onItemSelect(index);
  };

  const generateSpeedDescription = (speed, postfix) => {
    if (speed.trim().endsWith('Mbps')) {
      return `${speed.trim().slice(0, -4)} ${postfix}`;
    } else {
      return `${speed} ${postfix}`;
    }
  };

  const returnBBProviderUI = (isDiscount, item, index) => {
    const itemDescription =
      item.NetworkDesc && item.BYOModemAllowed
        ? item.NetworkDesc + ', ' + byoModemText
        : item.NetworkDesc
        ? item.NetworkDesc
        : item.BYOModemAllowed
        ? byoModemText
        : '';
    return (
      <>
        {!!item.Label && (
          <div className="broadbandCarousel_block_label">{item.Label}</div>
        )}
        <div className="broadbandCarousel_block_header">{item.Name}</div>
        <div className="broadbandCarousel_block_priceContainer">
          <div className="broadbandCarousel_block_priceContainer_amount">
            {isDiscount ? item.BundlePrice : item.OriginalPrice}
          </div>
          {isDiscount && (
            <div className="broadbandCarousel_block_priceContainer_originPrice">
              <div className="broadbandCarousel_block_priceContainer_originPrice_crossed">
                {item.OriginalPrice}
              </div>
              <div className="broadbandCarousel_block_priceContainer_originPrice_crossLine" />
            </div>
          )}
        </div>
        <div className="broadbandCarousel_block_priceContainer_dataCap">
          {item.DataCapDesc}
        </div>
        <div className="broadbandCarousel_block_priceContainer_nwkDesc">
          {itemDescription}
        </div>
        <div className="broadbandCarousel_block_speed">
          {item.DownloadSpeed && (
            <div className="broadbandCarousel_block_speed_speedContainer">
              <LongArrowDown />
              <div className="broadbandCarousel_block_speed_speedContainer_text">
                {generateSpeedDescription(item.DownloadSpeed, downLoadText)}
              </div>
            </div>
          )}
          {item.UploadSpeed && (
            <div className="broadbandCarousel_block_speed_speedContainer">
              <LongArrowUp />
              <div className="broadbandCarousel_block_speed_speedContainer_text">
                {generateSpeedDescription(item.UploadSpeed, uploadText)}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const returnPlanUI = (plan, field, url) => {
    return (
      <div>
        {plan.BestFor?.value && (
          <div className="contactUIComponentsPlanCard_container_block_label">
            <span>{plan.BestFor?.value}</span>
          </div>
        )}
        {plan.PlanRibbon.value && (
          <div className="contactUIComponentsPlanCard_container_block_ribbon-container">
            <div
              className={classNames(
                'contactUIComponentsPlanCard_container_block_ribbon-container_ribbon',
                'contactUIComponentsPlanCard_container_block_ribbon-container_ribbon--color'
              )}
            >
              <div className="contactUIComponentsPlanCard_container_block_ribbon-container_ribbon_text">
                {plan.PlanRibbon.value}
              </div>
            </div>
          </div>
        )}
        {plan.PlanRibbonBackground?.value && (
          <div className="contactUIComponentsPlanCard_container_block_ribbon-container">
            <div
              style={{ background: plan.PlanRibbonBackground.value }}
              className={classNames(
                'contactUIComponentsPlanCard_container_block_ribbon-container_ribbon--background',
                'contactUIComponentsPlanCard_container_block_ribbon-container_ribbon'
              )}
            />
          </div>
        )}
        {plan.AASFlogoImage?.value?.src && (
          <div className="contactUIComponentsPlanCard_container_block_logo-image">
            <img src={url} alt="" aria-hidden="true" />
          </div>
        )}
        <div className="contactUIComponentsPlanCard_container_block_name">
          {field.Title?.value}
        </div>
        <div className="contactUIComponentsPlanCard_container_block_description">
          <div
            className="html_raw"
            dangerouslySetInnerHTML={{ __html: plan.PlanPromo?.value }}
          />
        </div>

        <div className="contactUIComponentsPlanCard_container_block_subHeader">
          {field.FirstRowSingleLineText?.value}
        </div>
      </div>
    );
  };

  const renderSelectButton = (index, field, value, plan) => (
    <Button
      className={classNames(
        'contactUIComponentsPlanCard_container_block_buttons_button',
        itemChecked === index
          ? 'contactUIComponentsPlanCard_container_block_buttons_button_buttonChecked'
          : ''
      )}
      variant="transparent"
      onClick={() => choosePlanitemSelect(index)}
      id={
        isBBCard
          ? `choose-${index}-button`
          : `choose-${field.Title?.value}-button`
      }
    >
      {itemChecked === index ? (
        <WhiteCheck width="81" height="24" />
      ) : (
        <div className="contactUIComponentsPlanCard_container_block_buttons_button_buttonText">
          {capitalize(isBBCard ? value : plan?.Label?.value)}
        </div>
      )}
    </Button>
  );

  const onModalShow = (plan, field, imageUrl, index) => {
    setIsModalActive(true);
    setModal({
      header: field.Title.value,
      content: field.DetailsDescription.value,
      card: returnPlanUI(plan, field, imageUrl),
      tcButtonText: field.DetailsTermsAndConditionsText?.value,
      buttonIndex: index,
      field: field,
      plan: plan,
      tcUrl: url + field.TandCPdfLink.value?.href,
    });
    dataLayerEvent({
      event: 'cta_click',
      cta_text: 'Learn More',
      item_id: field.Title.value,
    });
  };

  const onModalShowBBCard = (isDiscount, item, learnMoreDescription, index) => {
    setIsModalActive(true);
    setModalBBCard({
      header: item.Name,
      content: learnMoreDescription,
      card: returnBBProviderUI(isDiscount, item, index),
      buttonIndex: index,
      item: item,
    });

    dataLayerEvent({
      event: 'cta_click',
      cta_text: 'Learn More',
      item_id: item.Name,
    });
  };

  const renderButtonLink = (item, index) => {
    return isBBCard ? (
      siteCoreData?.LearnMoreDescription ? (
        <Button
          id={`learn-${item.Name}-button`}
          variant="none"
          onClick={() =>
            onModalShowBBCard(
              isDiscount,
              item,
              siteCoreData?.LearnMoreDescription,
              index
            )
          }
          className="contactUIComponentsPlanCard_container_block_buttons_button_link"
        >
          {buttonLinkText}
        </Button>
      ) : null
    ) : (
      <Button
        id={
          isBBCard
            ? `choose-${item.Name}-button`
            : `learn-${field.Title?.value}-button`
        }
        onClick={() => onModalShow(plan, field, imageUrl, index)}
        className="contactUIComponentsPlanCard_container_block_buttons_button_link"
        variant="none"
      >
        {isBBCard ? buttonText : field.FullPlanDetailsText?.value}
      </Button>
    );
  };

  return (
    <div
      key={index}
      className="contactUIComponentsPlanCard_container_block"
      id={item.id}
      data-productid={item.id}
      ref={itemRef}
    >
      {isBBCard
        ? returnBBProviderUI(isDiscount, item, index)
        : returnPlanUI(plan, field, imageUrl)}
      <div className="contactUIComponentsPlanCard_container_block_buttons">
        {renderSelectButton(index, field, buttonText, plan)}
        {renderButtonLink(item, index)}
      </div>
      {isModalActive &&
        ReactDOM.createPortal(
          <div className="contactUIComponentsPlanCard_modal">
            <div className="contactUIComponentsPlanCard_modal_container">
              <div className="contactUIComponentsPlanCard_modal_container_mini-card">
                <div
                  className="contactUIComponentsPlanCard_modal_container_mini-card_close"
                  onClick={() => setIsModalActive(false)}
                >
                  <CloseIcon />
                </div>

                <div className="contactUIComponentsPlanCard_modal_container_block--card">
                  {isBBCard ? modalBBCard.card : modal.card}
                </div>
              </div>
              <div className="contactUIComponentsPlanCard_modal_container_block">
                <div className="contactUIComponentsPlanCard_modal_container_block_header">
                  {isBBCard ? modalBBCard.header : modal.header}
                </div>
                <div
                  className="html_raw"
                  dangerouslySetInnerHTML={{
                    __html: isBBCard ? modalBBCard.content : modal.content,
                  }}
                />
                <div
                  className={
                    isBBCard
                      ? 'contactUIComponentsPlanCard_modal_container_block_buttons contactUIComponentsPlanCard_modal_container_block_buttons--bbcard'
                      : 'contactUIComponentsPlanCard_modal_container_block_buttons '
                  }
                >
                  {!isBBCard ? (
                    <Button
                      id="TC"
                      onClick={() => setIsTCModalActive(true)}
                      className="contactUIComponentsPlanCard_modal_container_block_buttons_button-tc"
                      variant="secondary"
                    >
                      {modal.tcButtonText}
                    </Button>
                  ) : null}
                  {renderSelectButton(
                    modal.buttonIndex,
                    modal.field,
                    buttonText,
                    modal.plan
                  )}
                </div>
              </div>
            </div>
          </div>,
          modalRoot
        )}
      {isTCModalActive &&
        ReactDOM.createPortal(
          <div className="contactUIComponentsPlanCard_modal">
            <div
              className={classNames(
                'contactUIComponentsPlanCard_modal_container',
                'contactUIComponentsPlanCard_modal_container--column'
              )}
            >
              <div
                className="contactUIComponentsPlanCard_modal_container_mini-card_close"
                onClick={() => setIsTCModalActive(false)}
              >
                <CloseIcon />
              </div>
              <div className="contactUIComponentsPlanCard_modal_container_title">
                {modal.field.TandCTitle.value}
              </div>
              <div>
                <div
                  className="html_raw"
                  dangerouslySetInnerHTML={{
                    __html: modal.field.TandCBodyText.value,
                  }}
                />
              </div>
              <div className="contactUIComponentsPlanCard_modal_container_single-button">
                <a href={modal.tcUrl} target="_blank" rel="noopener noreferrer">
                  <div className="contactUIComponentsPlanCard_modal_container_single-button_button-tc">
                    {modal.field.TandCPdfLink.value.text}
                  </div>
                </a>
              </div>
            </div>
          </div>,
          modalRoot
        )}
    </div>
  );
}
