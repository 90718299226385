import React from 'react';
import { Empty } from '../Empty';
import { useTranslation } from '../Translation';
import { useTabContext } from './Context';
import { HeaderTab, HeaderTabComponentKey } from './TabTypes';

export interface TabbedPageContentProps {
  tabs: Record<HeaderTabComponentKey, () => JSX.Element>;
}

export function TabbedPageContent({ tabs }: TabbedPageContentProps) {
  const [t] = useTranslation({
    useSuspense: true,
  });
  const tabDescriptors: HeaderTab[] = t(`tabs`, {
    returnObjects: true,
  });
  const { selectedTabIndex } = useTabContext();
  const Tab = tabs[tabDescriptors[selectedTabIndex]?.componentKey];
  if (!Tab) {
    return <Empty />;
  }
  return <Tab />;
}
