const baseStyle = {
  fontWeight: 'black',
};

const sizes = {
  h1: {
    fontSize: {
      sm: '32px',
      md: '32px',
      lg: '38px',
    },
    lineHeight: {
      sm: '1.22',
      md: '1.22',
      lg: '1.24',
    },
    letterSpacing: {
      sm: '-0.32px',
      md: '-0.32px',
      lg: '-0.38px',
    },
  },
  h2: {
    fontSize: {
      sm: '22px',
      md: '22px',
      lg: '32px',
    },
    lineHeight: {
      sm: '1.23',
      md: '1.23',
      lg: '1.22',
    },
    letterSpacing: {
      sm: '-0.22px',
      md: '-0.22px',
      lg: '-0.32px',
    },
  },
  h3: {
    fontSize: {
      sm: '18px',
      md: '18px',
      lg: '28px',
    },
    lineHeight: {
      sm: '1.19',
      md: '1.19',
      lg: '1.2',
    },
    letterSpacing: {
      sm: '-0.16px',
      md: '-0.16px',
      lg: '-0.2px',
    },
  },
  h4: {
    fontSize: {
      sm: '14px',
      md: '14px',
      lg: '24px',
    },
    lineHeight: {
      sm: '1.21',
      md: '1.21',
      lg: '1.21',
    },
    letterSpacing: {
      sm: '-0.24px',
      md: '-0.24px',
      lg: '-0.24px',
    },
  },
  h5: {
    fontSize: {
      sm: '10px',
      md: '10px',
      lg: '20px',
    },
    lineHeight: {
      sm: '1.2',
      md: '1.2',
      lg: '1.2',
    },
    letterSpacing: {
      sm: '-0.2px',
      md: '-0.2px',
      lg: '-0.2px',
    },
  },
};

export const Heading = {
  baseStyle,
  sizes,
};
