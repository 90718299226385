export const IconImage = {
  baseStyle: {
    maxWidth: 'var(--icon-image-max-size, 24px)',
    maxHeight: 'var(--icon-image-max-size, 24px)',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  defaultProps: {},
  variants: {},
};
