export const Modal = {
  baseStyle: {
    overlay: { bg: 'purpleAlpha.900', zIndex: 'modal' },
    dialogContainer: {
      display: 'flex',
      zIndex: 'modal',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    dialog: {
      borderRadius: 'md',
      bg: 'white',
      color: 'inherit',
      zIndex: 'modal',
      boxShadow: 'lg',
      mx: 5,
    },
    header: {
      px: 4,
      pt: 5,
      fontSize: 'md',
      fontWeight: 'bold',
      lineHeight: 'base',
    },
    body: {
      px: 4,
      pt: 2,
      pb: 3,
      fontSize: 'sm',
      fontWeight: 'medium',
      lineHeight: 'base',
      flex: 1,
      overflow: 'auto',
      '> p': {
        mb: 3,
      },
    },
    footer: {
      px: 0,
      py: 0,
    },
  },
  sizes: {
    md: {
      dialog: {
        maxWidth: '480px',
      },
    },
  },
  variants: {
    default: {},
    welcome: {
      header: {
        px: {
          base: '24px',
          sm: '72px',
        },
        pt: 0,
        fontSize: '26px',
        fontWeight: 'extrabold',
        lineHeight: 'shorter',
      },
      dialog: {
        maxWidth: '640px',
      },
      body: {
        px: {
          base: '24px',
          sm: '72px',
        },
        pt: 0,
        pb: 4,
        fontSize: '20px',
        lineHeight: 'shorter',
        '> p': {
          mb: 0,
        },
        '> strong': {
          fontWeight: 'bold',
        },
      },
      footer: {
        px: 4,
        pb: 4,
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};
