import React from 'react';
import './Footer.scss';
import { Footer as Constants } from './Constants';
import _debounce from 'lodash/debounce';
import logo from '../../Assets/Images/contact-logo.svg';
import logoMobile from '../../Assets/Images/contact-logo-mobile.svg';

export const Footer = () => {
  return (
    <div className="contactUIComponentsFooter">
      <div className="contactUIComponentsFooter_image">
        <img
          src={logo}
          alt={Constants.contactLogoAlt}
          className="desktopLogo"
        />
        <img
          src={logoMobile}
          alt={Constants.contactLogoAlt}
          className="mobileLogo"
        />
      </div>
      <div className="contactUIComponentsFooter_blocks">
        <div className="contactUIComponentsFooter_blocks_links">
          {Constants.links.map(({ title, href }, index) => (
            <a
              key={'link' + index}
              href={href}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>{title}</span>
            </a>
          ))}
        </div>
        <div>{Constants.copyright}</div>
      </div>
    </div>
  );
};
