import {
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export type BadgeProps = Omit<
  ChakraBadgeProps,
  'variant' | 'colorScheme' | 'size'
>;

export const Badge = (props: BadgeProps): ReactElement => {
  return <ChakraBadge {...props}></ChakraBadge>;
};
