import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import { ArrowDown } from '../../Assets/Icons';

import './styles.scss';

interface ToggleButtonProps {
  expandLabel: string;
  collapseLabel: string;
  expanded: boolean;
  onToggle: () => void;
  mobileInline?: boolean;
  tableInline?: boolean;
  className?: string;
}

const ToggleButton = ({
  expandLabel,
  collapseLabel,
  expanded,
  onToggle,
  mobileInline,
  tableInline,
  className,
}: ToggleButtonProps) => (
  <span
    className={cx('contactUIComponentsToggleButton', className, {
      'contactUIComponentsToggleButton--expanded': expanded,
      'contactUIComponentsToggleButton--tabletInline': tableInline,
      'contactUIComponentsToggleButton--mobileInline': mobileInline,
    })}
    onClick={onToggle}
  >
    <span className="contactUIComponentsToggleButton_collapsedLabel">
      {expandLabel}
    </span>
    <span className="contactUIComponentsToggleButton_expandedLabel">
      {collapseLabel}
    </span>
    <Icon
      icon={<ArrowDown />}
      className="contactUIComponentsToggleButton_arrowDownIcon"
    />
  </span>
);

export default ToggleButton;
