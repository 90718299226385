import { baseInstance } from './base-service';
import { AxiosRequestConfig } from 'axios';
import { BroadbandRange, BroadbandUsageApiResponse } from './models';
import { BroadbandUsagePath, BroadbandUsageVersion } from './apiPaths';

// API documentation: https://developer.contact-digital-devops.net/apis/6272bdb261713e740d4526aca4bc49d192e48c5a

export interface BroadbandUsageParams {
  range: BroadbandRange;
  /** format: YYYY-MM-DD */
  start: string;
  /** format: YYYY-MM-DD */
  end?: string;
  ba: string;
  config?: AxiosRequestConfig;
}

export const getBroadbandUsage = (
  version: BroadbandUsageVersion,
  {
    range,
    start,
    end,
    ba,
    config,
    token,
  }: BroadbandUsageParams & { token: string }
) => {
  return baseInstance(config).get<BroadbandUsageApiResponse>(
    BroadbandUsagePath[version],
    {
      params: {
        range,
        start,
        end,
        ba,
      },
      headers: { session: token },
    }
  );
};
