import React, { ReactElement } from 'react';
import {
  Stack,
  StackProps,
  RadioGroupProps as ChakraRadioGroupProps,
  useRadioGroup,
} from '@chakra-ui/react';
import { Radio, RadioProps } from '../radio';

export interface RadioGroupOptionProps
  extends Pick<RadioProps, 'value' | 'isDisabled' | 'isInvalid'> {
  label: React.ReactNode;
}

export interface RadioGroupProps
  extends Omit<ChakraRadioGroupProps, 'colorScheme' | 'children'>,
    Pick<StackProps, 'direction' | 'spacing'> {
  options: RadioGroupOptionProps[];
}

export const RadioGroup = (props: RadioGroupProps): ReactElement => {
  const { getRootProps, getRadioProps } = useRadioGroup(props);

  const group = getRootProps();

  return (
    <Stack {...group} direction={props.direction} spacing={props.spacing}>
      {props.options.map(({ value, label, ...rest }) => {
        const radio = getRadioProps(({
          value,
          ...rest,
        } as unknown) as Parameters<typeof getRadioProps>[0]);
        return (
          <Radio {...radio} key={value}>
            {label}
          </Radio>
        );
      })}
    </Stack>
  );
};
