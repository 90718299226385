import React, { PropsWithChildren, ReactNode } from 'react';
import './Message.scss';
import { DefaultSVG } from './Assets/Default';
import ClassNames from 'classnames';

export interface MessageProps {
  title: string;
  message: string;
  imageSrc?: string;
  image?: ReactNode;
  className?: string;
}

export function Message({
  title,
  message,
  imageSrc,
  image,
  children,
  className,
}: PropsWithChildren<MessageProps>) {
  return (
    <div className={ClassNames('contactUIComponentsMessage', className)}>
      {image ?? <img src={imageSrc ?? DefaultSVG} alt="" role="presentation" />}
      <h2 className="contactUIComponentsMessage_title">{title}</h2>
      <span className="contactUIComponentsMessage_message">{message}</span>
      {children}
    </div>
  );
}
