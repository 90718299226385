import React, { useState, useMemo } from 'react';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import './AutoSuggestInput.scss';
import customInput from './CustomInput';
import suggestion from './Suggestion';
import { validateEmailAddress } from '../Utils/ValidationUtil';
import { emailDomains } from './Constants';

// Material UI styles
const styles = (theme) => ({
  root: {
    height: 60,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function IntegrationAutosuggest(props) {
  const {
    classes,
    containerClassName,
    handleChange,
    handleError,
    inputProps,
    hasError,
    value,
  } = props;

  const [suggestions, setSuggestions] = useState<{ name: string }[]>([]);

  const valueChangedHandler = (_event, { newValue }) => {
    handleChange(newValue);
  };

  const autoSuggestClassName = classNames(classes.root, containerClassName);

  const domains = useMemo(() => emailDomains, []);

  const getSuggestions = (value) => {
    if (value) {
      const index = value.indexOf('@'); // e.g. abc@gmail.com
      let emailBody = '';
      let emailDomain = '';

      if (index !== -1) {
        emailBody = value.substring(0, index); // e.g. body=abc
        emailDomain = value.substring(index + 1);
      } else {
        emailBody = value;
      }

      const options = domains
        .filter((item) => item.startsWith(emailDomain) && item !== emailDomain)
        .map((item) => ({
          name: emailBody + '@' + item,
        }));

      return options;
    }

    return [];
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_event, { suggestionValue }) => {
    if (suggestionValue) {
      const isValid = validateEmailAddress(suggestionValue);
      handleError(!isValid);
    }
  };

  // https://contactenergy.atlassian.net/browse/LSMS-1695
  // https://github.com/moroshko/react-autosuggest#should-render-suggestions-prop
  // only show the suggestions when the input value is changed or when the email is invalid
  const shouldRenderSuggestions = (value, reason) => {
    // we need to use "suggestions-updated" and "render" here since they will always get fired when input value is changed after "input-changed" gets fired first
    return (
      reason === 'input-changed' ||
      reason === 'suggestions-updated' ||
      reason === 'render' ||
      hasError
    );
  };

  return (
    <div className={autoSuggestClassName} ref={inputProps && inputProps.ref}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderSuggestion={suggestion}
        getSuggestionValue={(suggestion) => suggestion.name}
        inputProps={{
          classes,
          value,
          onChange: valueChangedHandler,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={(inputProps) => customInput(inputProps, props)}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

// @ts-expect-error: dont want to deal with ts errors for the styles types mismatch etc
export default withStyles(styles)(IntegrationAutosuggest);
