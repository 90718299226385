import { AxiosRequestConfig } from 'axios';
import { GetSiteCoreContentPath, GetSiteCoreContentVersion } from './apiPaths';
import { baseInstance } from './base-service';
import { SiteCoreApiResponse } from './models';

export const getSiteCoreContent = (
  version: GetSiteCoreContentVersion,
  url: string,
  config?: AxiosRequestConfig
) =>
  baseInstance(config).get<SiteCoreApiResponse>(
    `${GetSiteCoreContentPath[version]}/${url}`
  );
