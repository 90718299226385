import {
  useTranslation as useTranslationWithNamespace,
  UseTranslationOptions as BaseUseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';
import { useTranslationNamespaceContextOptional } from './Context';

export { useTranslationWithNamespace };

export type UseTranslationOptions = BaseUseTranslationOptions &
  Record<string, unknown>;

export function useTranslation(
  optionsOrNamespace?: UseTranslationOptions | string,
  otherOptions?: UseTranslationOptions
): UseTranslationResponse {
  const namespace = useTranslationNamespaceContextOptional();
  let args: [string, UseTranslationOptions | undefined];
  if (typeof optionsOrNamespace === 'string') {
    args = [optionsOrNamespace, otherOptions];
  } else {
    if (!namespace) {
      throw new Error('Expected translation namespace to be provided');
    }
    args = [namespace, optionsOrNamespace ?? otherOptions];
  }
  return useTranslationWithNamespace(...args);
}
