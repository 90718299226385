import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '../Icons';
import './CTACard.scss';

type Props = {
  to: string;
  title?: string;
  text?: string;
  underlinedText?: string;
  className?: string;
  onClick?(): void;
};

export const CTACard = ({
  to,
  title,
  text,
  underlinedText,
  className,
  onClick,
}: Props) => {
  return (
    <div className={classNames('contactUIComponentsCtaCard', className)}>
      <Link onClick={onClick} to={to}>
        <div className="contactUIComponentsCtaCard_container">
          <div className="contactUIComponentsCtaCard_content">
            <p className="contactUIComponentsCtaCard_title">{title}</p>
            <p className="contactUIComponentsCtaCard_text">
              {text}{' '}
              <span className="contactUIComponentsCtaCard_text--underlined">
                {underlinedText}
              </span>
            </p>
          </div>
          <ChevronRightIcon boxSize={{ base: '24px', lg: '40px' }} />
        </div>
      </Link>
    </div>
  );
};
