import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const UsageLargeIcon = ({
  color = '#303030',
  ...props
}: IconProps): ReactElement => (
  <Icon
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M18.7832 12.4457H15.2967C14.7421 12.4457 14.2925 12.8953 14.2925 13.4499V35.554C14.2925 36.1086 14.7421 36.5581 15.2967 36.5581H18.7832C19.3378 36.5581 19.7874 36.1086 19.7874 35.554V13.4499C19.7874 12.8953 19.3378 12.4457 18.7832 12.4457Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M27.6489 19.0281H24.1624C23.6078 19.0281 23.1582 19.4777 23.1582 20.0323V35.5549C23.1582 36.1095 23.6078 36.5591 24.1624 36.5591H27.6489C28.2035 36.5591 28.6531 36.1095 28.6531 35.5549V20.0323C28.6531 19.4777 28.2035 19.0281 27.6489 19.0281Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M36.5151 25.1054H33.0286C32.474 25.1054 32.0244 25.555 32.0244 26.1096V35.5539C32.0244 36.1085 32.474 36.5581 33.0286 36.5581H36.5151C37.0697 36.5581 37.5193 36.1085 37.5193 35.5539V26.1096C37.5193 25.555 37.0697 25.1054 36.5151 25.1054Z"
      stroke={color as string}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </Icon>
);
