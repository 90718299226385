export const Table = {
  baseStyle: {
    th: {
      fontWeight: 'medium',
      color: 'gray.600',
      fontFamily: 'inherit',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    td: {
      fontWeight: 'semibold',
      color: 'gray.900',
    },
  },
  defaultProps: { size: 'md', variant: 'stripped' },
  sizes: {
    md: {
      th: {
        fontSize: 'xs',
        lineHeight: 'shorter',
        paddingX: 2,
        paddingTop: 0,
        paddingBottom: 2,
      },
      td: {
        fontSize: 'sm',
        lineHeight: 'shorter',
        paddingX: 2,
        paddingY: 1,
      },
    },
  },
  variants: {
    stripped: {
      tbody: {
        tr: {
          '&:nth-of-type(even)': {
            background: 'gray.300',
          },
        },
      },
    },
  },
};
