import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const PipedGasIconWithCircle = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 49.506 49.506" {...props}>
    <g
      id="all_elements_icons_48px_gas"
      data-name="all / elements / icons / 48px gas"
      transform="translate(0.753 0.753)"
    >
      <circle
        id="Ellipse_106"
        cx="24"
        cy="24"
        r="24"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.506"
      />
      <path
        id="Gas-Copy_1_"
        d="M20.5,38.5S9.3,32.9,18.4,21.3c4.9-6.2,3.8-11.8,3.8-11.8s8.7,9.7,3.1,17.7a6.38,6.38,0,0,0,4.3-5.3s10.2,10.5-3.9,16.5a7.63,7.63,0,0,0,1.5-8.3s-2.7,2.9-4.5,3.3a5.575,5.575,0,0,1-1.9-7.1C20.8,26.4,13.8,32.8,20.5,38.5Z"
        fill="none"
        stroke="#dd353e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.506"
      />
    </g>
  </Icon>
);
