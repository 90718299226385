import React, { useState } from 'react';
import { FileUpload, QuestionMarkIcon } from '@contact/ui-components';
import {
  FormControl,
  FormErrorMessage,
  H5,
  Text,
  Box,
} from '@contact/ui-volta';
import { Namespace } from '../TrimesterComponents/Translations';
import { useTranslationWithNamespace } from '@contact/react';
import { Controller } from 'react-hook-form';
import { ContactPhoneLink } from '../TrimesterComponents';
import { tooltipClick } from '../Utils';
import { UploadDocumentsHelpModal } from './UploadDocumentsHelpModal';

// eslint-disable-next-line no-useless-escape
const filenameValidator = /^[^\\\/:*?"<>|]+$/gm;

export type UploadDocumentsProps = {
  name?: string;
  control: any;
  errors: any;
  trigger: any;
  hasTips?: boolean;
  errorMessage?: string;
  files?: string[];
  setFiles?: (fileItems) => void;
  isRequired?: boolean;
};

export const UploadDocuments: React.FC<UploadDocumentsProps> = ({
  name = 'uploadDocuments',
  control,
  errors,
  trigger,
  errorMessage,
  files,
  setFiles,
  isRequired = true,
  hasTips = true,
}) => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', {
    returnObjects: true,
  });
  const [modalOpened, setModalOpened] = useState(false);
  const uploadDocumentsQuestion = translations.uploadDocumentsQuestion;

  const customerUploadMessage = () => (
    <Text>
      <span>{uploadDocumentsQuestion.descriptionWithTips.apply}</span>
      <span
        className="form-control-description link"
        onClick={() => setModalOpened(!modalOpened)}
      >
        {uploadDocumentsQuestion.descriptionWithTips.link}
      </span>
      <span>{uploadDocumentsQuestion.descriptionWithTips.upload}</span>
    </Text>
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? errorMessage : undefined,
      }}
      render={({ field: { onChange, ref } }) => {
        const handleUpdateFiles = async (e) => {
          await trigger(name); //manual trigger validation on file upload
          onChange(e);
          setFiles(e);
        };

        const onTooltipClick = () => {
          setModalOpened(true);
          tooltipClick(
            'baby due date',
            translations.helpTooltips.documentsUpload.content.description.substring(
              0,
              100
            )
          );
        };
        return (
          <FormControl
            className="form-control"
            isRequired={isRequired}
            isInvalid={!!errors[name]}
          >
            {uploadDocumentsQuestion.title && (
              <Box className="form-control-title">
                <H5>{uploadDocumentsQuestion.title}</H5>
                {hasTips && (
                  <Box className="form-control-icon" onClick={onTooltipClick}>
                    <QuestionMarkIcon
                      margin="10px"
                      boxSize={{
                        base: '20px',
                        lg: '20px',
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
            {modalOpened && (
              <UploadDocumentsHelpModal
                isActive={modalOpened}
                onHandleClose={() => setModalOpened(false)}
              />
            )}
            <Text paddingBottom={3}>
              {hasTips
                ? customerUploadMessage()
                : uploadDocumentsQuestion.descriptionNoTips.apply}
            </Text>

            {hasTips && (
              <Text className="form-control-description" fontWeight="bold">
                {uploadDocumentsQuestion.descriptionWithTips?.help?.text}
                <ContactPhoneLink />
                {uploadDocumentsQuestion.descriptionWithTips?.help?.text2}
              </Text>
            )}

            <FileUpload
              fileRef={ref}
              isRequired={true}
              acceptedFileTypes={['application/pdf', 'image/*']}
              maxFiles={5}
              maxFileSize="6MB"
              files={files}
              handleUpdateFiles={handleUpdateFiles}
              filenameValidator={filenameValidator}
            />

            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};
