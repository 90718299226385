import React, { PropsWithChildren } from 'react';
import { useStyleConfig, Box } from '@chakra-ui/react';
import { PageContent } from '../PageContent';
import { Alerts } from '../../Alerts';
import './PageHeader.scss';

export interface PageHeaderProps {
  variant?: string;
}

export function PageHeader({
  variant,
  children,
}: PropsWithChildren<PageHeaderProps>) {
  const styles = useStyleConfig('PageHeader', { variant });
  return (
    <PageContent variant={variant} sx={styles}>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Alerts
          section="PageHeader"
          className="contactUIComponentsPageHeader_alerts"
        />
        {children}
      </Box>
    </PageContent>
  );
}
