import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  H4,
  Text,
} from '@contact/ui-volta';
import { AutoCompleteEmail } from '@contact/ui-components';

export const FormAutoCompleteEmail = ({
  name,
  label,
  title = undefined,
  description = undefined,
  className = undefined,
  maxLength = 50,
  control,
  errors,
  isRequired = false,
  errorMessage,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: errorMessage.required,
        validate: (value) => {
          const regexp = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          return regexp.test(value.trim()) ? undefined : errorMessage.notValid;
        },
      }}
      render={({
        field: { onChange, value = '', ref, ...rest },
        fieldState: { invalid, isTouched, isDirty },
      }) => (
        <FormControl
          className={className}
          isRequired={isRequired}
          isInvalid={!!errors[name]}
        >
          {title && <H4 className="form-control-title">{title}</H4>}
          {description && (
            <Text className="form-control-description">{description}</Text>
          )}
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
          <AutoCompleteEmail
            name={name}
            handleChange={onChange}
            value={value}
            placeholder={label}
            maxLength={maxLength}
            inputProps={{ ref }}
            handleError={(error) => {
              console.log('error', error);
            }}
            hasError={invalid}
            validationType="email"
            showSuccessIcon
          />

          <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
