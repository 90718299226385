import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { AxiosConfig, CustomerVersion } from '@contact/data-access';
import { AxiosConfigProvider, useCustomer } from '@contact/data-access-hooks';

export interface User extends Record<string, unknown> {
  isCSR: boolean;
  // We always expect a logged in user with a token right...?
  token: string;
  xcsrfToken?: string;
  ba?: string;
  selectedBaId?: string;
  bp?: string;
  premise?: string;
  selectedPremiseId?: string;
  setBaId?: (baId: string) => void;
  setPremiseId?: (premiseId: string) => void;
}

export const UserContext = createContext<User | undefined>(undefined);

export interface UseUserOptions {
  ba?: boolean;
  bp?: boolean;
  premise?: boolean;
}

export function useOptionalUser() {
  return useContext(UserContext);
}

export function useUser(required: UseUserOptions = {}): User {
  const user = useOptionalUser();
  if (!user) {
    throw new Error('No UserProvider utilised');
  }
  if (!user.ba && required.ba)
    throw new Error(
      'User has no ba, this is a technical requirement for this feature, the accounts api probably has not responded yet'
    );
  if (!user.bp && required.bp)
    throw new Error(
      'User has no bp, this is a technical requirement for this feature, the accounts api probably has not responded yet'
    );
  if (!user.premise && required.premise)
    throw new Error(
      'User has no premise, this is a technical requirement for this feature, the accounts api probably has not responded yet'
    );
  return user;
}

export interface UserProviderProps {
  useIsCSR?(): boolean;
  useBpId?(): string | undefined;
  useBaId?(): string | undefined;
  useSelectedBaId?(): string | undefined;
  usePremise?(): string | undefined;
  useSelectedPremiseId?(): string | undefined;
  useToken?(): string | undefined;
  useXcsrfToken?(): string | undefined;
  useBaIdSetter?(): (baId: string) => void;
  usePremiseIdSetter?(): (premiseId: string) => void;
  // user must return a stable object that will not change between
  // renders, this will cause anywhere that uses this context to re-render
  user?: Partial<User>;
}

function useFalse() {
  return false;
}

function useUndefined() {
  return undefined;
}

function useUndefinedSetter() {
  return useUndefined;
}

export function UserProvider({
  useIsCSR = useFalse,
  useBpId = useUndefined,
  useBaId = useUndefined,
  useSelectedBaId = useUndefined,
  useToken = useUndefined,
  useXcsrfToken = useUndefined,
  usePremise = useUndefined,
  useSelectedPremiseId = useUndefined,
  useBaIdSetter = useUndefinedSetter,
  usePremiseIdSetter = useUndefinedSetter,
  user: givenUser = undefined,
  children,
}: PropsWithChildren<UserProviderProps>) {
  const isCSR = useIsCSR();
  const bp = useBpId();
  const ba = useBaId();
  const selectedBaId = useSelectedBaId();
  const token = useToken();
  const xcsrfToken = useXcsrfToken();
  const premise = usePremise();
  const selectedPremiseId = useSelectedPremiseId();
  const setBaId = useBaIdSetter();
  const setPremiseId = usePremiseIdSetter();
  const user = useMemo(
    (): User => ({
      isCSR,
      bp,
      ba,
      selectedBaId,
      premise,
      selectedPremiseId,
      xcsrfToken,
      token: token || '',
      setBaId,
      setPremiseId,
      ...givenUser,
    }),
    [
      isCSR,
      bp,
      ba,
      selectedBaId,
      premise,
      selectedPremiseId,
      xcsrfToken,
      token,
      setBaId,
      setPremiseId,
      givenUser,
    ]
  );
  const axios = useMemo((): AxiosConfig | undefined => {
    if (!user.token) return undefined;
    return {
      headers: {
        session: user.token,
        Authorization: user.token,
      },
    };
  }, [user.token]);
  return (
    <UserContext.Provider value={user}>
      {/* auto configure session for the user */}
      <AxiosConfigProvider value={axios}>{children}</AxiosConfigProvider>
    </UserContext.Provider>
  );
}

/**
 * Returns true when its possible to start loading data against the user.
 * When this returns false, some queries may produce errors.
 */
export function useIsUserReady(version: CustomerVersion): boolean {
  const { token = '', ba } = useOptionalUser() || {};
  const { isFetching } = useCustomer(version, token);
  return !!token && !!ba && !isFetching;
}
