import React from 'react';

export const LongArrowUp = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      viewBox="0 0 18 23"
      {...props}
    >
      <g
        fill="none"
        fill-rule="evenodd"
        stroke="#DD353F"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.3"
      >
        <path
          d="M6.9 18.4L6.9 0M0 6.9L6.9 0 13.8 6.9"
          transform="translate(2.4 2.65)"
        />
      </g>
    </svg>
  );
};
