import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';

export const forgotPassword = (
  username: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<void>('/password/forgotpassword', {
    username,
  });
};
