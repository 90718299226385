import React, { useRef } from 'react';
import classNames from 'classnames';
import './Error.scss';
import { useStyleConfig } from '@chakra-ui/react';
import { Button } from '../Button';
import { Link } from '../Link';
import {
  dleTechnicalError,
  useAnalyticsOnDisplay,
  useReload,
  useTranslationWithNamespace,
} from '@contact/react';
import { isDisplayError } from './DisplayError';
import { isDisplayReactComponentError } from './ComponentError';
import { Message } from '../Message';
import { isAxiosError } from './AxiosError';
import { Box } from '@contact/ui-volta';
import { CactusSVG } from '../Images';

export type ErrorHTTPStatusMessages = Record<number, JSX.Element>;

export interface ErrorViewProps {
  error?: unknown;
  content?: boolean;
  className?: string;
  httpStatusErrors?: ErrorHTTPStatusMessages;
  onReset?: () => void;
  backMessage?: string;
}

export function ErrorView({
  error,
  content,
  className,
  httpStatusErrors = {},
  onReset,
  backMessage,
}: ErrorViewProps) {
  const styles = useStyleConfig('PageContent');
  const reload = useReload();
  const [t] = useTranslationWithNamespace('ErrorBoundaryError');
  const ref = useRef();
  if (typeof backMessage === 'undefined') {
    backMessage = t('navigateHome', {
      defaultValue: 'Back to Dashboard',
    });
  }
  useAnalyticsOnDisplay(ref, dleTechnicalError);
  if (isDisplayReactComponentError(error)) {
    return error.node;
  }
  if (
    isAxiosError(error) &&
    error.response &&
    httpStatusErrors?.[error.response.status]
  ) {
    return httpStatusErrors[error.response.status];
  }
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Box sx={{ ...styles }} ref={ref}>
      <Message
        title={t('title', {
          defaultValue: 'Sorry, something went wrong',
        })}
        message={isDisplayError(error) ? error.message : ''}
        image={<CactusSVG />}
        className={classNames('contactUIComponentsError_container', className, {
          contactUIComponentsError_container_content: content,
        })}
      >
        <Button
          variant="primary"
          className="contactUIComponentsError_button"
          onClick={onReset ?? reload}
        >
          {t('reload', {
            defaultValue: 'Try again',
          })}
        </Button>
        {backMessage && <Link to="/">{backMessage}</Link>}
      </Message>
    </Box>
  );
}
