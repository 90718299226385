export const Modal = {
  baseStyle: {
    overlay: { bg: 'purpleAlpha.900', zIndex: 'modal' },
    dialogContainer: {
      display: 'flex',
      zIndex: 'modal',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
    dialog: {
      borderRadius: 'md',
      bg: 'white',
      color: 'inherit',
      zIndex: 'modal',
      boxShadow: 'lg',
      mx: 5,
    },
    header: {
      px: 4,
      pt: 5,
      fontSize: 'md',
      fontWeight: 'bold',
      lineHeight: 'base',
    },
    body: {
      px: 4,
      pt: 2,
      pb: 3,
      fontSize: 'sm',
      fontWeight: 'medium',
      lineHeight: 'base',
      flex: 1,
      overflow: 'auto',
      '> p': {
        mb: 3,
      },
    },
    footer: {
      px: 0,
      py: 0,
    },
  },
  sizes: {
    md: {
      dialog: {
        maxWidth: '480px',
      },
    },
  },
  variants: {
    default: {},
    welcome: {
      dialog: {
        width: '358px',
        padding: '24px',
        alignItems: 'center',
      },
      header: {
        padding: 0,
        fontSize: '20px',
        fontWeight: 900,
        lineHeight: 1.4,
        textAlign: 'center',
        marginBottom: '16px',
      },
      body: {
        padding: 0,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: 1.5,
        textAlign: 'center',
        marginBottom: '24px',
      },
      footer: {
        padding: 0,
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};
