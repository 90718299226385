import * as React from 'react';
import { useSpring, animated } from 'react-spring';
import './ExpansionPanel.scss';

import { useMeasure, usePrevious } from '../Utils/hooks';

const defaultProps = {
  isExpanded: false,
};

const ExpansionPanel = (props) => {
  const previous = usePrevious(props.isExpanded);
  const [bind, { height: viewHeight }] = useMeasure();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: props.isExpanded ? viewHeight : 0,
      opacity: props.isExpanded ? 1 : 0,
    },
  });

  const expandedHasChanged =
    typeof previous !== 'undefined' && previous !== props.isExpanded;

  return (
    <animated.div
      className="contactUIComponentsExpansionPanel"
      aria-expanded={props.isExpanded}
      style={{
        opacity: props.isExpanded && !expandedHasChanged ? 1 : opacity,
        height: props.isExpanded && !expandedHasChanged ? 'auto' : height,
        overflow: props.isExpanded ? 'visible' : 'hidden',
      }}
    >
      <div {...bind}>{props.children}</div>
    </animated.div>
  );
};

ExpansionPanel.defaultProps = defaultProps;

export default ExpansionPanel;
