import React from 'react';
import { Sidebar, SidebarProps } from './SideBar';
import './SlidingHeader.scss';

const SlidingHeader = (props: SidebarProps) => {
  return (
    <div className="contactUIComponentsSlidingHeaderLeft contactUIComponentsSlidingHeaderLeftAlign">
      <Sidebar {...props} />
    </div>
  );
};

export { SlidingHeader };
