import React, { ReactElement } from 'react';
import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
} from '@chakra-ui/react';

export type FormLabelProps = ChakraFormLabelProps;

export const FormLabel = (props: FormLabelProps): ReactElement => {
  return <ChakraFormLabel {...props} />;
};
