import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const UsageIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 23.963 24" {...props}>
    <g>
      <path
        d="M21.615 13.5H10.506V2.391a.771.771 0 00-.75-.75h-.094A11.239 11.239 0 0010.834 24h.422a11.218 11.218 0 0011.109-9.656.792.792 0 00-.75-.844zm-10.359 8.25h-.328a9.071 9.071 0 01-8.672-8.672 8.932 8.932 0 016-8.766V15.75h11.438a8.906 8.906 0 01-8.438 6zm2.25-21.7a.046.046 0 00-.047-.047.776.776 0 00-.7.8V11.25h10.447a.713.713 0 00.75-.75A11.2 11.2 0 0013.506.047z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
