import { ContractDetail, Consumption } from '@contact/data-access';

export type Utility = 'electricity' | 'gas' | 'broadband';

export const MONTHLY: Range = 'Month';
export const DAILY: Range = 'Day';
export const HOURLY: Range = 'Hour';

export type Range = 'Month' | 'Day' | 'Hour';

export const GAS: Utility = 'gas';
export const ELECTRICITY: Utility = 'electricity';
export const BROADBAND: Utility = 'broadband';

/** Used for displaying usage in $ instead of kWh. */
export type UnitMode = 'cost' | 'units';

export interface DataItem extends Partial<Consumption> {
  date: string;
  value: string;
  dollarValue?: string;
  offpeakValue: string;
  offpeakDollarValue?: string;
  month: number;
  day: number;
  year: number;
  hour: number;
  unit: string;
  error?: {
    type: 'dollar-usage';
  };
}

export type ElectricityPlanType = 'Good Nights' | 'Dream Charge';

export interface BroadbandDataItem extends DataItem {
  endDate: string;
}

export type ElectricityPlanDetails = {
  [T in ElectricityPlanType]: ElectricityPlanDetail;
};

export interface ElectricityPlanDetail {
  OffpeakFree: boolean;
}

export type SelectedContracts = { [K in Utility]?: ContractDetail };

export interface RangeButtonModel {
  label: Range;
  handleClick: () => void;
  selected: boolean;
  disabled?: boolean;
}
