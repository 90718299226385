import { CustomerApiResponse } from '@contact/data-access';
import { useCustomer, useTrimesterReward } from '@contact/data-access-hooks';
import { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, without } from 'lodash-es';
import { usePlanType } from './usePlanType';
import * as Usage from './UsageConstants';
import * as Util from './UsageUtil';
import { useCostModeEnabled } from './useCostModeEnabled';
import DateUtil from '../Utilities/DateUtil';
import { UnifiedUsageQueryOptions, useUnifiedUsage } from './useUnifiedUsage';
import { useSelectedContracts } from './useSelectedContracts';
import { useOptionalUser } from '../User';

/**
 * The first supported unit mode is the default, when
 * unit modes are disabled.
 */
const supportedUnitModes = {
  [Usage.ELECTRICITY]: ['units', 'cost'] as Usage.UnitMode[],
  [Usage.GAS]: ['units'] as Usage.UnitMode[],
  [Usage.BROADBAND]: ['units'] as Usage.UnitMode[],
};

const emptyData: Usage.DataItem[] = [];

export interface UsageGraphDetailHookParams {
  selectedUtility: Usage.Utility;
  baseAxiosConfig: Partial<AxiosRequestConfig>;
  onUsageErrorPopup?: () => void;
  onPeriodChange?: (period: Usage.Range) => void;
  hideHourlyButton?: boolean;
  dollarUsageStartDate: string;
  electricityPlanDetails: Usage.ElectricityPlanDetails;
}

export interface UsageGraphDetail {
  customer: CustomerApiResponse | undefined;
  isFourthTrimester: boolean;
  isAccountsReady: boolean;
  isFetching: boolean;
  hasError: boolean;
  isDataEmpty: boolean;
  isMonthlyDataEmpty: boolean;
  selectedContracts: Usage.SelectedContracts;
  buttons: Usage.RangeButtonModel[];
  selectedPeriod: Usage.Range;
  unitMode: Usage.UnitMode;
  availableUnitModes: Usage.UnitMode[];
  unavailableUnitModes: { [K in Usage.UnitMode]: boolean };
  unitModeErrors: { [K in Usage.UnitMode]: boolean };
  setUnitModePreferred: React.Dispatch<React.SetStateAction<Usage.UnitMode>>;
  usageCap: number;
  data: Usage.DataItem[];
  slideIndex: number;
  setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
  electricityPlanType: Usage.ElectricityPlanType | undefined;
}

export function useUsageGraphDetails({
  selectedUtility,
  baseAxiosConfig,
  onUsageErrorPopup,
  onPeriodChange,
  hideHourlyButton,
  dollarUsageStartDate: dollarUsageStartDateProp,
  electricityPlanDetails,
}: UsageGraphDetailHookParams): UsageGraphDetail {
  const {
    token = '',
    xcsrfToken = '',
    ba: baId = '',
    selectedBaId = '',
    premise: premiseId = '',
    selectedPremiseId = '',
  } = useOptionalUser() || {};
  const isPremiseSwitching =
    (selectedBaId && selectedBaId !== baId) ||
    (selectedPremiseId && selectedPremiseId !== premiseId);

  const { data: customer, isLoading: isCustomerLoading } = useCustomer(
    'v2',
    token
  );
  const [isFourthTrimester, setIsFourthTrimester] = useState(false);
  const [isDataVisible, setDataVisible] = useState(false);

  const {
    isTrimesterCustomer,
    isFetching: isRewardsFetching,
  } = useTrimesterReward('v2', token, baId, baseAxiosConfig);

  const {
    data: selectedContracts,
    isLoading: isAccountLoading,
  } = useSelectedContracts();

  const contractId = selectedContracts[selectedUtility]?.id;

  const {
    planType: electricityPlanType,
    isFetching: isElectricityPlanFetching,
    isError: isElectricityPlanError,
  } = usePlanType<Usage.ElectricityPlanType>({
    version: 'v2',
    token,
    accountId: baId,
    contractId: contractId ?? '',
  });

  const [
    selectedPeriodElectricity,
    setSelectedPeriodElectricity,
  ] = useState<Usage.Range>(Usage.MONTHLY);
  const [
    selectedPeriodBroadband,
    setSelectedPeriodBroadband,
  ] = useState<Usage.Range>(Usage.MONTHLY);
  const [selectedPeriod, setSelectedPeriod] = {
    [Usage.ELECTRICITY]: [
      selectedPeriodElectricity,
      setSelectedPeriodElectricity,
    ],
    [Usage.GAS]: [Usage.MONTHLY, () => undefined],
    [Usage.BROADBAND]: [selectedPeriodBroadband, setSelectedPeriodBroadband],
  }[selectedUtility] as [
    Usage.Range,
    React.Dispatch<React.SetStateAction<Usage.Range>>
  ];
  const [slideIndexElectricityMonth, setSlideIndexElectricityMonth] = useState(
    -1
  );
  const [slideIndexElectricityDay, setSlideIndexElectricityDay] = useState(-1);
  const [slideIndexElectricityHour, setSlideIndexElectricityHour] = useState(
    -1
  );
  const [slideIndexElectricity, setSlideIndexElectricity] = {
    [Usage.MONTHLY]: [
      slideIndexElectricityMonth,
      setSlideIndexElectricityMonth,
    ],
    [Usage.DAILY]: [slideIndexElectricityDay, setSlideIndexElectricityDay],
    [Usage.HOURLY]: [slideIndexElectricityHour, setSlideIndexElectricityHour],
  }[selectedPeriod] as [number, React.Dispatch<React.SetStateAction<number>>];
  const [slideIndexGas, setSlideIndexGas] = useState(-1);
  const [slideIndexBroadbandMonth, setSlideIndexBroadbandMonth] = useState(-1);
  const [slideIndexBroadbandDay, setSlideIndexBroadbandDay] = useState(-1);
  const [slideIndexBroadbandHour, setSlideIndexBroadbandHour] = useState(-1);
  const [slideIndexBroadband, setSlideIndexBroadband] = {
    [Usage.MONTHLY]: [slideIndexBroadbandMonth, setSlideIndexBroadbandMonth],
    [Usage.DAILY]: [slideIndexBroadbandDay, setSlideIndexBroadbandDay],
    [Usage.HOURLY]: [slideIndexBroadbandHour, setSlideIndexBroadbandHour],
  }[selectedPeriod] as [number, React.Dispatch<React.SetStateAction<number>>];
  const [slideIndex, setSlideIndex] = {
    [Usage.ELECTRICITY]: [slideIndexElectricity, setSlideIndexElectricity],
    [Usage.GAS]: [slideIndexGas, setSlideIndexGas],
    [Usage.BROADBAND]: [slideIndexBroadband, setSlideIndexBroadband],
  }[selectedUtility] as [number, React.Dispatch<React.SetStateAction<number>>];
  const [slideIndexMonth, setSlideIndexMonth] = {
    [Usage.ELECTRICITY]: [
      slideIndexElectricityMonth,
      setSlideIndexElectricityMonth,
    ],
    [Usage.GAS]: [slideIndexGas, setSlideIndexGas],
    [Usage.BROADBAND]: [slideIndexBroadbandMonth, setSlideIndexBroadbandMonth],
  }[selectedUtility] as [number, React.Dispatch<React.SetStateAction<number>>];
  const [slideIndexDay, setSlideIndexDay] = {
    [Usage.ELECTRICITY]: [
      slideIndexElectricityDay,
      setSlideIndexElectricityDay,
    ],
    [Usage.GAS]: [-1, () => undefined],
    [Usage.BROADBAND]: [slideIndexBroadbandDay, setSlideIndexBroadbandDay],
  }[selectedUtility] as [number, React.Dispatch<React.SetStateAction<number>>];
  const [slideIndexHour, setSlideIndexHour] = {
    [Usage.ELECTRICITY]: [
      slideIndexElectricityHour,
      setSlideIndexElectricityHour,
    ],
    [Usage.GAS]: [-1, () => undefined],
    [Usage.BROADBAND]: [slideIndexBroadbandHour, setSlideIndexBroadbandHour],
  }[selectedUtility] as [number, React.Dispatch<React.SetStateAction<number>>];
  const ignoreNextSlideIndexDayResetRef = useRef(false);
  const ignoreNextSlideIndexHourResetRef = useRef(false);

  const [unitModePreferred, setUnitModePreferred] = useState<Usage.UnitMode>(
    'cost'
  );
  const costModeEnabled = useCostModeEnabled();

  const availableUnitModes = useMemo(() => {
    let availableUnitModes = supportedUnitModes[selectedUtility];
    if (!costModeEnabled) {
      availableUnitModes = without(availableUnitModes, 'cost');
    }
    return availableUnitModes;
  }, [costModeEnabled, selectedUtility]);

  const isAccountsReady =
    !isPremiseSwitching &&
    !isAccountLoading &&
    !isCustomerLoading &&
    !!customer;

  const todayDate = DateUtil.getTodaysDate();

  const [dailyElectricityDateRange, setDailyElectricityDateRange] = useState<
    string[]
  >([]);
  const [hourlyElectricityDateRange, setHourlyElectricityDateRange] = useState<
    string[]
  >([]);
  const [dailyBroadbandDateRange, setDailyBroadbandDateRange] = useState<
    string[]
  >([]);
  const [hourlyBroadbandDateRange, setHourlyBroadbandDateRange] = useState<
    string[]
  >([]);
  const monthlyDateRange = useMemo(
    () => [
      DateUtil.substractDateByMonths(todayDate, 15),
      DateUtil.getMonthEndDate(todayDate),
    ],
    [todayDate]
  );
  const [dailyDateRange, setDailyDateRange] = {
    [Usage.ELECTRICITY]: [
      dailyElectricityDateRange,
      setDailyElectricityDateRange,
    ],
    [Usage.GAS]: [[], () => undefined],
    [Usage.BROADBAND]: [dailyBroadbandDateRange, setDailyBroadbandDateRange],
  }[selectedUtility] as [
    string[],
    React.Dispatch<React.SetStateAction<string[]>>
  ];
  const [hourlyDateRange, setHourlyDateRange] = {
    [Usage.ELECTRICITY]: [
      hourlyElectricityDateRange,
      setHourlyElectricityDateRange,
    ],
    [Usage.GAS]: [[], () => undefined],
    [Usage.BROADBAND]: [hourlyBroadbandDateRange, setHourlyBroadbandDateRange],
  }[selectedUtility] as [
    string[],
    React.Dispatch<React.SetStateAction<string[]>>
  ];

  const currentPeriodDateRange = useMemo(
    () => [
      {
        [Usage.MONTHLY]: monthlyDateRange[0], // Broadband has 15 month limit
        [Usage.DAILY]: dailyDateRange[0],
        [Usage.HOURLY]: hourlyDateRange[0],
      }[selectedPeriod],
      {
        [Usage.MONTHLY]: monthlyDateRange[1],
        [Usage.DAILY]: dailyDateRange[1],
        [Usage.HOURLY]: hourlyDateRange[1],
      }[selectedPeriod],
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      todayDate,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dailyDateRange[0],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dailyDateRange[1],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hourlyDateRange[0],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hourlyDateRange[1],
      selectedPeriod,
    ]
  );

  const useUnifiedUsageConfigured = (
    {
      period,
      dateRange,
    }: {
      period: Usage.Range;
      dateRange: string[];
    },
    options?: UnifiedUsageQueryOptions
  ) => {
    return useUnifiedUsage(
      {
        version: 'v2',
        token,
        xcsrfToken,
        baId: baId || '',
        contractId: contractId || '',
        period,
        from: dateRange[0],
        to: dateRange[1],
        ba: baId,
        selectedUtility,
      },
      options
    );
  };

  const {
    data: usageData = emptyData,
    rawBroadbandData,
    isFetching: isFetchingUsage,
    error: usageError,
  } = useUnifiedUsageConfigured({
    period: selectedPeriod,
    dateRange: currentPeriodDateRange,
  });

  // We need monthly data for reference regardless of the selected period
  const { data: monthlyUsageData = emptyData } = useUnifiedUsageConfigured({
    period: Usage.MONTHLY,
    dateRange: monthlyDateRange,
  });

  const usageCap = useMemo(
    () =>
      ({
        [Usage.BROADBAND]: Math.round(
          parseFloat(rawBroadbandData?.services[0].dataCap || '0')
        ),
      }[selectedUtility] ?? 0),
    [rawBroadbandData, selectedUtility]
  );

  let data: Usage.DataItem[] = usageData;

  const isOffpeakFree =
    !availableUnitModes.includes('cost') ||
    !electricityPlanType ||
    Util.isOffpeakElectricityFree({
      planType: electricityPlanType,
      planDetails: electricityPlanDetails,
    });

  const dollarUsageStartDate = DateUtil.nzDate(dollarUsageStartDateProp);

  // Validate data for each unit mode
  const unitModeErrors = useMemo<{ [K in Usage.UnitMode]: boolean }>(
    () => ({
      cost: false, // Do not error out on invalid dollar data, denote it as unavailable instead
      units: !Util.isValidUnitsData(data),
    }),
    [data]
  );

  const unavailableUnitModes = useMemo<{ [K in Usage.UnitMode]: boolean }>(
    () => ({
      cost:
        availableUnitModes.includes('cost') &&
        Util.isCostDataUnavailable(data, {
          slideIndex,
          startDate: dollarUsageStartDate,
        }),
      units: false,
    }),
    [availableUnitModes, data, dollarUsageStartDate, slideIndex]
  );

  const latestMonthUnavailableUnitModes = useMemo<
    { [K in Usage.UnitMode]: boolean }
  >(
    () => ({
      cost:
        availableUnitModes.includes('cost') &&
        Util.isCostDataUnavailable(monthlyUsageData, {
          slideIndex: monthlyUsageData.length - 1,
          startDate: dollarUsageStartDate,
        }),
      units: false,
    }),
    [availableUnitModes, dollarUsageStartDate, monthlyUsageData]
  );

  // Remove null values only after scanning for errors
  data = useMemo(() => Util.safeUsageValues(data), [data]);

  const unitMode =
    availableUnitModes.includes(unitModePreferred) &&
    !unitModeErrors[unitModePreferred]
      ? unitModePreferred
      : availableUnitModes[0] || 'units';

  const unitModeData = useMemo(() => [...data], [data]);

  data = useMemo(() => {
    if (unitMode === 'cost') {
      // Display dollar values instead of unit values
      return unitModeData.map((item) => ({
        ...item,
        value: Util.safeFloatString(item.dollarValue),
        offpeakValue: Util.safeFloatString(item.offpeakDollarValue),
        hasFreeOffpeakUsage:
          isOffpeakFree && Util.safeFloat(item.offpeakValue) !== 0,
      }));
    }
    return unitModeData;
  }, [isOffpeakFree, unitMode, unitModeData]);

  const isFetching = !!{
    [Usage.ELECTRICITY]:
      isFetchingUsage || isRewardsFetching || isElectricityPlanFetching,
    [Usage.GAS]: isFetchingUsage || isRewardsFetching,
    [Usage.BROADBAND]: isFetchingUsage,
  }[selectedUtility];
  const hasError =
    !isFetching &&
    !!(
      unitModeErrors[unitMode] ||
      {
        [Usage.ELECTRICITY]: !!usageError || isElectricityPlanError,
        [Usage.GAS]: !!usageError,
        [Usage.BROADBAND]: !!usageError,
      }[selectedUtility]
    );
  const isDataEmpty =
    !isFetching && !hasError && Util.isUsageDataEmpty(unitModeData);

  const isMonthlyDataEmpty = Util.isUsageDataEmpty(monthlyUsageData);

  const getDefaultSelectedDate = () => {
    const localDate = DateUtil.nzDate();
    let lastDate = localDate;
    if (monthlyUsageData?.length && slideIndexMonth >= 0) {
      lastDate = DateUtil.nzDate(
        Util.getMonthItemDailyDateRange(monthlyUsageData[slideIndexMonth])[1]
      );
    }
    return localDate.isBefore(lastDate) ? localDate : lastDate;
  };

  const [fallbackSelectedDate, setFallbackSelectedDate] = useState(
    getDefaultSelectedDate
  );

  const resetFallbackSelectedDate = () =>
    setFallbackSelectedDate(getDefaultSelectedDate());

  const selectedDate = data[slideIndex]?.date
    ? DateUtil.nzDate(data[slideIndex].date)
    : fallbackSelectedDate;

  const setSelectedDate = (date: Date) => {
    const localDate = DateUtil.nzDate(date);

    // Look for date
    for (let i = 0; i < monthlyUsageData.length; i++) {
      const monthItem = monthlyUsageData[i];
      const range = Util.getMonthItemDailyDateRange(monthItem).map((date) =>
        DateUtil.nzDate(date)
      );
      if (
        localDate.isSameOrAfter(range[0], 'day') &&
        localDate.isSameOrBefore(range[1], 'day')
      ) {
        if (slideIndexMonth !== i) {
          // Changing the month will reset the selected day.
          // To prevent that, enable this flag:
          ignoreNextSlideIndexDayResetRef.current = true;
          ignoreNextSlideIndexHourResetRef.current = true;
          setSlideIndexMonth(i);
          setDailyDateRange(Util.getMonthItemDailyDateRange(monthItem));
        }
        // count days for daily slide index
        const dayIndex = localDate.date() - range[0].date();
        if (slideIndexMonth !== i || dayIndex !== slideIndexDay) {
          setSlideIndexDay(dayIndex);
          setSlideIndexHour(-1);
          ignoreNextSlideIndexHourResetRef.current = true;
          setHourlyDateRange([
            DateUtil.convertDateToFormat(localDate.clone().startOf('day')),
            DateUtil.convertDateToFormat(localDate.clone().endOf('day')),
          ]);
        }
        break;
      }
    }
    setFallbackSelectedDate(localDate);
  };

  const getSelectableDateRange = () => {
    const today = DateUtil.nzDate();
    let minDate: moment.MomentInput = monthlyDateRange[0];
    let maxDate: moment.MomentInput = today.clone();
    if (!isMonthlyDataEmpty) {
      minDate = Util.getMonthItemDailyDateRange(monthlyUsageData[0])[0];
      maxDate = Util.getMonthItemDailyDateRange(
        monthlyUsageData[monthlyUsageData.length - 1]
      )[1];
    }
    if (today.isBefore(maxDate)) {
      maxDate = today.clone();
    }
    return {
      minDate: DateUtil.nzDate(minDate).startOf('day').toDate(),
      maxDate: DateUtil.nzDate(maxDate).endOf('day').toDate(),
    };
  };

  useEffect(() => {
    if (
      isAccountsReady &&
      selectedUtility === 'electricity' &&
      unitModePreferred !== 'units' &&
      unitModeErrors[unitModePreferred]
    ) {
      // This unit mode has an error, revert to default
      setUnitModePreferred('units');

      if (!isDataVisible) {
        // No data displayed to user yet, just switch unit modes
        return;
      }

      // Present an error popup
      onUsageErrorPopup?.();
    }
  }, [
    selectedUtility,
    unitModePreferred,
    unitModeErrors,
    isDataVisible,
    isAccountsReady,
    onUsageErrorPopup,
  ]);

  const switchedFromUnavailableUnitModeOnceRef = useRef(false);
  useEffect(() => {
    if (switchedFromUnavailableUnitModeOnceRef.current) {
      return;
    }
    if (
      isAccountsReady &&
      selectedUtility === 'electricity' &&
      unitModePreferred !== 'units' &&
      latestMonthUnavailableUnitModes[unitModePreferred]
    ) {
      // This unit mode us unavailable in the latest month, revert to default
      setUnitModePreferred('units');
      switchedFromUnavailableUnitModeOnceRef.current = true;
    }
  }, [
    selectedUtility,
    unitModePreferred,
    unitModeErrors,
    isDataVisible,
    isAccountsReady,
    onUsageErrorPopup,
    latestMonthUnavailableUnitModes,
  ]);

  const createButtonProps = () => {
    if (selectedUtility === 'broadband') {
      return [
        {
          label: Usage.MONTHLY,
          handleClick: () => changePeriod(Usage.MONTHLY),
          selected: selectedPeriod === Usage.MONTHLY,
        },
        {
          label: Usage.DAILY,
          handleClick: () => changePeriod(Usage.DAILY),
          selected: selectedPeriod === Usage.DAILY,
        },
        {
          label: Usage.HOURLY,
          handleClick: () => changePeriod(Usage.HOURLY),
          selected: selectedPeriod === Usage.HOURLY,
        },
      ];
    } else {
      const selectedMonthItem = monthlyUsageData[slideIndexMonth];
      const monthEmpty =
        selectedPeriod === Usage.MONTHLY &&
        Util.isDataItemEmpty(selectedMonthItem);

      const buttons: Usage.RangeButtonModel[] = [
        {
          label: Usage.MONTHLY,
          handleClick: () => changePeriod(Usage.MONTHLY),
          selected: selectedPeriod === Usage.MONTHLY,
        },
        {
          label: Usage.DAILY,
          handleClick: () => changePeriod(Usage.DAILY),
          selected: selectedPeriod === Usage.DAILY,
          disabled: monthEmpty,
        },
      ];

      if (!hideHourlyButton) {
        buttons.push({
          label: Usage.HOURLY,
          handleClick: () => changePeriod(Usage.HOURLY),
          selected: selectedPeriod === Usage.HOURLY,
          disabled: monthEmpty,
        });
      }
      return buttons;
    }
  };

  const buttons = createButtonProps();

  const onPressDailyUsage = (
    monthItem: Usage.DataItem & Partial<Usage.BroadbandDataItem>
  ) => {
    // request the daily usage for the selected month or billing period
    setDailyDateRange(Util.getMonthItemDailyDateRange(monthItem));
    setFallbackSelectedDate(Util.getMonthDataItemEndDate(monthItem));
  };

  const onPressHourlyUsage = ({
    monthItem,
    dayDate,
  }: {
    monthItem?: Usage.DataItem & Partial<Usage.BroadbandDataItem>;
    dayDate?: moment.MomentInput;
  }) => {
    const day = dayDate
      ? dayDate
      : monthItem && Util.getMonthDataItemEndDate(monthItem);
    if (!day) {
      return;
    }
    const today = DateUtil.nzDate().startOf('day');
    let dateEnd = DateUtil.nzDate(day).startOf('day');
    if (!dateEnd.isValid()) {
      return;
    }
    if (dateEnd.isAfter(today)) {
      dateEnd = today;
    }
    let dateStart = dateEnd.clone();
    if (monthItem && selectedUtility === Usage.ELECTRICITY) {
      // We display the last available date and due to consumption data delays,
      // we're not sure what the latest day is, so use a buffer of 7 days.
      let bufferStart = Util.getMonthDataItemEndDate(monthItem);
      if (bufferStart.isAfter(today)) {
        bufferStart = today;
      }
      bufferStart = bufferStart.subtract(7, 'days');
      dateStart = bufferStart.isBefore(dateStart) ? bufferStart : dateStart;
    }
    setHourlyDateRange([
      DateUtil.convertDateToFormat(dateStart),
      DateUtil.convertDateToFormat(dateEnd.clone().endOf('day')),
    ]);
    setFallbackSelectedDate(dateEnd);
  };

  const changePeriod = (
    period: Usage.Range,
    { slideIndexOverride = slideIndex }: { slideIndexOverride?: number } = {}
  ) => {
    switch (selectedUtility) {
      case 'electricity':
      case 'broadband': {
        switch (selectedPeriod) {
          case Usage.MONTHLY: {
            const selectedItem = data[slideIndexOverride];
            if (!selectedItem) {
              return;
            }

            if (period === Usage.DAILY) {
              onPressDailyUsage(selectedItem);
              setSelectedPeriod(Usage.DAILY);
            } else if (period === Usage.HOURLY) {
              onPressDailyUsage(selectedItem);
              onPressHourlyUsage({
                monthItem: selectedItem,
                dayDate: Util.getMonthDataItemNthDate(
                  selectedItem,
                  slideIndexDay
                ),
              });
              setSelectedPeriod(Usage.HOURLY);
            }

            break;
          }
          case Usage.DAILY: {
            if (period === Usage.MONTHLY) {
              setSelectedPeriod(Usage.MONTHLY);
            } else if (period === Usage.HOURLY) {
              const selectedItem = data[slideIndexOverride];
              if (!selectedItem) {
                return;
              }
              onPressHourlyUsage({ dayDate: selectedItem.date });
              setSelectedPeriod(Usage.HOURLY);
            }

            break;
          }
          case Usage.HOURLY:
            if (period === Usage.MONTHLY) {
              setSelectedPeriod(Usage.MONTHLY);
            } else if (period === Usage.DAILY) {
              setSelectedPeriod(Usage.DAILY);
            }

            break;

          default:
            return;
        }
        break;
      }
      default:
        return;
    }

    onPeriodChange?.(period);
  };

  useEffect(() => {
    const dataLength = data?.length || 0;
    if (dataLength === 0) {
      return;
    }
    let index = dataLength - 1;
    if (selectedPeriod === Usage.HOURLY) {
      index = Math.min(12, index);
    }
    if (slideIndex < 0 || slideIndex >= dataLength) {
      setSlideIndex(index);
    }
  }, [data, slideIndex, setSlideIndex, selectedPeriod]);

  useEffect(() => {
    // Reset selected day when month changes
    if (slideIndexElectricityMonth >= 0) {
      if (ignoreNextSlideIndexDayResetRef.current) {
        ignoreNextSlideIndexDayResetRef.current = false;
      } else {
        setSlideIndexElectricityDay(-1);
      }
    }
  }, [slideIndexElectricityMonth]);

  useEffect(() => {
    // Reset selected hour when date changes
    if (slideIndexElectricityMonth >= 0 || slideIndexElectricityDay >= 0) {
      if (ignoreNextSlideIndexHourResetRef.current) {
        ignoreNextSlideIndexHourResetRef.current = false;
      } else {
        setSlideIndexElectricityHour(-1);
      }
    }
  }, [slideIndexElectricityMonth, slideIndexElectricityDay]);

  useEffect(() => {
    // Reset selected day when month changes
    if (slideIndexBroadbandMonth >= 0) {
      if (ignoreNextSlideIndexDayResetRef.current) {
        ignoreNextSlideIndexDayResetRef.current = false;
      } else {
        setSlideIndexBroadbandDay(-1);
      }
    }
  }, [slideIndexBroadbandMonth]);

  useEffect(() => {
    // Reset selected hour when date changes
    if (slideIndexBroadbandMonth >= 0 || slideIndexBroadbandDay >= 0) {
      if (ignoreNextSlideIndexHourResetRef.current) {
        ignoreNextSlideIndexHourResetRef.current = false;
      } else {
        setSlideIndexBroadbandHour(-1);
      }
    }
  }, [slideIndexBroadbandMonth, slideIndexBroadbandDay]);

  useEffect(() => {
    // We dont want to see the trimester message when there is no usage data even if it is a trimester customer
    if (
      isTrimesterCustomer &&
      !isEmpty(monthlyUsageData) &&
      selectedUtility !== Usage.ELECTRICITY
    ) {
      setIsFourthTrimester(true);
    } else {
      setIsFourthTrimester(false);
    }
  }, [isTrimesterCustomer, monthlyUsageData, selectedUtility]);

  useEffect(() => {
    const isVisible = !isFetching && !hasError && isAccountsReady;
    let timer: any = 0;
    if (isVisible) {
      // Delay setting visiblity to true
      timer = setTimeout(() => setDataVisible(true), 1000);
    } else {
      setDataVisible(false);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hasError, isAccountsReady, isFetching]);

  return {
    customer,
    isFourthTrimester,
    isAccountsReady,
    isFetching,
    hasError,
    isDataEmpty,
    isMonthlyDataEmpty,
    selectedContracts,
    buttons,
    selectedPeriod,
    unitMode,
    availableUnitModes,
    unavailableUnitModes,
    unitModeErrors,
    setUnitModePreferred,
    usageCap,
    data,
    slideIndex,
    setSlideIndex,
    selectedDate: selectedDate.toDate(),
    setSelectedDate,
    ...getSelectableDateRange(),
    electricityPlanType,
  };
}
