import React from 'react';
import { Modal } from '@contact/ui-components';

export type ModalAlertProps = {
  title: string;
  text: string;
  isActive: boolean;
  onHandleClose: () => void;
};

export const ModalAlert = (props: ModalAlertProps) => {
  const { title, text, isActive, onHandleClose } = props;
  return (
    <Modal
      isActive={isActive}
      title={title}
      content={text}
      buttonText="Close"
      handleClose={onHandleClose}
      handleButtonClick={onHandleClose}
    />
  );
};
