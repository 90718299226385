import React, { Fragment } from 'react';
import classNames from 'classnames';

import './LayoutRow.scss';

export const LayoutRow = ({
  left,
  right = undefined,
  reverseRow = false,
  hideColumn = false,
}) => {
  const layoutRow = classNames({
    layoutRow: true,
    'contactUIComponentsLayoutRow--standard': !reverseRow,
    'contactUIComponentsLayoutRow--reverseRow': reverseRow,
    'contactUIComponentsLayoutRow--hideColumn': hideColumn,
  });

  return (
    <div className={layoutRow}>
      <div className="contactUIComponentsLayoutRow_rowPrimary">{left}</div>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <Fragment>{right}</Fragment>
    </div>
  );
};
