import React, { PropsWithChildren } from 'react';
import { Message, MessageProps } from './Message';
import { useTranslationWithNamespace } from '@contact/react';

export interface TranslatedMessageProps
  extends Omit<MessageProps, 'title' | 'message'> {
  namespace: string;
}

export function TranslatedMessage({
  namespace,
  ...rest
}: PropsWithChildren<TranslatedMessageProps>) {
  const [t] = useTranslationWithNamespace(namespace);
  return <Message title={t('title')} message={t('message')} {...rest} />;
}
