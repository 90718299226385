import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  UploadDocuments,
  ModalAlert,
  CsrSendEmail,
  ContactPhoneLink,
  BabyDueModal,
  AccountNumberFindModal,
} from '../TrimesterComponents';
import './TrimesterForm.scss';
import {
  useTrimesterCreate,
  useTrimesterSubmit,
  SubmitTrimesterParams,
  useTrimesterSendEmail,
} from '@contact/data-access-hooks';
import {
  Button,
  DateUtil,
  DATABASE_FORMAT,
  Loading,
} from '@contact/ui-components';
import { Namespace } from '../TrimesterComponents/Translations';
import { useTranslationWithNamespace } from '@contact/react';
import {
  FormInput,
  FormInputDate,
  FormRadioGroup,
  FormAutoCompleteEmail,
  FormCheckbox,
  FormText,
  FormAlert,
} from '../FormComponents';
import { H3, H5, Text, Box, Flex } from '@contact/ui-volta';
import {
  isValidBabyDueDate,
  initiateTrackings,
  stepCompleted,
  signUpCompleted,
  alertIneligible,
  apiError,
  closeApiError,
  injectGtm,
  getBool,
} from '../Utils';
import { TrimesterContainer } from '../TrimesterContainer';
import {
  baseAxiosConfig,
  userAuthKey,
  requestIdKey,
  passedSubmissionKey,
} from '../Config';

interface PrimaryCaregiverDetails {
  firstName: string;
  lastName: string;
}

interface TrimesterForm {
  babyDueDate: string;
  primaryCaregiverDetails: PrimaryCaregiverDetails;
  uploadedFiles: Array<string>;
  ba: string;
  firstName: string;
  lastName: string;
  email: string;
  paymentMethod: string;
  isTermsAndConditionsAccepted: boolean;
}

export const TrimesterForm = () => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', { returnObjects: true });
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [token, setToken] = useState(undefined);
  const [readyToCallCreate, setReadyToCallCreate] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const userAuthToken = urlParams.get(userAuthKey);

    if (userAuthToken) {
      sessionStorage.setItem(userAuthKey, userAuthToken);

      // remove the userAuth from url after saving to sessionStorage
      urlParams.delete(userAuthKey);
      history.replace({
        search: urlParams.toString(),
      });

      setToken(userAuthToken);
    } else {
      injectGtm(process.env.NX_GTM_ID);
      initiateTrackings();
    }

    setReadyToCallCreate(true);
    // only want to run it once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isEmailTriggered, setIsEmailTriggered] = useState(false);
  const babyDueDateQuestion = translations.babyDueDateQuestion;

  const formHeader = translations.header;
  const primaryResidenceQuestion = translations.primaryResidenceQuestion;
  const termsAndConditionsQuestion = translations.termsAndConditions;
  const privacyStatement = translations.privacyStatement;
  const customErrors = translations.customErrors;
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);
  const [
    shouldShowEmailLinkNotSentMessage,
    setShouldShowEmailLinkNotSentMessage,
  ] = useState(false);

  const accountNumber = translations.accountNumber;
  const firstName = translations.firstName;
  const lastName = translations.lastName;
  const emailAddress = translations.emailAddress;
  const phoneNumber = translations.phoneNumber;

  const notAvailableOfferRedirectUrl =
    translations.notAvailableOfferRedirectUrl;

  const continueButtonText = translations.continueButtonText;
  const currentBillingPaymentMethod = translations.currentBillingPaymentMethod;
  const [shouldCreateCallEnabled, setShouldCreateCallEnabled] = useState(false);
  const requestId = sessionStorage.getItem(requestIdKey) || null;

  // only enable when there is no requestId in sessionStorage
  useEffect(() => {
    setShouldCreateCallEnabled(!requestId && readyToCallCreate);
  }, [requestId, readyToCallCreate]);
  const { data: createData, error: createDataError } = useTrimesterCreate(
    'v2',
    token,
    shouldCreateCallEnabled,
    baseAxiosConfig
  );

  const [readyToCall, setReadyToCall] = useState(false);
  const [submitParams, setSubmitParams] = useState<SubmitTrimesterParams>();
  const { data: submitData, error: updateDataError } = useTrimesterSubmit(
    'v2',
    requestId,
    submitParams,
    readyToCall,
    baseAxiosConfig
  );

  useEffect(() => {
    sessionStorage.setItem(passedSubmissionKey, 'false');
  }, []);

  useEffect(() => {
    if (updateDataError || createDataError) {
      setReadyToCall(false);
      setIsErrorModalActive(true);
      apiError();
    }
  }, [updateDataError, createDataError]);

  useEffect(() => {
    if (createData) {
      if (createData.message === 'Success') {
        sessionStorage.setItem(requestIdKey, createData?.requestId);
      } else if (createData.message === 'Offer not available.') {
        sessionStorage.clear();
        window.location.replace(notAvailableOfferRedirectUrl);
      }
    }
  }, [createData, notAvailableOfferRedirectUrl]);

  const {
    handleSubmit,
    watch,
    control,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'all' });

  const watchPaymentMethod = watch('paymentMethod', undefined);
  const isMonthlyBill =
    watchPaymentMethod !== undefined && watchPaymentMethod === 'Monthly';

  const watchBabyDueDate = watch('dob', undefined);
  const watchFirstName = watch('firstName', '');
  const watchLastName = watch('lastName', '');
  const watchEmail = watch('emailAddress', '');
  const watchAccount = watch('accountNumber', '');
  const watchPhone = watch('phoneNumber', '');

  useEffect(() => {
    if (submitData && submitData.message === 'Success') {
      setIsFormCompleted(true);
      signUpCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitData]);

  const watchPrimaryResidence = watch('primaryResidence', undefined);

  const {
    isSuccess: isEmailSentSuccess,
    isError: isEmailSentError,
    isFetching: isEmailSentFetching,
  } = useTrimesterSendEmail(
    'v2',
    requestId,
    token,
    {
      email: watchEmail,
      firstName: watchFirstName,
    },
    isEmailTriggered,
    baseAxiosConfig
  );

  useEffect(() => {
    if (isEmailSentError) {
      setIsErrorModalActive(true);
      apiError();
    }

    if (!isEmailSentFetching && (isEmailSentError || isEmailSentSuccess)) {
      setIsEmailTriggered(false);
    }
  }, [isEmailSentFetching, isEmailSentError, isEmailSentSuccess]);

  useEffect(() => {
    if (token && readyToCall && !isEmailTriggered) {
      setShouldShowEmailLinkNotSentMessage(!isEmailSentSuccess);
    } else {
      setShouldShowEmailLinkNotSentMessage(false);
    }
  }, [token, readyToCall, isEmailTriggered, isEmailSentSuccess]);

  useEffect(() => {
    const contentText = '1.When is your baby coming';
    if (watchBabyDueDate) {
      if (isValidBabyDueDate(watchBabyDueDate)) {
        stepCompleted(contentText, watchBabyDueDate, 'date');
      } else {
        alertIneligible(contentText);
      }
    }
  }, [watchBabyDueDate]);

  useEffect(() => {
    const contentText = '2.Are you on a monthly plan';
    if (isMonthlyBill) {
      stepCompleted(contentText, watchPaymentMethod);
    } else if (
      watchPaymentMethod !== undefined &&
      watchPaymentMethod !== 'Monthly'
    ) {
      alertIneligible(contentText);
    }
  }, [isMonthlyBill, watchPaymentMethod]);

  useEffect(() => {
    const contentText = '3. Is the address the baby’s primary residence';
    if (
      watchPrimaryResidence !== undefined &&
      watchPrimaryResidence === 'true'
    ) {
      stepCompleted(contentText, 'Yes');
    } else if (watchPrimaryResidence === 'false') {
      alertIneligible(contentText);
    }
  }, [watchPrimaryResidence]);

  const [isBabyDueModalVisible, setIsBabyDueModalVisible] = useState(false);
  const [isAccountFindModalVisible, setIsAccountFindModalVisible] = useState(
    false
  );
  const onBabyDueTooltipClick = () => {
    setIsBabyDueModalVisible(true);
  };
  const onAccountFindModalTooltipClick = () => {
    setIsAccountFindModalVisible(true);
  };

  const [files, setFiles] = useState([]);

  if (isFormCompleted) {
    sessionStorage.setItem(passedSubmissionKey, 'true');
    return <Redirect to="/success" />;
  }

  const onSubmit = (values) => {
    // CSR: check if the email link button is triggered
    if (token && !isEmailSentSuccess) {
      setShouldShowEmailLinkNotSentMessage(true);
      return;
    }

    const uploadedFiles = files.map((file) => ({
      name: file.filename,
      data: file.getFileEncodeBase64String(),
    }));

    const dob = DateUtil.convertDateInstanceToFormat(
      values.dob,
      DATABASE_FORMAT
    );

    const formValues: SubmitTrimesterParams = {
      birthDueDate: dob,
      ba: values.accountNumber,
      accountHolder: {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
        isPrimaryCaregiver: getBool(values.primaryResidence),
      },
      files: uploadedFiles,
      action: 'submit',
      consent: values.termsAndConditions,
    };

    setSubmitParams(formValues);
    setReadyToCall(true);
  };

  const onHandleClose = () => {
    setIsErrorModalActive(false);
    closeApiError(window.document.location.pathname, window.document.title);
  };

  const isReadyToSendEmail =
    watchFirstName !== '' &&
    watchFirstName !== undefined &&
    watchEmail !== '' &&
    watchEmail !== undefined &&
    !errors['emailAddress'];

  const paymentMethodDescriptionComponent = () => {
    return (
      <>
        <Text>
          {currentBillingPaymentMethod.descriptionComponent.paragraph1}
        </Text>
        <Text paddingTop={3} paddingBottom="46px">
          {currentBillingPaymentMethod.descriptionComponent.paragraph2}
          <ContactPhoneLink />
          {'.'}
        </Text>
      </>
    );
  };

  const babyDueDescriptionComponent = () => {
    return (
      <Text marginBottom="32px">
        {babyDueDateQuestion.description}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="link-underlined"
          onClick={() => setIsBabyDueModalVisible(true)}
        >
          {babyDueDateQuestion.link}
        </a>
      </Text>
    );
  };

  const termsAndConditionsTextComponent = () => {
    return (
      <span className="tcText">
        {termsAndConditionsQuestion.textComponent.text}
        <a
          className="link-underlined"
          href={termsAndConditionsQuestion.textComponent.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {termsAndConditionsQuestion.textComponent.link}
        </a>
        {termsAndConditionsQuestion.textComponent.text2}
      </span>
    );
  };

  const onEmailButtonClick = async () => {
    await trigger('emailAddress');
    setIsEmailTriggered(true);
  };

  const shouldShowAccountDetails =
    watchPrimaryResidence === 'true' ||
    (watchPrimaryResidence === 'false' &&
      (watchAccount ||
        watchFirstName ||
        watchLastName ||
        watchEmail ||
        watchPhone));

  const shouldShowFileUpload =
    watchPrimaryResidence !== undefined &&
    watchAccount &&
    watchFirstName &&
    watchLastName &&
    watchEmail &&
    watchPhone;

  const trimesterForm = () => {
    return (
      <>
        <ModalAlert
          isActive={isErrorModalActive}
          title={translations.apiErrorHeader}
          text={translations.apiErrorText}
          onHandleClose={onHandleClose}
        />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box className="form-container">
            <Box className="form-header">
              <H3>{formHeader}</H3>
            </Box>
            <Box className="form-body">
              <FormInputDate
                name="dob"
                className="form-control"
                label={babyDueDateQuestion.label}
                title={babyDueDateQuestion.title}
                descriptionComponent={babyDueDescriptionComponent}
                isRequired
                control={control}
                errors={errors}
                errorMessage={customErrors.dob}
                onTooltipClick={onBabyDueTooltipClick}
                invalidDate={
                  watchBabyDueDate &&
                  watchBabyDueDate !== undefined &&
                  !isValidBabyDueDate(watchBabyDueDate)
                }
              />

              {isBabyDueModalVisible && (
                <BabyDueModal
                  isOpened={isBabyDueModalVisible}
                  setIsOpened={setIsBabyDueModalVisible}
                />
              )}
              {isAccountFindModalVisible && (
                <AccountNumberFindModal
                  isOpened={isAccountFindModalVisible}
                  setIsOpened={setIsAccountFindModalVisible}
                />
              )}

              {isDirty &&
                (isValidBabyDueDate(watchBabyDueDate) ||
                  watchPaymentMethod !== undefined) && (
                  <FormRadioGroup
                    name="paymentMethod"
                    className="form-control"
                    label={currentBillingPaymentMethod.label}
                    title={currentBillingPaymentMethod.title}
                    descriptionComponent={paymentMethodDescriptionComponent}
                    options={currentBillingPaymentMethod.options.map(
                      (option) => {
                        return { ...option, isInvalid: true };
                      }
                    )}
                    isRequired
                    control={control}
                    errors={errors}
                    errorMessage={customErrors.paymentMethod}
                    invalidValue={
                      watchPaymentMethod !== undefined &&
                      watchPaymentMethod !== 'Monthly'
                    }
                  />
                )}

              {((isDirty && watchPrimaryResidence !== undefined) ||
                (isMonthlyBill && isValidBabyDueDate(watchBabyDueDate))) && (
                <>
                  <FormRadioGroup
                    name="primaryResidence"
                    className="form-control"
                    label={primaryResidenceQuestion.label}
                    title={primaryResidenceQuestion.title}
                    description={primaryResidenceQuestion.description}
                    options={primaryResidenceQuestion.options}
                    isRequired
                    control={control}
                    errors={errors}
                    errorMessage={customErrors.careArrangement}
                    invalidValue={
                      watchPrimaryResidence !== undefined &&
                      watchPrimaryResidence === 'false'
                    }
                  />

                  {/* Your account details */}
                  {shouldShowAccountDetails && (
                    <Box className="account-details">
                      <H5 className="title">
                        {translations.accountDetailsTitle}
                      </H5>
                      <Text>{translations.accountDetailsDescription}</Text>
                      <Text paddingTop={3}>
                        {translations.accountDetailsDescription2}
                        <a
                          href={
                            translations.accountDetailsDescription2Link.target
                          }
                          className="link-underlined"
                        >
                          {translations.accountDetailsDescription2Link.label}
                        </a>
                        {translations.accountDetailsDescription3}
                      </Text>

                      <FormInput
                        name="accountNumber"
                        className="form-control"
                        label={accountNumber}
                        isRequired
                        maxLength={9}
                        errors={errors}
                        control={control}
                        errorMessage={customErrors.account}
                        translations={translations}
                        onTooltipClick={onAccountFindModalTooltipClick}
                      />

                      <Flex
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <FormInput
                          name="firstName"
                          className="form-control"
                          label={firstName}
                          isRequired
                          maxLength={30}
                          errors={errors}
                          control={control}
                          errorMessage={customErrors.firstName}
                        />

                        <FormInput
                          name="lastName"
                          className="form-control"
                          label={lastName}
                          isRequired
                          maxLength={30}
                          errors={errors}
                          control={control}
                          errorMessage={customErrors.lastName}
                        />
                      </Flex>

                      <Flex
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <Box className="form-field">
                          <FormAutoCompleteEmail
                            name="emailAddress"
                            className="form-control form-email"
                            label={emailAddress}
                            isRequired
                            maxLength={50}
                            control={control}
                            errors={errors}
                            errorMessage={customErrors.email}
                          />
                        </Box>

                        <Box className="form-field">
                          <FormInput
                            name="phoneNumber"
                            className="form-control"
                            label={phoneNumber}
                            isRequired
                            maxLength={14}
                            errors={errors}
                            control={control}
                            errorMessage={customErrors.phone}
                          />
                        </Box>
                      </Flex>

                      {shouldShowFileUpload && (
                        <>
                          {!token && (
                            <UploadDocuments
                              name="uploadDocuments"
                              control={control}
                              errors={errors}
                              errorMessage={customErrors.uploadDoc}
                              files={files}
                              setFiles={setFiles}
                              trigger={trigger}
                            />
                          )}

                          {token && (
                            <>
                              <CsrSendEmail
                                isReadyToSendEmail={isReadyToSendEmail}
                                onEmailButtonClick={onEmailButtonClick}
                                isEmailSentSuccess={isEmailSentSuccess}
                                isEmailSentFetching={isEmailSentFetching}
                              />
                              {shouldShowEmailLinkNotSentMessage && (
                                <FormAlert
                                  message={customErrors.emailLinkNotSent}
                                />
                              )}
                            </>
                          )}

                          <FormText
                            name="privacyStatement"
                            control={control}
                            className="form-control"
                            title={privacyStatement.title}
                            description={privacyStatement.description}
                          />
                          <FormCheckbox
                            control={control}
                            name="termsAndConditions"
                            isRequired
                            label={termsAndConditionsQuestion.label}
                            title={termsAndConditionsQuestion.title}
                            textComponent={termsAndConditionsTextComponent}
                            helperText={termsAndConditionsQuestion.helperText}
                            className={{
                              text: 'tcText',
                              container: 'tcContainer form-control',
                            }}
                            errors={errors}
                            errorMessage={customErrors.termsAndConditions}
                          />
                        </>
                      )}
                    </Box>
                  )}
                </>
              )}

              <Button
                type="submit"
                variant="primary"
                disabled={readyToCall}
                className={`submitButton ${
                  isValid ? (readyToCall ? 'disabled' : '') : 'disabled'
                }`}
              >
                {readyToCall && (
                  <Loading
                    customstyle={{
                      minWidth: '100%',
                      minHeight: '0',
                      top: '10px',
                    }}
                  />
                )}
                {continueButtonText}
              </Button>
            </Box>
          </Box>
        </form>
      </>
    );
  };

  return (
    <TrimesterContainer showSidebar namespace="TrimesterComponentsTranslations">
      {trimesterForm()}
    </TrimesterContainer>
  );
};
