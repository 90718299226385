import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, IconProps, Stack, Text } from '@contact/ui-volta';
import { ThumbsDownIcon, ThumbsUpIcon } from '../Icons';

export type FeedbackValue = 'yes' | 'no';

const Options: FeedbackOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    icon: ThumbsUpIcon,
  },
  {
    label: 'No',
    value: 'no',
    icon: ThumbsDownIcon,
  },
];

interface FeedbackOption {
  label: string;
  value: FeedbackValue;
  icon: FC<IconProps>;
}

interface FeedbackOptionProps extends FeedbackOption {
  visible: boolean;
  selected: boolean;
  onSelect(): void;
}

const FeedbackOption = ({
  label,
  icon: Icon,
  visible,
  selected,
  onSelect,
}: FeedbackOptionProps) => {
  const [hovered, setHovered] = useState(false);

  const iconColor = useMemo(
    () => (selected ? 'red.600' : hovered ? 'red.650' : 'red.700'),
    [hovered, selected]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="16px"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onClick={visible ? onSelect : undefined}
      opacity={visible ? 1 : 0}
    >
      <Text fontSize="16px" lineHeight="19px" fontWeight={500}>
        {label}
      </Text>
      <Icon width="24px" height="32px" color={iconColor} />
    </Stack>
  );
};

export interface FeedbackProps {
  label: string;
  value?: FeedbackValue;
  onChange(value: FeedbackValue): void;
}

export const Feedback = ({ label, value, onChange }: FeedbackProps) => {
  const handleSelect = useCallback(
    (option: FeedbackOption) => () => onChange(option.value),
    [onChange]
  );

  const isVisible = useCallback(
    (option: FeedbackOption) => !value || option.value === value,
    [value]
  );

  return (
    <Stack
      spacing={{
        base: '24px',
        md: '32px',
      }}
    >
      <Text fontSize="18px" lineHeight="22px" fontWeight={900}>
        {label}
      </Text>
      <Stack direction="row" paddingLeft="8px" spacing="40px">
        {Options.map((option) => (
          <FeedbackOption
            {...option}
            visible={isVisible(option)}
            selected={option.value === value}
            onSelect={handleSelect(option)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
