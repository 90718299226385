import React, { ReactElement } from 'react';
import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
} from '@chakra-ui/react';

export type FormControlProps = ChakraFormControlProps;

export const FormControl = (props: FormControlProps): ReactElement => (
  <ChakraFormControl {...props} />
);
