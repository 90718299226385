import React from 'react';

export interface HTMLAlertContentProps {
  html: string;
}

export function HTMLAlertContent({ html: __html }: HTMLAlertContentProps) {
  return (
    <div
      className="alertMessage_html_raw html_raw"
      dangerouslySetInnerHTML={{ __html }}
    />
  );
}
