import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ElectricityIconNew = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g id="Group_3648" data-name="Group 3648">
      <g id="Group_3647" data-name="Group 3647">
        <g id="Group_3646" data-name="Group 3646">
          <path
            id="Path_2193"
            data-name="Path 2193"
            d="M0,0H32V32H0Z"
            fill="none"
          />
          <path
            id="Path_2194"
            data-name="Path 2194"
            d="M14.5,18.374c-2.319.007-4.56.018-6.8.015-.519,0-1.124.154-1.438-.42-.3-.547.182-.9.458-1.271Q12.344,9.023,17.982,1.357c.069-.094.141-.187.208-.284a.8.8,0,0,1,.977-.417c.449.157.377.519.3.872q-1.058,5.258-2.1,10.52a2.222,2.222,0,0,0,0,.259h.654l6.669-.018c.451,0,.97,0,1.1.446a1.338,1.338,0,0,1-.188,1.034q-5.951,8.567-11.951,17.107c-.078.115-.144.262-.262.313-.239.1-.559.261-.753.186a.734.734,0,0,1-.353-.621c.1-.885.281-1.764.441-2.643.584-3.218,1.171-6.436,1.773-9.731"
            transform="translate(0 0)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </Icon>
);
