import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../components';

export const PipedGas = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g opacity="0.93">
      <g transform="translate(12 5)">
        <g>
          <path
            d="M7.925,40S-7.761,32.243,5,16.274C11.884,7.726,10.386,0,10.386,0s12.224,13.429,4.306,24.487a8.6,8.6,0,0,0,5.942-7.305S34.95,31.679,15.153,40c0,0,5-4.817,2.153-11.407,0,0-3.82,4.055-6.28,4.512,0,0-5.587-3.6-2.639-9.835C8.386,23.27-1.456,32.091,7.925,40Z"
            transform="translate(0)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
    <rect width="50" height="50" fill="none" />
  </Icon>
);
