import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const DisabledPipedGasIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g id="Group_3651" data-name="Group 3651">
      <g id="Group_3650" data-name="Group 3650">
        <g id="Group_3649" data-name="Group 3649">
          <path
            id="Path_2195"
            data-name="Path 2195"
            d="M0,0H32V32H0Z"
            fill="none"
          />
          <path
            id="Path_2196"
            data-name="Path 2196"
            d="M27.552,19a16.691,16.691,0,0,0-1.979-7.479c-.006-.009-.251-.374-.5.051a23.936,23.936,0,0,1-1.432,1.979.6.6,0,0,1-.634.182.566.566,0,0,1-.4-.506A13.848,13.848,0,0,0,15.585,1.9a.6.6,0,0,0-.59,0,.562.562,0,0,0-.29.494v.031c.017,2.248-.308,4.707-1.9,6.347-1.048,1.075-2.187.25-2.581-1.618-.213-1.012-.008-2.38-1.565,0-1.442,2.212-5,6.918-4.117,13.383.619,3,2.818,8.044,7.78,9.617A.31.31,0,0,0,12.689,30a.289.289,0,0,0-.1-.375,6.311,6.311,0,0,1-2.921-5.271,5.893,5.893,0,0,1,1.4-3.856c.689-.914,1.355-1.3,1.982-2.255a10.523,10.523,0,0,0,1.463-4.732v-.021a.339.339,0,0,1,.1-.242.363.363,0,0,1,.25-.1.372.372,0,0,1,.18.045c1.943,1.093,3.961,4.961,3.495,8.758-.046.376.216.536.572.377a1.594,1.594,0,0,0,.9-1.288c.706-4.625,2.1-.593,2.264,1.746a9,9,0,0,1-.558,4.051,6.482,6.482,0,0,1-1.753,2.522.288.288,0,0,0-.072.373.311.311,0,0,0,.369.135,11.516,11.516,0,0,0,4.114-2.4A10.372,10.372,0,0,0,27.552,19Z"
            fill="#858585"
          />
        </g>
      </g>
    </g>
  </Icon>
);
