import React, { ReactElement, forwardRef, ForwardedRef } from 'react';
import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react';

export type TextProps = ChakraTextProps;

export const Text = forwardRef(
  (props: TextProps, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
    return <ChakraText {...props} ref={ref}></ChakraText>;
  }
);

Text.displayName = 'Text';
