import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const AllPlansIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 65.5 65.5" {...props}>
    <g id="all-plans" transform="translate(0.75 0.75)">
      <g id="Group_1774" data-name="Group 1774">
        <g id="Group_3990" data-name="Group 3990">
          <circle
            id="Ellipse_45"
            data-name="Ellipse 45"
            cx="32"
            cy="32"
            r="32"
            fill="none"
            stroke="#e62a32"
            strokeWidth="1.5"
          />
          <g
            id="Group_4089"
            data-name="Group 4089"
            transform="translate(9.791 16.354)"
          >
            <path
              id="Path_1771"
              data-name="Path 1771"
              d="M66.755,49.588H50.309A3.168,3.168,0,0,1,47.1,46.379V21.509A3.168,3.168,0,0,1,50.309,18.3H66.755a3.168,3.168,0,0,1,3.209,3.209V46.245A2.986,2.986,0,0,1,66.755,49.588Z"
              transform="translate(-36.59 -18.3)"
              fill="none"
              stroke="#e62a32"
              strokeWidth="1.5"
            />
            <g
              id="Group_1771"
              data-name="Group 1771"
              transform="translate(17.054 6.44)"
            >
              <path
                id="Path_1772"
                data-name="Path 1772"
                d="M52.669,27.411A2.31,2.31,0,0,1,52,25.673a3.193,3.193,0,0,1,.267-1.2c.134-.4.535-.535.669-.8a1.608,1.608,0,0,1,1.07-.267,2.467,2.467,0,0,1,1.6.669,2.31,2.31,0,0,1,.669,1.738,2.816,2.816,0,0,1-.669,1.738,2,2,0,0,1-1.6.669A3.562,3.562,0,0,1,52.669,27.411Zm6.284-3.878h1.738l-5.482,8.023H53.471Zm-4.279,3.075a1.9,1.9,0,0,0,.267-.8,1.13,1.13,0,0,0-.267-.8c-.134-.267-.267-.267-.535-.267A.7.7,0,0,0,53.6,25a1.9,1.9,0,0,0-.267.8,1.13,1.13,0,0,0,.267.8.7.7,0,0,0,.535.267A.534.534,0,0,0,54.674,26.609Zm3.744,4.546a2.31,2.31,0,0,1-.669-1.738,2.542,2.542,0,0,1,.669-1.738,1.916,1.916,0,0,1,1.471-.669,2.467,2.467,0,0,1,1.6.669,2.31,2.31,0,0,1,.669,1.738,2.542,2.542,0,0,1-.669,1.738,1.953,1.953,0,0,1-1.6.535A1.826,1.826,0,0,1,58.418,31.155Zm1.872-.8a1.9,1.9,0,0,0,.267-.8,1.13,1.13,0,0,0-.267-.8c-.134-.267-.267-.267-.535-.267a.7.7,0,0,0-.535.267,1.9,1.9,0,0,0-.267.8,1.434,1.434,0,0,0,.267.8c.134.267.267.267.535.267C60.156,30.62,60.29,30.487,60.29,30.353Z"
                transform="translate(-52 -23.4)"
                fill="#e62a32"
              />
            </g>
            <line
              id="Line_147"
              data-name="Line 147"
              x2="11.633"
              transform="translate(16.103 20.229)"
              fill="none"
              stroke="#e62a32"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_148"
              data-name="Line 148"
              x2="11.633"
              transform="translate(16.103 24.666)"
              fill="none"
              stroke="#e62a32"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_1773"
              data-name="Path 1773"
              d="M64.234,21.2l8.156.936a2.6,2.6,0,0,1,2.273,2.942L72.39,44.332A2.6,2.6,0,0,1,69.448,46.6L64.1,45.936"
              transform="translate(-29.913 -17.21)"
              fill="none"
              stroke="#e62a32"
              strokeWidth="1.5"
            />
            <g
              id="Group_1772"
              data-name="Group 1772"
              transform="translate(34.222 8.657)"
            >
              <path
                id="Path_1774"
                data-name="Path 1774"
                d="M66.908,25l1.07.134L64.1,29.412V28.075Zm-1.07,4.947a1.441,1.441,0,0,1-.267-1.07.962.962,0,0,1,.535-.936.827.827,0,0,1,.936-.267,1.353,1.353,0,0,1,.936.535,1.441,1.441,0,0,1,.267,1.07.962.962,0,0,1-.535.936,1.04,1.04,0,0,1-1.07.267A2.034,2.034,0,0,1,65.838,29.947Zm1.337-.267c.134-.134.134-.267.267-.669v-.535c-.134-.134-.134-.267-.267-.267a.491.491,0,0,0-.4.134c-.134.134-.134.267-.267.535v.669c.134.134.134.267.4.267C67.042,29.814,67.175,29.814,67.175,29.68Z"
                transform="translate(-64.1 -25)"
                fill="#e62a32"
              />
            </g>
            <line
              id="Line_149"
              data-name="Line 149"
              x2="4.412"
              y2="0.535"
              transform="translate(34.669 19.677)"
              fill="none"
              stroke="#e62a32"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_150"
              data-name="Line 150"
              x2="4.011"
              y2="0.535"
              transform="translate(34.646 23.401)"
              fill="none"
              stroke="#e62a32"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_1775"
              data-name="Path 1775"
              d="M49.833,45.8l-5.215.669A2.6,2.6,0,0,1,41.676,44.2L39.4,25.078a2.6,2.6,0,0,1,2.273-2.942l7.889-.936"
              transform="translate(-39.391 -17.219)"
              fill="none"
              stroke="#e62a32"
              strokeWidth="1.5"
            />
            <g
              id="Group_1773"
              data-name="Group 1773"
              transform="translate(5.314 8.515)"
            >
              <path
                id="Path_1776"
                data-name="Path 1776"
                d="M44.535,27.708A1.7,1.7,0,0,1,44,26.772c0-.267,0-.669.134-.8.134-.267.267-.535.4-.535.267-.134.4-.267.669-.267a1.608,1.608,0,0,1,1.07.267,1.353,1.353,0,0,1,.535.936c0,.535,0,.8-.267,1.07a1.353,1.353,0,0,1-.936.535A.969.969,0,0,1,44.535,27.708Zm3.878-1.337-2.006,4.011-1.07.134L48.279,24.9Zm-2.674.8a.407.407,0,0,0,0-.535,1.021,1.021,0,0,0-.267-.669.491.491,0,0,0-.4-.134.288.288,0,0,0-.267.267.6.6,0,0,0,0,.669c.134.267.134.535.267.669a.491.491,0,0,0,.4.134C45.6,27.307,45.738,27.307,45.738,27.173Z"
                transform="translate(-44 -24.9)"
                fill="#e62a32"
              />
            </g>
            <line
              id="Line_151"
              data-name="Line 151"
              y1="0.535"
              x2="4.279"
              transform="translate(4.88 19.677)"
              fill="none"
              stroke="#e62a32"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_152"
              data-name="Line 152"
              y1="0.535"
              x2="4.145"
              transform="translate(5.165 23.401)"
              fill="none"
              stroke="#e62a32"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
