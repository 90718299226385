import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const PhoneIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 10 16" {...props}>
    <g>
      <path
        d="M8.5 0h-7A1.5 1.5 0 000 1.5v13A1.481 1.481 0 001.5 16h7a1.5 1.5 0 001.5-1.5v-13A1.521 1.521 0 008.5 0zm-2 14.125a.38.38 0 01-.375.375h-2.25a.361.361 0 01-.375-.375v-.25a.38.38 0 01.375-.375h2.25a.4.4 0 01.375.375zm2-2.5a.38.38 0 01-.375.375h-6.25a.361.361 0 01-.375-.375v-9.75a.38.38 0 01.375-.375h6.25a.4.4 0 01.375.375z"
        fill="#121820"
      />
    </g>
  </Icon>
);
