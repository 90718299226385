import { useState, useEffect } from 'react';
import { CustomerVersion, useCustomer } from '@contact/data-access-hooks';
import { useOptionalUser, User } from '../User';
import { useAppStateVisible } from './useAppStateVisible';
import { useDelayedValue } from './useDelayedValue';

const delay = 5000;

/**
 * Returns a stable user, without resseting the values
 * while the app is in the background or while the customer
 * is fetching.
 */
export function useStableOptionalUser(
  version: CustomerVersion
): User | undefined {
  const userUnstable = useOptionalUser();
  const { token = '' } = userUnstable || {};
  const userCurrent = useDelayedValue(userUnstable, {
    delay,
    filter: (user) => !user?.bp, // Delay resetting bp in case app is moving to background
  });
  const [user, setUser] = useState(userCurrent);
  const isAppVisible = useDelayedValue(useAppStateVisible(), {
    delay,
    filter: (x) => x, // Delay setting app as visible to allow time for customer fetch to start
  });
  const { isFetching: isFetchingCustomer } = useCustomer(version, token, true);

  useEffect(() => {
    // Only toggle the login state when active and ready
    if (isAppVisible && !isFetchingCustomer) {
      setUser(userCurrent);
    }
  }, [isAppVisible, userCurrent, isFetchingCustomer]);

  return user;
}
