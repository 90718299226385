import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const GasIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 14.743 22.5" {...props}>
    <g>
      <path
        d="M4.858 21.75s-8.076-4.072-1.5-12.456C6.9 4.806 6.129.75 6.129.75s6.294 7.05 2.217 12.856a4.48 4.48 0 003.06-3.836s7.371 7.612-2.822 11.98a5.6 5.6 0 001.109-5.989S7.726 17.89 6.46 18.13a4.067 4.067 0 01-1.359-5.163s-5.072 4.631-.243 8.783z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </Icon>
);
