import { useAppNotifications } from '@contact/react';
import React from 'react';
import { forwardRef } from '@chakra-ui/react';
import { AppNotificationIcon } from './AppNotificationIcon';

export interface AppNotificationIconConnectedProps {
  height?: number;
  width?: number;
}

export const AppNotificationIconConnected = forwardRef<
  AppNotificationIconConnectedProps,
  'div'
>((props, ref) => {
  const { unreadNotificationCount } = useAppNotifications();
  return (
    <AppNotificationIcon
      {...props}
      ref={ref}
      badge={!!unreadNotificationCount}
    />
  );
});
