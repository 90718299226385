import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import './Button.scss';
import { LinkNonStyled, LinkProps } from '../Link';

export interface ButtonLinkProps extends LinkProps {
  variant: 'primary' | 'ghost';
}

/**
 * This is a link styled as a button
 *
 * See LinkButton if you want a button styled like a link!
 */
export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (props: ButtonLinkProps, ref) => {
    const className = `contactUIComponentsButton contactUIComponentsButton_${props.variant} ${props.className}`;
    return (
      <LinkNonStyled ref={ref} {...props} className={className}>
        {props.children}
      </LinkNonStyled>
    );
  }
);
