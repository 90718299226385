import React from 'react';
import { HStack } from '@contact/ui-volta';
import { Step } from './Step';

export interface Step {
  id: string;
  label?: string;
}

export interface ProgressBarProps {
  steps: Step[];
  current: number;
}

export const ProgressBar = ({ steps, current }: ProgressBarProps) => (
  <HStack
    width="100%"
    spacing={{
      base: '8px',
      md: '16px',
    }}
    alignItems="flex-start"
  >
    {steps.map((step, index) => (
      <Step key={step.id} active={index <= current} label={step.label} />
    ))}
  </HStack>
);
