import React from 'react';
import { ErrorView, ErrorViewProps } from './Error';

export type ErrorBoundaryProps = Omit<ErrorViewProps, 'error'>;

interface ErrorBoundaryState {
  hasError: boolean;
  error?: unknown;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView {...this.props} error={this.state.error} />;
    }

    return this.props.children;
  }
}
