import React, { ReactElement } from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';
import { CheckedIcon } from './checked-icon';
import { UncheckedIcon } from './unchecked-icon';

export type CheckboxProps = Omit<ChakraCheckboxProps, 'size' | 'colorScheme'>;

export const Checkbox = (props: CheckboxProps): ReactElement => {
  let iconColor = 'purple.800';
  if (props.isInvalid) {
    iconColor = 'red.500';
  } else if (props.isDisabled) {
    iconColor = 'gray.600';
  }

  return (
    <ChakraCheckbox
      {...props}
      icon={props.isChecked ? <CheckedIcon /> : <UncheckedIcon />}
      iconColor={iconColor}
    />
  );
};
