import { createContext, useContext } from 'react';

export function useAppStateVisibleDefault(): boolean {
  return true;
}

export interface AppStateVisibleContextState {
  useAppStateVisible?: typeof useAppStateVisibleDefault;
}

export const AppStateVisibleContext = createContext<AppStateVisibleContextState>(
  {
    useAppStateVisible: useAppStateVisibleDefault,
  }
);
export const AppStateProvider = AppStateVisibleContext.Provider;
export function useAppStateVisibleContext() {
  return useContext(AppStateVisibleContext);
}

export function useAppStateVisible(): boolean {
  const {
    useAppStateVisible: useContextBased,
  } = useAppStateVisibleContext() ?? {
    useAppStateVisible: useAppStateVisibleDefault,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useContextBased();
}
