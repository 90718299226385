import React, { forwardRef, PropsWithChildren, RefObject } from 'react';
import classNames from 'classnames';
import './Link.scss';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export interface LinkProps extends RouterLinkProps {
  overrideClassName?: string;
  /**
   * @deprecated use "to"
   */
  href?: string;
  ref?: RefObject<HTMLAnchorElement>;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { overrideClassName, href, ...props }: PropsWithChildren<LinkProps>,
    ref
  ) => {
    return (
      <LinkNonStyled
        {...props}
        to={href || props.to}
        ref={ref}
        className={classNames('contactUIComponentsAnchor', props.className)}
      >
        {props.children}
      </LinkNonStyled>
    );
  }
);

export const LinkNonStyled = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ overrideClassName, ...props }: PropsWithChildren<LinkProps>, ref) => {
    return (
      <RouterLink ref={ref} {...props} className={props.className}>
        {props.children}
      </RouterLink>
    );
  }
);
