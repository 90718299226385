import React from 'react';
import { HTML } from '../../HTML';

const NoRates = ({ message }) => (
  <div className="contactUIComponentsRatesContent_detailedRate">
    <div className="contactUIComponentsRatesError">
      <HTML html={message} />
    </div>
  </div>
);

export default NoRates;
