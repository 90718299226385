import React, {
  ComponentType,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useTranslationNamespaceContextOptional } from './Context';
import { useTranslationWithNamespace } from './useTranslation';
import { I18nContext, Trans, TransProps } from 'react-i18next';
import { isEqual } from 'lodash-es';
import i18next, { TFunction } from 'i18next';
import { useUnstableRef } from '../Utilities';
import { Empty } from '../Empty';
import { useConfig } from '../Config';

export const TextContext = createContext<ComponentType | string | undefined>(
  undefined
);
export const TextProvider = TextContext.Provider;
export function useTextContext() {
  return useContext(TextContext);
}
function useTextContextRequired() {
  const Component = useTextContext();
  if (!Component) {
    throw new Error(
      'Expected Text Component to be provided using TextProvider'
    );
  }
  return Component;
}

export interface TextProps extends Record<string, unknown> {
  i18nKey?: string;
  namespace?: string;
  format?: boolean;
  formatComponents?: TransProps['components'];
  formatParent?: TransProps['parent'];
  count?: number;
  className?: string;
  style?: unknown;
  t?: TFunction;
  options?: Record<string, unknown>;
  // Utilises <Empty /> as a default value
  empty?: boolean;
  as?: ComponentType | string;
  staticValue?: ReactNode;
  'data-testid'?: string;
  testID?: string;
}

function isTFunctionWithNamespace(
  t?: TFunction
): t is TFunction & { ns: string } {
  function isTFunction(t: TFunction): t is TFunction & { ns: unknown } {
    return !!t && 'ns' in t;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return isTFunction(t) && typeof t.ns === 'string';
}

const defaultFormatParent = Text;

export function Text({
  i18nKey,
  namespace: givenNamespace,
  format,
  formatComponents,
  formatParent,
  children,
  className,
  style,
  t: givenT,
  options,
  empty,
  as,
  context,
  staticValue,
  testID: nativeTestID,
  'data-testid': webTestID,
  ...values
}: PropsWithChildren<TextProps>) {
  const testID = webTestID ?? nativeTestID;
  const props = {
    className,
    style,
    testID,
    'data-testid': testID,
  };
  let Component = useTextContextRequired();
  if (as) {
    Component = as;
  }
  const contextNamespace = useTranslationNamespaceContextOptional();
  const namespace =
    (isTFunctionWithNamespace(givenT) ? givenT.ns : undefined) ||
    givenNamespace ||
    contextNamespace;
  const [contextT] = useTranslationWithNamespace(namespace);
  const t = givenT ?? contextT;
  const valuesRef = useRef(values);
  valuesRef.current = isEqual(values, valuesRef.current)
    ? valuesRef.current
    : values;
  const stableOptions = useUnstableRef(options);
  const stableRestValues = useUnstableRef(values);
  const tOptions = useMemo(
    () => ({
      defaultValue: '',
      returnObjects: true,
      ...stableOptions,
      ...stableRestValues,
      context: context ?? stableOptions?.context,
    }),
    [stableOptions, stableRestValues, context]
  );
  const tComponents = useMemo(() => {
    if (Array.isArray(formatComponents)) {
      return formatComponents;
    }
    const defaultComponents = {
      br: (
        <Text
          staticValue={'\n'}
          style={
            {
              /*whiteSpace: 'pre-wrap'*/
            }
          }
        />
      ),
    };
    if (!formatComponents) {
      return defaultComponents;
    }
    return {
      ...defaultComponents,
      ...formatComponents,
    };
  }, [formatComponents]);
  if (
    typeof staticValue === 'string' ||
    typeof staticValue === 'number' ||
    staticValue
  ) {
    return <Component {...props}>{staticValue}</Component>;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const value = t(i18nKey, tOptions) || '';
  if (!value && empty) {
    return <Empty />;
  }
  if (format && i18nKey && namespace) {
    const formatted = (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Trans
        {...props}
        ns={namespace}
        count={tOptions.count}
        t={t}
        tOptions={tOptions}
        i18nKey={i18nKey}
        values={valuesRef.current}
        components={tComponents}
        parent={formatParent ?? defaultFormatParent}
      >
        {''}
      </Trans>
    );
    if (props.style || props.className || props.testID) {
      return <Component {...props}>{formatted}</Component>;
    }
    return formatted;
  }
  if (i18nKey && namespace) {
    return <Component {...props}>{t(i18nKey, tOptions) || ''}</Component>;
  }
  return <Component {...props}>{children || ''}</Component>;
}

export function createNamespace(namespace: string) {
  return function NamedText(props: PropsWithChildren<TextProps>) {
    return <Text {...props} namespace={namespace} />;
  };
}
