import React, { useState } from 'react';
import { isObject } from 'lodash-es';
import { Box, H5, Text } from '@contact/ui-volta';
import { useTranslationWithNamespace } from '@contact/react';
import { BabyDueModal } from '../BabyDueModal';
import { AccountNumberFindModal } from '../AccountNumberFindModal';
import { ContactPhoneLink } from '../ContactPhoneLink';
import './Sidebar.scss';
import { UploadDocumentsHelpModal } from '../UploadDocumentsHelpModal';

interface ListItem {
  key?: string;
  header: string;
  content?: string;
  content2?: string;
  icon?: string;
  link?: string;
  phoneLink?: Record<string, string>;
  onClick?: () => void;
}
interface ListProps {
  items: ListItem[];
}

const List: React.FC<ListProps> = ({ items = [] }) => {
  return (
    <ul className="list">
      {items.map((item, index) => (
        <li key={index} className="list-item">
          <Box>
            <Text className="list-item-header">{item.header}</Text>
            <Box className="list-item-content">
              {item.link && item.key === 'digital-documentation' && (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="link-underlined" onClick={item.onClick}>
                    {item.link}
                  </a>
                </>
              )}
              {item.content && item.key !== 'account-details' && item.content}
              {item.link && item.key === 'birth-date' && (
                <div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="link-underlined" onClick={item.onClick}>
                    {item.link}
                  </a>
                </div>
              )}
              {item.key === 'account-details' && (
                <div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="link-underlined" onClick={item.onClick}>
                    {item.link}
                  </a>
                  <Text>{item.content}</Text>
                  {item.content2 && <Text paddingTop={3}>{item.content2}</Text>}
                </div>
              )}
              {item.phoneLink && (
                <>
                  <span>{item.phoneLink.giveCall}</span>
                  <ContactPhoneLink />
                  <span>{item.phoneLink.free}</span>
                  <div>{item.phoneLink.additionalText}</div>
                </>
              )}
            </Box>
          </Box>
        </li>
      ))}
    </ul>
  );
};

interface SimpleListProps {
  items: string[];
}
const SimpleList: React.FC<SimpleListProps> = ({ items = [] }) => {
  return (
    <ul className="simple-list">
      {items.map((item, index) => (
        <li key={index} className="simple-list-item">
          {item}
        </li>
      ))}
    </ul>
  );
};

export interface SidebarProps {
  namespace: string;
}

export const Sidebar: React.FC<SidebarProps> = ({ namespace }) => {
  const [t] = useTranslationWithNamespace(namespace);
  const translations: any = t('translations', {
    returnObjects: true,
  });

  const [isBabyDueModalVisible, setIsBabyDueModalVisible] = useState(false);
  const [isAccountFindModalVisible, setIsAccountFindModalVisible] = useState(
    false
  );
  const [isDocumentationModalVisible, setDocumentationModalVisible] = useState(
    false
  );
  const sidebar = translations.sidebar;
  const list = sidebar.list.map((item) => {
    if (item.key === 'birth-date') {
      item.onClick = setIsBabyDueModalVisible;
    }
    if (item.key === 'account-details') {
      item.onClick = setIsAccountFindModalVisible;
    }
    if (item.key === 'digital-documentation') {
      item.onClick = setDocumentationModalVisible;
    }
    return item;
  });

  return (
    <Box className="Sidebar">
      {isBabyDueModalVisible && (
        <BabyDueModal
          isOpened={isBabyDueModalVisible}
          setIsOpened={setIsBabyDueModalVisible}
        />
      )}
      {isAccountFindModalVisible && (
        <AccountNumberFindModal
          isOpened={isAccountFindModalVisible}
          setIsOpened={setIsAccountFindModalVisible}
        />
      )}
      {isDocumentationModalVisible && (
        <UploadDocumentsHelpModal
          isActive={isDocumentationModalVisible}
          onHandleClose={() => setDocumentationModalVisible(false)}
        />
      )}

      <H5 className="title">{sidebar.title}</H5>
      <Text className="subtitle">{sidebar?.subtitle}</Text>
      <Text className="intro">{sidebar.intro}</Text>
      <Text className="listIntro">{sidebar?.listIntro}</Text>
      <Box>
        {isObject(sidebar?.list[0]) ? (
          <List items={list} />
        ) : (
          <SimpleList items={sidebar.list} />
        )}
      </Box>
    </Box>
  );
};
