import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';

type Props = {
  variant: 'primary' | 'ghost' | 'secondary' | 'card' | 'transparent' | 'none';
  allowDisabledClick?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  variant,
  allowDisabledClick,
  className,
  disabled,
  ...rest
}: Props) => {
  return (
    <button
      type="button"
      {...rest}
      disabled={allowDisabledClick ? false : disabled}
      className={`contactUIComponentsButton contactUIComponentsButton_${variant}${
        disabled ? '--disabled' : ''
      } ${className}`}
    >
      {children}
    </button>
  );
};
