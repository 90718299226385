const baseStyle = {
  textTransform: 'none',
  borderRadius: '3xl',
  fontWeight: 'bold',
  fontSize: 'xl',
};

const variants = {
  default: {
    padding: '6px 19px',
    bg: 'red.900',
    color: 'white',
    lineHeight: 'none',
  },
};

const defaultProps = {
  variant: 'default',
  colorScheme: 'default',
};

export const Badge = {
  baseStyle,
  variants,
  defaultProps,
};
