import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  RefCallback,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useOptionalUser } from '../User';
import { AnalyticsEvent } from '../Analytics';

export interface AlertDisplayRules {
  csr?: boolean;
  customer?: boolean;
}

export interface AlertAnalyticsEventFn {
  (alert: Alert): AnalyticsEvent;
}
export type AlertAnalyticsEvent = AnalyticsEvent | AlertAnalyticsEventFn;

export interface Alert {
  name?: string;
  content: string | ReactNode;
  header?: string;
  html?: boolean;
  maxLength?: number;
  variant?: string;
  section?: unknown;
  disableClose?: boolean;
  ref?: RefCallback<unknown>;
  icon?: string | JSX.Element;
  styles?: Record<string, any>;
  rules?: AlertDisplayRules;
  analytics?: {
    link?: AlertAnalyticsEvent;
    visible?: AlertAnalyticsEvent;
  };
}

export interface PartialAlert extends Partial<Alert> {
  content: string | ReactNode;
}

export const AlertContext = createContext<Alert[]>([]);
export const AlertSetterContext = createContext<
  Dispatch<SetStateAction<Alert[]>> | undefined
>(undefined);

export function useAlertContext() {
  return useContext(AlertContext);
}

export function useAlertSetterContext() {
  const setter = useContext(AlertSetterContext);
  if (!setter) {
    throw new Error('Expected SetAlertContext');
  }
  return setter;
}

export type AlertProviderProps = Record<string, unknown>;

export function AlertProvider({
  children,
}: PropsWithChildren<AlertProviderProps>) {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  return (
    <AlertContext.Provider value={alerts}>
      <AlertSetterContext.Provider value={setAlerts}>
        {children}
      </AlertSetterContext.Provider>
    </AlertContext.Provider>
  );
}

export function useAlertAdd(section: unknown = undefined) {
  const setAlerts = useAlertSetterContext();
  return useCallback(
    (alert: string | PartialAlert, isWarning = false, ref?: Alert['ref']) => {
      const builtAlert: Alert =
        typeof alert === 'string'
          ? {
              content: alert,
              variant: isWarning ? 'warning' : undefined,
              ref,
              section,
            }
          : {
              variant: isWarning ? 'warning' : undefined,
              section,
              ...alert,
            };
      setAlerts((alerts) => {
        const found = alerts.find(
          (value) =>
            value.content === builtAlert.content &&
            value.section === builtAlert.section
        );
        if (found) return alerts;
        return alerts.concat(builtAlert);
      });
    },
    [setAlerts, section]
  );
}

export function useAlertRemove(section: unknown = undefined) {
  const setAlerts = useAlertSetterContext();
  return useCallback(
    (alert: string | PartialAlert) => {
      setAlerts((notes) => notes.filter(isNotMatchingAlert));
      function isNotMatchingAlert(other: Alert) {
        return !isMatchingAlert(other);
      }

      function isMatchingAlert(other: PartialAlert) {
        if (typeof alert === 'string') {
          return other.content === alert && other.section === section;
        }
        return (
          alert === other ||
          (alert.section === other.section && alert.content === other.content)
        );
      }
    },
    [setAlerts, section]
  );
}

export function useAlertActions(
  section: unknown = undefined
): [ReturnType<typeof useAlertAdd>, ReturnType<typeof useAlertRemove>] {
  const add = useAlertAdd(section);
  const remove = useAlertRemove(section);
  return [add, remove];
}

export function useAlertsFiltered(section: unknown = undefined) {
  const allAlerts = useAlertContext();
  const user = useOptionalUser();
  return useMemo(
    () =>
      allAlerts.filter((alert) => {
        if (section !== alert.section) {
          return false;
        }
        if (!alert.rules) {
          return true;
        }
        if (!user) {
          return false; // No auth, if rules, don't show ever
        }
        if (alert.rules.csr && !user.isCSR) {
          return false;
        }
        if (alert.rules.customer && user.isCSR) {
          return false;
        }
        return true;
      }),
    [section, allAlerts, user]
  );
}

export function useAlerts(
  section: unknown = undefined
): [Alert[], ...ReturnType<typeof useAlertActions>] {
  const alerts = useAlertsFiltered(section);
  const [add, remove] = useAlertActions();
  return [alerts, add, remove];
}

export function isAlert(value: unknown): value is Alert {
  function isAlertLike(value: unknown): value is Record<keyof Alert, unknown> {
    return !!value && typeof value !== 'string';
  }
  return isAlertLike(value) && !!value.content;
}

export function isAlertArray(value: unknown): value is Alert[] {
  return Array.isArray(value) && value.every(isAlert);
}

export function assertAlert(value: unknown): asserts value is Alert {
  if (!isAlert(value)) {
    throw new Error('Expected alert');
  }
}
