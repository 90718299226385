import React from 'react';
import classNames from 'classnames';
import './NavigationDots.scss';

export const NavigationDots = ({ elements, onClick, index: currentIndex }) => {
  return (
    <div className="navigationDots">
      {elements.map((item, index) => (
        <div
          className="navigationDots_container"
          key={index}
          onClick={() => onClick(index, true)}
        >
          <div
            className={classNames(
              'navigationDots_container_dot',
              currentIndex === index
                ? 'navigationDots_container_dot--selected'
                : ''
            )}
          />
        </div>
      ))}
    </div>
  );
};
