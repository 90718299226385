export const Form = {
  baseStyle: {
    helperText: {
      width: 'fit-content',
      height: '18px',
      fontSize: 'sm',
      color: 'gray.900',
    },
  },
};
