import React from 'react';
import { HStack, Text, IconProps } from '@contact/ui-volta';

export interface HeadlineProps {
  title: string;
  icon?: React.FC<IconProps>;
}

export const Headline: React.FC<HeadlineProps> = ({ title, icon: Icon }) => (
  <HStack>
    {Icon && <Icon width="24px" height="24px" color="red.600" />}
    <Text fontSize="24px" fontWeight="bold">
      {title}
    </Text>
  </HStack>
);
