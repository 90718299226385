import React, { PropsWithChildren } from 'react';
import { useStyleConfig, Box } from '@chakra-ui/react';
import { PageContent } from '../PageContent';

export interface PageFooterProps {
  variant?: string;
}

export function PageFooter({
  variant,
  children,
}: PropsWithChildren<PageFooterProps>) {
  const styles = useStyleConfig('PageFooter', { variant });
  return (
    <PageContent variant={variant} sx={styles}>
      {children}
    </PageContent>
  );
}
