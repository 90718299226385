import { useContentSyncOptional } from '@contact/data-access-hooks';
import { useMemo } from 'react';
import { Resource } from 'i18next';
import { useConfig } from '../Config';

export function useContentSyncLanguageResource() {
  const { axios: baseConfig, contentSyncPath } = useConfig();
  // TODO: Check if we can hardcode this
  const contentSync = useContentSyncOptional('v1', contentSyncPath, baseConfig);
  return useMemo((): Resource => {
    // Returning empty for no content sync usage
    if (!contentSyncPath) return {};
    if (!contentSync.data) {
      throw new Error(
        'Expected suspense to be used for TranslationProvider with react-query, data is expected to be present'
      );
    }
    if (!isLanguagesSplit(contentSync.data)) {
      // Default language en
      return {
        'en-NZ': contentSync.data,
      };
    }
    return contentSync.data.languages;

    /**
     * This is an example of how we could move forward out of a single language
     * for our software, and use content sync to provide the contents:
     *
     * {
     *   "languages": {
     *     "en": {
     *       "key": "Hello"
     *     },
     *     "mi": {
     *       "key": "Kia ora"
     *     }
     *   }
     * }
     */
    function isLanguagesSplit(
      input: Record<string, unknown>
    ): input is { languages: Resource } {
      return !!input.languages;
    }
  }, [contentSync.data]);
}
