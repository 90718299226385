import React from 'react';
import { PageHeader, PageHeaderProps } from './PageHeader';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from '@contact/react';
import { PageContent } from '../PageContent';

export function TitledPageHeader(props: PageHeaderProps) {
  const [t] = useTranslation({
    useSuspense: true,
  });
  return (
    <PageHeader variant="titled" {...props}>
      <Heading>{t('title')}</Heading>
    </PageHeader>
  );
}
