import { useEffect, useMemo, useState } from 'react';

/*
Taken from
 $breakpoints: (
   large-desktop: 1440px,
   desktop: 1024px,
   tablet: 860px,
   mobile: 640px,
 );
 */
export const PreconfiguredMediaQuery = {
  belowLargeDesktop: '(max-width: 1439px)',
  aboveLargeDesktop: '(min-width: 1440px)',
  belowDesktop: '(max-width: 1023px)',
  aboveDesktop: '(min-width: 1024px)',
  belowTablet: '(max-width: 859px)',
  aboveTablet: '(min-width: 860px)',
  belowMobile: '(max-width: 639px)',
  aboveMobile: '(min-width: 640px)',
} as const;

export function useMediaQuery(
  query: keyof typeof PreconfiguredMediaQuery | string
) {
  const matcher = useMemo(
    () => window.matchMedia(PreconfiguredMediaQuery[query] || query),
    [query]
  );
  const [matches, setMatches] = useState(() => matcher.matches);
  useEffect(() => {
    matcher.addEventListener('change', handler);
    return () => {
      matcher.removeEventListener('change', handler);
    };
    function handler() {
      setMatches(matcher.matches);
    }
  }, [matcher, setMatches]);
  return matches;
}
