import React, { useMemo, lazy, ComponentType } from 'react';
import { Alert } from '@contact/react';
import { StarSVG } from './Assets/Star';
import { FlagSVG } from './Assets/Flag';
import { ElectricitySVG } from './Assets/Electricity';
import { CloseSVG } from './Assets/Close';
import { InfoSVG } from './Assets/Info';
import { IconComponent } from '../Icon/IconType';

export const AlertIconInfo = 'Info';
export const AlertIconClose = 'Close';
export const AlertIconElectricity = 'Electricity';
export const AlertIconFlag = 'Flag';
export const AlertIconStar = 'Star';

export type AlertIconKey =
  | typeof AlertIconInfo
  | typeof AlertIconClose
  | typeof AlertIconElectricity
  | typeof AlertIconFlag
  | typeof AlertIconStar;

export const AlertIcons: Record<AlertIconKey, IconComponent> = {
  [AlertIconInfo]: lazy(() =>
    importedIcon(Promise.resolve({ default: InfoSVG }))
  ),
  [AlertIconClose]: lazy(() =>
    importedIcon(Promise.resolve({ default: CloseSVG }))
  ),
  [AlertIconElectricity]: lazy(() =>
    importedIcon(Promise.resolve({ ReactComponent: ElectricitySVG }))
  ),
  [AlertIconFlag]: lazy(() =>
    importedIcon(Promise.resolve({ default: FlagSVG }))
  ),
  [AlertIconStar]: lazy(() =>
    importedIcon(Promise.resolve({ default: StarSVG }))
  ),
};

export function isSmallIcon(icon?: unknown): boolean {
  if (isAlertIconKey(icon)) {
    return icon === 'Info';
  }
  return false;
}

export function isAlertIconKey(icon?: unknown): icon is AlertIconKey {
  function isAlertIconsKey(icon?: unknown): icon is keyof typeof AlertIcons {
    return (
      typeof icon === 'string' &&
      Object.prototype.hasOwnProperty.call(AlertIcons, icon)
    );
  }
  return isAlertIconsKey(icon) && !!AlertIcons[icon];
}

interface PresentationImageProps {
  src: string;
}

function PresentationImage({ src }: PresentationImageProps) {
  return <img src={src} role="presentation" alt="" />;
}

async function importedIcon(
  importedSvg: Promise<{
    default?: string | JSX.Element;
    ReactComponent?: ComponentType;
  }>
) {
  const module = await importedSvg;
  const { default: svg } = module;
  if (isReactComponentAvailable(module)) {
    return { default: module.ReactComponent };
  }
  const component: ComponentType = () => {
    if (!svg) {
      return null;
    } else if (typeof svg === 'string') {
      return <PresentationImage src={svg} />;
    } else {
      return svg;
    }
  };
  return {
    default: component,
  };
  function isReactComponentAvailable(
    module: Record<string, unknown>
  ): module is { ReactComponent: ComponentType } {
    return !!module.ReactComponent;
  }
}

export interface AlertIconProps {
  icon?: Alert['icon'];
  fill?: string;
}

export function AlertIcon({ icon: input, fill }: AlertIconProps): JSX.Element {
  const icon = useMemo((): JSX.Element | undefined => {
    if (!input) return undefined;
    if (isAlertIconKey(input)) {
      const Icon = AlertIcons[input];
      return <Icon fill={fill} />;
    }
    if (typeof input === 'string') {
      if (
        input.startsWith('data:') ||
        input.startsWith('https:') ||
        input.startsWith('http:') ||
        input.startsWith('/')
      ) {
        return <PresentationImage src={input} />; // Direct image
      }
      return undefined;
    }
    // ReactNode
    return input;
  }, [input]);
  return icon ?? <AlertIcons.Info />;
}
