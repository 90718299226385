import React from 'react';
import { Header } from '../Header';
import { Footer } from '../Footer';

import './Layout.scss';

export interface LayoutProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  navigation?: Record<string, unknown>;
}
export const Layout: React.FC<LayoutProps> = ({
  component: Component,
  navigation,
}) => {
  return (
    <div className="App">
      <Header />
      <div className="contactUIComponentsLayout">
        <Component navigation={navigation} />
      </div>
      <Footer />
    </div>
  );
};
