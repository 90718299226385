import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';
import { LoginApiResponse } from './models';

export const login = (
  username: string,
  password: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).post<LoginApiResponse>('/login', {
    username,
    password,
  });
};
