import React, { ReactElement, forwardRef, ForwardedRef } from 'react';
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react';

export type SelectProps = Omit<ChakraSelectProps, 'size' | 'variant'>;

export const Select = forwardRef(
  (props: SelectProps, ref: ForwardedRef<HTMLSelectElement>): ReactElement => (
    <ChakraSelect {...props} ref={ref} />
  )
);

Select.displayName = 'Select';
