export const colors = {
  gray: {
    200: '#DBDCDD',
    400: '#e6e1e6',
    300: '#F2F3F3',
    600: '#585C62',
    800: '#121820',
  },

  grayAlpha: {
    200: 'rgba(219, 220, 221, 0.45)',
    300: 'rgba(18, 24, 32, 0.15)',
    500: 'rgba(48, 48, 48, 0.5)',
    700: 'rgba(18, 24, 32, 0.6)',
    800: 'rgba(18, 24, 32, 0.8)',
    850: 'rgba(48, 48, 48, 0.85)',
  },

  red: {
    300: '#FDE9EA',
    600: '#e62a32',
    650: '#974a5c',
    700: '#7D1D34',
  },

  purple: {
    100: '#F8F3F5',
    400: '#AD99B1',
    500: '#6C4772',
  },

  purpleAlpha: {
    800: 'rgba(125, 29, 52, 0.3)',
    900: 'rgba(92, 52, 99, 0.9)',
  },
};
