import React, { useMemo } from 'react';
import i18next, { Resource } from 'i18next';
import { Text, TextProps } from './Text';
import { TranslationProviderWithResources } from './TranslationProvider';
import { useConfig } from '../Config';

export interface DynamicTextProps
  extends Omit<TextProps, 't' | 'i18nKey' | 'namespace'> {
  text: string;
}

export function DynamicText({ text, ...rest }: DynamicTextProps) {
  const namespace = 'namespace';
  const i18nKey = 'html';
  const { translationResources } = useConfig();
  const resources = useMemo(() => {
    return [
      ...(i18next.languages ?? ['en-NZ', 'en', 'mi']),
      ...Object.keys(translationResources ?? {}),
    ].reduce(
      (resources: Resource, lng) => ({
        ...resources,
        [lng]: {
          [namespace]: {
            [i18nKey]: text,
          },
        },
      }),
      {}
    );
  }, [translationResources, text]);
  return (
    <TranslationProviderWithResources resources={resources}>
      <Text {...rest} i18nKey={i18nKey} namespace={namespace} />
    </TranslationProviderWithResources>
  );
}
