import React, { PropsWithChildren } from 'react';
import { Box, useStyleConfig, VisuallyHidden } from '@chakra-ui/react';
import { useTranslationWithNamespace } from '@contact/react';
// Use full import else will create circular dep
import { usePageContentAnchorId } from '../Page/PageContent';

export interface GlobalNavigationProps {
  variant?: string;
}

export function GlobalNavigation({
  variant,
  children,
}: PropsWithChildren<GlobalNavigationProps>) {
  const styles = useStyleConfig('GlobalNavigation', { variant });
  const [t] = useTranslationWithNamespace('GlobalNavigation');
  const contentId = usePageContentAnchorId();
  return (
    <Box as="nav" sx={styles}>
      <VisuallyHidden>
        <a href={`#${contentId}`}>
          {t('navigateToContent', {
            defaultValue: 'Navigate to Content',
          })}
        </a>
      </VisuallyHidden>
      {children}
    </Box>
  );
}
