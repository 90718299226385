import React, { PropsWithChildren, RefObject } from 'react';
import { UseOnScreenProvider as Provider } from '@contact/react';
import { useIntersectionObserver } from './useIntersectionObserver';

export function UseOnScreenProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  return <Provider value={{ useOnScreen }}>{children}</Provider>;
}

export function useOnScreen(ref: RefObject<Element>): boolean {
  const { isIntersecting } = useIntersectionObserver(ref);
  return isIntersecting;
}
