export const Header = {
  logo: {
    redirectURL: 'https://contact.co.nz/residential/',
    alt: 'Contact Energy Logo',
  },
  support: {
    label: 'Support',
    url: 'https://contact.co.nz/support',
  },
};
