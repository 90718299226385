import {
  AppNotificationListItem,
  useTranslationWithNamespace,
} from '@contact/react';
import React from 'react';
import { BellOutlineIcon } from '../../Icons';
import { AppNotificationNamespace } from '../Translations';
import { AppNotificationItem } from './AppNotificationItem';

import './AppNotificationList.scss';

export interface AppNotificationListProps {
  notifications: AppNotificationListItem[];
  onPressNotification?: (
    notification: AppNotificationListItem,
    index: number
  ) => void;
}

export function AppNotificationList({
  notifications,
  onPressNotification,
  ...props
}: AppNotificationListProps) {
  if (!notifications?.length) {
    return <AppNotificationListEmpty {...props} />;
  }
  return (
    <div
      {...props}
      className="contactUIComponentsAppNotificationList_container"
      role="list"
    >
      {notifications.map((notification, index) => (
        <AppNotificationItem
          key={notification.notificationId}
          title={notification.title}
          body={notification.body}
          date={notification.date}
          isRead={notification.isRead}
          onPress={() => onPressNotification?.(notifications[index], index)}
        />
      ))}
    </div>
  );
}

function AppNotificationListEmpty() {
  const [t] = useTranslationWithNamespace(AppNotificationNamespace);
  return (
    <div className="contactUIComponentsAppNotificationList_emptyContainer">
      <div>
        <div className="contactUIComponentsAppNotificationList_emptyLogoContainer">
          <BellOutlineIcon />
        </div>
        <p className="contactUIComponentsAppNotificationList_emptyTitle">
          {t('emptyTitle')}
        </p>
        <p className="contactUIComponentsAppNotificationList_emptyMessage">
          {t('emptyMessage')}
        </p>
      </div>
    </div>
  );
}
