// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useRef } from 'react';
import { isEqual } from 'lodash-es';

export function useUnstableRef<O>(
  value?: O,
  equalFn: (left: O, right: O) => boolean = isEqual
): O {
  const ref = useRef(value);
  if (equalFn(ref.current, value)) {
    return ref.current;
  }
  ref.current = value;
  return value;
}
