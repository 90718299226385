import React, { createContext, useContext } from 'react';
import { AppNotificationContextState } from './notification.types';
import { useAppNotificationStore } from './notification-store';
import { CustomerVersion } from '@contact/data-access';

function errorOp() {
  throw new Error('Outside of AppNotificationProvider');
}

async function errorPromiseOp() {
  errorOp();
}

export const AppNotificationContext = createContext<AppNotificationContextState>(
  {
    notifications: [],
    addNotification: errorOp,
    markAsRead: errorPromiseOp,
    markCategoryAsRead: errorPromiseOp,
    markAllAsRead: errorPromiseOp,
    unreadNotificationCount: 0,
    refresh: errorPromiseOp,
    isFetching: false,
    isUpdating: false,
  }
);

export function useAppNotifications() {
  return useContext(AppNotificationContext);
}

/** Needs to be inside UserProvider. */
export function AppNotificationProvider({
  children,
  version,
}: React.PropsWithChildren<{ version: CustomerVersion }>) {
  const contextState: AppNotificationContextState = useAppNotificationStore(
    version
  );

  return (
    <AppNotificationContext.Provider value={contextState}>
      {children}
    </AppNotificationContext.Provider>
  );
}
