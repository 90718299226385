// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react';

export function useErrorFromPromise(promise?: Promise<unknown>) {
  const [error, setError] = useState();
  useEffect(() => {
    if (!promise) return;
    let set = setError;
    promise.catch((error) => set?.(error));
    return () => {
      set = undefined;
    };
  }, [promise]);
  if (error) throw error;
}
