import React, { useState } from 'react';
import { QuestionMarkIcon } from '@contact/ui-components';
import { H5, Text, Box, FormControl, HStack } from '@contact/ui-volta';
import { Loading, Button } from '@contact/ui-components';
import { Namespace } from './Translations';
import { ModalHelp } from '../TrimesterComponents';
import { useTranslationWithNamespace } from '@contact/react';

export const CsrSendEmail = ({
  isReadyToSendEmail,
  onEmailButtonClick,
  isEmailSentSuccess,
  isEmailSentFetching,
}) => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', {
    returnObjects: true,
  });

  const sendEmailButtonLabel = translations.sendEmailCsrButton;
  const [modalOpened, setModalOpened] = useState(false);
  const uploadDocumentsQuestion = translations.uploadDocumentsQuestion;

  const title = () => {
    return (
      uploadDocumentsQuestion.title && (
        <Box className="form-control-title">
          <H5>{uploadDocumentsQuestion.title}</H5>
          <Box
            className="form-control-icon"
            onClick={() => setModalOpened(!modalOpened)}
          >
            <QuestionMarkIcon
              margin="10px"
              boxSize={{
                base: '20px',
                lg: '20px',
              }}
            />
          </Box>
        </Box>
      )
    );
  };

  return (
    <FormControl className="form-control">
      {title()}

      <Text className="form-control-description">
        {uploadDocumentsQuestion.descriptionNoTips.apply}
      </Text>

      {modalOpened && (
        <ModalHelp
          isActive={modalOpened}
          title={translations.helpTooltips.documentsUpload.title}
          text={
            <>
              <span className="form-tooltip-text">
                {translations.helpTooltips.documentsUpload.content.description}
              </span>
              <span>
                {translations.helpTooltips.documentsUpload.content.list.map(
                  (text, index) => (
                    <li key={index}>{text}</li>
                  )
                )}
              </span>
            </>
          }
          onHandleClose={() => setModalOpened(false)}
        />
      )}

      <HStack>
        <Button
          onClick={onEmailButtonClick}
          variant="primary"
          disabled={!isReadyToSendEmail}
          className={`submitButton ${!isReadyToSendEmail ? 'disabled' : ''}`}
        >
          {isEmailSentFetching && (
            <Loading
              customstyle={{
                minWidth: '100%',
                minHeight: '0',
                top: '10px',
              }}
            />
          )}
          {sendEmailButtonLabel}
        </Button>
        {!isEmailSentFetching && isEmailSentSuccess && (
          <Text color="var(--successColor)">Email sent successfully</Text>
        )}
      </HStack>
    </FormControl>
  );
};
