import React, { PropsWithChildren, ReactNode, useMemo, Suspense } from 'react';
import { isObject } from 'lodash-es';
import { useStyleConfig, Box } from '@chakra-ui/react';
import { Welcome, WelcomeProps } from '../Welcome';
import { GlobalFooter, GlobalHeader } from '../Global';
import {
  TranslationNamespaceProvider,
  TranslationAlertProvider,
  useTranslationWithNamespace,
  TabProvider,
} from '@contact/react';
import { ErrorBoundary } from '../Error';
import { LoadingPageContent } from '../LoadingPage/LoadingPageContent';

export interface PageProps {
  variant?: string;
  translationNamespace: string;
  globalHeaderLeft?: ReactNode;
  globalHeaderRight?: ReactNode;
  maxContentWidth?: number;
  contentPadding?: number;
  hasGlobalHeader?: boolean;
}

export function Page({
  variant,
  translationNamespace,
  children,
  globalHeaderLeft,
  globalHeaderRight,
  maxContentWidth = 1136,
  contentPadding = 16,
  hasGlobalHeader = true,
}: PropsWithChildren<PageProps>) {
  const styles = useStyleConfig('Page', { variant });
  const computedStyles = useMemo(
    () => ({
      ...styles,
      '--max-page-content-width': `${maxContentWidth + contentPadding * 2}px`,
      '--max-page-content-width-inner': `${maxContentWidth}px`,
      '--page-content-padding': `${contentPadding}px`,
    }),
    [styles, contentPadding, maxContentWidth]
  );
  const [t] = useTranslationWithNamespace(translationNamespace);
  const welcomePopup: WelcomeProps = t('welcomePopup', { returnObjects: true });
  return (
    <TranslationNamespaceProvider value={translationNamespace}>
      <TranslationAlertProvider alertKey="alerts" section="PageHeader">
        <TabProvider>
          {isObject(welcomePopup) ? (
            <Welcome {...welcomePopup} featureFlagsVersion="v1" />
          ) : undefined}
          <Box sx={computedStyles}>
            {hasGlobalHeader && (
              <GlobalHeader left={globalHeaderLeft} right={globalHeaderRight} />
            )}
            <ErrorBoundary>
              <Suspense fallback={<LoadingPageContent />}>{children}</Suspense>
            </ErrorBoundary>
            <GlobalFooter />
          </Box>
        </TabProvider>
      </TranslationAlertProvider>
    </TranslationNamespaceProvider>
  );
}
