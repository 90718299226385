import DateUtil from '../Utilities/DateUtil';
import * as Usage from './UsageConstants';

const safeFloatStringDecimalPlaces = 6;

/** Returns true if the `value` is a non empty string. */
export function isTruthyStr(value: string | null | undefined) {
  return typeof value === 'string' && value.length !== 0;
}

export function isValidUsageValue(value: string | null | undefined) {
  if (!isTruthyStr(value)) {
    return false;
  }
  return !isNaN(parseFloat((value as unknown) as string));
}

export function isValidUnitsItem(item: Usage.DataItem): boolean {
  return isValidUsageValue(item.value) && isValidUsageValue(item.offpeakValue);
}

export function isValidCostItem(item: Usage.DataItem): boolean {
  if (item.error?.type === 'dollar-usage') {
    return false;
  }
  return (
    isValidUsageValue(item.dollarValue) &&
    isValidUsageValue(item.offpeakDollarValue)
  );
}

export function isValidUnitsData(data: Usage.DataItem[]): boolean {
  try {
    for (const item of data) {
      if (!isValidUnitsItem(item)) {
        return false;
      }
    }
    return true;
  } catch (error) {
    return false;
  }
}

export function isValidCostData(
  data: Usage.DataItem[],
  {
    startDate: startDateProp,
  }: {
    startDate?: moment.MomentInput;
  }
): boolean {
  if (!isValidUnitsData(data)) {
    return false;
  }

  try {
    for (const item of data) {
      if (startDateProp) {
        const startDate = DateUtil.nzDate(startDateProp);
        if (DateUtil.nzDate(item.date).isBefore(startDate)) {
          // Dollar value not expected to be available
          continue;
        }
      }

      if (!isValidCostItem(item)) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
}

export function isCostDataUnavailable(
  data: Usage.DataItem[],
  {
    slideIndex,
    startDate: startDateProp,
  }: {
    slideIndex: number;
    startDate?: moment.MomentInput;
  }
): boolean {
  const item = data[slideIndex];
  if (!item) {
    return false;
  }
  if (!isValidUnitsItem(item) || !isValidCostItem(item)) {
    return true;
  }

  // Unavailable if before start date
  const startDate = DateUtil.nzDate(startDateProp);
  return (
    startDate &&
    startDate.isValid() &&
    !!item.date &&
    DateUtil.nzDate(item.date).isBefore(startDate)
  );
}

export function isOffpeakElectricityFree({
  planType,
  planDetails,
}: {
  planType: Usage.ElectricityPlanType;
  planDetails: Usage.ElectricityPlanDetails;
}): boolean {
  const planDetail: Usage.ElectricityPlanDetail = {
    OffpeakFree: false,
    ...(planDetails[planType] as Partial<Usage.ElectricityPlanDetail>),
  };
  return planDetail.OffpeakFree;
}

export function getMonthDataItemEndDate(
  monthItem: Usage.DataItem & Partial<Usage.BroadbandDataItem>
) {
  return DateUtil.nzDate(
    monthItem.endDate ?? DateUtil.getMonthPeriodEndDate(monthItem.date)
  );
}

/**
 * Returns the date of the nth day of the month.
 * If the nth day is after the end date, returns the end date.
 *
 * @param monthItem Month item
 * @param dayIndex Zero index day (1st day of the month = 0)
 * @returns Moment date object
 */
export function getMonthDataItemNthDate(
  monthItem: Usage.DataItem & Partial<Usage.BroadbandDataItem>,
  dayIndex: number
) {
  const endDate = getMonthDataItemEndDate(monthItem);
  let day =
    dayIndex < 0
      ? endDate
      : DateUtil.nzDate(monthItem.date).add(dayIndex, 'days');
  if (day.isAfter(endDate)) {
    day = endDate;
  }
  return day;
}

export function getMonthItemDailyDateRange(
  monthItem: Usage.DataItem & Partial<Usage.BroadbandDataItem>
) {
  return [
    DateUtil.convertDateToFormat(monthItem.date),
    DateUtil.convertDateToFormat(getMonthDataItemEndDate(monthItem)),
  ];
}

export function isDataItemEmpty(item?: Usage.DataItem) {
  return (
    Math.ceil(safeFloat(item?.value)) === 0 &&
    Math.ceil(safeFloat(item?.offpeakValue)) === 0
  );
}

export function isUsageDataEmpty(data?: Usage.DataItem[]) {
  return !data || data.findIndex((item) => !isDataItemEmpty(item)) < 0;
}

/** Return the parsed number or 0 if invalid. */
export function safeFloat(value: string | number | null | undefined) {
  let num = 0;
  switch (typeof value) {
    case 'number':
      num = value;
      break;
    case 'string': {
      num = parseFloat(value);
      break;
    }
    default:
      break;
  }
  if (isNaN(num)) {
    num = 0;
  }
  return num;
}

export interface SafeFloatStringOptions {
  decimalPlaces?: number;
}

/** Return the parsed number string or '0' if invalid. */
export function safeFloatString(value: string | number | null | undefined) {
  const num = safeFloat(value);
  if (num === 0) {
    return '0';
  }
  return num.toFixed(safeFloatStringDecimalPlaces);
}

/** Removes null values and replaces with zeros */
export function safeUsageValues(data: Usage.DataItem[]) {
  return data.map((item) => ({
    ...item,
    value: safeFloatString(item.value),
    offpeakValue: safeFloatString(item.offpeakValue),
  }));
}
