import React from 'react';

export const Uncheck = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width={18}
      height={18}
      style={{
        enableBackground: 'new 0 0 18 18',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M15,16H3c-0.6,0-1-0.5-1-1V3c0-0.6,0.4-1,1-1h12c0.5,0,1,0.4,1,1v12C16,15.5,15.5,16,15,16z M16,0
		H2C0.9,0,0,0.9,0,2v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V2C18,0.9,17.1,0,16,0z"
        fill="currentColor"
      />
    </svg>
  );
};
