import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface TabContextState {
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const TabContext = createContext<TabContextState>({
  selectedTabIndex: 0,
  setSelectedTabIndex: () => undefined,
});

export function useTabContext() {
  return useContext(TabContext);
}

export function TabProvider({ children }: { children?: React.ReactNode }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const initialContextValue = useMemo<TabContextState>(
    () => ({
      selectedTabIndex,
      setSelectedTabIndex,
    }),
    [selectedTabIndex]
  );
  return (
    <TabContext.Provider value={initialContextValue}>
      {children}
    </TabContext.Provider>
  );
}
