import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export type IconProps = ChakraIconProps;

export const Icon = (props: IconProps): ReactElement => {
  return <ChakraIcon {...props}></ChakraIcon>;
};
