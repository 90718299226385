import { useEffect, useRef, ReactPortal, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Portal = ({ children }): ReactPortal | null => {
  // 'docs-root' is used as 'modalRoot' isn't available in the Storybook
  const modalRoot = useMemo(
    () =>
      document.getElementById('modalRoot') ??
      document.getElementById('docs-root'),
    []
  );
  const mainDivRef = useRef(document.createElement('div'));
  useEffect(() => {
    const mainDiv = mainDivRef.current;

    if (modalRoot && mainDiv) {
      modalRoot.appendChild(mainDiv);
    }

    return () => {
      if (modalRoot && mainDiv) {
        modalRoot.removeChild(mainDiv);
      }
    };
  }, [modalRoot]);
  return mainDivRef.current ? createPortal(children, mainDivRef.current) : null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Portal;
