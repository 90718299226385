import React from 'react';
import { PageHeader, PageHeaderProps } from './PageHeader';
import {
  Box,
  Heading,
  Tab,
  TabList,
  Tabs,
  useStyleConfig,
} from '@chakra-ui/react';
import { useTranslation } from '@contact/react';
import { Link, matchPath, useLocation } from 'react-router-dom';

export type HeaderTabComponentKey = 'latest' | 'previous';

export type HeaderTab = {
  label: string;
  route: string;
  componentKey: HeaderTabComponentKey;
  isDefaultRoute: boolean;
};

export function TitledTabbedPageHeader(props: PageHeaderProps) {
  const [t] = useTranslation({
    useSuspense: true,
  });
  const tabs: HeaderTab[] = t(`tabs`, {
    returnObjects: true,
  });

  const pathname = useLocation().pathname;
  const defaultTabIndex = tabs.findIndex((tab) => tab.isDefaultRoute);
  const matchingTabIndex = tabs.findIndex((tab) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    matchPath(pathname, { path: tab.route, exact: true })
  );
  const initialTabIndex =
    matchingTabIndex > -1 ? matchingTabIndex : defaultTabIndex;

  const styles = useStyleConfig('PageContent');
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      marginBottom={{ base: '32px', lg: '64px' }}
      borderBottom="1px solid rgba(125, 29, 52, 0.3)"
    >
      <PageHeader variant="titled" {...props}>
        <Heading marginTop={0} marginBottom={{ base: '24px', lg: '48px' }}>
          {t('title')}
        </Heading>
        <Tabs defaultIndex={initialTabIndex} style={{ width: '100%' }}>
          <TabList>
            {tabs.map((tab) => (
              <Tab
                key={tab.route}
                as={Link}
                to={tab.route}
                fontSize={{ base: '20px', lg: '26px' }}
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </PageHeader>
    </Box>
  );
}
