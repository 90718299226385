import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ElectricityIconWithCircle = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 48 48" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1117"
          data-name="Rectangle 1117"
          width="48"
          height="48"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_3989" data-name="Group 3989" clipPath="url(#clip-path)">
      <circle
        id="Ellipse_142"
        data-name="Ellipse 142"
        cx="23.27"
        cy="23.27"
        r="23.27"
        transform="translate(0.73 0.73)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.506"
      />
      <path
        id="Path_2354"
        data-name="Path 2354"
        d="M25.831,21.5l2.1-12.473a.234.234,0,0,0-.429-.164l-11.889,18.9a.234.234,0,0,0,.233.356l7.269-1.095L21.015,39.5a.234.234,0,0,0,.429.164l11.889-18.9a.234.234,0,0,0-.233-.356Z"
        transform="translate(-0.474 -0.266)"
        fill="none"
        stroke="#dd353e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.506"
      />
    </g>
  </Icon>
);
