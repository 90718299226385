import React, { useEffect } from 'react';
import { ModalHelp } from './ModalHelp';
import { Namespace } from '../TrimesterComponents/Translations';
import { useTranslationWithNamespace } from '@contact/react';
import { tooltipClick } from '../Utils';

export const AccountNumberFindModal = ({ isOpened, setIsOpened }) => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', { returnObjects: true });

  useEffect(() => {
    tooltipClick(
      'account number',
      translations.helpTooltips.accountNumber.text.substring(0, 100)
    );
    // only want to run it once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalHelp
      isActive={isOpened}
      title={translations.helpTooltips.accountNumber.title}
      text={
        <>
          {translations.helpTooltips.accountNumber.text}
          <a
            href={translations.helpTooltips.accountNumber.textLink.target}
            className="link-underlined"
            target="_blank"
            rel="noreferrer"
          >
            {translations.helpTooltips.accountNumber.textLink.label}
          </a>
          {translations.helpTooltips.accountNumber.text2}
        </>
      }
      onHandleClose={() => setIsOpened(false)}
    />
  );
};
