import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const PlanIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 18 24" {...props}>
    <g>
      <path
        d="M9.188 17.062l-.656-1.968a1.054 1.054 0 00-1.031-.75 1.072 1.072 0 00-1.078.75l-.844 2.578a.577.577 0 01-.516.328H4.5a.771.771 0 00-.75.75.74.74 0 00.75.75h.563a1.971 1.971 0 001.922-1.359l.516-1.5.75 2.39a.779.779 0 00.656.469h.047a.772.772 0 00.7-.375l.328-.75a.573.573 0 01.469-.281.554.554 0 01.516.328A2 2 0 0012.75 19.5h.75a.771.771 0 00.75-.75.807.807 0 00-.75-.75h-.75a.516.516 0 01-.469-.281 2.1 2.1 0 00-3.093-.657zM17.3 4.594L13.359.7a2.258 2.258 0 00-1.547-.7H2.2A2.282 2.282 0 000 2.3v19.5A2.2 2.2 0 002.2 24h13.5a2.252 2.252 0 002.3-2.2V6.188a2.367 2.367 0 00-.7-1.594zm-5.344-2.156l3.609 3.609h-3.612zm3.8 19.359H2.2V2.3h7.5v4.875A1.141 1.141 0 0010.825 8.3h4.925zM4.125 5.25h3.75a.37.37 0 00.375-.375v-.75a.4.4 0 00-.375-.375h-3.75a.37.37 0 00-.375.375v.75a.345.345 0 00.375.375zm0 3h3.75a.37.37 0 00.375-.375v-.75a.4.4 0 00-.375-.375h-3.75a.37.37 0 00-.375.375v.75a.345.345 0 00.375.375z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
