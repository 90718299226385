export const Namespace = 'TrimesterSuccessTranslations';

export default {
  [Namespace]: {
    title: 'Thanks!',
    subtitle:
      'Your request to sign up to the Fourth Trimester offer has been sent.',
    details: [
      `You'll receive an email shortly confirming your details.`,
      'Our team is now processing your request.',
      `In the meantime, if we have any further questions we'll be in touch.`,
    ],
  },
};
