export const Text = {
  variants: {
    label: {
      fontSize: 'xs', // 12px
      fontWeight: 'medium',
      color: 'gray.600',
    },
    subTitle: {
      fontSize: 'sm', // 14px
      fontWeight: 'medium',
      color: 'gray.600',
    },
    normal: {
      fontSize: 'sm', // 14px
      fontWeight: 'semibold',
      color: 'gray.900',
    },
    title: {
      fontSize: 'sm', // 14px
      fontWeight: 'extrabold',
      color: 'gray.900',
    },
    highlight: {
      fontSize: '26px',
      fontWeight: 'extrabold',
      color: 'gray.900',
    },
    note: {
      fontSize: 'xs', // 12px
      fontWeight: 'medium',
      color: 'gray.900',
    },
  },
};
