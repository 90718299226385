import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';
import { AddressVerificationResponse } from './models';

export const verifyAddress = (
  address?: string,
  config?: Partial<AxiosRequestConfig>
) => {
  return baseInstance(config).get<AddressVerificationResponse>(
    '/address-finder/verification',
    {
      params: {
        address,
      },
    }
  );
};
