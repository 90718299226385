import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const InfoIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 12 12" {...props}>
    <g>
      <path
        d="M6 0a6 6 0 106 6 6 6 0 00-6-6zm.762 9.573H5.233v-5.6h1.529zm-.138-6.5a.873.873 0 01-.624.2q-.832 0-.832-.752T6 1.773q.833 0 .832.747a.728.728 0 01-.208.554z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
