import React from 'react';
import classNames from 'classnames';

import './Switch.scss';

export interface SwitchProps {
  values: string[];
  selectedIndex: number;
  onSelect?: (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  disabled?: boolean;
  className?: string;
}

export function Switch({
  values = [],
  selectedIndex,
  onSelect,
  disabled,
  className,
}: SwitchProps) {
  return (
    <div className={classNames('contactUIComponentsSwitch', className)}>
      <div
        className={classNames({
          contactUIComponentsSwitch_container: true,
          contactUIComponentsSwitch_container_disabled: disabled,
        })}
      >
        {values.map((value, index) => {
          const selected = index === selectedIndex;
          const className = classNames({
            contactUIComponentsSwitch_item: true,
            contactUIComponentsSwitch_item_selected: selected,
            contactUIComponentsSwitch_item_disabled: !selected && disabled,
            contactUIComponentsSwitch_item_selected_disabled:
              selected && disabled,
          });

          // Use button when enabled for maximum accessibility.
          // Use div when disabled to prevent click event capturing.
          return !disabled ? (
            <button
              key={index}
              className={className}
              onClick={(event) => onSelect?.(index, event)}
            >
              {String(value)}
            </button>
          ) : (
            <div key={index} className={className}>
              {String(value)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
