import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../icon';

export const CheckedIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g transform="translate(0 24)">
      <path
        fill="currentColor"
        d="M12-21a9 9 0 0 0-9 9 9 9 0 0 0 9 9 9 9 0 0 0 9-9 9 9 0 0 0-9-9zm0 16.2A7.2 7.2 0 0 1 4.8-12a7.2 7.2 0 0 1 7.2-7.2 7.2 7.2 0 0 1 7.2 7.2A7.2 7.2 0 0 1 12-4.8zm0-11.7A4.5 4.5 0 0 0 7.5-12 4.5 4.5 0 0 0 12-7.5a4.5 4.5 0 0 0 4.5-4.5 4.5 4.5 0 0 0-4.5-4.5z"
      />
    </g>
  </Icon>
);
