export const FormError = {
  baseStyle: {
    text: {
      width: 'fit-content',
      height: '18px',
      fontSize: 'sm',
      color: 'red.500',
    },
  },
};
