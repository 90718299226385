export const GlobalBrandedNavigation = {
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // min width inline with content outer width
    '@media (min-width: 1139px)': {
      justifyContent: 'flex-start',
    },
  },
  defaultProps: {},
  variants: {},
};
