import React, { createContext, ProviderProps, useContext } from 'react';
import { BaseConfig } from './Config';
import { AxiosConfigProvider } from '@contact/data-access-hooks';

export const ConfigContext = createContext<BaseConfig>({});
export function ConfigProvider({ value, children }: ProviderProps<BaseConfig>) {
  return (
    <ConfigContext.Provider value={value}>
      <AxiosConfigProvider value={value.axios}>{children}</AxiosConfigProvider>
    </ConfigContext.Provider>
  );
}

export function useConfigContext() {
  const config = useContext(ConfigContext);
  if (!config) {
    throw new Error('Expected ConfigContext');
  }
  return config;
}

export function useConfig<T extends Partial<BaseConfig> = BaseConfig>(): T {
  return useConfigContext() as T;
}
