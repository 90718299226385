import { VoltaTheme } from '@contact/ui-volta';

const olsButton = {
  minWidth: '160px',
  width: 'fit-content',
  height: '40px',
  py: 2,
  px: 3,
  fontWeight: 'semibold',
  _focus: {
    boxShadow: 'none',
  },
};

export const Button = {
  variants: {
    primary: {
      ...olsButton,
    },
    secondary: {
      ...olsButton,
    },
    ghost: (props) => ({
      ...VoltaTheme.components.Button.variants.ghost(props),
      ...olsButton,
    }),
    unstyled: {
      display: 'flex',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    xs: {
      minWidth: '12px',
      height: '12px',
      fontSize: 'xs',
    },
  },
};
