import { createContext, useContext } from 'react';

export const TranslationNamespaceContext = createContext<string | undefined>(
  undefined
);

export const TranslationNamespaceProvider =
  TranslationNamespaceContext.Provider;

export function useTranslationNamespaceContextOptional() {
  return useContext(TranslationNamespaceContext);
}

export function useTranslationNamespaceContext() {
  const context = useTranslationNamespaceContextOptional();
  if (!context) {
    throw new Error('Expected translation namespace to be provided');
  }
  return context;
}
