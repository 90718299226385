import React from 'react';
import { Stack, Image, Text, H1 } from '@contact/ui-volta';
import MaintenanceIcon from '../../Assets/Icons/MaintenanceIcon.svg';

export const Maintenance = () => (
  <Stack
    alignItems="center"
    backgroundColor="#eeeeee"
    minHeight="100vh"
    paddingY="80px"
    spacing="40px"
  >
    <Image src={MaintenanceIcon} width="80%" maxWidth="360px" />
    <Stack alignItems="center" maxWidth="80%" spacing="16px">
      <H1 color="red.500">Sorry, this is currently unavailable</H1>
      <Text textAlign="center">
        We're down for routine maintenance. Please check back later.
      </Text>
    </Stack>
  </Stack>
);
