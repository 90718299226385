import { Resource, ResourceLanguage } from 'i18next';

function mergeResourceLanguage(
  base: ResourceLanguage,
  merge: ResourceLanguage
): ResourceLanguage {
  const init = {
    ...base,
    ...merge,
  };
  return Object.keys(init).reduce((language: ResourceLanguage, key) => {
    const mergeSet = init[key];
    if (typeof mergeSet === 'string') {
      return {
        ...language,
        [key]: mergeSet,
      };
    }
    const baseSet = base[key];
    if (typeof baseSet === 'string') {
      throw new Error('Expected language resource key');
    }
    return {
      ...language,
      [key]: {
        ...baseSet,
        ...mergeSet,
      },
    };
  }, init);
}

export function mergeResources(base: Resource, merge: Resource): Resource {
  const init = {
    ...base,
    ...merge,
  };
  return Object.keys(init).reduce(
    (resource: Resource, key) => ({
      ...resource,
      [key]: mergeResourceLanguage(base[key] || {}, merge[key]),
    }),
    init
  );
}
