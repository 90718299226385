import { AxiosRequestConfig } from 'axios';
import { baseInstance } from './base-service';

export const accountActivation = (
  activationKey: string,
  requestId: string,
  config?: AxiosRequestConfig
) => {
  return baseInstance(config).put<void>('/password/account-activation', {
    activationKey,
    requestId,
  });
};
