import React, { ReactElement } from 'react';
import {
  FormHelperText as ChakraFormHelperText,
  HelpTextProps as ChakraHelpTextProps,
} from '@chakra-ui/react';

export type FormHelperTextProps = ChakraHelpTextProps;

export const FormHelperText = (props: FormHelperTextProps): ReactElement => (
  <ChakraFormHelperText {...props} />
);
