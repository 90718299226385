import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const BroadbandIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 15.471 20.525" {...props}>
    <g transform="translate(0.903 0.64)">
      <path
        d="M59.759,789.142v4.309m3.31,4.321h-6.62a1.893,1.893,0,0,1,0-3.787h6.62a1.893,1.893,0,1,1,0,3.787Zm-3.743-1.894h0Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        transform="translate(-52.927 -778.527)"
      />
      <path
        d="M56.7,785.333a3.9,3.9,0,0,1,6.123,0m-8.087-1.944a6.622,6.622,0,0,1,10.051,0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.28}
        transform="translate(-52.927 -778.527)"
      />
      <path
        d="M52.927,781.6a9.129,9.129,0,0,1,13.664,0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.28}
        transform="translate(-52.927 -778.527)"
      />
      <path
        d="M59.759,786.511a1.316,1.316,0,1,1-1.3,1.316,1.3,1.3,0,0,1,1.3-1.316Z"
        fill="none"
        stroke="currentColor"
        strokeWidth={1.28}
        fillRule="evenodd"
        transform="translate(-52.927 -778.527)"
      />
    </g>
  </Icon>
);
