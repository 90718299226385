import React from 'react';
import { Modal } from '@contact/ui-components';

export type ModalHelpProps = {
  title: string;
  text: Record<string, any>;
  isActive: boolean;
  onHandleClose: () => void;
};

export const ModalHelp = (props: ModalHelpProps) => {
  const { title, text, isActive, onHandleClose } = props;
  const closeModal = () => {
    const body = document.querySelector('body');
    onHandleClose();
    body.style.overflow = '';
  };
  return (
    <Modal
      isActive={isActive}
      title={title}
      content={text}
      handleClose={closeModal}
      containerClassName="trimester-modal"
      contentClassName="trimester-modal-content"
      titleClassName="trimester-modal-title"
    />
  );
};
