export const Footer = {
  links: [
    {
      title: 'Legal',
      href: 'https://contact.co.nz/legal',
    },
    {
      title: 'Terms and Conditions',
      href: 'https://contact.co.nz/legal/terms-and-conditions',
    },
    {
      title: 'Privacy',
      href: 'https://contact.co.nz/legal/privacy',
    },
  ],
  copyright: `© Contact Energy Ltd, All rights reserved ${new Date().getFullYear()}`,
  contactLogoAlt: 'Contact Energy Logo',
};
