import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useUnstableRef } from '../Utilities';

export function useTranslatedObject(
  t: TFunction,
  key: string,
  options?: Record<string, unknown>
) {
  const stableOptions = useUnstableRef(options);
  return useMemo(() => {
    return getTranslatedObject(t, key, stableOptions);
  }, [t, key, stableOptions]);
}

export function getTranslatedObject(
  t: TFunction,
  key: string,
  options?: Record<string, unknown>
) {
  return getObjectFromContextOrDefault();

  /**
   * Context does not work with returnObjects, this function resolves this issue
   */
  function getObjectFromContextOrDefault() {
    const resolvedOptions: Record<string, unknown> = {
      returnObjects: true,
      defaultValue: false,
      ...options,
    };
    if (typeof resolvedOptions.context !== 'string') {
      const defaultResult = t(key, resolvedOptions);
      return typeof defaultResult === 'object' ? defaultResult : {};
    }
    const contextResult = t(
      `${key}_${resolvedOptions.context}`,
      resolvedOptions
    );
    if (contextResult) return contextResult;
    const defaultResult = t(key, resolvedOptions);
    return typeof defaultResult === 'object' ? defaultResult : {};
  }
}
