import React from 'react';
import { IconComponent } from '../../Icon/IconType';

export const ElectricitySVG: IconComponent = ({ fill = '#5c3463' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path fill="none" d="M0 0H32V32H0z" />
    <path
      fill={fill}
      d="M457.844 682.023c-1.847.006-3.632.016-5.417.013-.413 0-.895.134-1.145-.365-.239-.476.145-.779.365-1.106q4.476-6.678 8.967-13.348c.055-.082.112-.163.166-.247.182-.288.42-.5.778-.363s.3.452.242.759q-.843 4.576-1.675 9.153a1.877 1.877 0 0 0 0 .225h.521l5.312-.016c.359 0 .773 0 .879.388a1.252 1.252 0 0 1-.15.9q-4.74 7.453-9.519 14.884c-.062.1-.115.228-.209.272-.19.089-.445.227-.6.162a.651.651 0 0 1-.281-.54c.083-.77.224-1.535.351-2.3.465-2.8.933-5.6 1.412-8.467"
      transform="translate(-443.206 -663.564)"
    />
  </svg>
);
