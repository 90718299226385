import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export interface DelayedValueHookOptions<T> {
  delay: number;
  /** Return true to delay. If false, return immdiately. */
  filter?: (value: T) => boolean;
}

export function useDelayedValue<T>(
  value: T,
  { delay, filter = () => true }: DelayedValueHookOptions<T>
): T {
  const filterRef = useRef(filter); // Ignore filter changes to allow in-line filter prop usage

  const [delayedValue, setDelayedValue] = useState(value);
  const returnValueRef = useRef(delayedValue);
  returnValueRef.current = delayedValue;

  const unmountedRef = useRef(false);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any;
    if (value !== delayedValue) {
      if (filterRef.current(value)) {
        timer = setTimeout(
          () => !unmountedRef.current && setDelayedValue(value),
          delay
        );
      } else {
        returnValueRef.current = value;
        setDelayedValue(value);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [delay, delayedValue, filter, value]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return returnValueRef.current;
}
