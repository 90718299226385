// Foundational style overrides
import { colors } from './colors';
import { typography } from './typography';
import { spacing } from './spacing';

export const foundations = {
  colors,
  ...typography,
  space: spacing,
};
