// Component style overrides

import { Button } from './button';
import { Heading } from './heading';
import { Badge } from './badge';
import { Form } from './form';
import { Input } from './input';
import { Select } from './select';
import { FormLabel } from './form-label';
import { FormError } from './form-error';
import { Checkbox } from './checkbox';

export const components = {
  Button,
  Heading,
  Badge,
  Form,
  Input,
  Select,
  FormLabel,
  FormError,
  Checkbox,
};
