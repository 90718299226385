import React, { ReactElement } from 'react';
import {
  CheckboxGroupProps as ChakraCheckboxGroupProps,
  useCheckboxGroup,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { Checkbox, CheckboxProps } from '../checkbox';

interface CheckboxGroupOptionProps
  extends Pick<CheckboxProps, 'value' | 'isDisabled' | 'isInvalid'> {
  label: React.ReactNode;
}

export interface CheckboxGroupProps
  extends Omit<ChakraCheckboxGroupProps, 'colorScheme'>,
    Pick<StackProps, 'direction' | 'spacing'> {
  options: CheckboxGroupOptionProps[];
}

export const CheckboxGroup = (props: CheckboxGroupProps): ReactElement => {
  const { getCheckboxProps } = useCheckboxGroup(props);

  return (
    <Stack direction={props.direction} spacing={props.spacing}>
      {props.options.map(({ value, label, ...rest }) => {
        const checkbox = getCheckboxProps(({
          value,
          ...rest,
        } as unknown) as Parameters<typeof getCheckboxProps>[0]);
        return (
          <Checkbox {...checkbox} key={value}>
            {label}
          </Checkbox>
        );
      })}
    </Stack>
  );
};
