import { ContactEnergyTheme } from '@contact/ui-components';

export const Input = {
  ...ContactEnergyTheme.components.Input,
  baseStyle: {
    ...ContactEnergyTheme.components.Input.baseStyle,
    field: {
      ...ContactEnergyTheme.components.Input.baseStyle.field,
      borderColor: 'var(--secondary)',
      _focus: {
        borderColor: 'var(--secondary)',
      },
      _invalid: {
        borderColor: 'var(--primary)',
        _focus: {
          borderColor: 'var(--primary)',
        },
      },
    },
  },
};
