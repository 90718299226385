import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../components';

export const Electricity = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g transform="translate(14 5)">
      <g>
        <path
          d="M13.188,16.6,16.113,0,0,24.827,9.812,23.4,6.887,40,23,15.173Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
    <rect width="50" height="50" fill="none" />
  </Icon>
);
