import React, { useCallback, useEffect, useState } from 'react';
import { HTMLExpansionPanelContent } from './HTMLExpansionPanelContent';
import { Panel as ExpansionPanelShape } from './Context';
import { Arrow } from './Assets';
import { Box, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import './ExpansionPanels.scss';
import { dlePanelExpanded, useTranslationWithNamespace } from '@contact/react';
import { Modal } from '../Modal';
import { useMediaQuery } from '@contact/web-react';
import { ExpansionPanelsNamespace } from './Translations';
import { Button } from '../Button';

export interface ExpansionPanelProps {
  panel: ExpansionPanelShape;
  isLastChild: boolean;
  containerClassName?: string;
  // Maybe remove and just check if "content" is undefined
  noToggle?: boolean;
  /**
   * Disables the modal associated with a compact/tablet/mobile view
   */
  disableModal?: boolean;
  onExpanded?(panel: ExpansionPanelShape): void;
}
export function ExpansionPanel({
  panel,
  isLastChild,
  containerClassName,
  noToggle,
  disableModal,
  onExpanded,
  ...rest
}: ExpansionPanelProps) {
  const [t] = useTranslationWithNamespace(ExpansionPanelsNamespace);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      onExpanded?.(panel);
    }
  }, [onExpanded, open, panel]);

  const toggleOpen = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (open && panel.dlePanelExpandedItem) {
      dlePanelExpanded(panel.dlePanelExpandedItem);
    }
  }, [open, panel.dlePanelExpandedItem]);

  const isTablet = useMediaQuery('belowMobile');

  const renderHeader = () => {
    if (typeof panel.header === 'string') {
      return (
        <Text className={'contactUIComponentsExpansionPanels_header'}>
          {panel.header}
        </Text>
      );
    }

    return panel.header;
  };

  return (
    <Box
      {...rest}
      sx={panel.styles}
      className={classNames(
        panel.variant === 'account'
          ? 'contactUIComponentsExpansionPanels_accountBox'
          : 'contactUIComponentsExpansionPanels_faqBox',
        panel.variant === 'list' && isLastChild
          ? 'contactUIComponentsExpansionPanels_lastBox'
          : '',
        'contactUIComponentsExpansionPanels_container',
        noToggle && 'contactUIComponentsExpansionPanels_noToggle',
        open
          ? 'contactUIComponentsExpansionPanels_container__contentShown'
          : 'contactUIComponentsExpansionPanels_container__contentHidden',
        containerClassName
      )}
    >
      <div
        className={classNames(
          'contactUIComponentsExpansionPanels_panelsContent',
          panel.variant === 'account'
            ? 'contactUIComponentsExpansionPanels_panelAccountHeader'
            : 'contactUIComponentsExpansionPanels_faqBoxHeader',
          noToggle && 'contactUIComponentsExpansionPanels_noToggle'
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={!noToggle && toggleOpen}
      >
        {renderHeader()}
        <Arrow
          data-testid={`ExpansionPanelToggle_${open ? 'Open' : 'Closed'}`}
          className={classNames(
            'contactUIComponentsExpansionPanels_arrow',
            noToggle && 'contactUIComponentsExpansionPanels_arrow--invisible',
            open
              ? 'contactUIComponentsExpansionPanels_arrowUp'
              : 'contactUIComponentsExpansionPanels_arrowDown'
          )}
        />
      </div>
      <Box
        className={
          (disableModal || !isTablet) && open
            ? 'contactUIComponentsExpansionPanels_content'
            : 'contactUIComponentsExpansionPanels_contentHidden'
        }
        data-testid={`ExpansionPanelContent_${
          (disableModal || !isTablet) && open ? 'Open' : 'Closed'
        }`}
      >
        <ExpansionPanelContent panel={panel} />
      </Box>
      <Modal
        data-testid={`ExpansionPanelContent_${
          !disableModal && isTablet && open ? 'Open' : 'Closed'
        }_Modal`}
        className="contactUIComponentsExpansionPanels_compact"
        containerClassName="contactUIComponentsExpansionPanels_full"
        contentClassName="contactUIComponentsExpansionPanels_full"
        isActive={isTablet && open && !disableModal}
        content={
          <Box
            display="flex"
            flex="1"
            minHeight="100%"
            flexDir="column"
            justifyContent="space-between"
          >
            <ExpansionPanelContent panel={panel} />
            <Button
              variant="ghost"
              onClick={toggleOpen}
              style={{
                alignSelf: 'flex-start',
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              {t('modalBackButton')}
            </Button>
          </Box>
        }
        handleClose={close}
      />
    </Box>
  );
}

function ExpansionPanelContent({ panel }: Pick<ExpansionPanelProps, 'panel'>) {
  return (
    <>
      {panel.html && typeof panel.content === 'string' ? (
        <HTMLExpansionPanelContent html={panel.content} />
      ) : typeof panel.content === 'string' ? (
        <Text>{panel.content}</Text>
      ) : (
        panel.content
      )}
    </>
  );
}
