import React, { useState, useCallback, useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import { Modal, ModalVariant } from '../Modal';
import WelcomeImage from './Welcome.svg';
import { Empty, useFeatureFlag } from '@contact/react';
import { FeatureFlagsVersion } from '@contact/data-access';

export interface WelcomeProps {
  name: string;
  title: string;
  content: string | React.ReactNode;
  featureFlagsVersion?: FeatureFlagsVersion;
  primaryButtonLabel?: string;
  onPressPrimaryButton?: () => void;
  disabled?: boolean;
  variant?: ModalVariant;
}

export const Welcome = ({
  featureFlagsVersion = 'v1',
  name,
  title,
  content,
  primaryButtonLabel,
  onPressPrimaryButton,
  disabled: providedDisabled,
  variant = 'default',
}: WelcomeProps) => {
  const [isActive, setIsActive] = useState(false);
  const flagName = `components_welcome_popup_${name}`
    .toLowerCase()
    .replace(/[-\s]+/g, '_');
  const { isEnabled: flaggedEnabled } = useFeatureFlag(
    featureFlagsVersion,
    flagName,
    {
      useLocal: true,
    }
  );
  const { isEnabled: flaggedAlwaysShow } = useFeatureFlag(
    featureFlagsVersion,
    `${flagName}_always_show`,
    { useLocal: true }
  );
  const localStorageKey = `welcome.popup.${name}.viewed`;
  const disabled = !flaggedEnabled || providedDisabled;

  const handleClose = useCallback(() => {
    setIsActive(false);
    localStorage.setItem(localStorageKey, JSON.stringify(true));
  }, [localStorageKey]);

  useEffect(() => {
    const viewed = flaggedAlwaysShow
      ? false
      : JSON.parse(localStorage.getItem(localStorageKey) || 'false');
    setIsActive(!disabled && !viewed);
  }, [disabled, localStorageKey, flaggedAlwaysShow]);

  if (!isActive) {
    return <Empty />;
  }

  return (
    <Modal
      title={title}
      isActive={isActive}
      handleClose={handleClose}
      handleButtonClick={() => {
        handleClose();
        onPressPrimaryButton?.();
      }}
      icon={
        variant === 'default' ? (
          <Image
            src={WelcomeImage}
            width="100px"
            height="100px"
            marginBottom="16px"
          />
        ) : undefined
      }
      buttonText={primaryButtonLabel || 'Close'}
      content={content}
      variant={variant}
    />
  );
};
