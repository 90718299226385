// Main theme entry point
import { extendTheme } from '@chakra-ui/react';
import defaultTheme from '@chakra-ui/theme';

// Global style overrides
import { styles } from './styles';

// Foundational style overrides
import { foundations } from './foundations';

// Component style overrides
import { components } from './components';

const overrides = {
  ...foundations,
  components,
  styles,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const VoltaTheme: typeof overrides & typeof defaultTheme = extendTheme(
  overrides,
  defaultTheme
);
