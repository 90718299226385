import React from 'react';
import { FormControl, H4, Text } from '@contact/ui-volta';
import { Controller } from 'react-hook-form';

export const FormText = ({
  control,
  name,
  className = undefined,
  title = undefined,
  description = undefined,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={() => (
        <FormControl className={className}>
          {title && <H4 className="form-control-title">{title}</H4>}
          {description && <Text>{description}</Text>}
        </FormControl>
      )}
    />
  );
};
