import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TrimesterContainer } from '../TrimesterContainer';
import { TrimesterForm } from '../TrimesterForm';
import { TrimesterSuccess } from '../TrimesterSuccess';
import { TrimesterUploadDocuments } from '../TrimesterUploadDocuments';
import './Trimester.scss';

export function Trimester() {
  return (
    <Switch>
      <Route path="/success">
        <TrimesterSuccess />
      </Route>

      <Route path="/upload-documents">
        <TrimesterUploadDocuments />
      </Route>

      <Route path="/404-not-found">
        <div>Not Found</div>
      </Route>

      <Route path="/">
        <TrimesterForm />
      </Route>
    </Switch>
  );
}
