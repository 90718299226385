import React, { PropsWithChildren, useMemo } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next, { Resource } from 'i18next';
import { useConfig } from '../Config';
import { mergeResources } from './mergeResources';
import { useContentSyncLanguageResource } from './useContentSyncLanguageResource';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useErrorFromPromise } from './useErrorFromPromise';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { interpolation } from './interpolation';

function createInstance(config = {}) {
  // The options here will later be replaced by the options provided by `init`
  const i18n = i18next.createInstance(config);
  return i18n.use(initReactI18next);
}

export interface TranslationProviderWithResourcesProps {
  resources: Resource;
}

export function TranslationProviderWithResources({
  resources,
  children,
}: PropsWithChildren<TranslationProviderWithResourcesProps>) {
  const { i18next: i18nextConfig } = useConfig();
  const [i18n, promise] = useMemo(() => {
    const i18n = createInstance(i18nextConfig);
    i18n.use(resourcesToBackend(resources));
    return [
      i18n,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      i18n.init({ initImmediate: true, interpolation, ...i18nextConfig }),
    ];
  }, [i18nextConfig, resources]);
  useErrorFromPromise(promise);
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export function TranslationProviderWithCustomQueryClient({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const config = useConfig();
  const configuredResources = config.translationResources;
  const contentSyncResources = useContentSyncLanguageResource();
  const resources = useMemo(
    () =>
      contentSyncResources
        ? mergeResources(configuredResources || {}, contentSyncResources || {})
        : configuredResources || {},
    [configuredResources, contentSyncResources]
  );
  return (
    <TranslationProviderWithResources resources={resources}>
      {children}
    </TranslationProviderWithResources>
  );
}

export function TranslationProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const queryClient = useQueryClient();
  const customQueryClient = useMemo(() => {
    const defaultOptions = queryClient.getDefaultOptions();
    return new QueryClient({
      defaultOptions: {
        ...defaultOptions,
        queries: {
          ...defaultOptions.queries,
          suspense: true,
        },
      },
    });
  }, [queryClient]);
  return (
    <QueryClientProvider client={customQueryClient}>
      <TranslationProviderWithCustomQueryClient>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </TranslationProviderWithCustomQueryClient>
    </QueryClientProvider>
  );
}
