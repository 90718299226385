import React from 'react';
import {
  TabPanels as VoltaTabPanels,
  TabPanelsProps as VoltaTabPanelsProps,
} from '@contact/ui-volta';

export type TabPanelsProps = VoltaTabPanelsProps;

export const TabPanels = (props: TabPanelsProps) => (
  <VoltaTabPanels {...props} />
);
