import React, { ReactNode } from 'react';
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@contact/ui-volta';

export interface CollapsiblePanelProps {
  header: string;
  children: ReactNode;
  defaultCollapsed?: boolean;
}

export const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({
  header,
  children,
  defaultCollapsed,
}) => (
  <Accordion allowToggle defaultIndex={defaultCollapsed ? -1 : 0}>
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text variant="title">{header}</Text>
            </Box>
            <AccordionIcon transform={!isExpanded && 'rotate(-90deg)'} />
          </AccordionButton>
          <AccordionPanel>{children}</AccordionPanel>
        </>
      )}
    </AccordionItem>
  </Accordion>
);
