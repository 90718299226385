import React from 'react';

export const Check = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      width={18}
      height={18}
      style={{
        enableBackground: 'new 0 0 18 18',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M16,0H2C0.9,0,0,0.9,0,2v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V2C18,0.9,17.1,0,16,0z M7.7,13.3
		c-0.4,0.4-1,0.4-1.4,0L2.7,9.7C2.5,9.5,2.4,9.3,2.4,9s0.1-0.5,0.3-0.7c0.4-0.4,1-0.4,1.4,0L7,11.2l6.9-6.9c0.4-0.4,1-0.4,1.4,0
		c0.4,0.4,0.4,1,0,1.4L7.7,13.3z"
        fill="currentColor"
      />
    </svg>
  );
};
