import React from 'react';
import { useTranslationWithNamespace } from '@contact/react';
import { Namespace } from '../TrimesterComponents/Translations';
import { ModalHelp, ModalHelpProps } from '../TrimesterComponents';

export const UploadDocumentsHelpModal = (
  props: Omit<ModalHelpProps, 'title' | 'text'>
) => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', {
    returnObjects: true,
  });

  return (
    <ModalHelp
      {...props}
      title={translations.helpTooltips.documentsUpload.title}
      text={
        <>
          <span className="form-tooltip-text">
            {translations.helpTooltips.documentsUpload.content.description}
          </span>
          <span>
            {translations.helpTooltips.documentsUpload.content.list.map(
              (text, index) => (
                <li key={index}>{text}</li>
              )
            )}
          </span>
        </>
      }
    />
  );
};
