import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

function Suggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        <strong style={{ fontWeight: 300 }}>{suggestion.name}</strong>
      </div>
    </MenuItem>
  );
}

export default Suggestion;
