import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const StarIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 49.5 49.5" {...props}>
    <g
      id="all_elements_icons_48px_star"
      data-name="all / elements / icons / 48px star"
      transform="translate(0.75 0.75)"
    >
      <circle
        id="Ellipse_129"
        data-name="Ellipse 129"
        cx="24"
        cy="24"
        r="24"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <path
        id="Path_2344"
        data-name="Path 2344"
        d="M547.95,82.169l2.309,4.678a1.726,1.726,0,0,0,1.3.945l5.163.751a1.727,1.727,0,0,1,.957,2.946l-3.736,3.642a1.724,1.724,0,0,0-.5,1.529l.881,5.142a1.727,1.727,0,0,1-2.506,1.821l-4.618-2.428a1.731,1.731,0,0,0-1.608,0l-4.618,2.428a1.727,1.727,0,0,1-2.506-1.821l.882-5.142a1.728,1.728,0,0,0-.5-1.529l-3.736-3.642a1.727,1.727,0,0,1,.957-2.946l5.163-.751a1.726,1.726,0,0,0,1.3-.945l2.309-4.678A1.727,1.727,0,0,1,547.95,82.169Z"
        transform="translate(-522.401 -68.515)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
    </g>
  </Icon>
);
