import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
import './Link.scss';

export interface LinkStyledButtonProps {
  onClick?(): void;
  className?: string;
  disabled?: boolean;
}

/**
 * This is a button styled as a link
 *
 * See ButtonLink if you want a link styled like a button!
 */
export const LinkButton = (props: PropsWithChildren<LinkStyledButtonProps>) => {
  return (
    <button
      {...props}
      className={classNames('contactUIComponentsAnchor', props.className)}
    >
      {props.children}
    </button>
  );
};
