import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const AddressIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g id="Path_2355" data-name="Path 2355" fill="none">
      <path d="M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z" stroke="none" />
      <path
        d="M 24 1.5 C 20.96186065673828 1.5 18.01539039611816 2.094688415527344 15.24245071411133 3.267551422119141 C 12.56333923339844 4.400711059570312 10.15694046020508 6.023250579833984 8.090099334716797 8.090099334716797 C 6.023250579833984 10.15694046020508 4.400711059570312 12.56333923339844 3.267551422119141 15.24245071411133 C 2.094688415527344 18.01539039611816 1.5 20.96186065673828 1.5 24 C 1.5 27.03813934326172 2.094688415527344 29.98460960388184 3.267551422119141 32.75754928588867 C 4.400711059570312 35.43666076660156 6.023250579833984 37.84305953979492 8.090099334716797 39.9099006652832 C 10.15694046020508 41.97674942016602 12.56333923339844 43.59928894042969 15.24245071411133 44.73244857788086 C 18.01539039611816 45.90531158447266 20.96186065673828 46.5 24 46.5 C 27.03813934326172 46.5 29.98460960388184 45.90531158447266 32.75754928588867 44.73244857788086 C 35.43666076660156 43.59928894042969 37.84305953979492 41.97674942016602 39.9099006652832 39.9099006652832 C 41.97674942016602 37.84305953979492 43.59928894042969 35.43666076660156 44.73244857788086 32.75754928588867 C 45.90531158447266 29.98460960388184 46.5 27.03813934326172 46.5 24 C 46.5 20.96186065673828 45.90531158447266 18.01539039611816 44.73244857788086 15.24245071411133 C 43.59928894042969 12.56333923339844 41.97674942016602 10.15694046020508 39.9099006652832 8.090099334716797 C 37.84305953979492 6.023250579833984 35.43666076660156 4.400711059570312 32.75754928588867 3.267551422119141 C 29.98460960388184 2.094688415527344 27.03813934326172 1.5 24 1.5 M 24 0 C 37.25482940673828 0 48 10.74517059326172 48 24 C 48 37.25482940673828 37.25482940673828 48 24 48 C 10.74517059326172 48 0 37.25482940673828 0 24 C 0 10.74517059326172 10.74517059326172 0 24 0 Z"
        stroke="none"
        fill="#e62a32"
      />
    </g>
    <path
      id="Stroke-433-dce2c4cf26d54d388c900bb6e8ead9d9"
      d="M396.355-43.54v-3.7h3.611l-3.611-3.619v-8.922h-4.513v4.4l-3.995-4a1.38,1.38,0,0,0-.969-.395h0a1.38,1.38,0,0,0-.969.395L373.788-47.24H377.4v11.088h18.957Z"
      transform="translate(-362.877 70.449)"
      fill="none"
      stroke="#dd353f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      id="Path_2356"
      data-name="Path 2356"
      d="M464.535,208.952l2.183,2.36,5.878-6.236"
      transform="translate(-444.875 -184.27)"
      fill="none"
      stroke="#e62a32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.506"
    />
  </Icon>
);
