import {
  AppNotification,
  AppNotificationCategory,
  AppNotificationStatus,
} from '@contact/data-access';
import { merge } from 'lodash-es';
import {
  AppNotificationListItem,
  AppNotificationsById,
} from './notification.types';

export function countUnreadNotifications(
  notificationsById: AppNotificationsById
) {
  return Object.values(notificationsById).reduce(
    (count, notification) =>
      isAppNotificationRead(notification) ? count : count + 1,
    0
  );
}

export function sortedNotificationByDate(
  notificationsById: AppNotificationsById
): AppNotification[] {
  return Object.values(notificationsById).sort(
    (a, b) =>
      new Date(a.sent_on || 0).valueOf() - new Date(b.sent_on || 0).valueOf()
  );
}

export function groupNotificationsById(
  notifications: AppNotification[]
): AppNotificationsById {
  const notificationsById: AppNotificationsById = {};
  for (const notification of notifications) {
    const id = notification.notification_id;
    if (!id) {
      throw new Error('Missing notificationId');
    }
    notificationsById[id] = notification;
  }
  return notificationsById;
}

/** Merges `source` into `target` in place. */
export function mergeNotifications(
  target: AppNotificationsById,
  source: AppNotificationsById
): void {
  // Remove old notification data
  Object.values(target).forEach((notification) => {
    const id = notification.notification_id;
    if (!source[id]) {
      delete target[id];
    }
  });

  // Add new notification data
  Object.values(source).forEach((notification) => {
    const id = notification.notification_id;
    if (!id) {
      throw new Error('Missing notification_id');
    }
    target[id] = mergeNotification(target[id], notification);
  });
}

/**
 * Merges `source` into `target` in place and
 * returns `target`.
 */
export function mergeNotification(
  target: AppNotification | undefined,
  source: AppNotification
): AppNotification {
  // Read status always takes precedence
  let status = AppNotificationStatus.Unread;
  if (
    target?.status === AppNotificationStatus.Read ||
    source?.status === AppNotificationStatus.Read
  ) {
    status = AppNotificationStatus.Read;
  }
  return merge(target || {}, source, { status });
}

export function convertAppNotificationToListItem(
  notification: AppNotification
): AppNotificationListItem {
  return {
    notificationId: notification.notification_id,
    category: notification.category,
    date: (notification.sent_on && new Date(notification.sent_on)) || undefined,
    title: notification.notification_details?.title,
    body: notification.notification_details?.detailed,
    isRead: isAppNotificationRead(notification),
    targetPath: notificationTargetPath(notification.category),
    ba: notification.ba,
  };
}

function notificationTargetPath(category: AppNotificationCategory): string {
  switch (category) {
    case AppNotificationCategory.Bill:
      return '/view-bill';
    default:
      return '';
  }
}

export function isAppNotificationRead(notification: AppNotification) {
  return notification.status === AppNotificationStatus.Read;
}

export function setAppNotificationAsRead(notification: AppNotification) {
  notification.status = AppNotificationStatus.Read;
}

export function withToken<T>(
  value: T,
  { token, fallback }: { token: string; fallback: T }
): T {
  return token ? value : fallback;
}
