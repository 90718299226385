import {
  convertAppNotificationToListItem,
  useAppNotifications,
} from '@contact/react';
import React from 'react';
import {
  AppNotificationList,
  AppNotificationListProps,
} from './AppNotificationList';
import { ErrorView as ErrorView } from '../../Error/Error';

import './AppNotificationList.scss';
import { AppNotificationListLoading } from './AppNotificationListLoading';

export function AppNotificationListConnected(
  props: Omit<AppNotificationListProps, 'notifications'>
) {
  const {
    notifications,
    markAsRead,
    isFetching,
    fetchError,
    refresh,
  } = useAppNotifications();
  const notificationsItems = [
    ...notifications.map(convertAppNotificationToListItem),
  ].reverse();

  if (isFetching) {
    return <AppNotificationListLoading />;
  }

  if (fetchError) {
    return (
      <ErrorView
        error={fetchError}
        onReset={refresh}
        className="contactUIComponentsAppNotificationList_errorContainer"
      />
    );
  }

  return <AppNotificationList {...props} notifications={notificationsItems} />;
}
