import React, { useMemo, useEffect, useRef } from 'react';
import { useMediaQuery } from '@contact/web-react';
import { AppNotificationPopoverMobile } from './AppNotificationPopoverMobile';
import { AppNotificationPopoverDesktop } from './AppNotificationPopoverDesktop';
import { AppNotificationPopoverProps } from './AppNotificationPopover.types';
import { useAppNotifications } from '@contact/react';
import { useDisclosure } from '@chakra-ui/react';
import { throttle } from 'lodash-es';

export function AppNotificationPopover({
  onOpen,
  ...props
}: AppNotificationPopoverProps = {}) {
  const isMobile = useMediaQuery('belowMobile');

  // Store refresh in a ref to prevent re-rendering
  // when it changes. Also throttle refresh calls.
  const { refresh } = useAppNotifications();
  const refreshRef = useRef(refresh);
  refreshRef.current = refresh;
  const refreshThrottled = useMemo(
    () => throttle(() => refreshRef.current(), 5000, { trailing: false }),
    []
  );

  const disclosure = useDisclosure({ onOpen });
  const { isOpen } = disclosure;

  useEffect(() => {
    if (isOpen) {
      refreshThrottled();
    }
  }, [isOpen, refreshThrottled]);

  return isMobile ? (
    <AppNotificationPopoverMobile {...props} {...disclosure} />
  ) : (
    <AppNotificationPopoverDesktop {...props} {...disclosure} />
  );
}
