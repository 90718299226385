import React, { useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';

import { Header as Constants } from '../Header/Constants';

import './PrimaryNavBar.scss';
import logoMobile from '../../Assets/Images/contact-wordmark.svg';
import logoDesktop from '../../Assets/Images/contact-logo-arc.svg';

const PrimaryNavBar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const debounceWindowWidth = _debounce(getWindowWidth, 150);

  useEffect(() => {
    window.addEventListener('resize', debounceWindowWidth);
    return () => {
      window.removeEventListener('resize', debounceWindowWidth);
    };
  });

  const handleLogoClick = () => {
    window.location.href = Constants.logo.redirectURL;
  };

  return (
    <nav className="primaryNavBar">
      <div className="primaryNavBar_logo" onClick={handleLogoClick}>
        <img
          src={windowWidth > 640 ? logoDesktop : logoMobile}
          alt={Constants.logo.alt}
        />
      </div>
    </nav>
  );
};

export default PrimaryNavBar;
