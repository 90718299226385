import React, { useCallback, useMemo } from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryLine,
} from 'victory';
import { OLSTheme } from '../theme';
import { formatDateTime } from './BarChartUtil';

export type BarChartDataItem = {
  x: number | string;
  y: number;
};

export interface BarChartProps {
  data: BarChartDataItem[];
  maxNumberOfBars: number;
  cap?: string | number;
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  xTickFormat?: (value: number | string) => string;
  yTickFormat?: (value: number) => string;
}

/**
 * The base width and height of the bar chart, which is used to calculate the base bar width, spacing, etc.
 * The bar chart will be rendered as an SVG, which will be scaled automatically.
 */
const BASE_WIDTH = 400;
const BASE_HEIGHT = 350;

export const BarChart = ({
  data,
  cap,
  maxNumberOfBars,
  selectedIndex,
  onSelect,
  xTickFormat = formatDateTime,
  yTickFormat,
}: BarChartProps) => {
  cap = Math.max(parseFloat(((cap as unknown) as string) || '0'), 0); // Only positive numbers
  const isDataEmpty = data.length === 0;

  const fakeDataForEmptyState =
    isDataEmpty &&
    [...Array(maxNumberOfBars)].map((_value, index) => ({
      x: `${index + 1}`,
      y: 1,
    }));

  // For zero value item, set its value to 2% of the maximum value, so we still have a small clickable bar.
  const maxY = Math.max(...data.map((item) => item.y));
  const barChartData = data.map((item) =>
    item.y > 0 ? item : { ...item, y: maxY * 0.02 }
  );
  const barFill = useCallback(
    ({ index }) => (index === selectedIndex ? '#E62A32' : '#E6E1E6'),
    [selectedIndex]
  );

  const axisXFontWeight = useCallback(
    ({ index }) => (index === selectedIndex ? 600 : 500),
    [selectedIndex]
  );

  const axisYFontWeight = useCallback(
    (item) => (cap && item.tickValue === cap ? 700 : 500),
    [cap]
  );

  const yAxisOffsetX = useMemo(() => {
    if (isDataEmpty) {
      return 0;
    }
    const multiplier = 9;
    const refMaxY = Math.ceil(maxY);
    let maxString = refMaxY + '';
    if (refMaxY < 10) {
      // Assume .5 intervals - add extra char
      maxString += '_';
    }
    return multiplier * maxString.length + 4;
  }, [isDataEmpty, maxY]);

  const handleClick = useCallback(
    ({ index }) => {
      onSelect && onSelect(index);
    },
    [onSelect]
  );

  const paddingLeft = isDataEmpty ? 8 : yAxisOffsetX;
  const paddingRight = 8;
  const barSpacing = 2;
  const barWidth =
    (BASE_WIDTH - paddingLeft - paddingRight - barSpacing) / maxNumberOfBars -
    barSpacing;
  const xDomainPadding =
    (BASE_WIDTH -
      paddingLeft -
      paddingRight +
      barSpacing -
      (data.length || maxNumberOfBars) * (barWidth + barSpacing) +
      barWidth) /
    2;
  return (
    <VictoryChart
      width={BASE_WIDTH}
      height={BASE_HEIGHT}
      domainPadding={{ x: xDomainPadding }}
      domain={{ y: isDataEmpty ? [0, 100] : undefined }}
      padding={{
        top: 16,
        right: paddingRight,
        bottom: isDataEmpty ? 8 : 24,
        left: paddingLeft,
      }}
    >
      <VictoryStack>
        <VictoryBar
          data={isDataEmpty ? fakeDataForEmptyState : barChartData}
          x="x"
          y="y"
          style={{
            data: {
              fill: barFill,
              width: barWidth,
            },
          }}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: 'data',
                      mutation: !isDataEmpty && handleClick,
                    },
                  ];
                },
              },
            },
          ]}
        />
      </VictoryStack>

      {!!cap && (
        <VictoryLine
          style={{
            data: {
              stroke: '#303030',
              strokeDasharray: '2, 1',
              strokeWidth: 1,
            },
          }}
          data={[
            { x: 0, y: cap },
            { x: 10, y: cap },
          ]}
        />
      )}
      <VictoryAxis
        tickValues={data.map((item) => item.x)}
        tickFormat={xTickFormat}
        style={{
          axis: { stroke: '#3030301A' },
          tickLabels: {
            display: isDataEmpty ? 'none' : 'block',
            fontSize: 10,
            padding: 3,
            fill: '#303030',
            fontWeight: axisXFontWeight,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        crossAxis={false}
        offsetX={yAxisOffsetX}
        tickFormat={yTickFormat}
        style={{
          axis: { strokeWidth: 0 },
          tickLabels: {
            display: isDataEmpty ? 'none' : 'block',
            fontSize: 10,
            padding: 9,
            fill: '#303030',
            fontWeight: axisYFontWeight,
          },
          grid: { stroke: '#3030301A' },
        }}
      />
    </VictoryChart>
  );
};
