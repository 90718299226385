import React, { useMemo } from 'react';
import { Box } from '@contact/ui-volta';
import './QuestionMarkPopover.scss';
import { htmlToText, useAnalyticsEventFnConfigured } from '@contact/react';
import { Popover } from '../Popover';
import { QuestionMarkIcon } from '../Icons';

type Props = {
  className?: string;
  title: string;
  body: string;
  analyticsTitle?: string;
  iconColor?: string;
};

export function QuestionMarkPopover({
  className,
  title,
  body,
  analyticsTitle,
  iconColor,
}: Props) {
  const bodyAnalytics = useMemo(() => htmlToText(body), [body]);

  return (
    <Box display="inline-block" position="relative" className={className}>
      <Popover
        title={title}
        body={body}
        trigger={
          <Box
            style={{
              height: 0,
              marginTop: '-8px',
            }}
          >
            <QuestionMarkIcon
              margin="10px"
              boxSize={{
                base: '20px',
                lg: '20px',
              }}
              color={iconColor}
            />
          </Box>
        }
      />
    </Box>
  );
}
