import React, { ReactElement } from 'react';
import { Icon, IconProps } from '../components';

export const Broadband = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g transform="translate(11 7)">
      <g transform="translate(3.393 28.204)">
        <path
          d="M17.736,7.89H3.945a3.945,3.945,0,0,1,0-7.89H17.736a3.945,3.945,0,1,1,0,7.89Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(13.332 32.149)">
        <path
          d="M0,.4H1.8"
          transform="translate(0 -0.401)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(7.855 11.091)">
        <path
          d="M0,3.088a8.131,8.131,0,0,1,12.757,0"
          transform="translate(0 -0.401)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(3.763 5.317)">
        <path
          d="M0,4.811a13.8,13.8,0,0,1,20.941,0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g>
        <path
          d="M0,6.4a19.018,19.018,0,0,1,28.466,0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(11.531 16.634)">
        <path
          d="M2.7,0A2.7,2.7,0,1,1,0,2.7,2.69,2.69,0,0,1,2.7,0Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </g>
    <rect width="50" height="50" fill="none" />
  </Icon>
);
