import React from 'react';
import { Empty } from '../Empty';
import { ConfigContext } from '../Config';
import { ErrorContext } from './Context';

export interface ErrorBoundaryProps {
  provideContextOnError?: boolean;
  didCatch?(error: unknown, info: unknown): void;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, info) {
    if (this.props.didCatch) {
      this.props.didCatch(error, info);
    } else if (process.env.NODE_ENV === 'development') {
      console.group('Error caught');
      console.log({
        error,
        info,
      });
      if (info.componentStack) {
        console.log(info.componentStack);
      }
      console.groupEnd();
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { children, provideContextOnError } = this.props;
    if (hasError) {
      return (
        <ConfigContext.Consumer>
          {(config) =>
            provideContextOnError ||
            config.errorBoundaryProvideContextOnError ? (
              <ErrorContext.Provider value={error}>
                {children}
              </ErrorContext.Provider>
            ) : (
              <Empty />
            )
          }
        </ConfigContext.Consumer>
      );
    }
    return children;
  }
}
