import { Button } from './button';
import { Link } from './link';
import { Tabs } from './tabs';
import { Text } from './text';
import { Modal } from './modal';
import { CloseButton } from './close-button';
import { Tooltip } from './tooltip';
import { Accordion } from './accordion';
import { Table } from './table';
import { Popover } from './popover';

import * as GlobalComponents from './global';
import * as PageComponents from './page';
import * as Icon from './icon';

export const components = {
  ...GlobalComponents,
  ...PageComponents,
  ...Icon,
  Button,
  Link,
  Tabs,
  Text,
  Modal,
  CloseButton,
  Tooltip,
  Accordion,
  Table,
  Popover,
};
