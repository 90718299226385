import { AxiosRequestConfig } from 'axios';

export const baseAxiosConfig: Partial<AxiosRequestConfig> = {
  baseURL: process.env.NX_BASE_URL,
  timeout: 60000,
  headers: {
    'x-api-key': process.env.NX_X_API_KEY,
    'Content-Type': 'application/json',
  },
};
