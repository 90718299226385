import React from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  H4,
  Text,
} from '@contact/ui-volta';
import { Controller } from 'react-hook-form';

export const FormCheckbox = ({
  control,
  name,
  errors,
  className = undefined,
  label,
  title = undefined,
  description = undefined,
  textComponent: TextComponent = undefined, // react component
  text = undefined,
  helperText = undefined,
  isRequired = false,
  errorMessage,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: errorMessage }}
      render={({
        field: { onChange, value, name, ref },
        fieldState: { invalid },
      }) => (
        <FormControl
          className={className.container}
          isInvalid={!value}
          isRequired={isRequired}
        >
          {title && <H4 className="form-control-title">{title}</H4>}
          {description && (
            <Text className="form-control-description">{description}</Text>
          )}
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

          <Checkbox
            onChange={onChange}
            isChecked={value}
            isInvalid={invalid}
            isRequired={isRequired}
          />
          {text && <span className={className.text}>{text}</span>}
          {TextComponent && <TextComponent />}
          {helperText && (
            <FormHelperText className="form-helper-text">
              {helperText}
            </FormHelperText>
          )}
          {errors[name]?.message && (
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
          )}
        </FormControl>
      )}
    />
  );
};
