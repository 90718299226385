import React from 'react';
import { Loading } from '../../Loading';

export function AppNotificationListLoading() {
  return (
    <div className="contactUIComponentsAppNotificationList_loadingContainer">
      <div className="contactUIComponentsAppNotificationList_loadingInnerContainer">
        <Loading />
      </div>
    </div>
  );
}
