export const USAGE_TYPE_STANDARD = 'S';
export const USAGE_TYPE_LOW = 'L';

export const USER_TYPE_STANDARD = 'Standard';
export const USER_TYPE_LOW = 'Low';

export const RATE_UNIT_TYPE_DAY = 'day';
export const RATE_UNIT_TYPE_WATT = 'watt';

export const SERVICE_ID_ELECTRICITY = 'ELEC';
export const SERVICE_ID_PIPED_GAS = 'GAS';
export const SERVICE_ID_LPG = 'LPG';
export const SERVICE_ID_BROADBAND = 'BB';

export const tooltipText =
  'If you use less than 8,000kWh of electricity (or 9,000kWh in some parts of the South Island) ' +
  "across 12 months then you'll most likely be better off on low user.";

export const WITH_GST = 'With GST';
