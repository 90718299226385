import { useMemo } from 'react';
import {
  useTranslatedObject,
  useTranslationNamespaceContextOptional,
  useTranslationWithNamespace,
} from '../Translation';
import { Alert, isAlert } from './Context';

export function useTranslatedAlert(
  name: string,
  enabled: boolean,
  options?: Record<string, unknown>,
  namespace?: string
) {
  const foundNamespace = useTranslationNamespaceContextOptional();
  const resolvedNamespace = namespace ?? foundNamespace;
  const [t] = useTranslationWithNamespace(resolvedNamespace);
  const alert = useTranslatedObject(t, name, options);
  return useMemo((): Alert | undefined => {
    return enabled && isAlert(alert) ? alert : undefined;
  }, [enabled, alert]);
}
