import React, { PropsWithChildren } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { AppStateProvider as Provider } from '@contact/react';

export function AppStateProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  return <Provider value={{ useAppStateVisible }}>{children}</Provider>;
}

export function useAppStateVisible(): boolean {
  return usePageVisibility();
}
