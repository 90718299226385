import { MeterType } from '@contact/data-access';
import { useAccounts } from '@contact/data-access-hooks';
import { useFeatureFlag } from '../FeatureFlags';
import { useOptionalUser } from '../User';
import { useSelectedContracts } from './useSelectedContracts';

/**
 * Uses feature flags:
 * - ols-dollar-usage
 * - ols-dollar-usage-csr
 */
export function useCostModeEnabled() {
  const { token = '', ba: baId = '', isCSR = false } = useOptionalUser() || {};
  const { data: account } = useAccounts('v2', token, baId);

  const { isEnabled: costModeFeatureEnabledForUser } = useFeatureFlag(
    'v1',
    'ols-dollar-usage'
  );
  const { isEnabled: costModeFeatureEnabledForCSRs } = useFeatureFlag(
    'v1',
    'ols-dollar-usage-csr'
  );
  const costModeFeatureEnabled =
    costModeFeatureEnabledForUser || (isCSR && costModeFeatureEnabledForCSRs);

  const { data: selectedContracts } = useSelectedContracts();

  if (!costModeFeatureEnabled) {
    // Feature disabled
    return false;
  }

  if (account) {
    if (account.accountDetail.isPrepay) {
      // Prepay is excluded
      return false;
    }
  } else {
    // Wait for account
    return false;
  }

  const electricyContract = selectedContracts.electricity;
  if (electricyContract) {
    if (electricyContract.meterType !== MeterType.SmartMeter) {
      // Smart meters only
      return false;
    }

    if (
      electricyContract.devices?.find((device) =>
        device.registers?.find(
          (register) => register.registerType === 'Generation'
        )
      )
    ) {
      // Customer with solar excluded
      return false;
    }
  } else {
    // Wait for electricity contract (or none available)
    return false;
  }

  return true;
}
