/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import ExpansionPanel from '../ExpansionPanel';

import './Rates.scss';

import {
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
  USER_TYPE_LOW,
  USER_TYPE_STANDARD,
} from './constants';
import { hasElectricityRatesError, hasGasRatesError } from './utils';

import ToggleButton from '../ToggleButton';
import RatesContent from './RatesContent';

const Rates = ({
  id,
  rates,
  isBachPlan,
  messages,
  className,
  isOpenByDefault,
  isNarrow,
  isSummary,
  setIsStandard,
  isIcon,
  gasRate,
  managedContent,
  gasUsageType,
  onGasUsageToggle,
  setElecUserType,
  elecUserType,
  withGST,
  onToggleGST,
  hideWithGST,
  showElectricityRates,
  showGasRates,
  onElectricityTooltipHover,
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpenByDefault);
  const elecUsageType =
    elecUserType === USER_TYPE_LOW ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD;
  const gasUsageTypeShorthand =
    gasUsageType?.split(' - ')[0] === USER_TYPE_LOW
      ? USAGE_TYPE_LOW
      : USAGE_TYPE_STANDARD;

  useEffect(() => {
    if (setIsStandard) {
      setIsStandard(elecUsageType === USAGE_TYPE_STANDARD);
    }
  }, [elecUsageType, setIsStandard]);

  const handleToggleUsageType = useCallback(() => {
    setElecUserType(
      elecUsageType === USAGE_TYPE_STANDARD ? USER_TYPE_LOW : USER_TYPE_STANDARD
    );
  }, [elecUsageType, setElecUserType]);

  return (
    <div id={id} className={classNames(className, 'contactUIComponentsRates')}>
      <div
        className={classNames(
          'contactUIComponentsRates_top-row',
          isSummary ? 'contactUIComponentsRates_top-row--summary' : ''
        )}
      >
        <div className="contactUIComponentsRates_top-row_column">
          <ToggleButton
            onToggle={() => setIsExpanded(!isExpanded)}
            expanded={isExpanded}
            expandLabel={messages.expand}
            collapseLabel={messages.collapse}
            className="contactUIComponentsRates_toggleRates"
          />
        </div>
      </div>
      <ExpansionPanel isExpanded={isExpanded}>
        <RatesContent
          messages={messages}
          rates={rates}
          gasRates={gasRate}
          elecUsageType={elecUsageType}
          gasUsageType={gasUsageTypeShorthand}
          onToggleUsageType={handleToggleUsageType}
          onGasUsageToggle={onGasUsageToggle}
          hasElectricityRatesError={
            showElectricityRates &&
            hasElectricityRatesError(rates, elecUsageType)
          }
          hasGasRatesError={
            showGasRates && hasGasRatesError(gasRate, gasUsageTypeShorthand)
          }
          isBachPlan={isBachPlan}
          isNarrow={isNarrow}
          isSummary={isSummary}
          isIcon={isIcon}
          managedContent={managedContent}
          withGST={withGST}
          onToggleGST={onToggleGST}
          hideWithGST={hideWithGST}
          onElectricityTooltipHover={onElectricityTooltipHover}
          showElectricityRates={showElectricityRates}
          showGasRates={showGasRates}
        />
      </ExpansionPanel>
    </div>
  );
};

export default Rates;
