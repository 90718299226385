import React, { useState } from 'react';
import { Box, Tooltip } from '@contact/ui-volta';

export interface TouchFriendlyTooltipProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

export const TouchFriendlyTooltip = ({
  label,
  children,
}: TouchFriendlyTooltipProps) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Tooltip label={label} isOpen={isLabelOpen}>
      <Box
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
