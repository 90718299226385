// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { createContext, useContext, useMemo, useRef } from 'react';
import {
  GetInteractiveBillDetailApiResponse,
  InteractiveBillCharge,
  InteractiveBillChargeDiscount,
  InteractiveBillChargeFixed,
  InteractiveBillChargeVariable,
  InteractiveBillClassificationDetail,
  InteractiveBillClassificationHeader,
  InteractiveBillClassificationType,
  InteractiveBillClassificationTypeMapping,
  InteractiveBillDetail,
  InteractiveBillDetailRebillMessageId,
  InteractiveBillInstallation,
  InteractiveBillPremise,
  InteractiveBillRegister,
  PaymentScheme,
  Transaction,
} from '@contact/data-access';
import { uniq } from 'lodash-es';

interface BillListItemType extends Transaction {
  title?: string;
}

const currentlySupportedMajorAPIVersion = 1;

export const ViewBillContext = createContext<InteractiveBillDetail | undefined>(
  undefined
);
export const ViewBillProvider = ViewBillContext.Provider;

export function useViewBill(): InteractiveBillDetail {
  const context = useContext(ViewBillContext);
  if (!context) {
    throw new Error('Expected ViewBillContext to be utilised');
  }
  return context;
}

export interface ViewBillTransactionsContextState {
  transactions: BillListItemType[];
  isLoading: boolean;
}

function createEmptyViewBillTransactionsContextState(): ViewBillTransactionsContextState {
  return {
    transactions: [],
    isLoading: false,
  };
}

export const ViewBillTransactionsContext = createContext<ViewBillTransactionsContextState>(
  createEmptyViewBillTransactionsContextState()
);
export const ViewBillTransactionsProvider =
  ViewBillTransactionsContext.Provider;
export function useViewBillTransactions() {
  return useViewBillTransactionsWithState().transactions;
}

export function useViewBillTransactionsWithState() {
  const context = useContext(ViewBillTransactionsContext);
  return useMemo(
    () => context ?? createEmptyViewBillTransactionsContextState(),
    [context]
  );
}

export const ViewBillInvoiceIdContext = createContext<string | undefined>(
  undefined
);
export const ViewBillInvoiceIdProvider = ViewBillInvoiceIdContext.Provider;
export function useViewBillInvoiceId() {
  return useContext(ViewBillInvoiceIdContext);
}

export function getIsNegative(amount: string | number | undefined) {
  if (!(typeof amount === 'number' || typeof amount === 'string')) {
    return false;
  }
  const number = +amount;
  if (Math.abs(number) === 0) return false;
  return number < 0;
}

export const getIsZero = (value: string) => {
  if (value) {
    return parseFloat(value) === 0;
  }
  return false;
};

export function useViewBillHeaderAccountNumber() {
  return useViewBill().AccountNumber;
}

export function useViewBillHeaderTotalAmountDue() {
  const context = useViewBill();
  let amount = +(context.TotalAmount ?? 0);
  const ppdAmount = +(context.TotalAmountDueLessPromptPaymentDiscount ?? 0);
  const schemeAmount = +(context.PaymentSchemeAmount ?? 0);
  if (
    context.PaymentSchemeType === 'SmoothPay' ||
    context.PaymentSchemeType === 'ControlPay'
  ) {
    amount = schemeAmount;
  } else if (ppdAmount) {
    amount = ppdAmount;
  }
  return amount;
}

export function useViewBillTotalAmountDue() {
  return useViewBill().TotalAmount;
}

export function useViewBillHeaderBillDueDate() {
  const context = useViewBill();
  if (context.PaymentSchemeNextDue && context.PaymentScheme) {
    return context.PaymentSchemeNextDue;
  }
  return context.DueDate;
}

export function useViewBillHeaderShowBillDueDate() {
  const context = useViewBill();
  const isCPorSP =
    context.PaymentSchemeType === 'SmoothPay' ||
    context.PaymentSchemeType === 'ControlPay';
  const hasAmountDue = useViewBillHeaderTotalAmountDue() > 0;
  return !context.DirectDebit && !isCPorSP && hasAmountDue;
}

export type ViewBillTranslationInterpolationContext =
  | 'Debit'
  | 'Credit'
  | 'DirectDebitCredit'
  | 'DirectDebit'
  | 'ZeroCharges'
  | PaymentScheme;

export function useViewBillTranslationInterpolationContext(): ViewBillTranslationInterpolationContext {
  const context = useViewBill();
  let accountType: ViewBillTranslationInterpolationContext = 'Debit';
  const amountNegative = getIsNegative(
    context.TotalAmountDueLessPromptPaymentDiscount
  );
  if (context.DirectDebit) {
    accountType = 'DirectDebit';
    if (amountNegative) {
      accountType = 'DirectDebitCredit';
    }
  } else if (amountNegative) {
    accountType = 'Credit';
  }
  if (context.PaymentScheme) {
    // @ts-ignore
    accountType = context.PaymentSchemeType;
  }
  // @ts-ignore
  if (+context.TotalAmountDueLessPromptPaymentDiscount === 0) {
    accountType = 'ZeroCharges';
  }
  return accountType;
}

export function useHasAutomaticPayment() {
  const paymentStatus = useViewBillTranslationInterpolationContext();

  return (
    paymentStatus === 'DirectDebit' ||
    paymentStatus === 'DirectDebitCredit' ||
    paymentStatus === 'SmoothPay' ||
    paymentStatus === 'Credit'
  );
}

export function useViewBillHeaderFinalBill() {
  return useViewBill().FinalBill;
}

export function useViewBillHeaderFinalBillPremiseAddressLines() {
  const { Premises } = useViewBill();
  return useMemo(
    () =>
      Premises?.filter((premise) => premise.FinalBill).map(
        (premise) => premise.AddressSingleLine
      ) ?? [],
    [Premises]
  );
}

export function useViewBillHeaderOverlappingDatesContext(): string {
  const context = useViewBill();
  const isCPorSP =
    context.PaymentSchemeType === 'SmoothPay' ||
    context.PaymentSchemeType === 'ControlPay';
  const overLapping = !isCPorSP && context.OverlappingDueDates;
  if (!overLapping) {
    return '';
  }
  return context.DirectDebit
    ? 'DirectDebitOverlappingBills'
    : 'OverlappingBills';
}

export function useViewBillHeaderAmountOverdue() {
  const context = useViewBill();
  const amountOverdue = context.OverdueAmount;
  const isCPorSP =
    context.PaymentSchemeType === 'SmoothPay' ||
    context.PaymentSchemeType === 'ControlPay';
  return isCPorSP ? '' : amountOverdue;
}

export function useViewBillHeaderAmountOverdueContext() {
  const context = useViewBill();
  if (!useViewBillHeaderAmountOverdue()) {
    return '';
  }
  return context.DirectDebit ? 'DirectDebitOverDue' : 'OverDue';
}

export function useViewBillFooterAmountOverdueContext() {
  return useViewBillHeaderAmountOverdue() ? 'OverDue' : '';
}

export function useViewBillHeaderShowInformation() {
  const context = useViewBill();
  return !(
    context.DirectDebit &&
    (context.OverdueAmount || context.OverlappingDueDates)
  );
}

export function useViewBillHeaderPaymentSchemeFrequency() {
  const frequency = useViewBill().PaymentSchemeFrequency;
  return frequency === 'Weekly'
    ? 'weekly'
    : frequency === 'Fortnightly'
    ? 'fortnightly'
    : 'monthly';
}

export function useViewBillHeaderShowPayButton() {
  const context = useViewBill();
  if (
    context.PaymentSchemeType === 'SmoothPay' ||
    context.PaymentSchemeType === 'ControlPay'
  ) {
    return false;
  }
  if (context.OverdueAmount) {
    return true;
  }
  // No overdue amount
  if (context.DirectDebit) {
    // Do not show even if there are overlapping due dates (DS2-1116)
    return false;
  }

  const hasTotalLessPPDDue = !getIsNegative(
    context.TotalAmountDueLessPromptPaymentDiscount
  );
  return hasTotalLessPPDDue || context.OverlappingDueDates;
}

export function useViewBillHeaderCreditAmount() {
  return useViewBill().TotalCurrentCharges;
}

export function useViewBillPromptPaymentDiscount() {
  return useViewBill().PromptPaymentDiscountGSTIncl;
}

export function useViewBillHeaderDynamicPeriodControl() {
  const context = useViewBill();
  return context.DynamicPeriodControl;
}

export function useViewBillHasPromptPaymentDiscount() {
  const ppd = useViewBillPromptPaymentDiscount();
  // @ts-ignore
  return !getIsZero(ppd);
}

export function useViewBillHasRebill() {
  const context = useViewBill();
  // AccountNumber of all messages always match the base AccountNumber
  return !!context.BillMessages?.find(
    (message) => message.MessageID === InteractiveBillDetailRebillMessageId
  );
}

// ---------------------------------------

export function useViewBillPremises(): InteractiveBillPremise[] {
  const context = useViewBill();
  return useMemo(() => context.Premises ?? [], [context.Premises]);
}

export function useViewBillInstallationForPremise(
  premise: InteractiveBillPremise,
  product?: string
): InteractiveBillInstallation {
  const context = useViewBill();
  // @ts-ignore
  return useMemo(() => {
    return (context.Installations ?? []).find((installation) => {
      if (installation.Premise !== premise.Premise) {
        return false;
      }
      if (!product) {
        return true;
      }
      return product === installation.Product;
    });
  }, [premise, context, product]);
}

function getCharges(
  bill: GetInteractiveBillDetailApiResponse,
  filter: (charge: Omit<InteractiveBillCharge, 'ChargeType'>) => boolean
): InteractiveBillCharge[] {
  const charges = [
    ...(bill.FixedCharges ?? [])?.map(
      (charge): InteractiveBillChargeFixed => ({
        ...charge,
        ChargeType: 'Fixed',
      })
    ),
    ...(bill.VariableCharges ?? [])?.map(
      (charge): InteractiveBillChargeVariable => ({
        ...charge,
        ChargeType: 'Variable',
      })
    ),
    ...(bill.Discounts ?? [])?.map(
      (charge): InteractiveBillChargeDiscount => ({
        ...charge,
        ChargeType: 'Discount',
      })
    ),
  ];
  return charges.filter(filter);
}

export function useViewBillChargesForInstallation(
  installation: InteractiveBillInstallation
): InteractiveBillCharge[] {
  const context = useViewBill();
  return useMemo(() => {
    return getCharges(
      context,
      (charge) => charge.Contract === installation.Contract
    );
  }, [installation, context]);
}

export function useViewBillClassificationHeadersForPremise(
  premise: InteractiveBillPremise
): InteractiveBillClassificationHeader[] {
  const context = useViewBill();
  return useMemo((): InteractiveBillClassificationHeader[] => {
    const matchingHeaders =
      context.ClassificationHeaders?.filter(
        (header) => header.Premise === premise.Premise
      ) ?? [];
    const types = Object.fromEntries(
      Object.entries(
        InteractiveBillClassificationTypeMapping
        // @ts-ignore
      ).map(([key, value]: [InteractiveBillClassificationType, string]): [
        string,
        InteractiveBillClassificationType
      ] => [value, key])
    );
    // @ts-ignore
    const expectedHeaders = context.Installations.filter(
      (installation) => installation.Premise === premise.Premise
    )
      .filter((installation) => types[installation.Product])
      .flatMap((installation) => {
        const classification: InteractiveBillClassificationHeader = {
          AmountExclGST: '0.00',
          AmountInclGST: '0.00',
          GSTAmount: '0.00',
          DynamicPeriodControl: false,
          Description: installation.Product,
          Classification: types[installation.Product],
          Premise: installation.Premise,
          Contract: installation.Contract,
        };
        const headers = [classification];
        // If we have electricity, check for a register for the same
        // installation, we might additionally have solar
        if (classification.Classification === '001') {
          const exportRegister = context.Registers?.find(
            (register) =>
              register.Installation === installation.Installation &&
              register.ElectricityExport
          );
          if (exportRegister) {
            headers.push({
              ...classification,
              Classification: '004',
              Description: InteractiveBillClassificationTypeMapping['004'],
            });
          }
        }
        return headers;
      });
    const missingHeaders = expectedHeaders.filter(
      (classification) =>
        !matchingHeaders.find(
          (other) =>
            other.Premise === classification.Premise &&
            other.Classification === classification.Classification
        )
    );
    if (!missingHeaders.length) return matchingHeaders;
    return matchingHeaders.concat(missingHeaders).sort((a, b) => {
      if (a.Premise !== b.Premise) {
        return a.Premise < b.Premise ? -1 : 1;
      }
      return a.Classification < b.Classification ? -1 : 1;
    });
  }, [context, premise]);
}

export function useViewBillClassificationDetailsForClassificationHeader(
  header: InteractiveBillClassificationHeader
): InteractiveBillClassificationDetail[] {
  const context = useViewBill();
  return useMemo(() => {
    const matching = context.ClassificationDetails?.filter(
      (detail) =>
        detail.Premise === header.Premise &&
        detail.Classification === header.Classification
    );
    if (matching?.length) return matching;
    const Contract = header.Contract;
    if (typeof Contract !== 'string') return [];
    const detail: InteractiveBillClassificationDetail = {
      ...header,
      Contract,
    };
    return [detail];
  }, [context, header]);
}

export function useViewBillChargesByContract<
  T extends Omit<InteractiveBillCharge, 'ChargeType'>
>(contract?: string, filter?: (charge: T) => boolean) {
  const context = useViewBill();
  // return getCharges(context, (charge) => charge.Contract === contract);
  const filterRef = useRef(filter); // Immutable after mount
  return useMemo(() => {
    if (!contract) return [];
    return getCharges(context, (charge) => {
      return (
        charge.Contract === contract &&
        // @ts-ignore
        (filterRef.current ? filterRef.current(charge) : true)
      );
    });
  }, [context, contract]);
}

function checkInteractiveBillDetailDataVersion(
  data: GetInteractiveBillDetailApiResponse
) {
  const majorVersion = parseInt(
    data.TechnicalDetails?.[0]?.Version?.split('.')[0] ?? '0'
  );
  if (majorVersion !== currentlySupportedMajorAPIVersion) {
    throw new Error(
      `Unsupported major version: ${majorVersion}. Required major version: ${currentlySupportedMajorAPIVersion}`
    );
  }
}

export function useViewBillInteractiveBillDetail<
  T extends GetInteractiveBillDetailApiResponse
>(data: T): InteractiveBillDetail {
  return useMemo((): InteractiveBillDetail => {
    checkInteractiveBillDetailDataVersion(data);
    return data;
  }, [data]);
}

export function useViewBillClassificationHeaderCredits() {
  const { ClassificationHeaders } = useViewBill();
  return useMemo(
    () =>
      ClassificationHeaders?.find((header) => header.Classification === '008'),
    [ClassificationHeaders]
  );
}

export function useViewBillClassificationDetailsCredits() {
  const { ClassificationDetails } = useViewBill();
  return useMemo(
    () =>
      ClassificationDetails?.filter(
        (detail) => detail.Classification === '008'
      ) || [],
    [ClassificationDetails]
  );
}

export function useViewBillClassificationDetailsOtherSecurityDeposits() {
  const { ClassificationDetails } = useViewBill();
  return useMemo(
    () =>
      ClassificationDetails?.filter(
        (detail) => detail.Classification === '012'
      ) || [],
    [ClassificationDetails]
  );
}

export function useViewBillBalanceTransfersIn() {
  const { BalanceTransfers } = useViewBill();
  return useMemo(
    () => BalanceTransfers?.filter((transfer) => !transfer.TransferOut) || [],
    [BalanceTransfers]
  );
}

export function useViewBillBalanceTransfersOut() {
  const { BalanceTransfers } = useViewBill();
  return useMemo(
    () => BalanceTransfers?.filter((transfer) => transfer.TransferOut) || [],
    [BalanceTransfers]
  );
}

export function useViewBillHeaderEstimatedUsageDate() {
  const context = useViewBill();

  const installations = context.Installations;
  const registers = context.Registers;
  // @ts-ignore
  const MeterReadTypes = installations.map((item) => {
    const regs = viewBillRegistersByInstallationId(
      // @ts-ignore
      installations,
      registers,
      item.Installation
    );
    return filterReadTypes(regs);
  });
  const hasEstimatedRegister = MeterReadTypes?.includes('03');

  return hasEstimatedRegister && !context.FinalBill
    ? context.NextBillDate
    : undefined;
}

export function useViewBillRegisterCurrentMeterReadType(
  installationId?: string,
  product?: string
) {
  const registers = useViewBillRegistersByInstallationId(
    installationId,
    product
  );
  return filterReadTypes(registers);
}

export function useViewBillRegistersByInstallationId(
  installationId?: string,
  product?: string
) {
  const context = useViewBill();
  return useMemo(() => {
    return viewBillRegistersByInstallationId(
      // @ts-ignore
      context.Installations,
      context.Registers,
      installationId,
      product
    );
  }, [installationId, context.Installations, context.Registers, product]);
}

export function viewBillRegistersByInstallationId(
  installations: InteractiveBillInstallation[],
  registers: InteractiveBillRegister[],
  installationId?: string,
  product?: string
) {
  if (!installationId) {
    return [];
  }
  const installation = installations?.find(
    (installation) => installation.Installation === installationId
  );
  if (!installation) return [];
  return (
    registers?.filter((register) => {
      if (register.Installation !== installationId) {
        return false;
      }
      if (register.ICP !== installation.ICP) {
        return false;
      }
      if (product === 'Solar') {
        // Solar is ElectricityExport=true, if it is false, it is electricity
        if (!register.ElectricityExport) {
          return false;
        }
      } else if (product === 'Electricity') {
        // Non-solar electricity is ElectricityExport=false, if it is true, it is solar
        if (register.ElectricityExport) {
          return false;
        }
      }
      if (!product) {
        return true;
      }
      if (product === 'Solar') {
        return register.Product === 'Electricity';
      }
      return register.Product === product;
    }) ?? []
  );
}

function filterReadTypes(registers: InteractiveBillRegister[]) {
  const readTypesAll = registers.map(
    (register) => register.CurrentMeterReadType
  );
  const readTypes = uniq(readTypesAll);
  return readTypes.length === 1 ? readTypes[0] : undefined;
}
