import {
  UsagePlanDetailsVersion,
  useUsagePlanDetails,
} from '@contact/data-access-hooks';
import { useMemo } from 'react';
import { flatten, uniq } from 'lodash-es';

export interface PlanTypeHookOptions {
  version: UsagePlanDetailsVersion;
  token: string;
  accountId: string;
  contractId: string;
}

export interface PlanTypeQueryResult<T extends string | symbol> {
  planType?: T;
  isFetching: boolean;
  isError: boolean;
}

export function usePlanType<T extends string | symbol>({
  version,
  token,
  accountId,
  contractId,
}: PlanTypeHookOptions): PlanTypeQueryResult<T> {
  const { data, isFetching, isError } = useUsagePlanDetails({
    version,
    token,
    baId: accountId,
  });
  return useMemo(() => {
    if (isFetching || isError) {
      return { isFetching, isError };
    }
    return {
      planType: uniq(
        flatten(
          data?.premises.map((premise) =>
            premise.services
              .filter((service) => service.contract?.contractId === contractId)
              .map((service) => service.planDetails.internalPlanDescription)
          )
        ).filter(Boolean)
      )[0] as T | undefined,
      isFetching: false,
      isError: false,
    };
  }, [contractId, data?.premises, isError, isFetching]);
}
