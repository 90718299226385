// all global styles overrides
import { Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: {
    'html body': {
      fontFamily: 'Montserrat',
      color: 'gray.900',
      fontSize: 'md',
      fontWeight: 'medium',
      lineHeight: 1.5,
    },
  },
};
