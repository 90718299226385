import React from 'react';

import classNames from 'classnames';

import './LayoutColumn.scss';

export const LayoutColumn = ({
  top = undefined,
  bottom = undefined,
  hideBottom = undefined,
  hideColumn = undefined,
  reverseColumn = undefined,
  reverseTabletWidth = undefined,
  topIsSection = undefined,
  bottomIsImageContainer = undefined,
  fullHeight = undefined,
}) => {
  const layoutColumn = classNames({
    layoutColumn: true,
    'contactUIComponentsLayoutColumn--reverseColumn': reverseColumn,
    'contactUIComponentsLayoutColumn--hideColumn': hideColumn,
    'contactUIComponentsLayoutColumn--hideBottom': hideBottom,
    'contactUIComponentsLayoutColumn--reverseTabletWidth': reverseTabletWidth,
    'contactUIComponentsLayoutColumn--fullHeight': fullHeight,
  });

  const renderTop = () => {
    if (topIsSection) {
      return (
        <section className="contactUIComponentsLayoutColumn_topContainer">
          {top}
          <div className="contactUIComponentsLayoutColumn_colBackground" />
        </section>
      );
    }

    return (
      <div className="contactUIComponentsLayoutColumn_topContainer">{top}</div>
    );
  };

  const renderBottom = () => {
    if (bottomIsImageContainer) {
      return (
        <div className="contactUIComponentsLayoutColumn_bottomImageContainer">
          {bottom}
        </div>
      );
    }

    return (
      <div className="contactUIComponentsLayoutColumn_bottomContainer">
        {bottom}
      </div>
    );
  };

  return (
    <div className={layoutColumn}>
      {renderTop()}
      {renderBottom()}
    </div>
  );
};
