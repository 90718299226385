import {
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverFooter,
  PopoverTrigger,
  Popover as PopoverCh,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/popover';
import { Box, useDisclosure } from '@contact/ui-volta';
import React, { forwardRef, ReactNode, useEffect } from 'react';
import './Popover.scss';

export interface PopoverProps {
  trigger: ReactNode;
  title: string;
  body: string;
  footer?: React.ReactNode;
  onOpen?: () => void;
  disabled?: boolean;
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ title, body, footer, trigger, onOpen, disabled }: PopoverProps, ref) => {
    const { isOpen, onClose, ...disclosure } = useDisclosure({ onOpen });

    useEffect(() => {
      if (disabled) {
        onClose();
      }
    }, [disabled, onClose]);

    return (
      <Box display="inline-block" position="relative">
        <PopoverCh
          {...disclosure}
          isOpen={!disabled && isOpen}
          onClose={onClose}
          isLazy
          placement="bottom"
        >
          <Box cursor="pointer" display="inline-block">
            <PopoverTrigger>
              <Box
                style={{
                  height: 0,
                  marginTop: '-8px',
                }}
              >
                {trigger}
              </Box>
            </PopoverTrigger>
          </Box>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton
              position="absolute"
              size="md"
              top="14px"
              right="8px"
            />
            <PopoverHeader fontWeight="black">{title}</PopoverHeader>
            <PopoverBody>
              <div ref={ref}>{body}</div>
            </PopoverBody>
            {footer ? <PopoverFooter>{footer}</PopoverFooter> : undefined}
          </PopoverContent>
        </PopoverCh>
      </Box>
    );
  }
);
