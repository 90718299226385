import React from 'react';
import { Stack, Box } from '@contact/ui-volta';

export interface StepProps {
  active: boolean;
  label?: string;
}

export const Step = ({ active, label }: StepProps) => (
  <Stack flex={1} spacing="8px">
    <Box
      paddingY="4px"
      backgroundColor={active ? 'purple.700' : 'purple.100'}
    />
    <Box
      textAlign="center"
      fontSize="sm"
      color={active ? 'gray.900' : 'gray.500'}
    >
      {label}
    </Box>
  </Stack>
);
