import { createContext, useContext, useMemo } from 'react';
import { useFlag } from '../Flags';

export interface Logger {
  info(...messages: unknown[]): void;
  log(...messages: unknown[]): void;
  error(...messages: unknown[]): void;
  warn(...messages: unknown[]): void;
}

export const consoleLogger: Logger = console;

const nop = () => void 0;
export const noopLogger: Logger = {
  info: nop,
  log: nop,
  warn: nop,
  error: nop,
};

export const LoggerContext = createContext<Logger | undefined>(undefined);
export const LoggerProvider = LoggerContext.Provider;

export function useLogger(logger = consoleLogger) {
  const contextLogger = useContext(LoggerContext);
  const resolvedLogger =
    contextLogger && logger === consoleLogger ? contextLogger : logger;
  const disable = useFlag('logger_disable');
  const disableInfo = useFlag('logger_disable_info') || disable;
  const disableLog = useFlag('logger_disable_log') || disable;
  const disableWarn = useFlag('logger_disable_warn') || disable;
  // No disable error log
  return useMemo(
    (): Logger => ({
      info: disableInfo ? nop : resolvedLogger.info,
      log: disableLog ? nop : resolvedLogger.log,
      warn: disableWarn ? nop : resolvedLogger.warn,
      error: resolvedLogger.error,
    }),
    [disableInfo, disableLog, disableWarn, resolvedLogger]
  );
}
