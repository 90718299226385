import React from 'react';

export const Edit = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      style={{
        enableBackground: 'new 0 0 15 15',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M0 12.1v2.5c0 .2.2.4.4.4H3c.1 0 .2 0 .3-.1l9.1-9.1-3.2-3.1-9.1 9.1c-.1 0-.1.1-.1.3zm14.8-8.7c.3-.3.3-.9 0-1.2l-2-1.9c-.1-.2-.4-.3-.6-.3-.2 0-.4.1-.6.2l-1.5 1.5 3.1 3.1 1.6-1.4z"
        fill="currentColor"
      />
    </svg>
  );
};
