import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const EmailIconWithCircle = (props: IconProps): ReactElement => (
  <Icon
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_36_45)">
      <rect width="64" height="64" fill="white" />
      <path
        d="M32 62C48.5685 62 62 48.5685 62 32C62 15.4315 48.5685 2 32 2C15.4315 2 2 15.4315 2 32C2 48.5685 15.4315 62 32 62Z"
        stroke="#E62A32"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.1436 45.6149L29.7929 35.6909C30.4111 35.2062 31.174 34.9429 31.9596 34.9429C32.7451 34.9429 33.508 35.2062 34.1262 35.6909L46.8062 45.6215"
        stroke="#E62A32"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M27.656 37.0146L15.4453 27.7173"
        stroke="#E62A32"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M48.5024 27.7083L36.3984 37.2669"
        stroke="#E62A32"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M19.3574 30.4549V16.6135C19.3574 16.3345 19.4683 16.0669 19.6656 15.8696C19.8629 15.6724 20.1304 15.5615 20.4094 15.5615H43.5388C43.8178 15.5615 44.0853 15.6724 44.2826 15.8696C44.4799 16.0669 44.5908 16.3345 44.5908 16.6135V30.6282"
        stroke="#E62A32"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M19.1778 24.5523L15.4191 27.4963L15.3711 27.5856V42.5536C15.3711 43.4851 15.7411 44.3784 16.3997 45.037C17.0584 45.6956 17.9517 46.0656 18.8831 46.0656H45.0658C45.527 46.0656 45.9837 45.9748 46.4098 45.7983C46.8358 45.6218 47.223 45.3631 47.5491 45.037C47.8752 44.7109 48.1339 44.3237 48.3104 43.8976C48.4869 43.4715 48.5778 43.0148 48.5778 42.5536V27.4963H48.5631L44.5911 24.3843"
        stroke="#E62A32"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M28.0467 24.1395L29.3547 24.8475L28.7667 25.8315L27.4467 25.0515L27.4707 26.5035H26.3427L26.3547 25.0395L25.0347 25.8315L24.4587 24.8475L25.7667 24.1395L24.4587 23.4195L25.0347 22.4355L26.3547 23.2275L26.3427 21.7635H27.4707L27.4467 23.2275L28.7667 22.4355L29.3547 23.4195L28.0467 24.1395ZM36.6483 24.1395L37.9563 24.8475L37.3683 25.8315L36.0483 25.0515L36.0723 26.5035H34.9443L34.9563 25.0395L33.6363 25.8315L33.0603 24.8475L34.3683 24.1395L33.0603 23.4195L33.6363 22.4355L34.9563 23.2275L34.9443 21.7635H36.0723L36.0483 23.2275L37.3683 22.4355L37.9563 23.4195L36.6483 24.1395Z"
        fill="#E62A32"
      />
    </g>
    <defs>
      <clipPath id="clip0_36_45">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
