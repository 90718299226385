import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import {
  AddressVerificationResponse,
  verifyAddress,
} from '@contact/data-access';
import { cancellableQuery } from '../utils';

export const useAddressVerification = (
  address?: string,
  queryOptions?: UseQueryOptions<AddressVerificationResponse>,
  axiosOptions?: Partial<AxiosRequestConfig>
) =>
  useQuery<AddressVerificationResponse>(
    ['address-verification', address],
    () =>
      cancellableQuery(async (extraOptions) => {
        const { data } = await verifyAddress(address, {
          ...axiosOptions,
          ...extraOptions,
        });
        return data;
      }),
    {
      ...queryOptions,
      enabled: !!address,
    }
  );
