import React from 'react';
import { Button } from '@contact/ui-volta';

export interface ModalCloseButtonProps {
  onClick: () => void;
}

export const ModalCloseButton = ({ onClick }: ModalCloseButtonProps) => (
  <Button
    variant="primary"
    fontSize="lg"
    fontWeight="bold"
    borderTopLeftRadius={0}
    borderTopRightRadius={0}
    width="100%"
    height="54px"
    onClick={onClick}
  >
    Close
  </Button>
);
