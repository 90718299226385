import React, { Suspense } from 'react';
import { AppNotificationPopoverContentProps } from './AppNotificationPopover.types';
import { useAppNotifications } from '@contact/react';
import { ErrorBoundary } from '../../Error';
import {
  AppNotificationListConnected,
  AppNotificationListLoading,
} from '../List';

export function AppNotificationPopoverContent({
  onPressNotification,
  onClose,
}: AppNotificationPopoverContentProps) {
  const { markAsRead } = useAppNotifications();

  return (
    <div style={{ overflowY: 'auto' }}>
      <Suspense fallback={<AppNotificationListLoading />}>
        <ErrorBoundary>
          <AppNotificationListConnected
            onPressNotification={(notification, index) => {
              const markAsReadPromise = markAsRead(notification.notificationId);
              const { targetPath } = notification;
              let didPerformAction = false;

              if (targetPath) {
                // Notification opens a page when clicked
                if (window.location.pathname !== targetPath) {
                  // Wait for mark as read request to finish before
                  // navigating to the target page
                  markAsReadPromise.finally(() => {
                    // Open the target page
                    window.location.href = targetPath;
                  });
                } else {
                  // Already at the target page
                  onClose();
                }
                didPerformAction = true;
              }

              onPressNotification?.(notification, index, { didPerformAction });
            }}
          />
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}
