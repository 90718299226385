import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ServicesIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 21 24" {...props}>
    <g>
      <path
        d="M10.5 2.25c4.547 0 8.25 1.312 8.25 2.906v.235c0 1.593-3.7 2.859-8.25 2.859-4.594 0-8.25-1.266-8.25-2.859v-.235c0-1.594 3.656-2.906 8.25-2.906zm8.25 6.375v1.219c0 1.594-3.7 2.906-8.25 2.906-4.594 0-8.25-1.312-8.25-2.906V8.625C3.984 9.891 7.266 10.5 10.5 10.5c3.188 0 6.469-.609 8.25-1.875zm0 4.5v1.219c0 1.594-3.7 2.906-8.25 2.906-4.594 0-8.25-1.312-8.25-2.906v-1.219C3.984 14.391 7.266 15 10.5 15c3.188 0 6.469-.609 8.25-1.875zm0 4.5v1.266c0 1.593-3.7 2.859-8.25 2.859-4.594 0-8.25-1.266-8.25-2.859v-1.266C3.984 18.891 7.266 19.5 10.5 19.5c3.188 0 6.469-.609 8.25-1.875zM10.5 0C6.422 0 0 1.125 0 5.156v13.735C0 22.922 6.422 24 10.5 24c4.031 0 10.5-1.078 10.5-5.109V5.156C21 1.125 14.531 0 10.5 0z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
