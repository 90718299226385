import React, { useEffect, useRef } from 'react';
import { Box, Fade } from '@chakra-ui/react';
import { AppNotificationIconConnected } from '../Icon';
import { AppNotificationPopoverContainerProps } from './AppNotificationPopover.types';
import { AppNotificationPopoverContent } from './AppNotificationPopoverContent';
import { useViewHeightCssVar } from '@contact/web-react';

import './AppNotificationPopoverMobile.scss';

export function AppNotificationPopoverMobile(
  props: AppNotificationPopoverContainerProps
) {
  const { isOpen, onOpen, onClose, onToggle } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useViewHeightCssVar();

  useEffect(() => {
    // Close list on escape and return focus to button, to make it
    // behave in the same way as the desktop popover.
    const handleEsc = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
        buttonRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleEsc, false);
    return () => document.removeEventListener('keydown', handleEsc, false);
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      // Focus the container when opening the container
      containerRef.current?.focus();

      // Prevent scrolling main page while showing fullscreen popover
      setBodyScrollEnabled(false);

      // Close the container when focus leaves the container and its children
      // See: https://gomakethings.com/detecting-when-focus-leaves-a-group-of-elements-with-vanilla-js/
      const handleFocusOut = (ev: FocusEvent) => {
        // If focus is still in the container, do nothing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (containerRef.current?.contains(ev.relatedTarget as any)) {
          return;
        }

        // Focus left the container
        onClose();
      };

      document.addEventListener('focusout', handleFocusOut, false);

      onOpen();
      return () => {
        document.removeEventListener('focusout', handleFocusOut, false);

        // Restore body scrolling
        setBodyScrollEnabled(true);
      };
    } else {
      // Restore body scrolling
      setBodyScrollEnabled(true);
    }
  }, [isOpen, onOpen, onClose]);

  return (
    <>
      <button
        ref={buttonRef}
        onClick={onToggle}
        className="contactUIComponentsAppNotificationPopoverMobile_button"
      >
        <AppNotificationIconConnected />
      </button>

      <Fade in={isOpen} unmountOnExit>
        <Box
          ref={containerRef}
          className="contactUIComponentsAppNotificationPopoverMobile_container"
        >
          <AppNotificationPopoverContent {...props} />
        </Box>
      </Fade>
    </>
  );
}

function setBodyScrollEnabled(enabled: boolean) {
  if (enabled) {
    document.body.classList.remove(
      'contactUIComponentsAppNotificationPopoverMobile_noscroll'
    );
  } else {
    document.body.classList.add(
      'contactUIComponentsAppNotificationPopoverMobile_noscroll'
    );
  }
}
