import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const CrossIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="a"
        d="M9 0c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zM5.698 4.22a1.053 1.053 0 0 0-1.477 1.478l.087.099L7.512 9l-3.204 3.203-.087.1a1.053 1.053 0 0 0 1.576 1.389L9 10.488l3.203 3.204.1.087a1.053 1.053 0 0 0 1.476-1.477l-.087-.099L10.488 9l3.204-3.203.087-.1a1.053 1.053 0 0 0-1.576-1.389L9 7.512 5.797 4.308z"
      />
    </defs>
    <g transform="translate(3 3)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#a" />
      <g fill="#303030" mask="url(#b)">
        <path d="M-3-3h24v24H-3z" />
      </g>
    </g>
  </Icon>
);
