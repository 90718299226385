import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

// Use javascript-time-ago as an optional dependency, so call methods conditionally
TimeAgo.addDefaultLocale?.(en);

/**
 * See https://github.com/catamphetamine/react-time-ago#react-native
 */
export interface TimeAgoCustomComponentProps
  extends React.PropsWithChildren<Record<string, unknown>> {
  date: Date;
  verboseDate?: string;
  tooltip: boolean;
}
