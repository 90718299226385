export const Tabs = {
  baseStyle: {
    tab: {
      transition: 'all 0.2s',
    },
    tablist: {
      flexDirection: 'row',
    },
  },
  defaultProps: {
    variant: 'enclosed',
  },
  variants: {
    enclosed: {
      tab: {
        color: '#4F5359',
        backgroundColor: '#DDDDDF',
        fontWeight: 'semibold',
        justifyContent: 'flex-start',
        borderTopLeftRadius: 'base',
        borderTopRightRadius: '2xl',
        borderBottomRadius: 'sm',
        border: '1px solid',
        borderColor: 'transparent',
        marginBottom: '-1px',
        borderBottomColor: 'grayAlpha.700',
        minWidth: '160px',
        height: '40px',
        fontSize: 'md',
        px: 3,

        ':not(:last-child)': {
          marginRight: 2,
        },

        '> svg': {
          width: 'auto',
          height: '20px',
          marginRight: 2,
        },

        _selected: {
          color: 'white',
          backgroundColor: 'purple.800',
        },
      },
      tablist: {
        justifyContent: 'center',
        borderBottom: '1px solid',
        borderBottomColor: 'grayAlpha.700',
      },
      tabpanel: {
        paddingTop: 4,
      },
    },
    underlined: {
      tab: {
        color: 'purple.800',
        fontSize: 'xl',
        fontWeight: 'bold',
        paddingLeft: 2,
        paddingRight: 3,
        py: 0,

        _selected: {
          textDecoration: 'underline',
        },
      },
      tabpanel: {
        paddingTop: 3,
      },
    },
  },
};
