import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const HomeChargeIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 49.5 49.5" {...props}>
    <g transform="translate(0.75 0.75)">
      <circle
        cx="24"
        cy="24"
        r="24"
        fill="none"
        stroke="#e62a32"
        stroke-miterlimit="10"
        stroke-width="1.5"
      />
      <path
        d="M395.2-44.371v-3.511h3.426L395.2-51.316v-8.465h-4.283v4.173l-3.79-3.8a1.309,1.309,0,0,0-.92-.375h0a1.309,1.309,0,0,0-.92.375l-11.5,11.525h3.426v10.521H395.2Z"
        transform="translate(-362.538 72.449)"
        fill="none"
        stroke="#dd353f"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M20.153,14.446,21.09,8.88a.1.1,0,0,0-.191-.073l-5.305,8.434a.1.1,0,0,0,.1.159l3.244-.488L18,22.477a.1.1,0,0,0,.191.073L23.5,14.116a.1.1,0,0,0-.1-.159Z"
        transform="translate(4.123 9.639)"
        fill="none"
        stroke="#dd353e"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.506"
      />
    </g>
  </Icon>
);
