import React, { useEffect } from 'react';
import { ModalHelp } from './ModalHelp';
import { Namespace } from '../TrimesterComponents/Translations';
import { useTranslationWithNamespace } from '@contact/react';
import { tooltipClick } from '../Utils';

export const BabyDueModal = ({ isOpened, setIsOpened }) => {
  const [t] = useTranslationWithNamespace(Namespace);
  const translations: any = t('translations', { returnObjects: true });

  useEffect(() => {
    tooltipClick(
      'baby due date',
      translations.helpTooltips.babyDueDate.text.substring(0, 100)
    );
    // only want to run it once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalHelp
      isActive={isOpened}
      title={translations.helpTooltips.babyDueDate.title}
      text={
        <div className="trimester-modal-text">
          {translations.helpTooltips.babyDueDate.text}
        </div>
      }
      onHandleClose={() => setIsOpened(false)}
    />
  );
};
