import React from 'react';

export interface ExpansionPanelContentProps {
  html: string;
}

export function HTMLExpansionPanelContent({
  html: __html,
}: ExpansionPanelContentProps) {
  // console.log('html: ', __html);
  return <div className="html_raw" dangerouslySetInnerHTML={{ __html }} />;
}
