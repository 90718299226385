import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { Flags, FlagsRecord } from './Flags';
import * as Unleash from '@unleash/proxy-client-react';
import UnleashFlagsProvider from '@unleash/proxy-client-react';
import { useConfig } from '../Config';

export const FlagsContext = createContext<Flags>({});

export const UseFlagsContext = createContext<typeof useFlagFromContext>(
  useFlagFromContext
);
export const UseFlagsProvider = UseFlagsContext.Provider;

export interface FlagProviderProps {
  flag: string;
  enabled?: boolean;
}

export function FlagProvider({
  flag,
  enabled,
  children,
}: PropsWithChildren<FlagProviderProps>) {
  const override = useMemo(
    () => ({
      [flag]: enabled !== false,
    }),
    [flag, enabled]
  );
  const flags = useFlagsContext(override);
  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
}

export interface FlagsProviderProps {
  override?: FlagsRecord;
  defaults?: FlagsRecord;
}

export function FlagsProvider({
  children,
  override,
  defaults,
}: PropsWithChildren<FlagsProviderProps>) {
  const config = useConfig();
  const flags = useFlagsContext(override, defaults || config.flags);
  // If no unleash config, it is disabled, the default context flags will be used
  if (!config.unleash) {
    return (
      <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
    );
  }
  return (
    // Configure our context flags still, but we might not end up using it
    <FlagsContext.Provider value={flags}>
      <UnleashFlagsProvider config={config.unleash}>
        {/* @ts-ignore */}
        <UseFlagsProvider value={Unleash.useFlag}>{children}</UseFlagsProvider>
      </UnleashFlagsProvider>
    </FlagsContext.Provider>
  );
}

export function useFlagsContext(
  override?: FlagsRecord,
  defaults?: FlagsRecord
): Flags {
  const environment = useEnvironmentFlags();
  const context = useContext(FlagsContext);
  return useMemo(
    () => ({
      ...environment,
      ...defaults,
      ...context,
      ...override,
    }),
    [environment, defaults, context, override]
  );
}

export function useFlagFromContext(name: keyof Flags): boolean;
export function useFlagFromContext(name: string): boolean;
export function useFlagFromContext(name: keyof Flags): boolean {
  const flags = useFlagsContext();
  return !!flags[name];
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function useFlag(name: keyof Flags): boolean;
export function useFlag(name: string): boolean {
  const fn = useContext(UseFlagsContext);
  const useFlagHook: typeof useFlagFromContext = fn ?? useFlagFromContext;
  return useFlagHook(name);
}

export function getEnvironmentFlags(): FlagsRecord {
  if (typeof process === 'undefined') {
    return {};
  }
  if (typeof process.env === 'undefined') {
    return {};
  }
  return Object.fromEntries(
    Object.entries(process.env)
      .filter(
        ([key, value]) =>
          /^REACT_APP_CONTACT_FLAG_/i.test(key) &&
          (value === '1' || value?.toLowerCase() === 'true')
      )
      .map(([key]) => [
        key.toLowerCase().replace(/^REACT_APP_CONTACT_FLAG_/i, ''),
        true,
      ])
  );
}

export function useEnvironmentFlags(): FlagsRecord {
  return useMemo(getEnvironmentFlags, []);
}
