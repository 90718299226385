import { Heading, HeadingProps as ChakraHeadingProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export type HeadingProps = ChakraHeadingProps;

export const H1 = (props: HeadingProps): ReactElement => {
  return <Heading {...props} as="h1" size="h1" />;
};

export const H2 = (props: HeadingProps): ReactElement => {
  return <Heading {...props} as="h2" size="h2" />;
};

export const H3 = (props: HeadingProps): ReactElement => {
  return <Heading {...props} as="h3" size="h3" />;
};

export const H4 = (props: HeadingProps): ReactElement => {
  return <Heading {...props} as="h4" size="h4" />;
};

export const H5 = (props: HeadingProps): ReactElement => {
  return <Heading {...props} as="h5" size="h5" />;
};
