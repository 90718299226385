import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const QuestionMarkIcon = ({
  color = '#7d1d34',
  ...props
}: IconProps): ReactElement => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      id="Path_35"
      data-name="Path 35"
      d="M9-18A9,9,0,0,0,0-9,9,9,0,0,0,9,0a9,9,0,0,0,9-9A9,9,0,0,0,9-18ZM9.9-2.7H8.1V-4.5H9.9Zm1.863-6.975-.81.828a3.152,3.152,0,0,0-.936,1.521A4.129,4.129,0,0,0,9.9-6.3H8.1v-.45a3.641,3.641,0,0,1,.2-1.179A3.6,3.6,0,0,1,9.153-9.3l1.116-1.134a1.767,1.767,0,0,0,.495-1.62,1.792,1.792,0,0,0-1.251-1.377A1.815,1.815,0,0,0,7.29-12.285a.8.8,0,0,1-.738.585h-.27a.78.78,0,0,1-.738-1.008,3.607,3.607,0,0,1,2.907-2.547,3.661,3.661,0,0,1,3.483,1.62,2.976,2.976,0,0,1-.171,3.96Z"
      transform="translate(0 18)"
      fill={color as never}
    />
  </Icon>
);
