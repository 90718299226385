/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { dataLayerEvent } from './AnalyticsUtils';
import { AnalyticsEvent } from './event';
import { useOnScreen, useUnstableRef } from '../Utilities';

export const AnalyticsEventContext = createContext(dataLayerEvent);

export interface AnalyticsOptions {
  once?: boolean;
}

export function useAnalytics({ once }: AnalyticsOptions = { once: true }) {
  const fn = useContext(AnalyticsEventContext);
  const calledRef = useRef(false);
  return useCallback(
    (event: AnalyticsEvent) => {
      if (once && calledRef.current) {
        return;
      }
      calledRef.current = true;
      if (process.env.STORYBOOK) {
        console.log('Analytics Event:', event);
      }
      return fn?.(event);
    },
    [fn, once]
  );
}

export function useAnalyticsEventFnConfigured<T extends unknown[]>(
  eventFn: (...args: T) => AnalyticsEvent,
  options: AnalyticsOptions,
  ...args: Partial<T>
) {
  const fn = useAnalytics(options);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const unknownEvent: (...args: unknown[]) => AnalyticsEvent = eventFn;
  const stableArgs = useUnstableRef<unknown[]>(args);
  return useCallback(
    (...restArgs: unknown[]) => {
      if (!unknownEvent) {
        return;
      }
      return fn(unknownEvent(...stableArgs, ...restArgs));
    },
    [fn, unknownEvent, stableArgs]
  );
}

export function useAnalyticsEventFn<T extends any[]>(
  eventFn: (...args: T) => AnalyticsEvent,
  ...args: Partial<T>
) {
  return useAnalyticsEventFnConfigured(eventFn, { once: true }, ...args);
}

export function useAnalyticsEvent<T extends any[]>(
  eventFn: (...args: T) => AnalyticsEvent,
  ...args: T
) {
  const trigger = useAnalyticsEventFn(eventFn, ...args);
  // No dependencies, force only once trigger
  useEffect(trigger, []);
}

export interface AnalyticsFn<A extends any[]> {
  (...args: A): AnalyticsEvent;
}

export type AnalyticsEventsRecord<
  E extends string,
  Z extends AnalyticsFn<any[]> = AnalyticsFn<any[]>
> = Record<E, Z>;

export function createAnalyticsEvents<
  E extends string,
  Z extends AnalyticsFn<any[]> = AnalyticsFn<any[]>,
  Events extends AnalyticsEventsRecord<E, Z> = AnalyticsEventsRecord<E, Z>
>(events: Events): Events {
  return events;
}

export function useAnalyticsOnDisplay<T extends any[]>(
  ref: RefObject<any>,
  eventFn: (...args: T) => AnalyticsEvent,
  ...args: T
): boolean {
  const fn = useAnalyticsEventFn(eventFn, ...args);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onScreen = useOnScreen(ref);
  const [fired, setFired] = useReducer(() => true, false);
  const firedRef = useRef(fired);
  firedRef.current = fired;
  useEffect(() => {
    if (onScreen && !firedRef.current) {
      fn();
      setFired();
    }
  }, [onScreen, fn, setFired]);
  return fired;
}
