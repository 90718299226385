import React from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export function BellIcon(props: IconProps) {
  return (
    <Icon width={22} height={22} viewBox="0 0 28 33" fill="none" {...props}>
      <path
        d="M23.692 22.846v-8.461c0-5.196-2.775-9.545-7.615-10.696V2.54A2.535 2.535 0 0013.538 0 2.535 2.535 0 0011 2.538V3.69C6.143 4.84 3.385 9.172 3.385 14.385v8.461L0 26.231v1.692h27.077v-1.692l-3.385-3.385zM13.54 33a3.395 3.395 0 003.384-3.385h-6.77A3.384 3.384 0 0013.54 33z"
        fill="#303030"
      />
    </Icon>
  );
}
