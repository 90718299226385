import {
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

export function useIntersectionObserver(
  ref: RefObject<Element>
): { isIntersecting: boolean } {
  const [isPresent, setIsPresent] = useState(!!ref.current);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }),
    []
  );
  // eslint-disable-next-line
  useLayoutEffect(() => {
    setIsPresent(!!ref.current);
  });
  useLayoutEffect(() => {
    if (!ref.current || !isPresent) return setIsIntersecting(false);
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [observer, ref, isPresent]);
  return useMemo(
    () => ({
      isIntersecting,
    }),
    [isIntersecting]
  );
}
