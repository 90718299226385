import React from 'react';

export const Help = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      style={{
        enableBackground: 'new 0 0 18 18',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M9 0C4 0 0 4 0 9s4 9 9 9 9-4 9-9-4-9-9-9zm.9 15.3H8.1v-1.8h1.8v1.8zm1.9-7-.8.9c-.4.5-.8.9-.9 1.5-.1.3-.1.6-.1 1H8.1v-.4c0-.4.1-.8.2-1.2.2-.5.5-1 .9-1.4l1.1-1.1c.4-.4.6-1 .5-1.6-.1-.6-.6-1.2-1.3-1.4-1-.3-1.9.3-2.2 1.1-.1.3-.4.6-.7.6h-.3c-.5 0-.9-.5-.7-1C5.9 4 7.1 3 8.5 2.7c1.4-.2 2.7.5 3.5 1.6 1 1.5.7 3.1-.2 4z"
        fill="currentColor"
      />
    </svg>
  );
};
