import React from 'react';
import { Flex, IconProps, Text } from '@contact/ui-volta';
import { EnhancedText } from '../EnhancedText';

export enum ServiceStatus {
  Connected = 'Connected',
  Pending = 'Pending',
  Inactive = 'Inactive',
}

export interface ServiceContainerProps {
  icon: React.FC<IconProps>;
  title: string;
  subTitle: string;
  status: ServiceStatus;
  showSignUpNow?: boolean;
  signUpLink?: React.ReactElement;
}

export const ServiceContainer = ({
  icon: Icon,
  title,
  subTitle,
  status,
  showSignUpNow,
  signUpLink,
}: ServiceContainerProps) => (
  <Flex>
    <Flex direction="column" flex={1}>
      <Flex>
        <Icon width="auto" height="20px" marginRight={3} />
        <Flex direction="column">
          <Text variant="title">{title}</Text>
          <EnhancedText
            variant="subTitle"
            marginTop={1}
            withTooltip
            tooltipPlacement="top-start"
          >
            {subTitle}
          </EnhancedText>
        </Flex>
      </Flex>
    </Flex>
    <Flex direction="column" flex={1} justifyContent="flex-end">
      <Text variant="label">{!showSignUpNow && 'Status'}</Text>
      {showSignUpNow ? (
        signUpLink
      ) : (
        <Text variant="normal" marginTop={1}>
          {status}
        </Text>
      )}
    </Flex>
  </Flex>
);
