import React from 'react';
import { H1, Box, Flex } from '@contact/ui-volta';
import { useTranslationWithNamespace } from '@contact/react';
import { Sidebar } from '../TrimesterComponents';
import './TrimesterContainer.scss';

export interface TrimesterContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any>;
  namespace: string;
  showSidebar: boolean;
}

export const TrimesterContainer: React.FC<TrimesterContainerProps> = ({
  children,
  namespace,
  showSidebar,
}) => {
  const [t] = useTranslationWithNamespace(namespace);
  const translations: any = t('translations', { returnObjects: true });
  const formTitle = translations.title;

  return (
    <Flex className="container">
      <Flex className="container-title">
        <H1>{formTitle}</H1>
      </Flex>

      <Flex className="trimester-container">
        <Box
          className="trimester-form"
          style={{ width: showSidebar ? 'inherit' : '100%' }}
        >
          {children}
        </Box>
        <Box className="trimester-sidebar">
          {showSidebar && <Sidebar namespace={namespace} />}
        </Box>
      </Flex>
    </Flex>
  );
};
