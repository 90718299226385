import { useTranslationWithNamespace } from '@contact/react';
import React from 'react';
import * as Icons from '../Icons';
import './EmptyPage.scss';
import { EmptyPageDefaultNamespace } from './Translations';
import classnames from 'classnames';
import { HTML } from '../HTML';

export interface EmptyPageProps {
  namespace?: string;
  className?: string;
}

export function EmptyPage({
  namespace = EmptyPageDefaultNamespace,
  className,
  ...props
}: EmptyPageProps) {
  const [t] = useTranslationWithNamespace(namespace);
  const Icon = Icons[t('icon')];
  return (
    <div
      {...props}
      className={classnames(
        'contactUIComponentsEmptyPage_container',
        className
      )}
    >
      <div className="contactUIComponentsEmptyPage_innerContainer">
        <div className="contactUIComponentsEmptyPage_iconContainer">
          {!!Icon && <Icon />}
        </div>
        <span className="contactUIComponentsEmptyPage_title">
          <HTML html={t('title')} />
        </span>
        <div className="contactUIComponentsEmptyPage_divider" />
        <div className="contactUIComponentsEmptyPage_message">
          <HTML html={t('message')} />
        </div>
      </div>
    </div>
  );
}
