import React, { ReactElement, forwardRef, ForwardedRef } from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';

export type InputProps = Omit<ChakraInputProps, 'size' | 'variant'> & {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint
  enterKeyHint?: unknown;
};

export const Input = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>): ReactElement => (
    <ChakraInput {...props} ref={ref} />
  )
);

Input.displayName = 'Input';
